import { TableRow } from "@material-ui/core";
import EditCategoryWindow from "../ModalWindows/EditCategoryWindow";
import { TableCell } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import useStyles from "./Categories.styles";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useState } from "react";

import CommonModal from "../common/modals/CommonModal";
import DeleteEditButton from "../common/buttons/DeleteEditButton";

import { COMMON_MODAL_TEXTS } from "../../constants/dictionary";

const Category = ({ data, handleEdit, handleDelete }) => {
  const theme = useTheme();
  const classes = useStyles();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const toggleOpenEdit = () => setOpenEdit(!openEdit);

  const toggleOpenDelete = () => setOpenDelete(!openDelete);

  return (
    <TableRow>
      <EditCategoryWindow
        handleEdit={handleEdit}
        toggleOpen={toggleOpenEdit}
        open={openEdit}
        currentData={{
          id: data.id,
          name: data.name,
          description: data.description,
        }}
      />
      <CommonModal
        open={openDelete}
        toggleOpen={toggleOpenDelete}
        handleConfirmation={() => handleDelete(data.id)}
        title={COMMON_MODAL_TEXTS.CATEGORY_TITLE}
        content={COMMON_MODAL_TEXTS.GENERAL_CONTENT}
      />
      <TableCell align="center" component="th" scope="row">
        {data.id}
      </TableCell>
      <TableCell align="left">
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid
            item
            xs={mobile ? 12 : 8}
            align={mobile ? "center" : "left"}
            className={mobile && classes.breakline}
          >
            <Typography className={classes.cell}>{data.name}</Typography>
          </Grid>
          {mobile && (
            <DeleteEditButton
              mobile={true}
              editClickHandler={toggleOpenEdit}
              deleteClickHandler={toggleOpenDelete}
            />
          )}
        </Grid>
      </TableCell>
      {!mobile && (
        <TableCell align="left">
          <Typography className={classes.cell}>{data.description}</Typography>
        </TableCell>
      )}
      {!mobile && (
        <DeleteEditButton
          mobile={false}
          editClickHandler={toggleOpenEdit}
          deleteClickHandler={toggleOpenDelete}
        />
      )}
    </TableRow>
  );
};

export default Category;
