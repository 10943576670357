import back from "../../assets/images/background.jpg";

import colors from "../../constants/colors";

const styles = {
  container: {
    display: "flex",
    "background-image": `url(${back})`,
    "min-height": "100%",
    "min-width": "100%",
    position: "absolute",
    "background-size": "cover",
    top: 0,
    left: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    color: colors.white,
  },
};

export default styles;
