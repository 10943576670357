import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import styles from "./CocoonMarketGlobalsScreen.styles.js";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { format } from "date-fns";
import { globalBoxSettings } from "../../utils/newApi";
import GlobalCocoonBoxEditWindow from "../../components/ModalWindows/GlobalCocoonBox/GlobalCocoonBoxEditWindow.js";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar.js";

import { ThemeProvider } from "@material-ui/styles";
import { theme } from "../Discounts/DatePickers.theme.js";

import {
  SERVER_ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
} from "../../constants/dictionary.js";
import CustomButton from "../../components/common/buttons/CustomButton.js";

const useStyles = makeStyles(styles);

const CocoonMarketGlobalsScreen = () => {
  const classes = useStyles();
  const [openEdit, setOpenEdit] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [globals, setGlobals] = useState({});
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [year, setYear] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  useEffect(() => {
    setIsLoading(true);

    globalBoxSettings
      .getSettings()
      .then((res) => {
        setGlobals(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_DATA);
        setIsLoading(false);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {isLoading ? (
        <div className={classes.loadingRoot}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div className={classes.root}>
          <Grid container spacing={2} direction="rows">
            <Grid item xs={matches ? 12 : 7}>
              <Grid
                container
                spacing={2}
                justify="center"
                direction="rows"
                alignItems="center"
              ></Grid>
              <Grid item xs={12} align="center">
                <Typography className={classes.header}>
                  Globalne ustawienia
                </Typography>
              </Grid>
              <Grid xs={12} align="center">
                <List className={classes.rootList}>
                  <Grid item xs={12}>
                    <Box className={classes.headerMargin}>
                      <Typography className={classes.header}>
                        Ustawienia Uli:
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary="Cena za UL"
                      secondary={
                        <Typography className={classes.listText}>
                          {globals?.hivePrice
                            ? `${globals.hivePrice} zł`
                            : "Brak"}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary="Cena za UL z kokonami"
                      secondary={
                        <Typography className={classes.listText}>
                          {globals?.hiveAndCocoonPrice
                            ? `${globals.hiveAndCocoonPrice} zł`
                            : "Brak"}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary="Maksymalna ilość uli"
                      secondary={
                        <Typography className={classes.listText}>
                          {globals?.hiveMaxShipmentSize
                            ? globals.hiveMaxShipmentSize
                            : "Brak"}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <Grid item xs={12}>
                    <Box className={classes.headerMargin}>
                      <Typography className={classes.header}>
                        Ustawienia dostaw:
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary="Cena dostawy"
                      secondary={
                        <Typography className={classes.listText}>
                          {globals?.courierPrice
                            ? `${globals.courierPrice} zł`
                            : "Brak"}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary="Cena zamówienia z darmową dostawą"
                      secondary={
                        <Typography className={classes.listText}>
                          {globals?.freeDeliveryMinimumOrderPrice
                            ? `${globals.freeDeliveryMinimumOrderPrice} zł`
                            : "Brak"}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </List>
              </Grid>
            </Grid>
            <Grid item xs={matches ? 12 : 5} className={classes.greyBox}>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                direction="row"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <Box className={classes.headerMargin}>
                    <Typography className={classes.header}>
                      Ustawienia kokonów:
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <List className={classes.rootList}>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="Maksymalna cena użytkownika"
                        secondary={
                          <Typography className={classes.listText}>
                            {globals?.cocoonBoxMaxUserPrice
                              ? `${globals.cocoonBoxMaxUserPrice} zł`
                              : "Brak"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="Cena sprzedaży pudełka"
                        secondary={
                          <Typography className={classes.listText}>
                            {globals?.cocoonBoxPrice
                              ? `${globals.cocoonBoxPrice} zł`
                              : "Brak"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="Data zwrotu pudełek"
                        secondary={
                          <Typography className={classes.listText}>
                            {globals && globals.cocoonReturnDate
                              ? format(
                                  new Date(globals.cocoonReturnDate),
                                  "dd/MM/yyyy"
                                )
                              : "Brak"}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.secondHeaderMargin}>
                    <Typography className={classes.header}>
                      Ustawienia termometrów:
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <List className={classes.rootList}>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="Cena termometru"
                        secondary={
                          <Typography className={classes.listText}>
                            {globals?.thermometerPrice
                              ? `${globals.thermometerPrice} zł`
                              : "Brak"}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </List>
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <Box className={classes.buttonBox}>
                      <CustomButton
                        variant={BUTTON_VARIANTS.WHITE}
                        size={BUTTON_SIZES.LARGE}
                        clickHandler={() => setOpenEdit(true)}
                        text="Edytuj"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      <GlobalCocoonBoxEditWindow
        isOpen={openEdit}
        close={() => setOpenEdit(false)}
        data={globals}
        handleError={handleError}
        yearDates={year}
      />
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </ThemeProvider>
  );
};

export default CocoonMarketGlobalsScreen;
