import colors from "../../constants/colors";

const styles = (theme) => {
  return {
    row: {
      transform: "all 0.25s ease-in-out",
      "&:hover": {
        transform: "scale(1.01)",
        cursor: "pointer",
      },
    },
    connectedTo: {
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      "border-bottom": "4px solid",
      width: "35%",
    },
    users: {
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      "border-bottom": "4px solid",
      width: "20%",
    },
    buttons: {
      width: "20%",
      "border-bottom": "4px solid",
    },
    cell: {
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
    },
    box: {
      marginLeft: "5%",
      marginRight: "5%",
    },
    connectedToRes: {
      "border-bottom": "3px solid",
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      minWidth: "200px",
    },
    usersRes: {
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      "border-bottom": "3px solid",
      minWidth: "150px",
    },
    buttonsRes: {
      "border-bottom": "3px solid",
      minWidth: "150px",
    },
    headerCell: {
      display: "flex",
    },
    headerCellRight: {
      marginLeft: "2px",
    },
    delete: {
      color: colors.htmlRed,
    },
    deleteText: {
      color: colors.htmlRed,
      "font-family": "Montserrat",
    },
    breakline: {
      "border-bottom": "1px solid grey",
    },
    text: {
      "font-size": "15px",
      "font-family": "Montserrat",
      "font-weight": "bold",
    },
    icon: {
      height: 40,
      width: 40,
    },

    caption: {
      fontSize: "15px",
      "font-family": "Montserrat",
    },
    toolbar: {
      "& > p:nth-of-type(2)": {
        fontSize: "15px",
        color: colors.black,
        "font-family": "Montserrat",
        fontWeight: 600,
      },
    },
  };
};

export default styles;
