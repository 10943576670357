import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Box,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import useForm from "../../hooks/useForm";
import { auth, users } from "../../utils/newApi";
import StyledFormTextField from "../common/textFields/StylesFormTextField";
import styles from "./LoginForm.styles";
import { useHistory } from "react-router";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Redirect } from "react-router-dom";
import tokenUtils from "../../utils/tokenUtils";
import { useEffect } from "react";
import CustomButton from "../common/buttons/CustomButton";

import { BUTTON_VARIANTS, BUTTON_SIZES } from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const LoginForm = () => {
  const history = useHistory();
  const [loginError, setLoginError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const { handleChange, values } = useForm({ login: "", password: "" });
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    users
      .whoami()
      .then(() => {
        setAuthorized(true);
      })
      .catch(() => {
        setAuthorized(false);
      });

    return function cleanup() {
      setAuthorized(false);
      setRedirect(false);
    };
  }, []);

  const submit = async (e) => {
    e.preventDefault();
    auth
      .login(values.login, values.password)
      .then((res) => {
        const { admin, jwt } = res.data;

        if (!admin) {
          window.location.reload();
          return;
        }

        tokenUtils.setToken(jwt);
        setRedirect(true);
      })
      .catch(() => {
        setLoginError(true);
        setPasswordError(true);
        setErrorText("Niepoprawny Login lub Hasło");
      });
  };

  return (
    <form autoComplete="off">
      <Box className={classes.root}>
        <Grid container spacing={0} justify="center" alignItems="center">
          <Grid item xs={12}>
            <Typography className={classes.loginText} variant="h4">
              Zaloguj się
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.box}>
              <StyledFormTextField
                fullWidth={true}
                InputLabelProps={{ classes: { root: classes.label } }}
                error={loginError}
                name="login"
                onChange={handleChange("login")}
                label="NAZWA UŻYTKOWNIKA LUB EMAIL"
                variant="outlined"
                required
                shrink="true"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.box}>
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                fullWidth={true}
                shrink="true"
                error={passwordError}
                name="password"
                onChange={handleChange("password")}
                label="HASŁO"
                variant="outlined"
                type={isVisible ? "text" : "password"}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {isVisible ? (
                        <VisibilityOffIcon
                          className={classes.cursors}
                          onClick={() => {
                            setIsVisible(!isVisible);
                          }}
                        />
                      ) : (
                        <VisibilityIcon
                          className={classes.cursors}
                          onClick={() => {
                            setIsVisible(!isVisible);
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.box2}>
              <Typography className={classes.error}>
                {loginError && errorText}
              </Typography>
              <CustomButton
                variant={BUTTON_VARIANTS.RED}
                size={BUTTON_SIZES.MEDIUM}
                clickHandler={submit}
                text="Zaloguj się"
                type="submit"
              />
            </Box>
          </Grid>
          <Box className={classes.box3}>
            <Grid
              container
              spacing={2}
              justify="center"
              direction="row"
              alignItems="center"
            >
              <Grid item align="right">
                <Box>
                  <Typography
                    noWrap
                    variant="overline"
                    className={classes.cursors}
                    onClick={() => history.push("/reset")}
                  >
                    Zapomniałeś hasła ?
                  </Typography>
                </Box>
              </Grid>
              <Grid item align="left">
                <Box>
                  <Typography
                    className={classes.cursors}
                    variant="subtitle1"
                    onClick={() => history.push("/register")}
                  >
                    Rejestracja
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
      {(redirect || authorized) && <Redirect to="/dashboard" />}
    </form>
  );
};

export default LoginForm;
