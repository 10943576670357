import { Container, makeStyles, Grid } from "@material-ui/core";

import React, { useEffect, useState } from "react";
import styles from "./Discounts.styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { shop } from "../../utils/api";
import NewDiscountModal from "./NewDiscountModal";
import EditDiscountModal from "./EditDiscountModal";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "./Discounts.theme";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DiscountTable } from "./DiscountTable";
import StyledFormTextField from "../../components/common/textFields/StyledFormTextField";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../components/common/pagination/TablePaginationActions";
import CommonModal from "../../components/common/modals/CommonModal";
import ListHeader from "../../components/common/ListHeader";

import {
  SERVER_ERROR_MESSAGES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const Discounts = () => {
  const classes = useStyles();

  const [newDiscountOpen, setNewDiscountOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState([]);
  const [apiResponseCopy, setApiResponseCopy] = useState([]);
  const [editDiscountOpen, setEditDiscountOpen] = useState(false);
  const [removeDiscountOpen, setRemoveDiscountOpen] = useState(false);
  const [currentlyEdited, setCurrentlyEdited] = useState({});
  const [reloadTrigger, setReload] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  const openEditModal = (item) => {
    setEditDiscountOpen(true);
    setCurrentlyEdited(item);
  };

  const handleDelete = (item) => {
    setRemoveDiscountOpen(true);
    setCurrentlyEdited(item);
  };

  const reload = () => setReload((prev) => !prev.reloadTrigger);

  useEffect(() => {
    shop.articles
      .all()
      .then((products) => {
        let origin = new URL(products.config.url).origin;
        products.data.articles.map((article) => {
          let repairedImages = [];
          article.images.map((image) => {
            let pathname = new URL(image).pathname;
            repairedImages.push(origin + pathname);
          });
          article.images = repairedImages;
        });
        const sorted = products.data.articles
          ? products.data.articles.sort((a, b) => b.id - a.id)
          : [];
        setApiResponse(sorted);
        setApiResponseCopy(sorted);
        setIsLoaded(true);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_DISCOUNTS);
      });
  }, [reloadTrigger]);

  const handleChange = (e) => {
    if (e.target.value !== "") {
      const res = apiResponseCopy.filter((article) => {
        return (
          article.name &&
          article.name.toLowerCase().startsWith(e.target.value.toLowerCase())
        );
      });
      setApiResponse(res);
      setPage(0);
    } else {
      setApiResponse(apiResponseCopy);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const removeDiscount = (item) => {
    shop.articles
      .delete(item.id)
      .then((response) =>
        setApiResponse(
          apiResponse.filter((discount) => discount.id !== item.id)
        )
      )
      .catch((error) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_DISCOUNT);
      });
  };

  return isLoaded ? (
    <Container className={classes.list}>
      <ThemeProvider theme={theme}>
        <Grid container>
          <ListHeader
            variant="title-add-search"
            title="Ekran promocji"
            handleAdd={() => setNewDiscountOpen(true)}
            searchComponent={
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                name="name"
                fullWidth={matches}
                label="Szukaj produktu"
                variant="outlined"
                shrink="true"
                onChange={(e) => handleChange(e)}
              />
            }
          />
          <Grid container>
            <Grid item xs={12} align="center">
              <DiscountTable
                products={apiResponse}
                openeditmodal={openEditModal}
                setedited={setCurrentlyEdited}
                handledelete={handleDelete}
                page={page}
                rowsPerPage={rowsPerPage}
              />
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 50]}
                component="div"
                count={apiResponse.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                classes={{
                  toolbar: classes.toolbar,
                  caption: classes.caption,
                }}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Ilość wierszy na stronę"}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}–${to} z ${
                    count !== -1 ? count : `więcej niż ${to}`
                  }`;
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <NewDiscountModal
          open={newDiscountOpen}
          handleError={handleError}
          defaultvalues={{
            name: "",
            discountPrice: 0,
            discountEndTime: "",
          }}
          handleClose={() => {
            setNewDiscountOpen(false);
          }}
          reload={reload}
          modalname="Dodawanie nowej promocji"
        />
        <EditDiscountModal
          handleError={handleError}
          open={editDiscountOpen}
          handleClose={() => {
            setEditDiscountOpen(false);
          }}
          item={currentlyEdited}
          discountStartTime={currentlyEdited.discountStartTime}
          discountEndTime={currentlyEdited.discountEndTime}
          modalname="Edycja istniejącej promocji"
        />

        <CommonModal
          open={removeDiscountOpen}
          toggleOpen={() => setRemoveDiscountOpen(false)}
          handleConfirmation={() => removeDiscount(currentlyEdited)}
          title={COMMON_MODAL_TEXTS.PRODUCT_TITLE}
          content={COMMON_MODAL_TEXTS.PRODUCT_CONTENT}
        />
        <ErrorSnackBar
          open={error}
          text={errorText}
          close={() => setError(false)}
        />
      </ThemeProvider>
    </Container>
  ) : (
    <div className={classes.container}>
      <CircularProgress color="secondary" className={classes.loader} />
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </div>
  );
};

export default Discounts;
