import { makeStyles } from "@material-ui/core";

import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  container: {
    flexGrow: 1,
    overflow: "auto",
  },
  table: {
    width: "100%",
  },
  id: {
    width: "10%",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  name: {
    width: "20%",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  description: {
    width: "55%",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  buttons: {
    paddingRight: "13%",
    paddingBottom: "3%",
    paddingTop: "3%",
    "border-bottom": "4px solid",
  },
  cell: {
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
  },
  nameCell: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  headerCell: {
    display: "flex",
  },
  headerCellRight: {
    marginLeft: "2px",
  },
  breakline: {
    "border-bottom": "1px solid grey",
  },
  containerLoad: {
    display: "flex",
    "flex-direction": "row",
    "justify-content": "center",
    "align-items": "center",
    height: "100%",
    width: "100%",
  },
  loader: {
    "justify-content": "center",
    "align-items": "center",
  },
  caption: {
    fontSize: "15px",
    "font-family": "Montserrat",
  },
  toolbar: {
    "& > p:nth-of-type(2)": {
      fontSize: "15px",
      color: colors.black,
      "font-family": "Montserrat",
      fontWeight: 600,
    },
  },
}));

export default useStyles;
