import React, { useEffect, useState } from "react";
import Chip from "@material-ui/core/Chip";
import { Typography, Grid, makeStyles, Box } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import CommonModal from "../../common/modals/CommonModal";
import StyledFormTextField from "../../common/textFields/StyledFormTextField";
import StyledDescriptionTextField from "../../common/textFields/StyledDescriptionTextField";

import useForm from "../../../hooks/useForm";
import { cultivations, plantProtectionProducts } from "../../../utils/newApi";
import styles from "./ProductsToProtectPlantsModal.styles";
import {
  SERVER_ERROR_MESSAGES,
  MODAL_TYPE,
  COMMON_MODAL_TEXTS,
} from "../../../constants/dictionary";

const useStyles = makeStyles(styles);

const NewProductToProtectPlantsWindow = ({ isOpen, close, handleError }) => {
  const classes = useStyles();
  const [substancesArray, setSubstancesArray] = useState([]);
  const [selectedSubstancesArray, setSelectedSubstancesArray] = useState([]);
  const { handleChange, values } = useForm({
    name: "",
    description: "",
  });
  const [emptyField, setEmptyField] = useState(false);

  useEffect(() => {
    cultivations.activeSubstances
      .all()
      .then((res) => {
        setSubstancesArray(res.data);
      })
      .catch(() => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_SUBSTANCE);
      });
  }, []);

  const selectChange = (e, n) => {
    setSelectedSubstancesArray(n);
  };

  const submit = (e) => {
    e.preventDefault();
    const dataToSend = {
      name: values.name,
      description: values.description,
      activeSubstanceIdList: selectedSubstancesArray.map((ss) => ss.id),
    };

    if (values.name === "") {
      setEmptyField(true);
      return;
    }
    plantProtectionProducts
      .addProduct(dataToSend)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_ADD_RESOURCE);
      });
  };

  return (
    <CommonModal
      open={isOpen}
      toggleOpen={close}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.ADD_PRODUCT_TITLE}
      type={MODAL_TYPE.ADD}
    >
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledFormTextField
            InputLabelProps={{ classes: { root: classes.label } }}
            fullWidth={true}
            name="name"
            label="Wpisz nazwę środka"
            variant="outlined"
            required
            shrink="true"
            onChange={handleChange("name")}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.box}>
          <Autocomplete
            multiple
            options={substancesArray}
            onChange={selectChange}
            getOptionLabel={(option) => option.name}
            renderTags={(tagValue) =>
              tagValue.map((option) => (
                <Chip
                  variant="outlined"
                  color="primary"
                  className={classes.chip}
                  label={option.name}
                />
              ))
            }
            renderInput={(params) => (
              <StyledFormTextField
                {...params}
                InputLabelProps={{ classes: { root: classes.label } }}
                name="name"
                fullWidth={true}
                label="Substancje czynne"
                variant="outlined"
                shrink="true"
              />
            )}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledDescriptionTextField
            InputLabelProps={{ classes: { root: classes.label } }}
            fullWidth={true}
            label="Opis środka"
            variant="outlined"
            rows={4}
            shrink="true"
            multiline={true}
            name="description"
            onChange={handleChange("description")}
          />
        </Box>
      </Grid>
      {emptyField && (
        <Grid item xs={12} align="center">
          <Typography className={classes.error}>
            Nie wypełniono obowiązkowych pól
          </Typography>
        </Grid>
      )}
    </CommonModal>
  );
};

export default NewProductToProtectPlantsWindow;
