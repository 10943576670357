import React from "react";
import Modal from "@material-ui/core/Modal";
import useForm from "../../hooks/useForm";
import useStyles from "./Modal.styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import StyledFormTextField from "../common/textFields/StyledFormTextField";
import CustomButton from "../common/buttons/CustomButton";

import { BUTTON_VARIANTS, BUTTON_SIZES } from "../../constants/dictionary";

const EditCategoryWindow = ({ handleEdit, open, toggleOpen, currentData }) => {
  const classes = useStyles();
  const { handleChange, values } = useForm({
    name: currentData.name,
    description: currentData.description,
  });
  const [emptyField, setEmptyField] = React.useState(false);

  return (
    <Modal open={open} onClose={toggleOpen}>
      <div className={classes.paper}>
        <Grid
          container
          spacing={2}
          direction="column"
          justify="center"
          align="center"
        >
          <Grid item xs={12}>
            <Typography className={classes.text} variant="h5">
              Edytuj kategorię
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StyledFormTextField
              label="Zmień nazwę kategorii"
              placeholder={currentData.name}
              variant="outlined"
              fullWidth
              value={values.name}
              onChange={handleChange("name")}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledFormTextField
              label="Zmień opis kategorii"
              placeholder={currentData.description}
              variant="outlined"
              fullWidth
              value={values.description}
              onChange={handleChange("description")}
            />
          </Grid>
          {emptyField && (
            <Grid item xs={12}>
              <Typography className={classes.error}>
                Należy podać nazwę kategorii
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} container justify="space-between" align="center">
            <Grid item xs={6}>
              <CustomButton
                variant={BUTTON_VARIANTS.GREEN}
                size={BUTTON_SIZES.MEDIUM}
                clickHandler={() => {
                  if (values.name === "") {
                    setEmptyField(true);
                    return;
                  }
                  handleEdit(currentData.id, {
                    name: values.name,
                    description: values.description,
                  });
                  toggleOpen();
                }}
                text="Edytuj"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomButton
                variant={BUTTON_VARIANTS.RED}
                size={BUTTON_SIZES.MEDIUM}
                clickHandler={toggleOpen}
                text="Anuluj"
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default EditCategoryWindow;
