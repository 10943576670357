import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import { Typography, Paper, Grid, makeStyles, Box } from "@material-ui/core";
import styles from "./AddServiceModal.styles";
import { services } from "../../../utils/api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import StyledFormTextField from "../../common/textFields/StyledFormTextField";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import {
  SERVER_ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
} from "../../../constants/dictionary";
import CustomButton from "../../common/buttons/CustomButton";

const useStyles = makeStyles(styles);

const AddServiceModal = ({ isOpen, close, handleError, usersList }) => {
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = useState({});
  const [date, setDate] = useState(new Date());

  const submit = () => {
    const dateToSend = new Date(date);

    let serviceToSend = {
      maintenance: {
        date: dateToSend.toISOString(),
        done: false,
        cocoonBoxes: null,
      },
    };

    services
      .add(selectedUser.retailId, serviceToSend)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_ADD_SERVICE);
      });
  };

  return (
    <Modal open={isOpen}>
      <Paper className={classes.root}>
        <div className={classes.scroll}>
          <Grid container spacing={4} justify="center" direction="column">
            <Grid item xs={12}>
              <Typography className={classes.header}>
                Dodaj nowy serwis
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container direction="row" justify="center">
                    <Grid item xs={12} md={6}>
                      <Box className={classes.box}>
                        <Autocomplete
                          options={usersList}
                          onChange={(e, n) => setSelectedUser(n)}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <StyledFormTextField
                              {...params}
                              InputLabelProps={{
                                classes: { root: classes.label },
                              }}
                              name="name"
                              fullWidth={true}
                              label="Użytkownik"
                              variant="outlined"
                              shrink="true"
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box className={classes.box}>
                        <KeyboardDatePicker
                          disableToolbar
                          fullWidth={true}
                          variant="outline"
                          format="MM/dd/yyyy"
                          margin="normal"
                          label="Data serwisu"
                          value={date}
                          onChange={(date) => setDate(date)}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <Grid container spacing={2} direction="row" justify="center">
                  <Grid item xs={6}>
                    <Box className={classes.box}>
                      <CustomButton
                        variant={BUTTON_VARIANTS.GREEN}
                        size={BUTTON_SIZES.LARGE}
                        clickHandler={submit}
                        text="Dodaj"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={classes.box}>
                      <CustomButton
                        variant={BUTTON_VARIANTS.RED}
                        size={BUTTON_SIZES.LARGE}
                        clickHandler={close}
                        text="Anuluj"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default AddServiceModal;
