import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Chip from "@material-ui/core/Chip";
import { Typography, Paper, Grid, makeStyles, Box } from "@material-ui/core";
import styles from "./NewProductWindow.styles";
import FileUploader from "../FileUploader/FileUploader";
import StyledFormTextField from "../common/textFields/StyledFormTextField";
import StyledCategorySelect from "../common/Selects/StyledCategorySelect";
import { shop, cultivations } from "../../utils/api";
import Picture from "../PictureFile.js/Picture";
import StyledDescriptionTextField from "../common/textFields/StyledDescriptionTextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {
  validatePrice,
  changePriceToInt,
  displayPrice,
} from "../../utils/price";
import Autocomplete from "@material-ui/lab/Autocomplete";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { Avatar } from "@material-ui/core";
import LocalFloristIcon from "@material-ui/icons/LocalFlorist";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  SERVER_ERROR_MESSAGES,
  ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
} from "../../constants/dictionary";
import CustomButton from "../common/buttons/CustomButton";

const useStyles = makeStyles(styles);

const EditProductsWindow = ({ isOpen, close, data, handleError }) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [categorieArray, setCategories] = useState([]);
  const [selectedCategoriesArray, setSelectedCategories] = useState([]);
  const [imagesSrcArray, setImageSrcArray] = useState([]);
  const [price, setPrice] = useState(0);
  const [unmarginedPrice, setUnmarginedPrice] = useState(0);
  const [amount, setAmount] = useState(0);
  const [switched, setSwitch] = useState(false);
  const [dose, setDose] = useState(null);
  const [emptyField, setEmptyField] = useState(false);
  const [capacity, setCapacity] = useState("");
  const [quantityPerH, setQuantityPerH] = useState(0);
  const [error, setError] = useState("");
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [devStages, setDevStages] = useState([]);
  const [selectedDev, setSelectedDev] = useState({});
  const [suggestedAmounts, setSuggestedAmount] = useState([]);
  const [label, setLabel] = useState("");

  useEffect(() => {
    setName(data.name);
    setPrice(displayPrice(data.price));
    setUnmarginedPrice(displayPrice(data.unmarginedPrice));
    setDescription(data.description);
    setAmount(data.availableAmount);
    setCapacity(data.capacity / 1000);
    setSelectedCategories(data.categories);
    setImageSrcArray(data.images);
    setSuggestedAmount(data.suggestedAmounts);
    setLabel(data.labelURL);
    setSwitch(
      (data && data.suggestedAmounts && data.suggestedAmounts.length > 0) ||
        (data && data.capacity > 0) ||
        data.labelURL !== ""
        ? true
        : false
    );
    setQuantityPerH(
      data && data.amountPerHectare ? data.amountPerHectare : null
    );
  }, [data]);

  useEffect(() => {
    cultivations.types
      .distinct()
      .then((res) => {
        setTypes(res.data.distinctNames);
      })
      .catch((err) => {
        handleError(ERROR_MESSAGES.COULD_NOT_FIND_CROPS_TYPES);
      });
    shop.categories
      .all()
      .then((category) => {
        const {
          data: { categories },
        } = category;
        setCategories(categories);
      })
      .catch((err) => {
        handleError(ERROR_MESSAGES.COULD_NOT_FIND_CATEGORIES_FOR_PRODUCT);
      });
  }, []);

  const handleFile = (file) => {
    const data = new FormData();
    data.append("file", file);
    shop.files
      .send(data)
      .then((res) => {
        setImageSrcArray([
          ...imagesSrcArray,
          `${res.config.url}/${res.data.address}`,
        ]);
      })
      .catch((err) => {
        handleError(ERROR_MESSAGES.COULD_NOT_UPLOAD_PHOTO);
      });
  };

  const handleSwitch = (event) => {
    setSwitch(event.target.checked);
  };

  const remove = (del) => {
    const filtered = imagesSrcArray.filter((ele) => {
      return ele !== del;
    });
    setImageSrcArray(filtered);
  };

  const selectChange = (e) => {
    const selected = e.target.value;
    const idToRemove = [];
    selected.forEach((ele1) => {
      let counter = 0;
      selected.forEach((ele2) => {
        if (ele1.id === ele2.id) {
          counter++;
          if (counter === 2) {
            idToRemove.push(ele1.id);
          }
        }
      });
    });
    const final = selected.filter((value) => {
      return value.id !== idToRemove[0];
    });

    setSelectedCategories(final);
  };

  const addSuggestedDose = () => {
    if (
      selectedType !== "" &&
      Object.keys(selectedDev).length > 0 &&
      quantityPerH != 0
    ) {
      const suggestedAmount = {
        cultivationTypeName: selectedType,
        developmentStageName: selectedDev.name,
        amountPerHectare: parseInt(
          quantityPerH.toString().replace(",", ".") * 1000,
          10
        ),
      };
      setSuggestedAmount((data) => [...data, suggestedAmount]);
      setSelectedDev({});
      setQuantityPerH(0);
    }
  };

  const handleLabel = (file) => {
    const data = new FormData();
    data.append("file", file);
    shop.files
      .send(data)
      .then((res) => {
        if (Object.keys(res.data).length > 0)
          setLabel(`${res.config.url}/${res.data.address}`);
        else setLabel("");
      })
      .catch((err) => {
        handleError(ERROR_MESSAGES.COULD_NOT_UPLOAD_PHOTO);
      });
  };

  const submit = (e) => {
    e.preventDefault();
    if (
      name === "" ||
      description === "" ||
      price === "" ||
      amount === "" ||
      unmarginedPrice === "" ||
      price === ""
    ) {
      setEmptyField(true);
      setTimeout(() => {
        setEmptyField(false);
      }, 2000);
      return;
    }

    if (
      validatePrice(price) === false ||
      validatePrice(unmarginedPrice) === false
    ) {
      setError(" Podana cena jest nieprawidłowa");
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }

    const dataToEdit = {
      name: name,
      description: description,
      categories: selectedCategoriesArray,
      price: changePriceToInt(price),
      images: imagesSrcArray,
      availableAmount: parseInt(amount, 10),
      capacity: switched
        ? parseInt(capacity.toString().replace(",", ".") * 1000, 10)
        : null,
      unmarginedPrice: changePriceToInt(unmarginedPrice),
      discountEndTime: data.discountEndTime,
      discountPrice: data.discountPrice,
      suggestedAmounts: switched ? suggestedAmounts : [],
      labelURL: switched ? label : "",
    };
    shop.articles
      .edit(data.id, dataToEdit)
      .then((res) => {
        window.location.reload();
        close();
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_EDIT_PRODUCT);
      });
  };

  return (
    <Modal open={isOpen}>
      <Paper className={classes.root}>
        <div className={classes.scroll}>
          <Grid container spacing={4} justify="center" direction="column">
            <Grid item xs={12}>
              <Typography className={classes.header}>Edytuj Produkt</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <StyledFormTextField
                  fullWidth={true}
                  variant="outlined"
                  required
                  label="Nazwa"
                  shrink="true"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <Grid container spacing={4} direction="row" justify="center">
                  <Grid item xs={12} md={6}>
                    <StyledCategorySelect
                      InputLabelProps={{ classes: { root: classes.label } }}
                      name="category"
                      fullWidth={true}
                      label="Kategoria"
                      variant="outlined"
                      shrink="true"
                      required
                      id="select"
                      select
                      onChange={selectChange}
                      value={selectedCategoriesArray}
                      SelectProps={{
                        multiple: true,
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        },
                        renderValue: (selected) => (
                          <div className={classes.chips}>
                            {selected.map((value) => (
                              <Chip
                                key={value.id}
                                label={value.name}
                                className={classes.chip}
                              />
                            ))}
                          </div>
                        ),
                      }}
                    >
                      {categorieArray.map((category) => {
                        return (
                          <ListItem
                            key={category.id}
                            value={category}
                            className={classes.cursor}
                          >
                            {category.name}
                          </ListItem>
                        );
                      })}
                    </StyledCategorySelect>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledFormTextField
                      InputLabelProps={{ classes: { root: classes.label } }}
                      fullWidth={true}
                      label="Cena produktu"
                      variant="outlined"
                      required
                      shrink="true"
                      multiline={true}
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box className={classes.box}>
                <StyledFormTextField
                  InputLabelProps={{ classes: { root: classes.label } }}
                  fullWidth={true}
                  label="Cena hurtowa produktu"
                  variant="outlined"
                  required
                  shrink="true"
                  multiline={true}
                  value={unmarginedPrice}
                  onChange={(e) => setUnmarginedPrice(e.target.value)}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box className={classes.box}>
                <Grid container spacing={4} direction="row" justify="center">
                  <Grid item xs={12} md={6}>
                    <StyledFormTextField
                      InputLabelProps={{ classes: { root: classes.label } }}
                      fullWidth={true}
                      name="amount"
                      label="Ilość"
                      variant="outlined"
                      required
                      shrink="true"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FileUploader handleFile={handleFile} />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} align="center">
              <Box className={classes.box}>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {imagesSrcArray
                    ? imagesSrcArray.map((ele, id) => (
                        <Picture
                          key={id}
                          pictureSrc={ele}
                          remove={remove}
                          renderIcon
                        />
                      ))
                    : null}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <StyledDescriptionTextField
                  InputLabelProps={{ classes: { root: classes.label } }}
                  fullWidth={true}
                  label="Opis produktu"
                  variant="outlined"
                  required
                  rows={4}
                  shrink="true"
                  multiline={true}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <Grid
                  container
                  spacing={4}
                  direction="row"
                  justify="center"
                  alignContent="center"
                  alignItems="center"
                >
                  {switched && (
                    <Grid item xs={12}>
                      <Box className={classes.box}>
                        <Grid
                          container
                          spacing={4}
                          direction="row"
                          justify="center"
                        >
                          <Grid item xs={12}>
                            <StyledFormTextField
                              InputLabelProps={{
                                classes: { root: classes.label },
                              }}
                              fullWidth={true}
                              value={capacity}
                              label="Pojemność"
                              variant="outlined"
                              shrink="true"
                              onChange={(e) => setCapacity(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FileUploader
                              handleFile={handleLabel}
                              name="Dodaj etykiete"
                            />
                          </Grid>
                          <Grid item xs={12} align="center">
                            <Box className={classes.box}>
                              <Grid
                                container
                                spacing={0}
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                {label !== "" ? (
                                  <Picture
                                    pictureSrc={label}
                                    customBreak={[12, 6, 6, 6]}
                                    remove={() => setLabel("")}
                                    renderIcon
                                  />
                                ) : null}
                              </Grid>
                            </Box>
                          </Grid>
                          {suggestedAmounts && suggestedAmounts.length > 0 && (
                            <Grid
                              item
                              xs={12}
                              align="center"
                              className={classes.amountsBox}
                            >
                              <Typography className={classes.listText}>
                                Lista proponowanych dawek
                              </Typography>
                            </Grid>
                          )}
                          {suggestedAmounts &&
                            suggestedAmounts.map((item, idx) => (
                              <List className={classes.rootList}>
                                <ListItem>
                                  <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                      <LocalFloristIcon
                                        className={classes.icon}
                                      />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary="Typ"
                                    secondary={
                                      <Typography className={classes.listText}>
                                        {item.cultivationTypeName}
                                      </Typography>
                                    }
                                  />
                                  <ListItemText
                                    primary="Faza rozwoju"
                                    secondary={
                                      <Typography className={classes.listText}>
                                        {item.developmentStageName}
                                      </Typography>
                                    }
                                  />
                                  <ListItemText
                                    primary="Ilość na hektar [l lub kg]"
                                    secondary={
                                      <Typography className={classes.listText}>
                                        {item.amountPerHectare / 1000}
                                      </Typography>
                                    }
                                  />
                                  <DeleteIcon
                                    className={classes.delete}
                                    onClick={() => {
                                      let filtered = suggestedAmounts.filter(
                                        (e, i) => {
                                          if (i === idx) return false;
                                          else return true;
                                        }
                                      );
                                      setSuggestedAmount(filtered);
                                    }}
                                  />
                                </ListItem>
                                <Divider />
                              </List>
                            ))}
                          <Grid item xs={12} aling="center">
                            <Typography className={classes.doseText}>
                              Dodaj sugerowane dawki (Typ, Faza, Ilość na
                              hektar)
                            </Typography>
                          </Grid>
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            alignContent="center"
                            alignItems="center"
                            spacing={4}
                          >
                            <Grid item xs={6}>
                              <Autocomplete
                                getOptionLabel={(option) => option}
                                options={types}
                                onChange={(e, val) => {
                                  setSelectedType(val);
                                  setSelectedDev({});
                                  setDevStages([]);
                                  cultivations.stages
                                    .byTypeName(val)
                                    .then((res) => {
                                      const key = "name";

                                      let uniqueNames = [
                                        ...new Map(
                                          res.data.developmentStages.map(
                                            (item) => [item[key], item]
                                          )
                                        ).values(),
                                      ];
                                      setDevStages(uniqueNames);
                                    })
                                    .catch((err) => {
                                      handleError(
                                        SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_DEVELOP_PHASES
                                      );
                                    });
                                }}
                                renderInput={(params) => (
                                  <StyledFormTextField
                                    {...params}
                                    InputLabelProps={{
                                      classes: { root: classes.label },
                                    }}
                                    label="Typ uprawy"
                                    fullWidth={true}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                getOptionLabel={(option) => option.name}
                                options={devStages}
                                value={selectedDev}
                                onChange={(e, val) => {
                                  if (val) setSelectedDev(val);
                                }}
                                renderInput={(params) => (
                                  <StyledFormTextField
                                    {...params}
                                    InputLabelProps={{
                                      classes: { root: classes.label },
                                    }}
                                    label="Faza rozwoju"
                                    fullWidth={true}
                                    variant="outlined"
                                    shrink="true"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <StyledFormTextField
                                InputLabelProps={{
                                  classes: { root: classes.label },
                                }}
                                fullWidth={true}
                                value={quantityPerH}
                                label="Ilość na Hektar"
                                variant="outlined"
                                shrink="true"
                                onChange={(e) =>
                                  setQuantityPerH(e.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Box className={classes.buttonBox}>
                                <CustomButton
                                  variant={BUTTON_VARIANTS.GREEN}
                                  size={BUTTON_SIZES.LARGE}
                                  clickHandler={addSuggestedDose}
                                  text="Dodaj dawkę"
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={6} align="center">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={switched}
                          onChange={handleSwitch}
                          color="primary"
                        />
                      }
                      label="Produkt dawkowany"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {emptyField && (
              <Grid item xs={12} align="center">
                <Typography className={classes.error}>
                  Nie wypełniono obowiązkowych pól
                </Typography>
              </Grid>
            )}
            {error !== "" && (
              <Grid item xs={12} align="center">
                <Typography className={classes.error}>{error}</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box className={classes.box}>
                <Grid container spacing={2} direction="row" justify="center">
                  <Grid item xs={6}>
                    <Box className={classes.box}>
                      <CustomButton
                        variant={BUTTON_VARIANTS.GREEN}
                        size={BUTTON_SIZES.LARGE}
                        clickHandler={submit}
                        text="Edytuj"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={classes.box}>
                      <CustomButton
                        variant={BUTTON_VARIANTS.RED}
                        size={BUTTON_SIZES.LARGE}
                        clickHandler={close}
                        text="Anuluj"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default EditProductsWindow;
