import React, { useState, useEffect } from "react";
import { qrCodes } from "../../utils/newApi";
import { makeStyles, Grid, Box, Typography } from "@material-ui/core";
import styles from "./QRInfoBox.styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import DownloadButton from "../common/buttons/DownloadButton";
import EditQRcodeWindow from "./EditQRcodeWindow";
import CommonModal from "../common/modals/CommonModal";
import { useHistory } from "react-router-dom";

import {
  SERVER_ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";
import CustomButton from "../common/buttons/CustomButton";

const useStyles = makeStyles(styles);

const QRInfoBox = ({ info, handleError }) => {
  const classes = useStyles(styles);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [codeImage, setCodeImage] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (info && info.uuid) {
      qrCodes
        .getImage(info.uuid)
        .then((res) => {
          setCodeImage(res.data);
        })
        .catch((err) => {
          handleError(SERVER_ERROR_MESSAGES.COULD_NOT_LOAD_QR_CODE_IMAGE);
        });
    }
  }, [info]);

  const download = () => {
    let a = document.createElement("a");
    a.href = "data:image/png;base64," + codeImage;
    a.download = `${info.uuid}.png`;
    a.click();
  };

  const deleteCode = (qrId) => {
    qrCodes
      .delete(qrId)
      .then((res) => {
        history.push("/dashboard/QR");
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_QR_CODE);
      });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="rows">
        <Grid item xs={matches ? 12 : 7}>
          <Grid
            container
            spacing={2}
            justify="center"
            direction="rows"
            alignItems="center"
          ></Grid>
          <Grid item xs={12} align="center">
            <Avatar
              src={`data:image/png;base64,${codeImage}`}
              className={classes.avatar}
              variant="square"
            />
          </Grid>
          <Grid xs={12} align="center">
            <Box className={classes.downloadBox}>
              <DownloadButton onClick={download}>Pobierz Kod</DownloadButton>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={matches ? 12 : 5} className={classes.greyBox}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            direction="row"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Box className={classes.headerMargin}>
                <Typography className={classes.header}>
                  Informacje o kodzie:
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <List className={classes.rootList}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Przypisany do"
                    secondary={
                      <Typography className={classes.listText}>
                        {info.isHive
                          ? "UL"
                          : info.isThermometer
                          ? "TERMOMETR"
                          : "Brak"}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Użytkownik"
                    secondary={
                      <Typography className={classes.listText}>
                        {info.username ? info.username : "Brak"}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </List>
            </Grid>

            <Grid container direction="row">
              <Grid item xs={6}>
                <Box className={classes.buttonBox}>
                  <CustomButton
                    variant={BUTTON_VARIANTS.WHITE}
                    size={BUTTON_SIZES.LARGE}
                    clickHandler={() => setOpenEdit(true)}
                    text="Edytuj"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className={classes.buttonBox}>
                  <CustomButton
                    variant={BUTTON_VARIANTS.RED}
                    size={BUTTON_SIZES.LARGE}
                    clickHandler={() => setOpenConfirmation(true)}
                    text="Usuń"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() => deleteCode(info.id)}
        title={COMMON_MODAL_TEXTS.QR_TITLE}
        content={COMMON_MODAL_TEXTS.QR_CONTENT}
      />
      <EditQRcodeWindow
        isOpen={openEdit}
        close={() => setOpenEdit(false)}
        data={info}
        handleError={handleError}
      />
    </div>
  );
};

export default QRInfoBox;
