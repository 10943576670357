import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "./DatePickers.theme";

const DatePickers = (props) => {
  const handleEndTimeChange = (date) => {
    if (date === null || isNaN(date.getMinutes()) || isNaN(date.getHours()))
      return;
    let tempdate = new Date(props.discountEndTime);
    tempdate.setHours(date.getHours());
    tempdate.setMinutes(date.getMinutes());

    props.setDiscountEndTime(tempdate);
  };

  const handleEndDateChange = (date) => {
    if (
      date === null ||
      isNaN(date.getDate()) ||
      isNaN(date.getMonth()) ||
      isNaN(date.getFullYear())
    )
      return;
    let tempdate = new Date(props.discountEndTime);
    tempdate.setDate(date.getDate());
    tempdate.setMonth(date.getMonth());
    tempdate.setFullYear(date.getFullYear());

    props.setDiscountEndTime(tempdate);
  };

  const handleStartTimeChange = (date) => {
    if (date === null || isNaN(date.getMinutes()) || isNaN(date.getHours()))
      return;
    let tempdate = new Date(props.discountStartTime);
    tempdate.setHours(date.getHours());
    tempdate.setMinutes(date.getMinutes());

    props.setDiscountStartTime(tempdate);
  };

  const handleStartDateChange = (date) => {
    if (
      date === null ||
      isNaN(date.getDate()) ||
      isNaN(date.getMonth()) ||
      isNaN(date.getFullYear())
    )
      return;
    let tempdate = new Date(props.discountStartTime);
    tempdate.setDate(date.getDate());
    tempdate.setMonth(date.getMonth());
    tempdate.setFullYear(date.getFullYear());

    props.setDiscountStartTime(tempdate);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={theme}>
        <Grid container direction="column" align="center" spacing={2}>
          <Grid container direction="row" align="center" spacing={2}>
            <Grid item xs={12} sm={6}>
              <KeyboardDatePicker
                disableToolbar
                margin="normal"
                id="date-picker-dialog"
                label="Data rozpoczęcia"
                format="dd/MM/yyyy"
                value={props.discountStartTime}
                onChange={(date) => handleStartDateChange(date)}
                error={props.error}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                minDateMessage={"Data nie może być mniejsza niż minimalna"}
                maxDateMessage={"Data nie może być większa niż maksymalna"}
                invalidDateMessage={"Niewłaściwy format daty"}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <KeyboardTimePicker
                ampm={false}
                margin="normal"
                id="time-picker"
                label="Godzina rozpoczęcia"
                value={props.discountStartTime}
                onChange={(date) => handleStartTimeChange(date)}
                error={props.error}
                invalidDateMessage={"Niewłaściwy format daty"}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" align="center" spacing={2}>
            <Grid item xs={12} sm={6}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Data zakończenia"
                format="dd/MM/yyyy"
                value={props.discountEndTime}
                onChange={(date) => handleEndDateChange(date)}
                error={props.error}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                minDateMessage={"Data nie może być mniejsza niż minimalna"}
                maxDateMessage={"Data nie może być większa niż maksymalna"}
                invalidDateMessage={"Niewłaściwy format daty"}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <KeyboardTimePicker
                ampm={false}
                margin="normal"
                id="time-picker"
                label="Godzina zakończenia"
                value={props.discountEndTime}
                onChange={(date) => handleEndTimeChange(date)}
                error={props.error}
                invalidDateMessage={"Niewłaściwy format daty"}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default DatePickers;
