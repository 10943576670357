import colors from "../../constants/colors";

const styles = (theme) => {
  return {
    box: {
      margin: "5%",
      width: "70%",
      height: "70%",
      borderRadius: 20,
      "box-shadow":
        "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
      border: "3px bold black",
      "background-size": "cover",
      padding: "1%",
      "align-self": "center",
    },
    image: {
      height: "100%",
      width: "100%",
      borderRadius: 20,
      "background-size": "cover",
      paddingTop: "2%",
      paddingBottom: "2%",
    },
    icon: {
      color: colors.carmine,
    },
    root: {
      height: 200,
      display: "flex",
      "flex-direction": "column",
      "justify-content": "center",
    },
    iconBox: {
      display: "flex",
      float: "center",
      "align-self": "center",
      cursor: "pointer",
    },
    iconLabelRed: {
      "font-weight": "bold",
      "font-size": "12px",
      "font-family": "Montserrat",
      marginLeft: "4%",
      marginTop: "2%",
      color: colors.carmine,
    },
  };
};

export default styles;
