import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@mui/material/Table";
import { Grid, Typography, MenuItem } from "@material-ui/core";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@material-ui/core/Paper";
import styles from "./ProductTable.styles";
import Avatar from "@material-ui/core/Avatar";
import CommonModal from "../common/modals/CommonModal";
import EditProductsWindow from "../ModalWindows/EditProductWindow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import StyledCategorySelect from "../common/Selects/StyledCategorySelect";
import fields from "../../constants/productsConstants";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { useHistory } from "react-router";
import { SortIcon } from "../common/buttons/SortIcon";
import { displayPrice } from "../../utils/price";
import { shop } from "../../utils/api";
import DeleteEditButton from "../common/buttons/DeleteEditButton";

import {
  SERVER_ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";
import CustomButton from "../common/buttons/CustomButton";

const useStyles = makeStyles(styles);

const ProductTable = ({
  products,
  setProducts,
  handleError,
  page,
  rowsPerPage,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [id, setId] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [infoForEdit, setInfoForEdit] = useState("");
  const [isAscV, setIsAscV] = useState(true);
  const [isAscID, setIsAscID] = useState(true);
  const [isAscN, setIsAscN] = useState(true);
  const [isDiscount, setisDiscount] = useState(true);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [sortBy, setSortBy] = useState("");
  const [isActiveID, setIsActiveId] = useState(false);
  const [isActiveName, setIsActiveName] = useState(false);
  const [isActiveValue, setIsActiveValue] = useState(false);
  const [isActiveDiscount, setIsActiveDiscount] = useState(false);
  const history = useHistory();

  const handleDelete = (id, e) => {
    e.stopPropagation();
    setOpenConfirmation(true);
    setId(id);
  };

  const handleEdit = (product, e) => {
    e.stopPropagation();
    setInfoForEdit(product);
    setOpenEdit(true);
  };

  const handlefield = (e) => {
    setSortBy(e.target.value);
  };

  const handleSortSelected = () => {
    switch (sortBy) {
      case "Id":
        sortID();
        break;
      case "Nazwa":
        sortName();
        break;
      case "Wartość":
        sortValue();
        break;
      case "Promocja":
        sortPromo();
        break;
    }
  };

  const isDiscountActive = (disc) => {
    const currentDate = new Date();
    if (
      currentDate >= new Date(disc.discountStartTime) &&
      currentDate <= new Date(disc.discountEndTime)
    )
      return true;
    else return false;
  };

  const sortID = () => {
    setIsAscID(!isAscID);
    setIsActiveId(true);
    setIsActiveName(false);
    setIsActiveValue(false);
    setIsActiveDiscount(false);
    products.sort((a, b) => {
      if (isAscID) {
        return a.id - b.id;
      } else {
        return b.id - a.id;
      }
    });
  };

  const sortValue = () => {
    setIsAscV(!isAscV);
    setIsActiveId(false);
    setIsActiveName(false);
    setIsActiveValue(true);
    setIsActiveDiscount(false);
    products.sort((a, b) => {
      if (isAscV) {
        return a.price - b.price;
      } else {
        return b.price - a.price;
      }
    });
  };

  const sortName = () => {
    setIsActiveId(false);
    setIsActiveName(true);
    setIsActiveValue(false);
    setIsActiveDiscount(false);
    setIsAscN(!isAscN);
    products.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (isAscN) {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      } else {
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
      }
      return 0;
    });
  };

  const sortPromo = () => {
    setIsActiveId(false);
    setIsActiveName(false);
    setIsActiveValue(false);
    setIsActiveDiscount(true);
    setisDiscount(!isDiscount);
    products.sort((a, b) => {
      if (isDiscount) {
        if (isDiscountActive(a)) {
          return 1;
        }
        if (isDiscountActive(b)) {
          return -1;
        }
      } else {
        if (isDiscountActive(a)) {
          return -1;
        }
        if (isDiscountActive(b)) {
          return 1;
        }
      }
    });
  };

  const deleteProduct = (productId) => {
    shop.articles
      .delete(productId)
      .then((res) => {
        setProducts(products.filter((item) => item.id !== productId));
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_PRODUCT);
      });
  };

  return (
    <>
      <TableContainer component={Paper} classes={classes.containerTable}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <TableHead>
            {matches ? (
              <TableRow>
                <TableCell className={classes.idRes}></TableCell>
                <TableCell className={classes.nameRes} align="right">
                  {sortBy !== "" && (
                    <UnfoldMoreIcon onClick={handleSortSelected} />
                  )}
                </TableCell>
                <TableCell className={classes.discountRes}>
                  <StyledCategorySelect
                    InputLabelProps={{ classes: { root: classes.label } }}
                    fullWidth={true}
                    label="Sortuj według"
                    variant="outlined"
                    shrink="true"
                    onChange={handlefield}
                    value={sortBy}
                    select={true}
                  >
                    {fields.map((field) => {
                      return (
                        <MenuItem key={field.id} value={field.name}>
                          {field.name}
                        </MenuItem>
                      );
                    })}
                  </StyledCategorySelect>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell align="center" className={classes.id}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={6}>
                      <div className={classes.headerCell}>
                        <Typography className={classes.cell}>ID</Typography>
                        <div
                          className={classes.headerCellRight}
                          onClick={sortID}
                        >
                          <SortIcon isactive={isActiveID} asacdesc={isAscID} />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" className={classes.name}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={12}>
                      <div className={classes.headerCell}>
                        <Typography className={classes.cell}>
                          NAZWA PRODUKTU
                        </Typography>
                        <div
                          className={classes.headerCellRight}
                          onClick={sortName}
                        >
                          <SortIcon isactive={isActiveName} asacdesc={isAscN} />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" className={classes.value}>
                  <Grid container direction="row">
                    <Grid item xs={12} md={6} align="left">
                      <div className={classes.headerCell}>
                        <Typography className={classes.cell}>
                          WARTOŚĆ
                        </Typography>
                        <div
                          className={classes.headerCellRight}
                          onClick={sortValue}
                        >
                          <SortIcon
                            isactive={isActiveValue}
                            asacdesc={isAscV}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" className={classes.discount}>
                  <Grid container direction="row">
                    <Grid item xs={12} md={6} align="left">
                      <div className={classes.headerCell}>
                        <Typography className={classes.cell}>
                          PROMOCJA
                        </Typography>
                        <div
                          className={classes.headerCellRight}
                          onClick={sortPromo}
                        >
                          <SortIcon
                            isactive={isActiveDiscount}
                            asacdesc={isDiscount}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell className={classes.buttons}></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {products
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((product) => (
                <TableRow
                  key={product.id}
                  className={
                    product.price === null || product.price === 0
                      ? classes.redRow
                      : classes.row
                  }
                  onClick={() =>
                    history.push(`/dashboard/product/${product.id}`)
                  }
                >
                  {matches ? (
                    <TableCell align="center" component="th" scope="row">
                      <Avatar
                        alt=""
                        src={product.images[0]}
                        className={classes.avatar}
                      />
                    </TableCell>
                  ) : (
                    <TableCell align="center" component="th" scope="row">
                      {product.id}
                    </TableCell>
                  )}
                  <TableCell align="left">
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      {!matches && (
                        <Grid item xs={4}>
                          <Avatar
                            alt=""
                            src={product.images[0]}
                            className={classes.avatar}
                          />
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={matches ? 12 : 8}
                        align="center"
                        className={matches && classes.breakline}
                      >
                        <Typography className={classes.cell}>
                          {product.name}
                        </Typography>
                      </Grid>
                      {matches && (
                        <DeleteEditButton
                          mobile={true}
                          editClickHandler={(e) => handleEdit(product, e)}
                          deleteClickHandler={(e) =>
                            handleDelete(product.id, e)
                          }
                        />
                      )}
                    </Grid>
                  </TableCell>
                  {!matches && (
                    <TableCell className={classes.cell} align="left">
                      {`${displayPrice(product.price)} zł`}
                    </TableCell>
                  )}
                  {!matches && (
                    <TableCell align="left" className={classes.buttons2}>
                      <CustomButton
                        variant={
                          isDiscountActive(product)
                            ? BUTTON_VARIANTS.GREEN
                            : BUTTON_VARIANTS.RED
                        }
                        size={BUTTON_SIZES.LARGE}
                        text={isDiscountActive(product) ? "Tak" : "Nie"}
                      />
                    </TableCell>
                  )}
                  {matches && (
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography className={classes.cell} align="center">
                            PROMOCJA
                          </Typography>
                        </Grid>
                        <Grid item xs={12} align="center">
                          <CustomButton
                            variant={
                              isDiscountActive(product)
                                ? BUTTON_VARIANTS.GREEN
                                : BUTTON_VARIANTS.RED
                            }
                            size={BUTTON_SIZES.SMALL}
                            text={isDiscountActive(product) ? "Tak" : "Nie"}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <Typography className={classes.cell}>
                            Cena:
                          </Typography>
                          <Typography
                            className={classes.cell}
                          >{`${product.price} zł`}</Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  )}
                  {!matches && (
                    <DeleteEditButton
                      mobile={false}
                      editClickHandler={(e) => handleEdit(product, e)}
                      deleteClickHandler={(e) => handleDelete(product.id, e)}
                    />
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() => deleteProduct(id)}
        title={COMMON_MODAL_TEXTS.PRODUCT_TITLE}
        content={COMMON_MODAL_TEXTS.PRODUCT_CONTENT}
      />
      <EditProductsWindow
        isOpen={openEdit}
        close={() => setOpenEdit(false)}
        data={infoForEdit}
        handleError={handleError}
      />
    </>
  );
};

export default ProductTable;
