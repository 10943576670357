const validate = (value) => {
  return /^\d+$/.test(value) || value === "";
};

export const validateConfig = (
  name,
  suggestedHivesCount,
  lowerDensityRate,
  upperDensityRate,
  setErrorName,
  setErrorHives,
  setErrorLower,
  setErrorUpper
) => {
  let error = false;

  if (name !== "") {
    setErrorName(false);
  } else {
    error = true;
    setErrorName(true);
  }

  if (
    validate(suggestedHivesCount) &&
    suggestedHivesCount !== "0" &&
    suggestedHivesCount !== ""
  ) {
    setErrorHives(false);
  } else {
    error = true;
    setErrorHives(true);
  }

  if (validate(lowerDensityRate)) {
    setErrorLower(false);
  } else {
    error = true;
    setErrorLower(true);
  }

  if (validate(upperDensityRate)) {
    setErrorUpper(false);
  } else {
    error = true;
    setErrorUpper(true);
  }

  if (upperDensityRate !== "" && lowerDensityRate !== "") {
    if (Number(upperDensityRate) > Number(lowerDensityRate)) {
      setErrorUpper(false);
    } else {
      error = true;
      setErrorUpper(true);
    }
  }

  return error;
};

export const validateFloat = (data) => {
  const regex = /^\d{1,6}(\.\d+)?$/;
  return regex.test(String(data).toLowerCase());
};

export const validateEmail = (email) => {
  const regex = /.*@.*\..*/;
  return regex.test(String(email).toLowerCase());
};

export const validatePostCode = (postCode) => {
  const regex = /[0-9]{2}-[0-9]{3}/;
  return regex.test(String(postCode));
};
