import React from "react";
import { useState } from "react";
import AddCategoryWindow from "../ModalWindows/AddCategoryWindow";
import { useEffect } from "react";
import useStyles from "./Categories.styles";
import { shop } from "../../utils/api";
import { Typography } from "@material-ui/core";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import clsx from "clsx";
import { SortIcon } from "../../components/common/buttons/SortIcon";
import ErrorSnackBar from "../Snackbar/ErrorSnackBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Category from "./Category";
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TablePaginationActions from "../../components/common/pagination/TablePaginationActions";
import ListHeader from "../common/ListHeader";

import {
  SERVER_ERROR_MESSAGES,
  ERROR_MESSAGES,
} from "../../constants/dictionary";

const Categories = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [error, setError] = useState(false);
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isAscID, setIsAscID] = useState(true);
  const [isAscN, setIsAscN] = useState(true);
  const [isActiveID, setIsActiveID] = useState(true);
  const [isActiveName, setIsActiveName] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleAdd = (data) => {
    shop.categories
      .add(data)
      .then(() => setTrigger(!trigger))
      .catch(() => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_ADD_CATEGORY);
      });
  };

  const handleDelete = (id) => {
    shop.categories
      .delete(id)
      .then(() => setTrigger(!trigger))
      .catch(() => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_CATEGORY);
      });
  };

  const handleEdit = (id, data) => {
    shop.categories
      .edit(id, data)
      .then(() => setTrigger(!trigger))
      .catch(() => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_EDIT_CATEGORY);
      });
  };

  const sortID = () => {
    setIsAscID(!isAscID);
    setIsActiveID(true);
    setIsActiveName(false);
    categories.sort((a, b) => {
      if (isAscID) {
        return b.id - a.id;
      } else {
        return a.id - b.id;
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const sortName = () => {
    setIsAscN(!isAscN);
    setIsActiveID(false);
    setIsActiveName(true);
    categories.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (isAscN) {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      } else {
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
      }
      return 0;
    });
  };

  useEffect(() => {
    setIsLoaded(false);
    shop.categories
      .all()
      .then((res) => {
        let sorted = res.data.categories
          ? res.data.categories.sort((a, b) => b.id - a.id)
          : [];
        setCategories(sorted);
        sortID();
        setIsLoaded(true);
      })
      .catch((err) => {
        setIsLoaded(false);
        handleError(ERROR_MESSAGES.COULD_NOT_CONNECT_TO_SERVER);
      });
  }, [trigger]);

  return isLoaded ? (
    <>
      <div className={classes.root}>
        <AddCategoryWindow
          handleAdd={handleAdd}
          toggleOpen={toggleOpen}
          open={open}
        />
        <ListHeader
          variant="title-add"
          title="Lista kategorii"
          handleAdd={toggleOpen}
        />
        <div className={classes.container}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" className={classes.id}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item xs={12} md={6}>
                        <div className={classes.headerCell}>
                          <Typography className={classes.cell}>ID</Typography>
                          <div
                            className={classes.headerCellRight}
                            onClick={sortID}
                          >
                            <SortIcon
                              isactive={isActiveID}
                              asacdesc={isAscID}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="left" className={classes.name}>
                    <Grid
                      container
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item xs={12} md={12}>
                        <div
                          className={clsx(classes.headerCell, classes.nameCell)}
                        >
                          <Typography className={classes.cell}>
                            NAZWA
                          </Typography>
                          <div
                            className={classes.headerCellRight}
                            onClick={sortName}
                          >
                            <SortIcon
                              isactive={isActiveName}
                              asacdesc={isAscN}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </TableCell>
                  {!mobile && (
                    <TableCell align="left" className={classes.description}>
                      <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid item xs={12} md={12}>
                          <div className={classes.headerCell}>
                            <Typography className={classes.cell}>
                              OPIS
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </TableCell>
                  )}
                  <TableCell className={classes.buttons}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((category, i) => (
                    <Category
                      key={i}
                      data={category}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 50]}
            component="div"
            count={categories.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
            classes={{
              toolbar: classes.toolbar,
              caption: classes.caption,
            }}
            labelRowsPerPage={"Ilość wierszy na stronę"}
            ActionsComponent={TablePaginationActions}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}–${to} z ${
                count !== -1 ? count : `więcej niż ${to}`
              }`;
            }}
          />
        </div>
        <ErrorSnackBar
          open={error}
          text={errorText}
          close={() => setError(false)}
        />
      </div>
    </>
  ) : (
    <div className={classes.containerLoad}>
      <CircularProgress color="secondary" className={classes.loader} />
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </div>
  );
};

export default Categories;
