import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import {
  Grid,
  FormControlLabel,
  Switch,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Checkbox,
  Hidden,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import CommonModal from "../common/modals/CommonModal";
import CustomButton from "../common/buttons/CustomButton";

import useStyles from "./Modal.styles";
import { validateConfig } from "../../utils/validation";
import {
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  MODAL_TYPE,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";
import { HARVEST_MESSAGES } from "../../constants/dictionary";

const AddCultivationTypeWindow = ({
  handleEdit,
  open,
  toggleOpen,
  current,
}) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [configs, setConfigs] = useState([]);
  const [suggestedHivesCount, setSuggestedHivesCount] = useState("");
  const [lowerDensityRate, setLowerDensityRate] = useState("");
  const [upperDensityRate, setUpperDensityRate] = useState("");
  const [switched, setSwitched] = useState(false);
  const [manualHarvesting, setManualHarvesting] = useState(true);
  const [combineHarvesting, setCombineHarvesting] = useState(false);
  const [errorLower, setErrorLower] = useState(false);
  const [errorUpper, setErrorUpper] = useState(false);
  const [errorHives, setErrorHives] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [noConfigsError, setNoConfigsError] = useState(false);
  const errorMsg = "Niepoprawna wartość";

  useEffect(() => {
    setErrorHives(false);
    setErrorLower(false);
    setErrorUpper(false);
    setErrorName(false);
    setName(current.name);
    setConfigs(current.hiveAmountConfigDAOList);
    setSuggestedHivesCount("");
    setUpperDensityRate("");
    setLowerDensityRate("");
  }, [open]);

  const handleSwitch = (event) => {
    setSwitched(event.target.checked);
  };

  const handleManualHarvestingSwitch = (event) => {
    setManualHarvesting(event.target.checked);
    setCombineHarvesting(!event.target.checked);
  };

  const handleCombineHarvestingSwitch = (event) => {
    setCombineHarvesting(event.target.checked);
    setManualHarvesting(!event.target.checked);
  };

  const submit = () => {
    if (errorName) {
      return;
    }

    if (configs.length > 0) {
      handleEdit(current.id, {
        hiveAmountConfigRequestList: configs,
        name: name,
      });
      toggleOpen();
    } else {
      setNoConfigsError(true);
    }
  };

  const addConfig = () => {
    const error = validateConfig(
      name,
      suggestedHivesCount,
      lowerDensityRate,
      upperDensityRate,
      setErrorName,
      setErrorHives,
      setErrorLower,
      setErrorUpper
    );

    const isHarvestManual = switched ? (manualHarvesting ? true : false) : null;

    if (!error) {
      const config = {
        isHarvestManual: isHarvestManual,
        lowerTreeDensityRate: lowerDensityRate,
        suggestedHivesCount: suggestedHivesCount,
        upperTreeDensityRate: upperDensityRate,
      };

      setConfigs((prev) => [...prev, config]);
      setNoConfigsError(false);
    }
  };

  return (
    <CommonModal
      open={open}
      toggleOpen={toggleOpen}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.EDIT_CULTIVATION_TYPE_TITLE}
      type={MODAL_TYPE.EDIT}
    >
      <Grid item xs={12}>
        <TextField
          label="Wpisz nazwę typu"
          InputLabelProps={{ classes: { root: classes.label } }}
          placeholder="Nowy typ"
          variant="outlined"
          fullWidth
          required
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            setErrorName(e.target.value.trim() === "" ? true : false);
          }}
          error={errorName}
        />
      </Grid>

      {configs && configs.length > 0 && (
        <Grid item xs={12} align="center" style={{ marginBottom: "2%" }}>
          <Typography className={classes.listText}>
            Lista konfiguracji
          </Typography>
        </Grid>
      )}

      <Grid container>
        {configs &&
          configs.map((config, idx) => (
            <List className={classes.rootList}>
              <ListItem className={classes.rootElements}>
                <ListItemText
                  primary="Typ zbiorów"
                  style={{ width: "15%" }}
                  secondary={
                    <Typography className={classes.listText}>
                      {HARVEST_MESSAGES[config.isHarvestManual]}
                    </Typography>
                  }
                />
                <ListItemText
                  primary="Zalecana ilość uli"
                  secondary={
                    <Typography className={classes.listText}>
                      {config.suggestedHivesCount}
                    </Typography>
                  }
                />
                <ListItemText
                  primary="Dolna granica"
                  secondary={
                    <Typography className={classes.listText}>
                      {config.lowerTreeDensityRate > 0
                        ? config.lowerTreeDensityRate
                        : "Nieokreślono"}
                    </Typography>
                  }
                />
                <ListItemText
                  primary="Górna granica"
                  secondary={
                    <Typography className={classes.listText}>
                      {config.upperTreeDensityRate > 0
                        ? config.upperTreeDensityRate
                        : "Nieokreślono"}
                    </Typography>
                  }
                />
                <DeleteIcon
                  className={classes.delete}
                  onClick={() => {
                    let filtered = configs.filter((e, i) => {
                      if (i === idx) return false;
                      else return true;
                    });
                    setConfigs(filtered);
                  }}
                />
              </ListItem>
              <Divider />
            </List>
          ))}
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={switched}
                onChange={handleSwitch}
                color="primary"
              />
            }
            label="Określ typ zbioru"
          />
        </Grid>
        {switched && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={manualHarvesting}
                  onChange={handleManualHarvestingSwitch}
                  color="primary"
                />
              }
              label="Zbiory ręczne"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={combineHarvesting}
                  onChange={handleCombineHarvestingSwitch}
                  color="primary"
                />
              }
              label="Zbiory maszynowe"
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Wpisz zalecaną ilość uli"
          InputLabelProps={{ classes: { root: classes.label } }}
          placeholder="1"
          variant="outlined"
          fullWidth
          required
          onChange={(e) => {
            setSuggestedHivesCount(e.target.value);
          }}
          error={errorHives}
          value={suggestedHivesCount}
          errorMsg={errorMsg}
        />
      </Grid>
      <Grid item>
        <Typography variant="h6">Ilość drzew</Typography>
        <Typography variant="subtitle1">
          (pozostaw puste, jeśli któraś z granic nie istnieje)
        </Typography>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        spacing={1}
        justifyContent="space-around"
      >
        <Grid item xs={12} sm={5}>
          <TextField
            className={classes.area}
            label="Dolna granica"
            InputLabelProps={{ classes: { root: classes.label } }}
            variant="outlined"
            onChange={(e) => setLowerDensityRate(e.target.value)}
            error={errorLower}
            errorMsg={errorMsg}
          />
        </Grid>
        <Hidden xsDown>
          <Grid item xs={2}>
            <Typography variant="body1">-</Typography>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={5}>
          <TextField
            className={classes.area}
            InputLabelProps={{ classes: { root: classes.label } }}
            label="Górna granica"
            variant="outlined"
            onChange={(e) => {
              setUpperDensityRate(e.target.value);
            }}
            error={errorUpper}
            errorMsg={errorMsg}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.buttonBox}>
          <CustomButton
            variant={BUTTON_VARIANTS.GREEN}
            size={BUTTON_SIZES.LARGE}
            clickHandler={addConfig}
            text="Dodaj konfigurację"
          />
        </Box>
        {noConfigsError && (
          <Typography className={classes.error} style={{ marginTop: "1%" }}>
            Proszę dodać przynajmniej jedną konfigurację
          </Typography>
        )}
        {errorName && <p className={classes.error}>Proszę podać nazwę typu</p>}
      </Grid>
    </CommonModal>
  );
};

export default AddCultivationTypeWindow;
