import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { Typography } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styles from "./FAQlist.styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import EditFAQmodalWindow from "../ModalWindows/FAQ/EditFAQmodalWindow";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../common/pagination/TablePaginationActions";

import ReactPlayer from "react-player";
import CommonModal from "../common/modals/CommonModal";
import { users } from "../../utils/newApi";
import DeleteEditButton from "../common/buttons/DeleteEditButton";

import {
  SERVER_ERROR_MESSAGES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const FAQlist = ({ listOfFaqs, setFaqs, handleError }) => {
  const theme = useTheme();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [id, setId] = useState("");
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [infoForEdit, setInfoForEdit] = useState("");
  const classes = useStyles(matches);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = (id, e) => {
    e.stopPropagation();
    setOpenConfirmation(true);
    setId(id);
  };

  const handleEdit = (ad, e) => {
    e.stopPropagation();
    setInfoForEdit(ad);
    setOpenEdit(true);
  };

  const deleteFAQ = (faqId) => {
    users.faq
      .delete(faqId)
      .then((res) => {
        setFaqs(listOfFaqs.filter((item) => item.id !== faqId));
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_FAQ);
      });
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            {matches ? (
              <TableRow>
                <TableCell className={classes.faqsRes} />
              </TableRow>
            ) : (
              <TableRow>
                <TableCell className={classes.faqs}></TableCell>
                <TableCell className={classes.buttons}></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {listOfFaqs
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((faq) => (
                <TableRow>
                  <TableCell>
                    <List className={classes.rootList}>
                      <ListItem
                        alignItems="flex-start"
                        className={classes.grey}
                      >
                        <ListItemText
                          primary="Pytanie"
                          secondary={
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                            >
                              {faq.question}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                      {faq.answer !== null && (
                        <>
                          <ListItem alignItems="flex-start">
                            <ListItemText
                              primary="Odpowiedź"
                              secondary={
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                >
                                  {faq.answer}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </>
                      )}
                      {faq.url !== null && (
                        <ListItem alignItems="flex-start">
                          <ListItemText
                            primary="Wideo"
                            secondary={
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                              >
                                <ReactPlayer id={0} url={faq.url} />
                              </Typography>
                            }
                          />
                        </ListItem>
                      )}
                    </List>
                    {matches && (
                      <DeleteEditButton
                        mobile={true}
                        editClickHandler={(e) => {
                          handleEdit(faq, e);
                        }}
                        deleteClickHandler={(e) => {
                          handleDelete(faq.id, e);
                        }}
                      />
                    )}
                  </TableCell>
                  {!matches && (
                    <DeleteEditButton
                      mobile={false}
                      editClickHandler={(e) => {
                        handleEdit(faq, e);
                      }}
                      deleteClickHandler={(e) => {
                        handleDelete(faq.id, e);
                      }}
                    />
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20, 50]}
          component="div"
          count={listOfFaqs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
          classes={{
            toolbar: classes.toolbar,
            caption: classes.caption,
          }}
          labelRowsPerPage={"Ilość wierszy na stronę"}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}–${to} z ${
              count !== -1 ? count : `więcej niż ${to}`
            }`;
          }}
        />
      </TableContainer>
      <EditFAQmodalWindow
        listOfFaqs={listOfFaqs}
        setFaqs={setFaqs}
        isOpen={openEdit}
        close={() => setOpenEdit(false)}
        info={infoForEdit}
        handleError={handleError}
      />
      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() => deleteFAQ(id)}
        title={COMMON_MODAL_TEXTS.FAQ_TITLE}
        content={COMMON_MODAL_TEXTS.FAQ_CONTENT}
      />
    </>
  );
};

export default FAQlist;
