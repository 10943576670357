import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { Grid, Typography } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styles from "./PathogensTable.styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import EditPathogenWindow from "../ModalWindows/Pythogens/EditPathogen";
import CommonModal from "../common/modals/CommonModal";
import { cultivations } from "../../utils/newApi";

import {
  SERVER_ERROR_MESSAGES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";
import DeleteEditButton from "../common/buttons/DeleteEditButton";

const useStyles = makeStyles(styles);

const PathogensTable = ({
  listOfPathogens,
  setPathogens,
  handleError,
  page,
  rowsPerPage,
}) => {
  const theme = useTheme();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [pathogenId, setPathogenId] = useState("");
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [infoForEdit, setInfoForEdit] = useState("");
  const classes = useStyles(matches);

  const handleDelete = (id, e) => {
    e.stopPropagation();
    setOpenConfirmation(true);
    setPathogenId(id);
  };

  const handleEdit = (pathogen, e) => {
    e.stopPropagation();
    setInfoForEdit(pathogen);
    setOpenEdit(true);
  };

  const deletePat = (pathogenId) => {
    cultivations.pathogens
      .delete(pathogenId)
      .then((res) => {
        setPathogens(listOfPathogens.filter((item) => item.id !== pathogenId));
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_PATHOGEN);
      });
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            {matches ? (
              <TableRow>
                <TableCell className={classes.productsRes}>
                  NAZWA PATOGENU
                </TableCell>
                <TableCell className={classes.buttonsRes}></TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell className={classes.products}>
                  NAZWA PATOGENU
                </TableCell>
                <TableCell className={classes.buttons}></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {listOfPathogens &&
              listOfPathogens
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((pathogen) => (
                  <TableRow key={pathogen.id} className={classes.row}>
                    <TableCell align="left" className={classes.cell}>
                      <Grid
                        container
                        justifyContent={matches && "center"}
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={matches ? 12 : 8}
                          align={matches ? "center" : "left"}
                          className={matches && classes.breakline}
                        >
                          <Typography
                            style={{
                              fontFamily: "Montserrat",
                              wordBreak: "break-word",
                            }}
                          >
                            {pathogen.name}
                          </Typography>
                        </Grid>
                        {matches && (
                          <DeleteEditButton
                            mobile={true}
                            editClickHandler={(e) => {
                              handleEdit(pathogen, e);
                            }}
                            deleteClickHandler={(e) => {
                              handleDelete(pathogen.id, e);
                            }}
                          />
                        )}
                      </Grid>
                    </TableCell>

                    {!matches && (
                      <DeleteEditButton
                        mobile={false}
                        editClickHandler={(e) => {
                          handleEdit(pathogen, e);
                        }}
                        deleteClickHandler={(e) => {
                          handleDelete(pathogen.id, e);
                        }}
                      />
                    )}
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() => deletePat(pathogenId)}
        title={COMMON_MODAL_TEXTS.PATHOGEN_TITLE}
        content={COMMON_MODAL_TEXTS.PATOHGEN_CONTENT}
      />
      <EditPathogenWindow
        listOfPathogens={listOfPathogens}
        setPathogens={setPathogens}
        isOpen={openEdit}
        close={() => setOpenEdit(false)}
        data={infoForEdit}
        handleError={handleError}
      />
    </>
  );
};

export default PathogensTable;
