import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { Grid, Typography, Button } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styles from "./ServiceTable.styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { format } from "date-fns";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PersonIcon from "@material-ui/icons/Person";
import { Animated } from "react-animated-css";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(styles);

const ServicesTable = ({ services, handleError, setCurrentService, page, rowsPerPage }) => {
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const handleDelete = (service, e) => {
    e.stopPropagation();
    setCurrentService(service);
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            {matches ? (
              <TableRow>
                <TableCell className={classes.connectedToRes} align="center">
                  <Button
                    endIcon={<LocalShippingIcon className={classes.icon} />}
                  >
                    <Typography className={classes.cell}>
                      DATA SERWISU
                    </Typography>
                  </Button>
                </TableCell>
                <TableCell className={classes.usersRes}>
                  <Button endIcon={<PersonIcon className={classes.icon} />}>
                    <Typography className={classes.cell}>UŻYTKOWNIK</Typography>
                  </Button>
                </TableCell>
                <TableCell className={classes.buttonsRes} />
              </TableRow>
            ) : (
              <TableRow>
                <TableCell align="left" className={classes.connectedTo}>
                  <Animated
                    animationIn="bounceInLeft"
                    isVisible={true}
                    animationInDuration={3000}
                  >
                    <Button
                      endIcon={<LocalShippingIcon className={classes.icon} />}
                    >
                      <Typography className={classes.cell}>
                        DATA SERWISU
                      </Typography>
                    </Button>
                  </Animated>
                </TableCell>
                <TableCell className={classes.users}>
                  <Button endIcon={<PersonIcon className={classes.icon} />}>
                    <Typography className={classes.cell}>UŻYTKOWNIK</Typography>
                  </Button>
                </TableCell>
                <TableCell className={classes.buttons} />
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {services &&
              services
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((service) => (
                  <TableRow
                    key={service && service.maintenance.id}
                    className={classes.row}
                    onClick={() =>
                      history.push(
                        `/dashboard/cocoonBoxes/${service.maintenance.id}`
                      )
                    }
                  >
                    <TableCell>
                      <Typography className={classes.text}>
                        {service.maintenance.date
                          ? format(
                              new Date(service.maintenance.date),
                              "dd/MM/yyyy"
                            )
                          : "BRAK"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.text}>
                        {service.user.email}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        endIcon={<DeleteIcon className={classes.delete} />}
                        onClick={(e) => {
                          handleDelete(service, e);
                        }}
                      >
                        <Typography className={classes.deleteText}>
                          Usuń
                        </Typography>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ServicesTable;
