import colors from "../../constants/colors";

const styles = (theme) => {
  return {
    label: {
      "white-space": "nowrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: 13,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 15,
      },
    },
    box: {
      marginTop: 20,
      marginBottom: 20,

      [theme.breakpoints.down("sm")]: {
        marginLeft: 25,
        marginRight: 25,
      },
      [theme.breakpoints.up("sm")]: {
        marginLeft: 40,
        marginRight: 40,
      },
    },
    root: {
      backgroundColor: colors.white,
      borderRadius: "30px",
      padding: 15,
      [theme.breakpoints.down("sm")]: {
        width: "80%",
      },
      [theme.breakpoints.up("sm")]: {
        width: "40%",
      },
    },
    error: {
      color: colors.htmlRed,
    },
    headerText: {
      marginTop: "20px",
      fontWeight: "bold",
    },
    text: {
      fontSize: "15px",
      color: colors.htmlGray,
      fontWeight: "regular",
    },
    cursors: {
      cursor: "pointer",
    },
    successText: {
      marginTop: "18px",
      fontWeight: "bold",
      color: colors.green,
    },
  };
};

export default styles;
