import React, { useState, useEffect } from "react";
import {
  Grid,
  makeStyles,
  ThemeProvider,
  TablePagination,
} from "@material-ui/core";
import theme from "../Login/LoginSreen.breakpoints";
import { shop } from "../../utils/newApi";
import styles from "./AdsScreen.styles";
import AdsTable from "../../components/AdsTable/AdsTable";
import TablePaginationActions from "../../components/common/pagination/TablePaginationActions";
import NewAdWindow from "../../components/ModalWindows/Ads/NewAdWindow";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import ListHeader from "../../components/common/ListHeader";

import { ERROR_MESSAGES } from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const AdsScreen = () => {
  const [ads, setAds] = useState([]);
  const [adsCopy, setAdsCopy] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [page, setPage] = React.useState(
    localStorage.getItem("currentAdsTablePage")
      ? Number(localStorage.getItem("currentAdsTablePage"))
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(
    localStorage.getItem("currentAdsTableRowsPerPage")
      ? Number(localStorage.getItem("currentAdsTableRowsPerPage"))
      : 10
  );

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  useEffect(() => {
    shop.ads
      .all()
      .then((res) => {
        const sorted = res.data ? res.data.sort((a, b) => b.id - a.id) : [];
        setAds(sorted);
        setAdsCopy(sorted);
        setIsLoaded(true);
      })
      .catch((err) => {
        handleError(ERROR_MESSAGES.COULD_NOT_CONNECT_TO_SERVER);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <Grid container>
          <ListHeader
            variant="title-add"
            title="Lista Reklam"
            handleAdd={() => setOpen(true)}
          />
          <Grid container>
            <Grid item xs={12} align="center">
              <AdsTable
                page={page}
                rowsPerPage={rowsPerPage}
                ads={ads}
                setAds={setAds}
                adsCopy={adsCopy}
                handleError={handleError}
              />
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 50]}
                component="div"
                count={ads.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                classes={{
                  toolbar: classes.toolbar,
                  caption: classes.caption,
                }}
                labelRowsPerPage={"Ilość wierszy na stronę"}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}–${to} z ${
                    count !== -1 ? count : `więcej niż ${to}`
                  }`;
                }}
              />
            </Grid>
          </Grid>
          <NewAdWindow
            isOpen={open}
            close={() => setOpen(false)}
            handleError={handleError}
          />
        </Grid>
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </ThemeProvider>
  );
};

export default AdsScreen;
