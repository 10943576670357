import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  makeStyles,
  Box,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import StyledFormTextField from "../../common/textFields/StyledFormTextField";
import CommonModal from "../../common/modals/CommonModal";
import StyledDescriptionTextField from "../../common/textFields/StyledDescriptionTextField";
import Picture from "../../PictureFile.js/Picture";
import FileUploader from "../../FileUploader/FileUploader";
import StyledCategorySelect from "../../common/Selects/StyledCategorySelect";

import { shop, cultivations } from "../../../utils/newApi";
import styles from "./AdModal.styles";
import {
  SERVER_ERROR_MESSAGES,
  ERROR_MESSAGES,
  MODAL_TYPE,
  COMMON_MODAL_TEXTS,
} from "../../../constants/dictionary";
import useForm from "../../../hooks/useForm";
import EVENTS_HELPER_TEXTS from "../../../constants/eventsHelperTexts";
import { getFirstFrameFromVideo } from "../../../utils/ads";

const useStyles = makeStyles(styles);

const NewAdWindow = ({ isOpen, close, handleError }) => {
  const classes = useStyles();
  const { handleChange, values } = useForm({
    name: "",
    description: "",
  });
  const currentDate = new Date();
  const [dateSince, setDateSince] = useState(currentDate);
  const [dateTo, setDateTo] = useState(currentDate);
  const [hourSince, setHourSince] = useState(currentDate);
  const [hourTo, setHourTo] = useState(currentDate);
  const [error, setError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [cultivationTypesArray, setCultivationTypesArray] = useState([]);
  const [selectedCultivationTypesArray, setSelectedCultivationTypesArray] =
    useState([]);
  const [emptyField, setEmptyField] = React.useState(false);
  const [file, setFile] = useState(null);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    cultivations.types
      .all()
      .then((res) => {
        setCultivationTypesArray([{ id: 0, name: "Wszystkie" }, ...res.data]);
      })
      .catch((err) => {
        handleError(ERROR_MESSAGES.COULD_NOT_DOWNLOAD_CROPS);
      });
  }, []);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFile = (file) => {
    setIsLoading(true);

    if (file.type.includes("video")) {
      /*toBase64(file)
        .then((res) => {
          getFirstFrameFromVideo(res).then((canvas) =>
            setVideoThumbnail(canvas.toDataURL().substring(22))
          );
          setIsLoading(false);
        })
        .catch((err) => {
          handleError(err.message);
          setIsLoading(false);
        });*/
      setFileError(true);
      setIsLoading(false);
    } else {
      setFile(file);
      setVideoThumbnail(null);
      setIsLoading(false);
      setFileError(false);
    }
  };

  const selectChange = (e) => {
    if (e.target.value.includes("Wszystkie")) {
      if (
        selectedCultivationTypesArray.length ===
        cultivationTypesArray.length - 1
      ) {
        setSelectedCultivationTypesArray([]);
      } else {
        setSelectedCultivationTypesArray(
          cultivationTypesArray.slice(1).map((type) => type.name)
        );
      }
    } else {
      setSelectedCultivationTypesArray(e.target.value);
    }
  };

  const submit = (e) => {
    if (fileError) {
      return;
    }

    const combinedDateTo = `${format(dateTo, "yyyy/MM/dd")} ${format(
      hourTo,
      "H:mm:ss"
    )}`;
    const date = Date.parse(combinedDateTo);
    const finalDateTo = new Date(date);
    const combinedDateSince = `${format(dateSince, "yyyy/MM/dd")} ${format(
      hourSince,
      "H:mm:ss"
    )}`;
    const date2 = Date.parse(combinedDateSince);
    const finalDateSince = new Date(date2);

    const intersections = cultivationTypesArray.filter((cultivation) =>
      selectedCultivationTypesArray.includes(cultivation.name)
    );

    const payload = new FormData();
    payload.append("file", file);
    payload.append(
      "request",
      new Blob(
        [
          JSON.stringify({
            name: values.name,
            description: values.description,
            startDate: finalDateSince,
            endDate: finalDateTo,
            cultivationTypesId: intersections.map((type) => type.id),
          }),
        ],
        {
          type: "application/json",
        }
      )
    );

    if (
      values.name === "" ||
      values.description === "" ||
      !file ||
      selectedCultivationTypesArray.length < 1
    ) {
      setEmptyField(true);
      return;
    }

    if (Date.parse(finalDateSince) < Date.parse(finalDateTo)) {
      setError(false);
      shop.ads
        .add(payload)
        .then((res) => {
          window.location.reload();
        })
        .catch((err) => {
          handleError(SERVER_ERROR_MESSAGES.COULD_NOT_ADD_AD);
        });
    } else {
      setError(true);
    }
  };

  return (
    <CommonModal
      open={isOpen}
      toggleOpen={close}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.ADD_AD_TITLE}
      type={MODAL_TYPE.ADD}
    >
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledFormTextField
            InputLabelProps={{ classes: { root: classes.label } }}
            fullWidth={true}
            name="name"
            label="Wpisz tytuł reklamy"
            variant="outlined"
            required
            shrink="true"
            onChange={handleChange("name")}
          />
        </Box>
      </Grid>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container direction="row" justify="center">
          <Grid item xs={12} md={6}>
            <Box className={classes.box2}>
              <KeyboardDatePicker
                disableToolbar
                fullWidth={true}
                variant="outline"
                format="MM/dd/yyyy"
                margin="normal"
                label="Od"
                value={dateSince}
                onChange={(date) => setDateSince(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.box2}>
              <KeyboardDatePicker
                disableToolbar
                fullWidth={true}
                variant="outline"
                label="Do"
                format="MM/dd/yyyy"
                margin="normal"
                value={dateTo}
                onChange={(date) => setDateTo(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center">
          <Grid item xs={12} md={6}>
            <Box className={classes.box2}>
              <KeyboardTimePicker
                fullWidth={true}
                ampm={false}
                variant="outline"
                label="Godzina od"
                margin="normal"
                value={hourSince}
                onChange={(date) => setHourSince(date)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.box2}>
              <KeyboardTimePicker
                fullWidth={true}
                ampm={false}
                variant="outline"
                label="Godzina do"
                margin="normal"
                value={hourTo}
                onChange={(date) => setHourTo(date)}
              />
            </Box>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      {error && (
        <Grid item xs={12} align="center">
          <Box className={classes.box}>
            <Typography className={classes.error}>
              {EVENTS_HELPER_TEXTS.dateError}
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box className={classes.box}>
          <Grid container spacing={4} direction="row" justifyContent="center">
            <Grid item xs={12}>
              <StyledCategorySelect
                InputLabelProps={{ classes: { root: classes.label } }}
                name="category"
                fullWidth={true}
                label="Typy upraw"
                variant="outlined"
                shrink="true"
                required
                onChange={selectChange}
                value={selectedCultivationTypesArray}
                select={true}
                SelectProps={{
                  multiple: true,
                  MenuProps: {
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  },
                  renderValue: (selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          variant="outlined"
                          color="primary"
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  ),
                }}
              >
                {cultivationTypesArray.map((cultivation) => {
                  return (
                    <MenuItem key={cultivation.id} value={cultivation.name}>
                      {cultivation.name}
                    </MenuItem>
                  );
                })}
              </StyledCategorySelect>
            </Grid>
            <Grid item xs={12}>
              <FileUploader handleFile={handleFile} />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Grid item xs={12} align="center">
        <Box className={classes.box}>
          <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {emptyField && !file && (
              <p className={classes.error}>Proszę dodać zdjęcie</p>
            )}
            {fileError && (
              <p className={classes.error}>
                Przesłany plik powinien być zdjęciem
              </p>
            )}
            {isLoading ? (
              <CircularProgress color="secondary" />
            ) : (
              (file || videoThumbnail) && (
                <Picture
                  pictureSrc={videoThumbnail ?? file}
                  binaryImage={videoThumbnail ? true : false}
                />
              )
            )}
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledDescriptionTextField
            InputLabelProps={{ classes: { root: classes.label } }}
            fullWidth={true}
            label="Opis reklamy"
            variant="outlined"
            required
            rows={4}
            shrink="true"
            multiline={true}
            name="description"
            onChange={handleChange("description")}
          />
        </Box>
      </Grid>
      {emptyField && (
        <Grid item xs={12} align="center">
          <Typography className={classes.error}>
            Nie wypełniono obowiązkowych pól
          </Typography>
        </Grid>
      )}
    </CommonModal>
  );
};

export default NewAdWindow;
