import React, { useState, useEffect } from "react";
import { Grid, makeStyles, Box } from "@material-ui/core";

import CommonModal from "../../common/modals/CommonModal";
import StyledFormTextField from "../../common/textFields/StyledFormTextField";
import StyledDescriptionTextField from "../../common/textFields/StyledDescriptionTextField";

import styles from "./FAQModal.styles";
import { users } from "../../../utils/newApi";
import {
  SERVER_ERROR_MESSAGES,
  MODAL_TYPE,
  COMMON_MODAL_TEXTS,
} from "../../../constants/dictionary";

const useStyles = makeStyles(styles);

const EditFAQmodalWindow = ({
  listOfFaqs,
  setFaqs,
  isOpen,
  close,
  handleError,
  info,
}) => {
  const classes = useStyles();
  const [answer, setAnswer] = useState("");
  const [question, setQuestion] = useState("");
  const [url, setUrl] = useState("");
  const [emptyFieldsError, setEmptyFieldsError] = useState(false);

  useEffect(() => {
    setAnswer(info.answer);
    setQuestion(info.question);
    setUrl(info.url);
    setEmptyFieldsError(false);
  }, [info, isOpen]);

  const submit = (e) => {
    e.preventDefault();

    if (question.trim().length === 0 || answer.trim().length === 0) {
      setEmptyFieldsError(true);
      return;
    }

    setEmptyFieldsError(false);

    const payload = {
      answer: answer,
      question: question,
      url: url,
    };

    users.faq
      .edit(info.id, payload)
      .then(() => {
        let faqsCopy = [...listOfFaqs];
        faqsCopy = faqsCopy.map((item) => {
          if (item.id === info.id) {
            return {
              id: item.id,
              question: question,
              answer: answer,
              url: url,
            };
          } else {
            return item;
          }
        });
        setFaqs(faqsCopy);
        close();
      })
      .catch(() => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_EDIT_FAQ);
      });
  };

  return (
    <CommonModal
      open={isOpen}
      toggleOpen={close}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.EDIT_FAQ_TITLE}
      type={MODAL_TYPE.EDIT}
    >
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledFormTextField
            InputLabelProps={{
              classes: { root: classes.label },
            }}
            fullWidth={true}
            label="Pytanie"
            variant="outlined"
            required
            value={question}
            shrink="true"
            onChange={(e) => setQuestion(e.target.value)}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledDescriptionTextField
            InputLabelProps={{
              classes: { root: classes.label },
            }}
            fullWidth={true}
            label="Odpowiedź"
            variant="outlined"
            required
            rows={4}
            shrink="true"
            value={answer}
            multiline={true}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledFormTextField
            InputLabelProps={{
              classes: { root: classes.label },
            }}
            fullWidth={true}
            label="Youtube URL"
            variant="outlined"
            value={url}
            shrink="true"
            onChange={(e) => setUrl(e.target.value)}
          />
        </Box>
      </Grid>
      {emptyFieldsError && (
        <p className={classes.error}>Proszę wypełnić wymagane pola</p>
      )}
    </CommonModal>
  );
};

export default EditFAQmodalWindow;
