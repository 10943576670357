import React, { useEffect } from "react";
import { Grid, makeStyles, Box } from "@material-ui/core";

import CommonModal from "../../common/modals/CommonModal";

import styles from "./WorkModal.styles";
import StyledFormTextField from "../../common/textFields/StyledFormTextField";
import { fieldWork } from "../../../utils/newApi";

import {
  SERVER_ERROR_MESSAGES,
  MODAL_TYPE,
  COMMON_MODAL_TEXTS,
} from "../../../constants/dictionary";

const useStyles = makeStyles(styles);

const EditWorkModalWindow = ({
  works,
  setWorks,
  isOpen,
  close,
  handleError,
  info,
}) => {
  const classes = useStyles();
  const [name, setName] = React.useState("");

  useEffect(() => {
    setName(info.name);
  }, [info]);

  const submit = (e) => {
    let payload = {
      name: name,
    };

    fieldWork
      .edit(info.id, payload)
      .then((res) => {
        let worksCopy = [...works];
        worksCopy = worksCopy.map((item) => {
          if (item.id === info.id) {
            return {
              id: item.id,
              name: name,
            };
          } else {
            return item;
          }
        });

        setWorks(worksCopy);
        close();
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_EDIT_WORK);
      });
  };

  return (
    <CommonModal
      open={isOpen}
      toggleOpen={close}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.EDIT_WORK_TITLE}
      type={MODAL_TYPE.EDIT}
    >
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledFormTextField
            InputLabelProps={{
              classes: { root: classes.label },
            }}
            value={name}
            fullWidth={true}
            label="Nazwa pracy"
            variant="outlined"
            required
            shrink="true"
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
      </Grid>
    </CommonModal>
  );
};

export default EditWorkModalWindow;
