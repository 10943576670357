import React, { useState } from "react";
import { makeStyles, Grid, Typography, Box } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import styles from "./EventInfoBox.styles";
import EditEventWindow from "../ModalWindows/Events/EditEventWindow";
import { format } from "date-fns";
import EventIcon from "@material-ui/icons/Event";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../screens/Login/LoginSreen.breakpoints";
import Avatar from "@material-ui/core/Avatar";
import { shop } from "../../utils/newApi";
import { useHistory } from "react-router";
import CommonModal from "../common/modals/CommonModal";

import { COMMON_MODAL_TEXTS } from "../../constants/dictionary";

import {
  SERVER_ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
} from "../../constants/dictionary";
import CustomButton from "../common/buttons/CustomButton";

const useStyles = makeStyles(styles);

const EventInfoBox = ({ info, handleError }) => {
  const classes = useStyles(styles);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const isEventActive = (event) => {
    const currentDate = new Date();
    if (
      currentDate >= new Date(event.startDate) &&
      currentDate <= new Date(event.endDate)
    )
      return true;
    else return false;
  };

  const deleteEvent = (eventId) => {
    shop.events
      .delete(eventId)
      .then((res) => {
        history.push("/dashboard/events");
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_EVENT);
      });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="rows">
        <Grid item xs={matches ? 12 : 7}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            direction="row"
            alignItems="center"
          >
            <Grid item xs={4} align="left">
              <Avatar className={classes.avatar}>
                <EventIcon className={classes.icon} />
              </Avatar>
            </Grid>
            <Grid item xs={8} align="left">
              <Typography className={classes.header}>{info.title}</Typography>
            </Grid>
            <Grid item xs={12} align="left">
              <Box className={classes.desBox}>
                <Typography className={classes.text}>
                  {info && info.description}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <List className={classes.rootList}>
                <Divider variant="inset" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Start"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                      >
                        {info.startDate &&
                          format(new Date(info.startDate), "yyyy/MM/dd")}
                      </Typography>
                    }
                  />
                  <ListItemText
                    primary="Godzina"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                      >
                        {info.startDate &&
                          format(new Date(info.startDate), "HH:mm")}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Koniec"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                      >
                        {info.endDate &&
                          format(new Date(info.endDate), "yyyy/MM/dd")}
                      </Typography>
                    }
                  />
                  <ListItemText
                    primary="Godzina"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                      >
                        {info.endDate &&
                          format(new Date(info.endDate), "HH:mm")}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={matches ? 12 : 5} className={classes.greyBox}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            direction="row"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Box className={!matches && classes.RightBox}>
                <Grid item xs={12} align="center">
                  <Box className={classes.margin}>
                    <Typography className={classes.active}>
                      Wydarzenie aktywne
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} align="center">
                  <Box className={classes.box}>
                    <CustomButton
                      variant={
                        isEventActive(info)
                          ? BUTTON_VARIANTS.GREEN
                          : BUTTON_VARIANTS.RED
                      }
                      size={BUTTON_SIZES.LARGE}
                      text={isEventActive(info) ? "Tak" : "Nie"}
                    />
                  </Box>
                </Grid>
              </Box>
            </Grid>

            <Grid container direction="row">
              <Grid item xs={6}>
                <Box className={classes.buttonBox}>
                  <CustomButton
                    variant={BUTTON_VARIANTS.WHITE}
                    size={BUTTON_SIZES.LARGE}
                    clickHandler={() => setOpenEdit(true)}
                    text="Edytuj wydarzenie"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className={classes.buttonBox}>
                  <CustomButton
                    variant={BUTTON_VARIANTS.RED}
                    size={BUTTON_SIZES.LARGE}
                    clickHandler={() => setOpenConfirmation(true)}
                    text="Usuń wydarzenie"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() => deleteEvent(info.id)}
        title={COMMON_MODAL_TEXTS.EVENT_TITLE}
        content={COMMON_MODAL_TEXTS.EVENT_CONTENT}
      />
      <EditEventWindow
        isOpen={openEdit}
        close={() => setOpenEdit(false)}
        data={info}
        handleError={handleError}
      />
    </div>
  );
};

export default EventInfoBox;
