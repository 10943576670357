const orderStatus = [
  "Oczekiwanie na uzupełnienie danych",
  "Oczekiwanie na płatność",
  "Oczekiwanie na wysyłkę",
  "Wysłane",
  "Zrealizowane",
  "Zamówienie anulowane",
];

export default orderStatus;
