import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { Typography } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styles from "./ActiveSubTable.styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import EditSubstanceWindow from "../ModalWindows/ActiveSubstances/EditSubstance";
import CommonModal from "../common/modals/CommonModal";
import { cultivations } from "../../utils/newApi";
import DeleteEditButton from "../common/buttons/DeleteEditButton";

import {
  SERVER_ERROR_MESSAGES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const ActiveSubstancesTable = ({
  listOfSubstances,
  setSubstances,
  handleError,
  page,
  rowsPerPage,
}) => {
  const theme = useTheme();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [id, setId] = useState("");
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [infoForEdit, setInfoForEdit] = useState("");
  const classes = useStyles(matches);
  const history = useHistory();

  const handleDelete = (id, e) => {
    e.stopPropagation();
    setOpenConfirmation(true);
    setId(id);
  };

  const handleEdit = (substance, e) => {
    e.stopPropagation();
    setInfoForEdit(substance);
    setOpenEdit(true);
  };

  const deleteSubstance = (substanceId) => {
    cultivations.activeSubstances
      .delete(substanceId)
      .then((res) => {
        setSubstances(
          listOfSubstances.filter((item) => item.id !== substanceId)
        );
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_SUBSTANCE);
      });
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            {matches ? (
              <TableRow>
                <TableCell className={classes.productsRes}>
                  NAZWA SUBSTACNCJI
                </TableCell>
                <TableCell className={classes.buttonsRes}></TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell className={classes.products}>
                  NAZWA SUBSTANCJI
                </TableCell>
                <TableCell className={classes.buttons}></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {listOfSubstances &&
              listOfSubstances
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((substance) => (
                  <TableRow key={substance.id}>
                    <TableCell>
                      <Typography className={classes.text}>
                        {substance.name}
                      </Typography>
                    </TableCell>
                    <DeleteEditButton
                      mobile={false}
                      editClickHandler={(e) => {
                        handleEdit(substance, e);
                      }}
                      deleteClickHandler={(e) => {
                        handleDelete(substance.id, e);
                      }}
                    />
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() => deleteSubstance(id)}
        title={COMMON_MODAL_TEXTS.SUBSTANCE_TITLE}
        content={COMMON_MODAL_TEXTS.SUBSTANCE_CONTENT}
      />
      <EditSubstanceWindow
        listOfSubstances={listOfSubstances}
        setSubstances={setSubstances}
        isOpen={openEdit}
        close={() => setOpenEdit(false)}
        data={infoForEdit}
        handleError={handleError}
      />
    </>
  );
};

export default ActiveSubstancesTable;
