import {
  Container,
  Grid,
  Modal,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import styles from "./NewDiscountModal.styles";
import DatePickers from "./DatePickers";
import { shop } from "../../utils/api";
import StyledFormTextField from "../../components/common/textFields/StyledFormTextField";
import { validatePrice, changePriceToInt } from "../../utils/price";

import {
  SERVER_ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
} from "../../constants/dictionary";
import CustomButton from "../../components/common/buttons/CustomButton";

const useStyles = makeStyles(styles);

const NewDiscountModal = (props) => {
  const classes = useStyles();
  const [shopArticles, setShopArticles] = useState([]);
  const [displayedPrice, setDisplayedPrice] = useState(0);
  const [currentProduct, setCurrentProduct] = useState({});
  const [discountEndTime, setDiscountEndTime] = useState(new Date());
  const [discountStartTime, setDiscountStartTime] = useState(new Date());
  const [error, setError] = useState(false);

  const modalSubmitAction = () => {
    if (new Date(discountStartTime) >= new Date(discountEndTime)) {
      setError(true);
      return;
    }

    let payload = currentProduct;
    payload.discountPrice = displayedPrice;
    payload.discountStartTime = discountStartTime.toISOString();
    payload.discountEndTime = discountEndTime.toISOString();

    if (validatePrice(displayedPrice) === false) {
      setError(true);
      return;
    }

    payload.discountPrice = changePriceToInt(displayedPrice);

    shop.articles
      .edit(currentProduct.id, payload)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        props.handleError(SERVER_ERROR_MESSAGES.COULD_NOT_ADD_DISCOUNT);
      });
  };

  useEffect(() => {
    shop.articles
      .all()
      .then((resp) => setShopArticles(resp.data.articles))
      .catch((err) => {
        props.handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_PRODUCTS);
      });
  }, []);

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.handleClose();
        setError(false);
      }}
    >
      <Container className={classes.modalBody}>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.modalGrid}
          spacing={3}
        >
          <Grid item>
            <Typography variant="h3">{props.modalname}</Typography>
          </Grid>

          <Grid
            item
            container
            className={classes.addNewDiscountGrid}
            direction="row"
            spacing={3}
          >
            <Grid item xs={12} sm={12}>
              <Autocomplete
                fullWidth
                options={shopArticles}
                getOptionLabel={(option) => option.name}
                onChange={(event, option) => setCurrentProduct(option)}
                renderInput={(params) => (
                  <StyledFormTextField
                    {...params}
                    error={error}
                    label="Produkt"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <StyledFormTextField
                name="discountPriceOnChange"
                label="Cena promocyjna"
                error={error}
                variant="outlined"
                color="primary"
                value={displayedPrice}
                onChange={(event) => setDisplayedPrice(event.target.value)}
                fullWidth
              />
            </Grid>

            <Grid container item xs={12} sm={12}>
              <DatePickers
                discountEndTime={discountEndTime}
                setDiscountEndTime={setDiscountEndTime}
                discountStartTime={discountStartTime}
                setDiscountStartTime={setDiscountStartTime}
                error={error}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={5}
          >
            <Grid item>
              <CustomButton
                variant={BUTTON_VARIANTS.GREEN}
                size={BUTTON_SIZES.LARGE}
                clickHandler={() => {
                  modalSubmitAction();
                }}
                text="Dodaj"
              />
            </Grid>

            <Grid item>
              <CustomButton
                variant={BUTTON_VARIANTS.RED}
                size={BUTTON_SIZES.LARGE}
                clickHandler={() => {
                  props.handleClose();
                  setError(false);
                }}
                text="Anuluj"
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
};

export default NewDiscountModal;
