import React, { useState, useEffect } from "react";
import { Grid, makeStyles, ThemeProvider, Typography } from "@material-ui/core";
import theme from "../Login/LoginSreen.breakpoints";
import { qrCodes } from "../../utils/newApi";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styles from "./QRScreen.styles";
import QRTable from "../../components/QRTable/QRTable";
import NewQRcodeWindow from "../../components/QR/NewQRcodeWindow";
import StyledFormTextField from "../../components/common/textFields/StyledFormTextField";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../components/common/pagination/TablePaginationActions";
import ListHeader from "../../components/common/ListHeader";

import { SERVER_ERROR_MESSAGES } from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const QRScreen = () => {
  const [QRs, setQRs] = useState([]);
  const [QRsCopy, setQRsCopy] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [page, setPage] = React.useState(
    localStorage.getItem("currentQRTablePage")
      ? Number(localStorage.getItem("currentQRTablePage"))
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(
    localStorage.getItem("currentQRTableRowsPerPage")
      ? Number(localStorage.getItem("currentQRTableRowsPerPage"))
      : 10
  );

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  useEffect(() => {
    qrCodes
      .all()
      .then((res) => {
        setQRs(res.data);
        setQRsCopy(res.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_QR_CODES);
      });
  }, []);

  const handleChange = (e) => {
    if (e.target.value !== "") {
      const res = QRsCopy.filter((code) => {
        return (
          code.username &&
          code.username.toLowerCase().startsWith(e.target.value.toLowerCase())
        );
      });
      setQRs(res);
      setPage(0);
    } else {
      setQRs(QRsCopy);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <Grid container spacing={matches ? 3 : 0}>
          <ListHeader
            variant="title-add-search"
            title="Lista kodów QR"
            handleAdd={() => setOpen(true)}
            searchComponent={
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                name="name"
                fullWidth={matches}
                label="Szukaj użytkownika"
                variant="outlined"
                shrink="true"
                onChange={(e) => handleChange(e)}
              />
            }
          />
          <Grid container>
            <Grid item xs={12} align="center">
              <QRTable
                QRcodes={QRs}
                setQRs={setQRs}
                QRcodesCopy={QRsCopy}
                handleError={handleError}
                page={page}
                rowsPerPage={rowsPerPage}
              />
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 50]}
                component="div"
                count={QRs ? QRs.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                classes={{
                  toolbar: classes.toolbar,
                  caption: classes.caption,
                }}
                labelRowsPerPage={"Ilość wierszy na stronę"}
                ActionsComponent={TablePaginationActions}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}–${to} z ${
                    count !== -1 ? count : `więcej niż ${to}`
                  }`;
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.container}>
          <Typography className={classes.loading}>
            Trwa ładowanie kodów ...
          </Typography>
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
      <NewQRcodeWindow
        open={open}
        close={() => setOpen(false)}
        handleError={handleError}
      />
    </ThemeProvider>
  );
};

export default QRScreen;
