import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { Grid, Typography } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styles from "./ProductsToProtectPlantsTable.styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { SortIcon } from "../common/buttons/SortIcon";
import EditProductToProtectPlantsWindow from "../ModalWindows/ProductsToProtectPlants/EditProductToProtectPlantsWindow";
import CommonModal from "../common/modals/CommonModal";
import DeleteEditButton from "../common/buttons/DeleteEditButton";
import CustomButton from "../common/buttons/CustomButton";

import {
  SERVER_ERROR_MESSAGES,
  COMMON_MODAL_TEXTS,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
} from "../../constants/dictionary";
import {
  sortConstants,
  plantsProtectionProductsSort,
} from "../../constants/localStorage";
import { plantProtectionProducts } from "../../utils/newApi";

const useStyles = makeStyles(styles);

const ProductsToProtectPlantsTable = ({
  products,
  setPlantsProducts,
  plantsProductsCopy,
  handleError,
  page,
  rowsPerPage,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [id, setId] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [infoForEdit, setInfoForEdit] = useState("");
  const [isAscN, setIsAscN] = useState(null);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [isActiveName, setIsActiveName] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (plantsProductsCopy.length !== 0) {
      const sortToApply = localStorage.getItem(
        sortConstants.plantsProtectionProducts
      );

      if (sortToApply) {
        switch (sortToApply) {
          case plantsProtectionProductsSort.nameA:
            sortName(true);
            break;
          case plantsProtectionProductsSort.nameD:
            sortName(false);
            break;
        }
      }
    }
  }, [plantsProductsCopy]);

  const handleDelete = (id, e) => {
    e.stopPropagation();
    setOpenConfirmation(true);
    setId(id);
  };

  const handleEdit = (product, e) => {
    e.stopPropagation();
    setInfoForEdit(product);
    setOpenEdit(true);
  };

  const sortName = (isAsc = null) => {
    setIsActiveName(true);
    setIsAscN((prev) => isAsc ?? !prev);
  };

  useEffect(() => {
    if (isAscN !== null) {
      const sorted = [...products].sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (isAscN) {
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        } else {
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
        }
        return 0;
      });

      setPlantsProducts(sorted);

      if (isAscN) {
        localStorage.setItem(
          sortConstants.plantsProtectionProducts,
          plantsProtectionProductsSort.nameA
        );
      } else {
        localStorage.setItem(
          sortConstants.plantsProtectionProducts,
          plantsProtectionProductsSort.nameD
        );
      }
    }
  }, [isAscN]);

  const deleteProduct = (productId) => {
    plantProtectionProducts
      .deleteProduct(productId)
      .then(() => {
        setPlantsProducts(products.filter((item) => item.id !== productId));
      })
      .catch(() => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_RESOURCE);
      });
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            {matches ? (
              <TableRow>
                <TableCell
                  className={classes.nameRes}
                  align="right"
                ></TableCell>
                <TableCell className={classes.descRes}></TableCell>
                <TableCell className={classes.subRes}></TableCell>
                <TableCell className={classes.subRes}></TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell align="left" className={classes.name}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={12}>
                      <div className={classes.headerCell}>
                        <Typography className={classes.cell}>
                          NAZWA PRODUKTU
                        </Typography>
                        <div
                          className={classes.headerCellRight}
                          onClick={() => sortName()}
                        >
                          <SortIcon isactive={isActiveName} asacdesc={isAscN} />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" className={classes.description}>
                  OPIS
                </TableCell>
                <TableCell align="left" className={classes.doses}>
                  DAWKI
                </TableCell>
                <TableCell align="left" className={classes.substance}>
                  SUBSTANCJA
                </TableCell>
                <TableCell className={classes.buttons}></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {products
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((product) => (
                <TableRow
                  key={product.id}
                  className={classes.row}
                  onClick={() => {
                    localStorage.setItem(
                      "currentProductsToProtectPlantsPage",
                      page
                    );
                    localStorage.setItem(
                      "currentProductsToProtectPlantsRowsPerPage",
                      rowsPerPage
                    );
                    history.push(
                      `/dashboard/plants-protection-product/${product.id}`
                    );
                  }}
                >
                  <TableCell align="left">
                    <Grid
                      container
                      justifyContent={matches && "center"}
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={matches ? 12 : 8}
                        align={matches ? "center" : "left"}
                        className={matches && classes.breakline}
                      >
                        <Typography noWrap className={classes.cell}>
                          {product.name}
                        </Typography>
                      </Grid>
                      {matches && (
                        <DeleteEditButton
                          mobile={true}
                          editClickHandler={(e) => {
                            handleEdit(product, e);
                          }}
                          deleteClickHandler={(e) => {
                            handleDelete(product.id, e);
                          }}
                        />
                      )}
                    </Grid>
                  </TableCell>

                  <TableCell>
                    <Typography noWrap className={classes.cell}>
                      {product.description}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <CustomButton
                      variant={
                        product?.dosageAdded
                          ? BUTTON_VARIANTS.GREEN
                          : BUTTON_VARIANTS.RED
                      }
                      size={BUTTON_SIZES.SMALL}
                      text={product?.dosageAdded ? "Tak" : "Nie"}
                    />
                  </TableCell>

                  {!matches && (
                    <TableCell noWrap align="left" className={classes.buttons2}>
                      <Typography className={classes.cell}>
                        {product.activeSubstanceDAO
                          ? product.activeSubstanceDAO.name
                          : "Brak"}
                      </Typography>
                    </TableCell>
                  )}
                  {matches && (
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            noWrap
                            className={classes.cell}
                            align="center"
                          >
                            SUBSTANCJA
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.cell} align="center">
                            {product.activeSubstanceDAO
                              ? product.activeSubstanceDAO.name
                              : "Brak"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  )}
                  {!matches && (
                    <DeleteEditButton
                      mobile={false}
                      editClickHandler={(e) => {
                        handleEdit(product, e);
                      }}
                      deleteClickHandler={(e) => {
                        handleDelete(product.id, e);
                      }}
                    />
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() => deleteProduct(id)}
        title={COMMON_MODAL_TEXTS.RESOURCE_TITLE}
        content={COMMON_MODAL_TEXTS.RESOURCE_CONTENT}
      />
      <EditProductToProtectPlantsWindow
        products={products}
        setProducts={setPlantsProducts}
        isOpen={openEdit}
        close={() => setOpenEdit(false)}
        data={infoForEdit}
        handleError={handleError}
      />
    </>
  );
};

export default ProductsToProtectPlantsTable;
