import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { Grid, Typography, Button } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styles from "./CocoonBoxesTable.styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { format } from "date-fns";
import TodayIcon from "@material-ui/icons/Today";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import CustomButton from "../common/buttons/CustomButton";

import { BUTTON_VARIANTS, BUTTON_SIZES } from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const CocoonBoxesTable = ({ cooconBoxes }) => {
  const theme = useTheme();
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            {matches ? (
              <TableRow>
                <TableCell className={classes.connectedToRes} align="center">
                  <Button startIcon={<TodayIcon />}>
                    <Typography className={classes.cell}>
                      DATA EKSTRAKCJI KOKONÓW
                    </Typography>
                  </Button>
                </TableCell>
                <TableCell className={classes.usersRes}>
                  <Button startIcon={<AttachMoneyIcon />}>
                    <Typography className={classes.cell}>SPRZEDANY</Typography>
                  </Button>
                </TableCell>
                <TableCell className={classes.usersRes}>
                  <Typography className={classes.cell}>ILOŚĆ</Typography>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell align="left" className={classes.connectedTo}>
                  <Button startIcon={<TodayIcon className={classes.icon} />}>
                    <Typography className={classes.cell}>
                      DATA EKSTRAKCJI KOKONÓW
                    </Typography>
                  </Button>
                </TableCell>
                <TableCell className={classes.users}>
                  <Button
                    startIcon={<AttachMoneyIcon className={classes.icon} />}
                  >
                    <Typography className={classes.cell}>SPRZEDANY</Typography>
                  </Button>
                </TableCell>
                <TableCell className={classes.users}>
                  <Typography className={classes.cell}>ILOŚĆ</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {cooconBoxes &&
              cooconBoxes.map((box) => (
                <TableRow key={box.id} className={classes.row}>
                  <TableCell>
                    <Typography className={classes.text}>
                      {box.harvestDate
                        ? format(new Date(box.harvestDate), "dd/MM/yyyy")
                        : "BRAK"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Grid item xs={12} align="left">
                      <CustomButton
                        variant={
                          box.sold ? BUTTON_VARIANTS.GREEN : BUTTON_VARIANTS.RED
                        }
                        size={BUTTON_SIZES.SMALL}
                        text={box.sold ? "Tak" : "Nie"}
                      />
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.text}>
                      {box.cocoonsAmount}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CocoonBoxesTable;
