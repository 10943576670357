import React, { useState } from "react";
import { Grid, makeStyles, Box } from "@material-ui/core";

import CommonModal from "../../common/modals/CommonModal";
import StyledFormTextField from "../../common/textFields/StyledFormTextField";
import StyledDescriptionTextField from "../../common/textFields/StyledDescriptionTextField";

import { users } from "../../../utils/newApi";
import useForm from "../../../hooks/useForm";
import styles from "./FAQModal.styles";
import {
  ERROR_MESSAGES,
  MODAL_TYPE,
  COMMON_MODAL_TEXTS,
} from "../../../constants/dictionary";

const useStyles = makeStyles(styles);

const NewFAQmodalWindow = ({ isOpen, close, handleError }) => {
  const [emptyFieldsError, setEmptyFieldsError] = useState(false);

  const classes = useStyles();
  const { handleChange, values } = useForm({
    answer: "",
    question: "",
    url: "",
  });

  const submit = (e) => {
    e.preventDefault();

    if (
      values.question.trim().length === 0 ||
      values.answer.trim().length === 0
    ) {
      setEmptyFieldsError(true);
      return;
    }

    setEmptyFieldsError(false);

    if (values.videoURL === "") values.videoURL = null;

    const payload = {
      answer: values.answer,
      question: values.question,
      url: values.videoURL,
    };

    users.faq
      .add(payload)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        handleError(ERROR_MESSAGES.COULD_NOT_ADD_FAQ);
      });
  };

  return (
    <CommonModal
      open={isOpen}
      toggleOpen={close}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.ADD_FAQ_TITLE}
      type={MODAL_TYPE.ADD}
    >
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledFormTextField
            InputLabelProps={{
              classes: { root: classes.label },
            }}
            fullWidth={true}
            name="question"
            label="Pytanie"
            variant="outlined"
            required
            shrink="true"
            onChange={handleChange("question")}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledDescriptionTextField
            InputLabelProps={{
              classes: { root: classes.label },
            }}
            fullWidth={true}
            label="Odpowiedź"
            variant="outlined"
            rows={4}
            required
            shrink="true"
            multiline={true}
            name="answer"
            onChange={handleChange("answer")}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledFormTextField
            InputLabelProps={{
              classes: { root: classes.label },
            }}
            fullWidth={true}
            name="videoURL"
            label="Youtube URL"
            variant="outlined"
            shrink="true"
            onChange={handleChange("videoURL")}
          />
        </Box>
      </Grid>
      {emptyFieldsError && (
        <p className={classes.error}>Proszę wypełnić wymagane pola</p>
      )}
    </CommonModal>
  );
};

export default NewFAQmodalWindow;
