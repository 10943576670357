import { createTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import colors from "../../constants/colors";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 980,
      md: 1080,
      lg: 1280,
      xl: 1920,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  header: {
    marginTop: "2em",
    marginLeft: "2em",
  },
  text: {
    fontFamily: "Montserrat",
    color: colors.black,
  },
  delete: {
    color: colors.htmlRed,
    fontFamily: "Montserrat",
  },
  loader: {
    "justify-content": "center",
    "align-items": "center",
  },
  cell: {
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    color: colors.black,
  },
  row: {
    transform: "all 0.25s ease-in-out",
    "&:hover": {
      transform: "scale(1.01)",
      cursor: "pointer",
    },
  },
  id: {
    width: "10%",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  orderer: {
    width: "30%",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  status: {
    width: "10%",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  value: {
    width: "10%",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  date: {
    width: "10%",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  buttons: {
    width: "10%",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  idRes: {
    minWidth: "50px",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  ordererRes: {
    minWidth: "200px",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  statusRes: {
    minWidth: "200px",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  valueRes: {
    minWidth: "100px",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  dateRes: {
    minWidth: "100px",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  buttonsRes: {
    minWidth: "200px",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  editIcon: {
    color: colors.black,
  },
  deleteIcon: {
    color: colors.htmlRed,
  },
  cancel: {
    backgroundColor: colors.melon,
    transform: "all 0.25s ease-in-out",
    "&:hover": {
      transform: "scale(1.01)",
      cursor: "pointer",
    },
  },
  caption: {
    fontSize: "15px",
    "font-family": "Montserrat",
  },
  toolbar: {
    "& > p:nth-of-type(2)": {
      fontSize: "15px",
      color: colors.black,
      "font-family": "Montserrat",
      fontWeight: 600,
    },
  },
  container: {
    display: "flex",
    "flex-direction": "row",
    "justify-content": "center",
    "align-items": "center",
    height: "100%",
    width: "100%",
  },
  loader: {
    "justify-content": "center",
    "align-items": "center",
  },
}));

export { useStyles, theme };
