import React, { useState } from "react";
import { makeStyles, Grid, Typography, Box } from "@material-ui/core";
import styles from "./roductsToProtectPlantsInfoBox.styles";
import Avatar from "@material-ui/core/Avatar";
import EditProductToProtectPlantsWindow from "../ModalWindows/ProductsToProtectPlants/EditProductToProtectPlantsWindow";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import { RiPlantLine } from "react-icons/ri";
import CommonModal from "../common/modals/CommonModal";
import { useHistory } from "react-router";

import {
  SERVER_ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";
import CustomButton from "../common/buttons/CustomButton";
import { plantProtectionProducts } from "../../utils/newApi";
import Doses from "./Doses";

const useStyles = makeStyles(styles);

const ProductsToProtectPlantsInfoBox = ({ info, handleError }) => {
  const classes = useStyles(styles);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const history = useHistory();

  const deleteProduct = (productId) => {
    plantProtectionProducts
      .deleteProduct(productId)
      .then(() => {
        history.push("/dashboard/plants-protection-products");
      })
      .catch(() => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_RESOURCE);
      });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="rows">
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            direction="row"
            alignItems="center"
          >
            <Grid item xs={3} align="left">
              <Avatar className={classes.avatar}>
                <RiPlantLine className={classes.icon} />
              </Avatar>
            </Grid>
            <Grid item xs={9} align="left">
              <Box sx={{ maxWidth: "50vw" }}>
                <Typography className={classes.header}>
                  {info && info.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} align="left">
              <Box className={classes.desBox}>
                <Typography className={classes.text}>
                  {info ? info.description : "Brak"}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <List className={classes.rootList}>
                <Divider variant="inset" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Substancje czynne"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                      >
                        {info.activeSubstanceDAOList &&
                          info.activeSubstanceDAOList.map(
                            (substance) => `${substance.name}, `
                          )}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.greyBox}>
          <Doses
            doses={info.dosageDAOList}
            productId={info.id}
            handleError={handleError}
          />
          <Grid
            container
            spacing={2}
            justifyContent="center"
            direction="row"
            alignItems="center"
          >
            <Grid container direction="row">
              <Grid item xs={6}>
                <Box className={classes.buttonBox}>
                  <CustomButton
                    variant={BUTTON_VARIANTS.WHITE}
                    size={BUTTON_SIZES.LARGE}
                    clickHandler={() => setOpenEdit(true)}
                    text="Edytuj środek"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className={classes.buttonBox}>
                  <CustomButton
                    variant={BUTTON_VARIANTS.RED}
                    size={BUTTON_SIZES.LARGE}
                    clickHandler={() => setOpenConfirmation(true)}
                    text="Usuń środek"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() => deleteProduct(info.id)}
        title={COMMON_MODAL_TEXTS.RESOURCE_TITLE}
        content={COMMON_MODAL_TEXTS.RESOURCE_CONTENT}
      />
      <EditProductToProtectPlantsWindow
        isOpen={openEdit}
        close={() => setOpenEdit(false)}
        data={info}
        handleError={handleError}
      />
    </div>
  );
};

export default ProductsToProtectPlantsInfoBox;
