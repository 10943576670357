const styles = (theme) => {
    return {
      row: {
        transform: "all 0.25s ease-in-out",
        "&:hover": {
          transform: "scale(1.01)",
          cursor: "pointer",
        },
      },
      connectedTo: {
        "font-weight": "bold",
        "font-size": "15px",
        "font-family": "Montserrat",
        "border-bottom": "4px solid",
        width: "35%",
      },
      users: {
        "font-weight": "bold",
        "font-size": "15px",
        "font-family": "Montserrat",
        "border-bottom": "4px solid",
        width: "20%",
      },
      cell: {
        "font-weight": "bold",
        "font-size": "15px",
        "font-family": "Montserrat",
      },
      box: {
        marginLeft: "5%",
        marginRight: "5%",
      },
      connectedToRes: {
        "border-bottom": "3px solid",
        "font-weight": "bold",
        "font-size": "15px",
        "font-family": "Montserrat",
        minWidth: "200px",
      },
      usersRes: {
        "font-weight": "bold",
        "font-size": "15px",
        "font-family": "Montserrat",
        "border-bottom": "3px solid",
        minWidth: "150px",
      },
      headerCell: {
        display: "flex",
      },
      headerCellRight: {
        marginLeft: "2px",
      },
      breakline: {
        "border-bottom": "1px solid grey",
      },
      text: {
        "font-size": "15px",
        "font-family": "Montserrat",
        "font-weight": "bold"
      },
      icon: {
          height: 30,
          width: 30
      }
    };
  };
  
  export default styles;
  