import React, { useState, useEffect } from "react";
import { Grid, makeStyles, Box, Typography } from "@material-ui/core";

import CommonModal from "../../common/modals/CommonModal";
import StyledFormTextField from "../../common/textFields/StyledFormTextField";

import styles from "./PathogensModal.styles";
import { cultivations } from "../../../utils/newApi";
import {
  SERVER_ERROR_MESSAGES,
  MODAL_TYPE,
  COMMON_MODAL_TEXTS,
} from "../../../constants/dictionary";

const useStyles = makeStyles(styles);

const EditPathogenWindow = ({
  listOfPathogens,
  setPathogens,
  isOpen,
  close,
  handleError,
  data,
}) => {
  const [emptyField, setEmptyField] = useState(false);
  const [pathogen, setPathogen] = useState("");

  const classes = useStyles();

  useEffect(() => {
    setEmptyField(false);
    setPathogen(data.name);
  }, [data, isOpen]);

  const submit = (e) => {
    e.preventDefault();

    if (pathogen === "") {
      setEmptyField(true);
      return;
    }

    const payload = {
      name: pathogen,
    };

    cultivations.pathogens
      .edit(data.id, payload)
      .then((res) => {
        let pathogensCopy = [...listOfPathogens];
        pathogensCopy = pathogensCopy.map((item) => {
          if (item.id === data.id) {
            return {
              id: data.id,
              name: pathogen,
              cultivationDevelopmentStageDAO:
                item.cultivationDevelopmentStageDAO,
            };
          } else {
            return item;
          }
        });

        setPathogens(pathogensCopy);
        close();
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_EDIT_PATHOGEN);
      });
  };

  return (
    <CommonModal
      open={isOpen}
      toggleOpen={close}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.EDIT_PATHOGEN_TITLE}
      type={MODAL_TYPE.EDIT}
    >
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledFormTextField
            value={pathogen}
            InputLabelProps={{ classes: { root: classes.label } }}
            fullWidth={true}
            name="pathogen"
            label="Nazwa"
            variant="outlined"
            required
            shrink="true"
            onChange={(e) => setPathogen(e.target.value)}
          />
        </Box>
      </Grid>
      {emptyField && (
        <Grid item xs={12} align="center">
          <Typography className={classes.error}>
            Nie podano nazwy patogenu
          </Typography>
        </Grid>
      )}
    </CommonModal>
  );
};

export default EditPathogenWindow;
