import React, { useEffect, useState } from "react";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import { push, cultivations, users } from "../../utils/newApi";
import { Box, Container } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import SuccessSnackBar from "../../components/Snackbar/SuccessSnackBar";
import {
  Checkbox,
  FormControlLabel,
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText,
} from "@material-ui/core";
import useStyles from "./PushNotifications.styles";
import CustomButton from "../../components/common/buttons/CustomButton";

import { BUTTON_VARIANTS, BUTTON_SIZES } from "../../constants/dictionary";

const PushNotifications = () => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [errorRecipients, setErrorRecipients] = useState(false);
  const [errorTextFields, setErrorTextFields] = useState(false);
  const [all, setAll] = useState(false);
  const [thermometers, setThermometers] = useState(false);
  const [hives, setHives] = useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [success, setSuccess] = useState(false);
  const [cultivationTypes, setCultivationTypes] = useState([]);
  const [chosenTypes, setChosenTypes] = useState([]);
  const [sending, setSending] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [chosenUsers, setChosenUsers] = useState([]);

  const clearAll = () => {
    setChosenUsers([]);
    setChosenTypes([]);
    setThermometers(false);
    setHives(false);
  };

  const handleAddAll = () => {
    clearAll();
    setAll(true);
  };

  const handleDeleteAll = () => {
    clearAll();
    setAll(false);
  };

  const handleChangeTypes = (event) => {
    if (all) {
      handleDeleteAll();
    }
    const {
      target: { value },
    } = event;
    setChosenTypes(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeUsers = (event) => {
    if (all) {
      handleDeleteAll();
    }
    const {
      target: { value },
    } = event;
    setChosenUsers(typeof value === "string" ? value.split(",") : value);
  };

  const onChangeAll = (e) => {
    const {
      target: { checked },
    } = e;
    checked ? handleAddAll() : handleDeleteAll();
  };

  const onChangeThermometers = (e) => {
    const {
      target: { checked },
    } = e;
    if (checked && all) {
      handleDeleteAll();
    }
    setThermometers(checked);
  };

  const onChangeHives = (e) => {
    const {
      target: { checked },
    } = e;
    if (checked && all) {
      handleDeleteAll();
    }
    setHives(checked);
  };

  const handleSend = async () => {
    if (sending) return;

    setErrorRecipients(false);
    setErrorTextFields(false);

    if (
      !all &&
      !thermometers &&
      !hives &&
      chosenTypes.length === 0 &&
      chosenUsers.length === 0
    ) {
      setErrorRecipients(true);
      return;
    }

    if (title.length === 0 || body.length === 0) {
      setErrorTextFields(true);
      return;
    }

    setSending(true);

    const intersections = cultivationTypes.filter((cultivation) =>
      chosenTypes.includes(cultivation.name)
    );

    const payload = {
      allUsers: all,
      body: body,
      cultivationTypesId: intersections.map((type) => type.id),
      hiveOwners: hives,
      thermometersOwners: thermometers,
      title: title,
      usersId: chosenUsers.map((el) => el.id),
    };

    push
      .send(payload)
      .then(() => {
        setSuccess(true);
        setSending(false);
      })
      .catch((err) => {
        setError(true);
        setSending(false);
      });
  };

  useEffect(() => {
    users.usersInfo
      .all()
      .then((res) => {
        setUsersList(res.data.filter((el) => el.type === "USER"));
      })
      .catch(() => setError(true));
    cultivations.types
      .all()
      .then((res) => setCultivationTypes(res.data))
      .catch(() => setError(true));
  }, []);

  return (
    <Container maxWidth="sm">
      <ErrorSnackBar
        open={error}
        close={() => setError(false)}
        text="Coś poszło nie tak"
      />
      <SuccessSnackBar
        open={success}
        close={() => setSuccess(false)}
        text="Powiadomienie wysłane"
      />
      <Grid container direction="column" spacing={2}>
        <Grid item align="center">
          <Typography variant="h5">Wysyłanie powiadomienia push</Typography>
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            variant="outlined"
            label="Tytuł"
            placeholder="Tytuł"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            error={errorTextFields}
            helperText={errorTextFields && "Należy nadać tytuł"}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            multiline
            maxRows={10}
            minRows={5}
            variant="outlined"
            label="Treść"
            placeholder="Treść"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            error={errorTextFields}
            helperText={errorTextFields && "Należy podać treść"}
          />
        </Grid>
        <Grid item align="center">
          <Typography variant="h6">Odbiorcy</Typography>
        </Grid>
        <Grid item>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gridGap={12}
          >
            <FormControlLabel
              control={<Checkbox checked={all} onChange={onChangeAll} />}
              label="Wszyscy"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={thermometers}
                  onChange={onChangeThermometers}
                />
              }
              label="Posiadacze termometrów"
            />
            <FormControlLabel
              control={<Checkbox checked={hives} onChange={onChangeHives} />}
              label="Posiadacze uli"
            />
            <FormControlLabel
              control={
                <Select
                  multiple
                  className={classes.cultivationSelect}
                  input={<OutlinedInput />}
                  value={chosenTypes}
                  renderValue={(selected) => selected.join(", ")}
                  onChange={handleChangeTypes}
                >
                  <MenuItem disabled>
                    <em>Typy upraw</em>
                  </MenuItem>
                  {cultivationTypes.map((type) => (
                    <MenuItem key={type.id} value={type.name}>
                      <Checkbox checked={chosenTypes.indexOf(type.name) >= 0} />
                      <ListItemText primary={type.name} />
                    </MenuItem>
                  ))}
                </Select>
              }
              label="Posiadacze upraw typu:"
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Select
                  multiple
                  className={classes.userSelect}
                  input={<OutlinedInput />}
                  renderValue={(selected) =>
                    selected.map((el) => el.email).join(", ")
                  }
                  value={chosenUsers}
                  onChange={handleChangeUsers}
                >
                  <MenuItem disabled>
                    <em>Użytkownicy</em>
                  </MenuItem>
                  {usersList.map((owner) => (
                    <MenuItem key={owner.id} value={owner}>
                      <Checkbox
                        checked={chosenUsers.some((el) => el.id === owner.id)}
                      />
                      <ListItemText primary={owner.email} />
                    </MenuItem>
                  ))}
                </Select>
              }
              label="Konkretni użytkownicy:"
              labelPlacement="start"
            />
          </Box>
        </Grid>
        <Grid item align="center" className={classes.margin}>
          <Typography variant="h6">Potwierdź wysłanie powiadomienia</Typography>
        </Grid>
        <Grid item align="center">
          <CustomButton
            variant={BUTTON_VARIANTS.GREEN}
            size={BUTTON_SIZES.MEDIUM}
            clickHandler={handleSend}
            text="Wyślij"
          />
        </Grid>
        {errorRecipients && (
          <Grid item align="center">
            <Typography variant="body1" className={classes.error}>
              Należy wybrać odbiorców!
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default PushNotifications;
