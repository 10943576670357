import colors from "../../constants/colors";

const styles = (theme, isDiscount) => {
  return {
    marginBox: {
      margin: "2%",
      paddingLeft: "2%",
      paddingRight: "2%",
    },
    topLeftBox: {
      marginTop: "2%",
      marginBottom: "2%",
    },
    buttonBox: {
      paddingLeft: "10%",
      paddingRight: "10%",
      marginBottom: "5%",
      marginTop: "10%",
    },
    headerMargin: {
      marginLeft: "3%",
      marginRight: "3%",
      marginTop: "5%",
    },
    productBox: {
      margin: "2%",
    },
    dataBox: {
      marginLeft: "5%",
      marginRight: "5%",
      borderRadius: "10px",
      border: "1px solid black",
    },
    dataBoxDesc: {
      marginLeft: "5%",
      marginRight: "5%",
      borderRadius: "10px",
      border: "1px solid black",
    },
    dataBoxPrices: {
      marginLeft: "5%",
      marginRight: "5%",
      borderRadius: "10px",
      border: "1px solid black",
      display: "flex",
      padding: "2%",
      "flex-direction": "row",
      "justify-content": "space-between",
      "flex-wrap": "wrap",
    },
    root: {
      backgroundColor: colors.white,
      padding: "5%",
    },
    cursors: {
      cursor: "pointer",
    },
    scroll: {
      flex: 1,
      "overflow-y": "scroll",
      "overflow-x": "hidden",
      width: "100%",
      height: "100%",
    },
    header: {
      [theme.breakpoints.down("sm")]: {
        "font-size": "20px",
      },
      [theme.breakpoints.up("sm")]: {
        "font-size": "25px",
      },
      "text-align": "left",
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    rootList: {
      maxWidth: "100%",
      backgroundColor: theme.palette.background.paper,
      borderRadius: 20,
    },
    prices: {
      "font-weight": "bold",
      "font-family": "Montserrat",
      "font-size": "20px",
    },
    lightPrices: {
      "font-family": "Montserrat",
      "font-size": "17px",
    },
    listText: {
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    avatar: {
      [theme.breakpoints.down("sm")]: {
        width: "75px",
        height: "75px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "100px",
        height: "100px",
      },
    },
    text: {
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    greyBox: {
      backgroundColor: colors.platinum,
      borderRadius: 20,
    },
  };
};

export default styles;
