import colors from "../../constants/colors";

const styles = () => ({
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  modalBody: {
    justifyContent: "center",
    alignItems: "center",
    padding: "3%",
    border: "1px solid black",
    borderRadius: "20px",
    backgroundColor: colors.white,
    minHeight: "30%",

    width: window.innerWidth < 700 ? "95%" : "40%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  modalGrid: {
    marginBottom: "5%",
  },
  addNewDiscountGrid: {
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
});

export default styles;
