export const getFirstFrameFromVideo = (file) =>
  new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.src = file;

    setTimeout(() => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      video.currentTime = 1;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0);
      resolve(canvas);
    }, 1000);
  });
