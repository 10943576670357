import React from "react";
import { Container } from "@material-ui/core";
import PdfViewer from "../../components/PdfViewer/PdfViewer.js";
import pdfFile from "../../assets/docs/terms.pdf";

const TermsScreen = () => {
  return (
    <Container>
      <PdfViewer source={pdfFile} />
    </Container>
  );
};

export default TermsScreen;
