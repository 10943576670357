import React from "react";

import CommonModal from "../common/modals/CommonModal";

import { qrCodes } from "../../utils/newApi";
import {
  SERVER_ERROR_MESSAGES,
  MODAL_TYPE,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";

const NewQRcodeWindow = ({ open, close, handleError }) => {
  const createCode = () => {
    qrCodes
      .add()
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_CREATE_QR_CODE);
      });
  };

  return (
    <CommonModal
      open={open}
      toggleOpen={close}
      handleConfirmation={createCode}
      title={COMMON_MODAL_TEXTS.ADD_QR_CODE_TITLE}
      content={COMMON_MODAL_TEXTS.ADD_QR_CODE_CONTENT}
      type={MODAL_TYPE.ADD}
    />
  );
};

export default NewQRcodeWindow;
