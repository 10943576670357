import colors from "../../constants/colors";

const styles = {
  box: {
    margin: "5%",
  },
  box2: {
    marginTop: "8%",
    marginLeft: "2%",
    marginRight: "2%",
    marginBottom: "8%",
  },

  header: {
    color: colors.white,
    fontWeight: "bold",
  },
  info: {
    color: colors.white,
  },
};

export default styles;
