import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import Email from "@material-ui/icons/Email";
import useStyles from "./OrderStatus.styles";
import orderStatus from "../../constants/orderConstants";

import { itemStatusColor } from "../../utils/orders";

const OrderStatus = (props) => {
  const classes = useStyles();
  return (
    <Grid className={classes.root} container direction="column" spacing={2}>
      <Grid item>
        <div className={classes.container}>
          <div
            className={classes.avatar}
            style={itemStatusColor(props.status)}
          />
          <Typography className={classes.text} variant="body1">
            {props.status}
          </Typography>
        </div>
      </Grid>
      {props.status === orderStatus[2] && (
        <Grid item align="center">
          <Button
            variant="contained"
            onClick={props.handleMarkAsSent}
            startIcon={<Email className={classes.buttonText} />}
          >
            <Typography className={classes.buttonText} variant="body1">
              oznacz jako wysłane
            </Typography>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default OrderStatus;
