import { makeStyles } from "@material-ui/core";

import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1em",
  },
  text: {
    fontFamily: "Montserrat",
  },
  buttonText: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 14,
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    height: "2.5em",
    width: "2.5em",
    marginRight: "1em",
    borderRadius: "2.5em",
    border: "2px solid",
    borderColor: colors.white,
  },
}));

export default useStyles;
