export const SERVER_ERROR_MESSAGES = {
  // SUBSTANCES
  COULD_NOT_ADD_SUBSTANCE: "Błąd serwera, nie udało się dodać substancji",
  COULD_NOT_DELETE_SUBSTANCE: "Błąd serwera, nie udało się usunąć substancji",
  COULD_NOT_EDIT_SUBSTANCE: "Błąd serwera, nie udało się edytować substancji",
  COULD_NOT_DOWNLOAD_SUBSTANCE: "Błąd serwera, nie udało się pobrać substancji",

  // ADS
  COULD_NOT_ADD_AD: "Błąd serwera, nie udało się dodać reklamy",
  COULD_NOT_DELETE_AD: "Błąd serwera, nie udało się usunąć reklamy",
  COULD_NOT_EDIT_AD: "Błąd serwera, nie udało się edytować reklamy",

  // CATEGORIES
  COULD_NOT_ADD_CATEGORY: "Błąd serwera, nie udało się dodać kategorii",
  COULD_NOT_DELETE_CATEGORY: "Błąd serwera, nie udało się usunąć kategorii",
  COULD_NOT_EDIT_CATEGORY: "Błąd serwera, nie udało się edytować kategorii",

  // ORDERS
  COULD_NOT_DOWNLOAD_ORDERS: "Błąd serwera, nie udało się pobrać zamówień",
  COULD_NOT_DELETE_ORDER: "Błąd serwera, nie udało się usunąć zamówienia",

  // EVENTS
  COULD_NOT_ADD_EVENT: "Błąd serwera, nie udało się dodać wydarzenia",
  COULD_NOT_DELETE_EVENT: "Błąd serwera, nie udało się usunąć wydarzenia",
  COULD_NOT_EDIT_EVENT: "Błąd serwera, nie udało się edytować wydarzenia",
  COULD_NOT_FIND_EVENT: "Błąd serwera, nie znaleziono wydarzenia",

  // FAQ
  COULD_NOT_DELETE_FAQ: "Błąd serwera, nie udało się usunąć FAQ",
  COULD_NOT_EDIT_FAQ: "Błąd serwera, nie udało się edytować FAQ",
  COULD_NOT_DOWNLOAD_FAQ: "Błąd serwera, nie udało się pobrać listy FAQ",

  // WORKS
  COULD_NOT_DELETE_WORK: "Błąd serwera, nie udało się usunąć pracy",
  COULD_NOT_EDIT_WORK: "Błąd serwera, nie udało się edytować pracy",
  COULD_NOT_ADD_WORK: "Błąd serwera, nie udało się dodać pracy",
  COULD_NOT_DOWNLOAD_WORKS: "Błąd serwera, nie udało się pobrać prac",

  // PRODUCTS
  COULD_NOT_EDIT_PRODUCT: "Błąd serwera, nie udało sie edytować produktu",
  COULD_NOT_DOWNLOAD_DEVELOP_PHASES:
    "Błąd serwera, nie udało się pobrać faz rozwoju",
  COULD_NOT_ADD_PRODUCT: "Błąd serwera, nie udało sie dodać produktu",
  COULD_NOT_DELETE_PRODUCT: "Błąd serwera, nie udało sie usunąć produktu",
  COULD_NOT_FIND_PRODUCT: "Błąd serwera, nie znaleziono produktu",
  COULD_NOT_DOWNLOAD_PRODUCTS:
    "Błąd serwera, nie udało się pobrać listy produktów",

  // RESOURCES
  COULD_NOT_DOWNLOAD_RESOURCES:
    "Błąd serwera, nie udało się pobrać listy środków",
  COULD_NOT_ASSIGN_RESOURCE: "Błąd serwera, nie udało się przypisać środka",
  COULD_NOT_ADD_RESOURCE: "Błąd serwera, nie udało się dodać środka",
  COULD_NOT_DELETE_RESOURCE: "Błąd serwera, nie udało się usunąć środka",
  COULD_NOT_FIND_RESOURCE: "Błąd serwera, nie udało się znaleźć środka",

  // SETTINGS
  COULD_NOT_CHANGE_SETTINGS: "Błąd serwera, nie udało się zmienić ustawień",

  // COMBINATIONS
  COULD_NOT_ADD_COMBINATION: "Błąd serwera, nie udało się dodać kombinacji",
  COULD_NOT_EDIT_COMBINATION: "Błąd serwera, nie udało się edytować kombinacji",
  COULD_NOT_DELETE_COMBINATION: "Błąd serwera, nie udało się usunąć kombinacji",

  // ARTICLES
  COULD_NOT_FIND_ARTICLE: "Błąd serwera, nie udało się znaleźć artykułów",
  COULD_NOT_DOWNLOAD_ARTICLES: "Błąd serwera, nie udało się pobrać artykułów",

  // PATHOGENS
  COULD_NOT_ADD_PATHOGEN: "Błąd serwera, nie udało się dodać patogenu",
  COULD_NOT_EDIT_PATHOGEN: "Błąd serwera, nie udało się edytować patogenu",
  COULD_NOT_DELETE_PATHOGEN: "Błąd serwera, nie udało się usunąć patogenu",
  COULD_NOT_DOWNLOAD_PATHOGEN: "Błąd serwera, nie udało się pobrać patogenu",
  COULD_NOT_DOWNLOAD_PATHOGENS: "Błąd serwera, nie udało się pobrać patogenów",

  // SERVICES
  COULD_NOT_ADD_SERVICE: "Błąd serwera, nie udało się dodać serwisu",
  COULD_NOT_DOWNLOAD_SERVICES: "Błąd serwera, nie udało się pobrać serwisów",
  COULD_NOT_DELETE_SERVICE: "Błąd serwera, nie udało się usunąć serwisu",

  // USERS
  COULD_NOT_EDIT_USER: "Błąd serwera, nie udało się edytować użytkownika",
  COULD_NOT_ADD_USER: "Błąd serwera, nie udało się dodać użytkownika",
  COULD_NOT_DOWNLOAD_USERS: "Błąd serwera, nie udało się pobrać użytkowników",
  COULD_NOT_BLOCK_USER: "Błąd serwera, nie udało się zablokować użytkownika",
  COULD_NOT_UNBLOCK_USER: "Błąd serwera, nie udało się odblokować użytkownika",
  COULD_NOT_FIND_USER: "Błąd serwera, nie udało się znaleźć użytkownika",

  // QR CODES
  COULD_NOT_EDIT_QR_CODE: "Błąd serwera, nie udało się edytować kodu",
  COULD_NOT_CREATE_QR_CODE: "Błąd serwera, nie udało się stworzyć kodu",
  COULD_NOT_LOAD_QR_CODE_IMAGE:
    "Błąd serwera, nie udało się załadować obrazu kodu",
  COULD_NOT_DELETE_QR_CODE: "Błąd serwera, nie udało się usunąć kodu",
  COULD_NOT_DOWNLOAD_QR_CODES: "Błąd serwera, nie udało się pobrać kodów",
  COULD_NOT_FIND_QR_CODE: "Błąd serwera, nie znaleziono kodu",

  // AUCTIONS
  COULD_NOT_DOWNLOAD_AUCTIONS: "Błąd serwera, nie udało się pobrać aukcji",

  // PLANTS PROTECTION PRODUCTS
  COULD_NOT_DOWNLOAD_PLANTS_PROTECTION_PRODUCTS:
    "Błąd serwera, nie udało się pobrać środków ochrony roślin",
  COULD_NOT_FIND_PLANTS_PROTECTION_PRODUCTS:
    "Nie znaleziono listy środków ochrony roślin",
  COULD_CHANGE_DOSAGE_FOR_PLANTS_PROTECTION_PRODUCT:
    "Błąd serwera, nie udało się dodać dawek dla środka ochrony",

  // CROPS
  COULD_NOT_FIND_CROP: "Błąd serwera, nie udało się znaleźć uprawy",
  COULD_NOT_EDIT_CROP: "Błąd serwera, nie udało się edytować uprawy",
  COULD_NOT_DELETE_CROP: "Błąd serwera, nie udało się usunąć uprawy",
  COULD_NOT_ADD_CROP: "Błąd serwera, nie udało się dodać uprawy",
  COULD_NOT_DOWNLOAD_CROPS: "Błąd serwera, nie udało się pobrać listy upraw",

  // DISCOUNTS
  COULD_NOT_DOWNLOAD_DISCOUNTS: "Błąd serwera, nie udało się pobrać promocji",
  COULD_NOT_DELETE_DISCOUNT: "Błąd serwera, nie udało się usunąć promocji",
  COULD_NOT_EDIT_DISCOUNT: "Błąd serwera, nie udało się edytować promocji",
  COULD_NOT_ADD_DISCOUNT: "Nie udało się dodać nowej promocji",

  // GENERAL
  COULD_NOT_DOWNLOAD_DATA: "Błąd serwera, nie udało się pobrać danych",
  SERVER_ERROR: "Błąd serwera",
};

export const ERROR_MESSAGES = {
  COULD_NOT_CONNECT_TO_SERVER: "Błąd połączenia z serwerem",
  COULD_NOT_FIND_CROPS_TYPES: "Nie znaleziono typów upraw",
  COULD_NOT_DOWNLOAD_CROPS: "Nie udało się pobrać listy upraw",
  COULD_NOT_FIND_CATEGORIES_FOR_PRODUCT:
    "Nie udało znaleźć listy kategorii dla produktów",
  COULD_NOT_UPLOAD_PHOTO: "Nie udało się wgrać zdjęcia",
  COULD_NOT_LOAD_PHOTO: "Nie udało się załadować zdjęcia",
  COULD_NOT_ADD_FAQ: "Błąd serwera, nie udało się dodać FAQ",
  INCORRECT_PRICES: "Podane ceny są nieprawidłowe",
  COULD_NOT_CANCEL_ORDER: "Nie udało się anulować zamówienia",
  COULD_NOT_EDIT_QUANTITY: "Nie udało się edytować ilości",
  COULD_NOT_DELETE_ITEM: "Nie udało się usunąć przedmiotu",
  COULD_NOT_ADD_ITEM: "Nie udało się dodać przedmiotu",
  COULD_NOT_MARK_ORDER_AS_SENT:
    "Nie udało się oznaczyć zamówienia jako wysłane",
  COULD_NOT_EDIT_SHIPPING_DETAILS: "Nie udało się edytować danych wysyłkowych",
  COUDL_NOT_DOWNLOAD_ORDER_DATA: "Nie udało się pobrać danych zamówienia",
  COULD_NOT_MODIFY_VALUE: "Nie udało się zmodyfikować wartości",
  COMBINATION_MUST_CONTAIN_TWO_ELEMENTS:
    "Kombinacja musi zawierać co najmniej 2 elementy",
};

export const BUTTON_VARIANTS = {
  GREEN: "green",
  RED: "red",
  WHITE: "white",
};

export const BUTTON_SIZES = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

export const COMMON_MODAL_TEXTS = {
  SUBSTANCE_TITLE: "Czy napewno chcesz usunąć substancję?",
  SUBSTANCE_CONTENT: `Usunięcie substancji spowoduje jej trwałe usunięcie z systemu i
  nie będzie możliwości jej przywrócenia.`,

  RESOURCE_TITLE: "Czy napewno chcesz usunąć środek?",
  RESOURCE_CONTENT: `Usunięcie środka spowoduje trwałe usunięcie jego substancji
  czynnej i nie będzie możliwości jej przywrócenia.`,

  AD_TITLE: "Czy napewno chcesz usunąć reklamę?",
  AD_CONTENT: `Usunięcie reklamy spowoduje jej trwałe usunięcie z systemu i nie
  będzie możliwości jej przywrócenia.`,

  CATEGORY_TITLE: "Czy na pewno chcesz usunąć kategorię?",

  ORDER_TITLE: "Czy na pewno chcesz anulować to zamówienie?",

  EVENT_TITLE: "Czy napewno chcesz usunąć wydarzenie?",
  EVENT_CONTENT: `Usunięcie wydarzenia spowoduje jego trwałe usunięcie z systemu i
  nie będzie możliwości jego przywrócenia.`,

  FAQ_TITLE: "Czy napewno chcesz usunąć FAQ?",
  FAQ_CONTENT: `Usunięcie FAQ spowoduje jego trwałe usunięcie z systemu i nie
  będzie możliwości jego przywrócenia.`,

  WORK_TITLE: "Czy napewno chcesz usunąć pracę?",
  WORK_CONTENT: `Usunięcie pracy spowoduje jego trwałe usunięcie z systemu i nie
  będzie możliwości jej przywrócenia.`,

  PATHOGEN_TITLE: "Czy napewno chcesz usunąć patogen?",
  PATOHGEN_CONTENT: `Usunięcie patogenu spowoduje jego trwałe usunięcie z systemu i
  nie będzie możliwości jego przywrócenia.`,

  COMBINATION_TITLE: "Czy napewno chcesz usunąć kombinację?",
  COMBINATION_CONTENT: `Usunięcie kombinacji spowoduje jej trwałe usunięcie z systemu i
  nie będzie możliwości jej przywrócenia.`,

  PRODUCT_TITLE: "Czy napewno chcesz usunąć produkt?",
  PRODUCT_CONTENT: `Usunięcie produktu spowoduje jego trwałe usunięcie z systemu i
  nie będzie możliwości jego przywrócenia.`,

  QR_TITLE: "Czy napewno chcesz usunąć kod QR?",
  QR_CONTENT: `Usunięcie kodu spowoduje jego trwałe usunięcie z systemu i nie
  będzie możliwości jego przywrócenia.`,

  USER_TITLE_BLOCK: "Czy napewno chcesz odblokować użytkownika",
  USER_TITLE_UNBLOCK: "Czy napewno chcesz zablokować użytkownika",
  USER_CONTENT_BLOCK:
    "Zablokowanie użytkownika odbierze mu możliwość korzystania ze strony",
  USER_CONTENT_UNBLOCK:
    "Odblokowanie użytkownika zwróci mu możliwość korzystania ze strony",

  DEVELOPMENT_STAGE_TITLE: "Czy na pewno chcesz usunąć stan rozwoju?",

  FIELD_TYPE_TITLE: "Czy na pewno chcesz usunąć typ uprawy?",

  SERVICE_TITLE: "Czy napewno chcesz usunąć serwis?",

  GENERAL_CONTENT: "Tej akcji nie można cofnąć!",

  GLOBAL_SETTINGS_TITLE: "Zmień globalne ustawienia",

  EDIT_WORK_TITLE: "Edytuj typ pracy",
  NEW_WORK_TITLE: "Dodaj nowy typ pracy",

  EDIT_QR_CODE_TITLE: "Edytuj produkt lub użytkownika przypisanego do kodu",
  ADD_QR_CODE_TITLE: "Czy chcesz dodać nowy kod QR?",
  ADD_QR_CODE_CONTENT: `Dodanie kodu spowoduje jego pojawienie się w bazie, będzie
  możliwość jego usunięcia`,

  EDIT_AD_TITLE: "Edytuj reklamę",
  ADD_AD_TITLE: "Dodaj nową reklamę",

  EDIT_EVENT_TITLE: "Edytuj wydarzenie",
  ADD_EVENT_TITLE: "Dodaj nowe wydarzenie",

  EDIT_USER_TITLE: "Dostosuj dane użytkownika",
  ADD_USER_TITLE: "Dodaj nowego użytkownika",

  ADD_FAQ_TITLE: "Dodaj nowe FAQ",
  EDIT_FAQ_TITLE: "Edytuj FAQ",

  ADD_CULTIVATION_TYPE_TITLE: "Dodaj nowy typ uprawy",
  EDIT_CULTIVATION_TYPE_TITLE: "Edytuj typ uprawy",

  ADD_SUBSTANCE_TITLE: "Dodaj nową substancję",
  EDIT_SUBSTANCE_TITLE: "Edytuj substancję",

  ADD_CULTIVATION_STAGE_TITLE: "Dodaj nowy stan rozwoju",
  EDIT_CULTIVATION_STAGE_TITLE: "Edytuj stan rozwoju",

  ADD_PATHOGEN_TITLE: "Dodaj nowy patogen",
  EDIT_PATHOGEN_TITLE: "Edytuj patogen",

  ADD_PRODUCT_TITLE: "Dodaj nowy środek",
  EDIT_PRODUCT_TITLE: "Edytuj środek",
};

export const HARVEST_MESSAGES = {
  null: "Nieokreślony",
  true: "Ręczny",
  false: "Maszynowy",
};

export const MODAL_TYPE = {
  EDIT: "edit",
  DELETE: "delete",
  ADD: "add",
};

export const COMMON_MODAL_CONFIRM_BUTTON_TEXTS = {
  edit: "Edytuj",
  add: "Dodaj",
  delete: "Tak",
};

export const COMMON_MODAL_REJECT_BUTTON_TEXTS = {
  edit: "Anuluj",
  add: "Anuluj",
  delete: "Nie",
};

export const API_URL =
  process.env.REACT_APP_API_URL || "https://beegrow.smlai.pl";
