import React from "react";
import { Typography, Fab, Box } from "@material-ui/core";
import { Add } from "@material-ui/icons";

import useStyles from "./ListHeader.styles";

const ListHeader = ({
  variant,
  title,
  handleAdd,
  searchComponent,
  searchComponent2,
}) => {
  const classes = useStyles();

  let content;

  if (variant === "title") {
    content = (
      <div className={classes.header}>
        <Typography variant="h4" className={classes.headerText}>
          {title}
        </Typography>
      </div>
    );
  } else if (variant === "title-add") {
    content = (
      <div className={classes.header}>
        <Typography variant="h4" className={classes.headerText}>
          {title}
        </Typography>
        <Fab
          className={classes.addButton}
          size="small"
          color="secondary"
          onClick={handleAdd}
        >
          <Add />
        </Fab>
      </div>
    );
  } else if (variant === "title-search") {
    content = (
      <div className={classes.header}>
        <Typography variant="h4" className={classes.headerText}>
          {title}
        </Typography>
        <Box className={classes.box}>{searchComponent}</Box>
      </div>
    );
  } else if (variant === "title-add-search") {
    content = (
      <div className={classes.header}>
        <Typography variant="h4" className={classes.headerText}>
          {title}
        </Typography>
        <Box className={classes.rightBox}>{searchComponent}</Box>
        <Fab
          className={classes.addButton}
          size="small"
          color="secondary"
          onClick={handleAdd}
        >
          <Add />
        </Fab>
      </div>
    );
  } else if (variant === "title-add-search2") {
    content = (
      <div className={classes.header} style={{ flexDirection: "column" }}>
        <div className={classes.container}>
          <Typography variant="h4" className={classes.headerText}>
            {title}
          </Typography>
          <Fab
            className={classes.addButton}
            size="small"
            color="secondary"
            onClick={handleAdd}
          >
            <Add />
          </Fab>
        </div>
        <div className={classes.searchContainer}>
          <Box className={classes.box}>{searchComponent}</Box>
          <Box className={classes.box}>{searchComponent2}</Box>
        </div>
      </div>
    );
  }

  return <>{content}</>;
};

export default ListHeader;
