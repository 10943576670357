import colors from "../../constants/colors";

const styles = () => ({
  registerHeader: {
    fontWeight: "bold",
    padding: "5px",
  },
  form: {
    marginTop: "15%",
    padding: "3%",
    "padding-bottom": "30px",

    minHeight: "350px",
    width: window.innerWidth < 700 ? "90%" : "70%",
    borderRadius: "20px",

    backgroundColor: colors.white,
    alignItems: "center",
  },
  textfield: {
    maxWidth: "375px",
  },
  toggleIcon: {
    backgroundColor: colors.transparent,
    borderRadius: "20px",
    border: "0px solid white",
  },
  apiError: {
    color: colors.htmlRed,
  },
});

export default styles;
