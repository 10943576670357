import colors from "../../constants/colors";

const styles = (theme, matches) => {
  return {
    faqs: {
      width: "80%",
      "border-bottom": "1px solid grey",
    },
    buttons: {
      width: "20%",
      "border-bottom": "1px solid grey",
    },
    faqsRes: {
      "border-bottom": "1px solid grey",
    },
    buttonsRes: {
      "border-bottom": "1px solid grey",
    },
    row: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    headerCellRight: {
      marginLeft: "2px",
    },
    listFont: {
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    box: {
      display: "flex",
      justifyContent: "center",
      "flex-direction": "row",
    },
    rootList: {
      maxWidth: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: "inline",
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    grey: {
      backgroundColor: colors.lightGray,
      "border-radius": "20px 20px 0 0",
    },
    caption: {
      fontSize: "15px",
      "font-family": "Montserrat",
    },
    toolbar: {
      "& > p:nth-of-type(2)": {
        fontSize: "15px",
        color: colors.black,
        "font-family": "Montserrat",
        fontWeight: 600,
      },
    },
  };
};

export default styles;
