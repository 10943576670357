import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Grid,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import { cultivations } from "../../utils/api";
import theme from "../../components/theme/theme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styles from "./ActiveSubstanesProduct.styles";
import { useParams } from "react-router-dom";
import ActiveSubstanceProductsTable from "../../components/ActiveSubstanceProductsTable/ActiveSubstanceProductsTable";
import NewActiveSubstanceProductWindow from "../../components/ModalWindows/ActiveSubstanceProducts/NewActiveSubstanceProductWindow";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import StyledFormTextField from "../../components/common/textFields/StyledFormTextField";
import TablePagination from "@material-ui/core/TablePagination";
import ListHeader from "../../components/common/ListHeader";

import { SERVER_ERROR_MESSAGES } from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const ActiveSubstancesProductScreen = () => {
  const [plantsProducts, setPlantsProducts] = useState([]);
  const [plantsProductsCopy, setPlantsProductsCopy] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [substance, setSubstance] = useState({});
  const [errorText, setErrorText] = useState("");
  const { id } = useParams();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  useEffect(() => {
    cultivations.activeSubstances
      .one(id)
      .then((res) => {
        setSubstance(res.data.activeSubstance);
        setPlantsProducts(res.data.activeSubstance.cultivationProducts);
        setPlantsProductsCopy(res.data.activeSubstance.cultivationProducts);
        setIsLoaded(true);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_RESOURCE);
      });
  }, []);

  const handleChange = (e) => {
    if (e.target.value !== "") {
      const res = plantsProductsCopy.filter((product) => {
        return (
          product.name &&
          product.name.toLowerCase().startsWith(e.target.value.toLowerCase())
        );
      });
      setPlantsProducts(res);
      setPage(0);
    } else {
      setPlantsProducts(plantsProductsCopy);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <Grid container>
          <ListHeader
            variant="title-add-search"
            title={`Lista środków ochrony roślin przypisanych do substancji ${substance.name.toLowerCase()}`}
            handleAdd={() => setOpen(true)}
            searchComponent={
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                name="name"
                fullWidth={matches}
                label="Szukaj produktu"
                variant="outlined"
                shrink="true"
                onChange={(e) => handleChange(e)}
              />
            }
          />
          <Grid container>
            <Grid item xs={12} align="center">
              <ActiveSubstanceProductsTable
                products={plantsProducts}
                setPlantsProducts={setPlantsProducts}
                handleError={handleError}
                page={page}
                rowsPerPage={rowsPerPage}
              />
              <TablePagination
                rowsPerPageOptions={[10, 15, 20]}
                component="div"
                count={plantsProducts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                classes={{
                  toolbar: classes.toolbar,
                  caption: classes.caption,
                }}
                labelRowsPerPage={"Ilość wierszy na stronę"}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}–${to} z ${
                    count !== -1 ? count : `więcej niż ${to}`
                  }`;
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
      <NewActiveSubstanceProductWindow
        isOpen={open}
        close={() => setOpen(false)}
        handleError={handleError}
        substance={substance}
      />
    </ThemeProvider>
  );
};

export default ActiveSubstancesProductScreen;
