import React, { useState, useEffect } from "react";
import { TextField, Grid } from "@material-ui/core";

import useStyles from "./Modal.styles";
import { COMMON_MODAL_TEXTS, MODAL_TYPE } from "../../constants/dictionary";
import CommonModal from "../common/modals/CommonModal";

const EditDevelopmentStageWindow = ({
  handleEdit,
  open,
  toggleOpen,
  current,
}) => {
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [errorName, setErrorName] = useState(false);
  const [errorDesc, setErrorDesc] = useState(false);

  useEffect(() => {
    setErrorName(false);
    setErrorDesc(false);
    setValues({
      description: current.description,
      name: current.name,
    });
  }, [open]);

  const submit = () => {
    let ok = +errorName + +errorDesc;
    if (ok === 0) {
      handleEdit({ ...values });
      toggleOpen();
    }
  };

  return (
    <CommonModal
      open={open}
      toggleOpen={toggleOpen}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.EDIT_CULTIVATION_STAGE_TITLE}
      type={MODAL_TYPE.EDIT}
    >
      <Grid item xs={12}>
        <TextField
          label="Wpisz nazwę stanu"
          value={values.name}
          variant="outlined"
          fullWidth
          required
          onChange={(e) => {
            setValues({ ...values, name: e.target.value });
            setErrorName(e.target.value === "" ? true : false);
          }}
          error={errorName}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Wpisz opis stanu"
          value={values.description}
          variant="outlined"
          fullWidth
          onChange={(e) => {
            setValues({ ...values, description: e.target.value });
            setErrorDesc(e.target.value === "" ? true : false);
          }}
          required
          error={errorDesc}
        />
      </Grid>
    </CommonModal>
  );
};

export default EditDevelopmentStageWindow;
