import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";

import colors from "../../../constants/colors";

const StyledUploadFileButton = withStyles({
  root: {
    background: colors.white,
    borderRadius: 50,
    border: "1px solid #a7a7a7",
    color: colors.black,
    height: "55px",
    width: "100%",
    padding: "0 30px",
    "box-shadow": "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    "&:hover": {
      background: colors.androidGreen,
      border: "1px solid #67c324",
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

export default StyledUploadFileButton;
