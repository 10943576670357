import React, { useState } from "react";
import {
  Grid,
  Container,
  TextField,
  IconButton,
  ThemeProvider,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { shop } from "../../utils/api";
import { theme } from "./AvailabilityModifier.theme";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";

import { ERROR_MESSAGES } from "../../constants/dictionary";

export const AvailabilityModifier = ({ item }) => {
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [displayedAmount, setDisplayedAmount] = useState(item.availableAmount);

  const editAmount = (newAmount) => {
    newAmount = newAmount < 0 ? 0 : newAmount;
    let tempitem = item;
    tempitem.availableAmount = newAmount;

    shop.articles
      .edit(item.id, tempitem)
      .then(() => {
        setDisplayedAmount(newAmount);
      })
      .catch((err) => {
        setError(true);
        setErrorText(ERROR_MESSAGES.COULD_NOT_MODIFY_VALUE);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={4} sm={4} align="center">
            <IconButton
              color="primary"
              onClick={error ? null : () => editAmount(displayedAmount - 1)}
            >
              <RemoveIcon />
            </IconButton>
          </Grid>
          <Grid item xs={4} sm={4}>
            <TextField
              value={displayedAmount}
              inputProps={{
                min: 0,
                style: { textAlign: "center" },
              }}
              onChange={(event) =>
                editAmount(
                  event.target.value === ""
                    ? 0
                    : parseInt(event.target.value, 10)
                )
              }
            />
          </Grid>
          <Grid item xs={4} sm={4} align="center">
            <IconButton
              color="primary"
              onClick={error ? null : () => editAmount(displayedAmount + 1)}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>

        <ErrorSnackBar
          open={error}
          text={errorText}
          close={() => setError(false)}
        />
      </Container>
    </ThemeProvider>
  );
};
