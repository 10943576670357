import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styles from "./CocoonAuctionsTable.styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { format } from "date-fns";
import { SortIcon } from "../common/buttons/SortIcon";
import { displayPrice } from "../../utils/price.js";
import CustomButton from "../common/buttons/CustomButton";
import { BUTTON_VARIANTS, BUTTON_SIZES } from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const CocoonAuctionTable = ({
  auctionList,
  handleError,
  page,
  rowsPerPage,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles(matches);
  const [isActiveDate, setIsActiveDate] = useState(false);
  const [isAscDate, setIsAscDate] = useState(false);
  const [isAscPrice, setIsAscPrice] = useState(false);
  const [isActivePrice, setIsActivePrice] = useState(false);
  const [isSold, setIsSold] = useState(false);
  const [isSoldActive, setIsSoldActive] = useState(false);

  const sortDate = (e, asc = false) => {
    setIsAscDate(asc ? asc : !isAscDate);
    setIsActiveDate(true);
    setIsActivePrice(false);
    setIsSoldActive(false);
    auctionList.sort((a, b) => {
      const dateA = new Date(a.endDate);
      const dateB = new Date(b.endDate);
      return (asc ? false : isAscDate) ? dateA - dateB : dateB - dateA;
    });
  };

  const sortPrice = () => {
    setIsAscPrice(!isAscPrice);
    setIsActiveDate(false);
    setIsSoldActive(false);
    setIsActivePrice(true);
    auctionList.sort((a, b) => {
      if (isAscPrice) {
        return a.userPrice - b.userPrice;
      } else {
        return b.userPrice - a.userPrice;
      }
    });
  };

  const sortSold = () => {
    setIsActiveDate(false);
    setIsSoldActive(true);
    setIsActivePrice(false);
    setIsSold(!isSold);
    auctionList.sort((a, b) => {
      if (isSold) {
        if (a.sold) {
          return 1;
        }
        if (b.sold) {
          return -1;
        }
      } else {
        if (a.sold) {
          return -1;
        }
        if (b.sold) {
          return 1;
        }
      }
    });
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.nick}>UŻYTKOWNIK</TableCell>
              <TableCell className={classes.boxCount}>ILOŚĆ PUDEŁEK</TableCell>
              <TableCell className={classes.price}>
                <Button
                  onClick={sortPrice}
                  className={classes.button}
                  endIcon={
                    <SortIcon isactive={isActivePrice} asacdesc={isAscPrice} />
                  }
                >
                  <Typography className={classes.cell}>CENA</Typography>
                </Button>
              </TableCell>
              <TableCell className={classes.status}>
                <Button
                  onClick={sortSold}
                  className={classes.button}
                  endIcon={
                    <SortIcon isactive={isSoldActive} asacdesc={isSold} />
                  }
                >
                  <Typography className={classes.cell}>SPRZEDANA</Typography>
                </Button>
              </TableCell>
              <TableCell className={classes.endDate}>
                <Button
                  onClick={sortDate}
                  className={classes.button}
                  endIcon={
                    <SortIcon isactive={isActiveDate} asacdesc={isAscDate} />
                  }
                >
                  <Typography className={classes.cell}>
                    DATA ZAKOŃCZENIA
                  </Typography>
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auctionList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((auction) => (
                <TableRow className={classes.row}>
                  <TableCell>
                    <Typography className={classes.cell}>
                      {auction.user}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.cell}>
                      {auction.boxesCount}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.cell}>
                      {`${displayPrice(auction.userPrice)} zł`}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography className={classes.cell}>
                      <Grid item xs={12} align="left">
                        <CustomButton
                          variant={
                            auction.sold
                              ? BUTTON_VARIANTS.GREEN
                              : BUTTON_VARIANTS.RED
                          }
                          size={BUTTON_SIZES.SMALL}
                          text={auction.sold ? "Tak" : "Nie"}
                        />
                      </Grid>
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography className={classes.cell}>
                      {auction.returnDate
                        ? format(auction.returnDate, "dd/MM/yyyy")
                        : "Brak"}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CocoonAuctionTable;
