import colors from "../../constants/colors";

export const styles = (theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
    },
    tableContainer: {
      width: "100%",
    },
    screenTitle: {
      padding: "15px",
    },
    table: {
      "overflow-x": "hidden",
      maxWidth: "100%",
    },
    initHead: {
      backgroundColor: colors.white,
      borderBottom: "2px solid black",
    },
    styledButton: {
      textTransform: "capitalize",
      fontWeight: "bold",
    },
    name: {
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
    },
    value: {
      "font-size": "15px",
      "font-family": "Montserrat",
      backgroundColor: colors.white,
      borderBottom: "2px solid black",
    },
    discount: {
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
    },
    buttons_v: {
      "font-size": "15px",
      "font-family": "Montserrat",
      backgroundColor: colors.white,
      borderBottom: "2px solid black",
    },
    cell: {
      "font-family": "Montserrat",
      fontWeight: "bold",
    },
    delete: {
      color: colors.carmine,
    },
    avatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      "box-sizing": "border-box",
      "box-shadow":
        "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
    },
    caption: {
      fontSize: "15px",
      "font-family": "Montserrat",
    },
    toolbar: {
      "& > p:nth-of-type(2)": {
        fontSize: "15px",
        color: colors.black,
        "font-family": "Montserrat",
        fontWeight: 600,
      },
    },
  };
};
