import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core";

import colors from "../../../constants/colors";

const StyledCategorySelect = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 50,
        color: colors.green,
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: colors.green,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: colors.green,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      color: colors.green,
      borderColor: colors.green,
      borderRadius: 30,
    },
  },
})(TextField);

export default StyledCategorySelect;
