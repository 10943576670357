import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, ThemeProvider, makeStyles } from "@material-ui/core";
import EventInfoBox from "../../components/Event/EventInfoBox";
import theme from "../Login/LoginSreen.breakpoints";
import { shop } from "../../utils/newApi";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./EventInfoScreen.styles";

import { SERVER_ERROR_MESSAGES } from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const EventInfoScreen = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [event, setEvent] = useState("");
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  useEffect(() => {
    shop.events
      .one(id)
      .then((res) => {
        setEvent(res.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_FIND_EVENT);
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <Grid
          container
          justifyContent="center"
          direction="row"
          alignItems="center"
        >
          <Grid item xs={12}>
            {event && <EventInfoBox info={event} handleError={handleError} />}
          </Grid>
        </Grid>
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </ThemeProvider>
  );
};

export default EventInfoScreen;
