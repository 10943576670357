import colors from "../../constants/colors";

export const styles = (theme) => {
  return {
    name: {
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
    },
    value: {
      "font-size": "15px",
      "font-family": "Montserrat",
      backgroundColor: colors.white,
      borderBottom: "2px solid black",
    },
    cell: {
      "font-family": "Montserrat",
      fontWeight: "bold",
      padding: "2em",
      borderBottom: "2px solid",
    },
    click: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    icon: {
      marginRight: 5,
    },
    container: {
      display: "flex",
      "flex-direction": "row",
      "justify-content": "center",
      "align-items": "center",
      height: "100%",
      width: "100%",
    },
    loader: {
      "justify-content": "center",
      "align-items": "center",
    },

    caption: {
      fontSize: "15px",
      "font-family": "Montserrat",
    },
    toolbar: {
      "& > p:nth-of-type(2)": {
        fontSize: "15px",
        color: colors.black,
        "font-family": "Montserrat",
        fontWeight: 600,
      },
    },
  };
};
