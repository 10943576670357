import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { makeStyles, Grid } from "@material-ui/core";
import styles from "./ProductInfoScreen.styles";
import { shop } from "../../utils/api";
import ProductInfoBox from "../../components/Product/ProductInfoBox";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import CircularProgress from "@material-ui/core/CircularProgress";

import { SERVER_ERROR_MESSAGES } from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const ProductInfo = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [product, SetProduct] = useState([]);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  useEffect(() => {
    shop.articles
      .one(id)
      .then((product) => {
        const { data } = product;
        SetProduct(data);
        setIsLoaded(true);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_FIND_PRODUCT);
      });
  }, []);

  return (
    <>
      {isLoaded ? (
        <Grid
          container
          justifyContent="center"
          direction="row"
          alignItems="center"
        >
          <Grid item xs={12}>
            <ProductInfoBox info={product} handleError={handleError} />
          </Grid>
        </Grid>
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </>
  );
};

export default ProductInfo;
