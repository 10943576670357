import React, { useState, useEffect } from "react";
import { TextField, Typography, Grid } from "@material-ui/core";

import CommonModal from "../common/modals/CommonModal";

import useStyles from "./Modal.styles";
import { MODAL_TYPE, COMMON_MODAL_TEXTS } from "../../constants/dictionary";

const NewDevelopmentStageWindow = ({ handleAdd, open, toggleOpen }) => {
  const classes = useStyles();
  const [values, setValues] = useState({});
  const [errorName, setErrorName] = useState(false);
  const [errorDesc, setErrorDesc] = useState(false);
  const [emptyField, setEmptyField] = React.useState(false);

  useEffect(() => {
    setErrorName(false);
    setErrorDesc(false);
    setValues({ description: "", name: "" });
  }, [open]);

  const submit = (e) => {
    const ok = +errorName + +errorDesc;
    if (ok === 0) {
      if (values.name === "") {
        setEmptyField(true);
        return;
      }
      handleAdd({ ...values });
      toggleOpen();
    }
  };

  return (
    <CommonModal
      open={open}
      toggleOpen={toggleOpen}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.ADD_CULTIVATION_STAGE_TITLE}
      type={MODAL_TYPE.ADD}
    >
      <Grid item xs={12}>
        <TextField
          label="Wpisz nazwę stanu"
          placeholder="Nowy stan"
          variant="outlined"
          fullWidth
          required
          onChange={(e) => {
            setValues({ ...values, name: e.target.value });
            setErrorName(e.target.value === "" ? true : false);
          }}
          error={errorName}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Wpisz opis stanu"
          placeholder="Opis"
          variant="outlined"
          fullWidth
          required
          onChange={(e) => {
            setValues({ ...values, description: e.target.value });
            setErrorDesc(e.target.value === "" ? true : false);
          }}
          error={errorDesc}
        />
      </Grid>
      {emptyField && (
        <Grid item xs={12}>
          <Typography className={classes.error}>
            Należy podać nazwę stanu
          </Typography>
        </Grid>
      )}
    </CommonModal>
  );
};

export default NewDevelopmentStageWindow;
