import React, { useState, useEffect } from "react";
import { Grid, makeStyles, ThemeProvider } from "@material-ui/core";
import theme from "../../components/theme/theme";
import { users } from "../../utils/newApi";
import styles from "./UserInfoScreen.styles";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserList from "../../components/UserInfoList/UserInfoList";
import StyledFormTextField from "../../components/common/textFields/StyledFormTextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import sortsOptions from "../../constants/usersConstants";
import NewUserWindow from "../../components/ModalWindows/UserInfo/NewUserWindow";
import { userTypes, userTypesforBackend } from "../../constants/usersConstants";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../components/common/pagination/TablePaginationActions";
import ListHeader from "../../components/common/ListHeader";

import { SERVER_ERROR_MESSAGES } from "../../constants/dictionary";
import {
  filterConstants,
  userFilter,
  searchConstants,
} from "../../constants/localStorage";

const useStyles = makeStyles(styles);

const UserInfoScreen = () => {
  const [usersList, setUsers] = useState([]);
  const [usersCopy, setUsersCopy] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [sortOptions, setSortOptions] = useState(sortsOptions);
  const [selectedSortOptions, setSelectedSortOptions] = useState("");
  const [page, setPage] = React.useState(
    localStorage.getItem("currentUserInfoListPage")
      ? Number(localStorage.getItem("currentUserInfoListPage"))
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(
    localStorage.getItem("currentUserInfoListRowsPerPage")
      ? Number(localStorage.getItem("currentUserInfoListRowsPerPage"))
      : 10
  );

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  useEffect(() => {
    setSelectedSortOptions(localStorage.getItem(filterConstants.users));
    users.usersInfo
      .all()
      .then((res) => {
        res.data.map((user) => {
          switch (user.type) {
            case userTypesforBackend[0]:
              user.type = userTypes[0];
              break;
            case userTypesforBackend[1]:
              user.type = userTypes[1];
              break;
          }
        });

        const sorted = res.data ? res.data.sort((a, b) => b.id - a.id) : [];

        setUsers(sorted);
        setUsersCopy(sorted);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_USERS);
      });
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (usersCopy.length !== 0) {
      handleChange(null, localStorage.getItem(searchConstants.users));
    }
  }, [usersCopy]);

  const handleChange = (e, savedText = null) => {
    localStorage.setItem(
      searchConstants.users,
      savedText ?? e?.target.value?.toLowerCase()
    );
    if ((e && e.target.value !== "") || savedText !== null) {
      const res = usersCopy.filter((user) => {
        switch (selectedSortOptions) {
          case "Email":
            if (user.email) {
              localStorage.setItem(filterConstants.users, userFilter.email);
              return user.email
                .toLowerCase()
                .startsWith(savedText ?? e.target.value.toLowerCase());
            }
            return null;
          case "Login":
            if (user.username) {
              localStorage.setItem(filterConstants.users, userFilter.login);
              return user.username
                .toLowerCase()
                .startsWith(savedText ?? e.target.value.toLowerCase());
            }
            return null;
          case "Uprawnienia":
            if (user.type) {
              localStorage.setItem(
                filterConstants.users,
                userFilter.permissions
              );
              return user.type
                .toLowerCase()
                .startsWith(savedText ?? e.target.value.toLowerCase());
            }
            return null;
          case "Imię":
            if (user.firstName) {
              localStorage.setItem(filterConstants.users, userFilter.name);
              return user.firstName
                .toLowerCase()
                .startsWith(savedText ?? e.target.value.toLowerCase());
            }
            return null;
          case "Nazwisko":
            if (user.lastName) {
              localStorage.setItem(filterConstants.users, userFilter.surname);
              return user.lastName
                .toLowerCase()
                .startsWith(savedText ?? e.target.value.toLowerCase());
            }
            return null;
          default:
            localStorage.setItem(filterConstants.users, "");
            return true;
        }
      });
      setUsers(res);
      setPage(0);
    } else {
      setUsers(usersCopy);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <Grid container>
          <ListHeader
            variant="title-add-search2"
            title="Lista użytkowników"
            handleAdd={() => setOpen(true)}
            searchComponent={
              <Autocomplete
                defaultValue={localStorage.getItem(filterConstants.users)}
                options={sortOptions}
                onChange={(event, newValue) => {
                  setSelectedSortOptions(newValue);
                }}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <StyledFormTextField
                    {...params}
                    InputLabelProps={{ classes: { root: classes.label } }}
                    fullWidth={true}
                    label="Wyszukaj po"
                    variant="outlined"
                    shrink="true"
                  />
                )}
              />
            }
            searchComponent2={
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                name="name"
                disabled={!selectedSortOptions || selectedSortOptions === ""}
                fullWidth={true}
                label="Szukaj..."
                variant="outlined"
                shrink="true"
                defaultValue={localStorage.getItem(searchConstants.users)}
                onChange={(e) => handleChange(e)}
              />
            }
          />
          <Grid container>
            <Grid item xs={12} align="center">
              <UserList
                users={usersList}
                setUsers={setUsers}
                page={page}
                rowsPerPage={rowsPerPage}
              />
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 50]}
                component="div"
                count={usersList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                classes={{
                  toolbar: classes.toolbar,
                  caption: classes.caption,
                }}
                labelRowsPerPage={"Ilość wierszy na stronę"}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}–${to} z ${
                    count !== -1 ? count : `więcej niż ${to}`
                  }`;
                }}
              />
            </Grid>
          </Grid>
          <NewUserWindow
            isOpen={open}
            close={() => setOpen(false)}
            handleError={handleError}
          />
        </Grid>
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </ThemeProvider>
  );
};

export default UserInfoScreen;
