import React, { useEffect, useState } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core";
import { cultivations } from "../../utils/newApi";
import { CultivationTable } from "./CultivationTable";
import { theme } from "./Cultivation.theme";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import { styles } from "./CultivationTable.styles";

import { SERVER_ERROR_MESSAGES } from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const Cultivation = () => {
  const classes = useStyles();
  const [articles, setArticles] = useState([]);
  const [trigger, setTrigger] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  useEffect(() => {
    cultivations.types
      .all()
      .then((resp) => {
        const sorted = resp.data ? resp.data.sort((a, b) => b.id - a.id) : [];
        setArticles(sorted);
        setIsLoaded(true);
      })
      .catch((err) =>
        handleError(
          SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_PLANTS_PROTECTION_PRODUCTS
        )
      );
  }, [trigger]);

  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <CultivationTable items={articles} handleError={handleError} />
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </ThemeProvider>
  );
};

export default Cultivation;
