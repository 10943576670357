import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useForm from "../../hooks/useForm";
import StyledFormPasswordField from "../common/textFields/StyledFormPasswordField";
import styles from "./ResetForm.styles";
import REGISTER_HELPER_TEXTS from "../../constants/registerHelperTexts";
import IconSrc from "../../assets/images/icon.png";
import CustomButton from "../common/buttons/CustomButton";

import { BUTTON_VARIANTS, BUTTON_SIZES } from "../../constants/dictionary";

const useStyle = makeStyles(styles);

const ResetForm = ({ handleReset, passwordChanged }) => {
  const classes = useStyle();
  const { handleChange, values } = useForm({
    password: "",
    repeatedPassword: "",
  });
  const [passwd1Vis, setPasswd1Vis] = useState(false);
  const [passwd2Vis, setPasswd2Vis] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState(
    REGISTER_HELPER_TEXTS[0]
  );
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  useEffect(() => {
    if (values.password.length > 0) {
      if (values.password.length < 4) {
        setIsPasswordValid(false);
        setPasswordHelperText(REGISTER_HELPER_TEXTS[403]);
      } else if (values.password !== values.repeatedPassword) {
        setIsPasswordValid(false);
        setPasswordHelperText(REGISTER_HELPER_TEXTS[69]);
      } else {
        setIsPasswordValid(true);
      }
    } else {
      setIsPasswordValid(true);
    }
    if (isPasswordValid) setPasswordHelperText("");
  }, [values.password, values.repeatedPassword, isPasswordValid]);

  return (
    <div className={classes.root}>
      <Grid container spacing={0} justify="center" alignItems="center">
        <Grid item xs={12}>
          <img src={IconSrc} alt="icon" />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.headerText} variant="h4">
            Reset hasła
          </Typography>
        </Grid>
        {passwordChanged ? (
          <Box>
            <Typography className={classes.successText}>
              Hasło zostało zmienione, przejdź do aplikacji mobilnej aby się
              zalogować
            </Typography>
          </Box>
        ) : (
          <Box>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <StyledFormPasswordField
                  name="repeatedPassword"
                  label={REGISTER_HELPER_TEXTS["repeatedPasswdLabel"]}
                  fullWidth
                  onChange={handleChange("password")}
                  passwdVis={passwd1Vis}
                  setPasswdVis={setPasswd1Vis}
                  buttonClass={classes.toggleIcon}
                  className={classes.textfield}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <StyledFormPasswordField
                  name="password"
                  label={REGISTER_HELPER_TEXTS["passwdLabel"]}
                  onChange={handleChange("repeatedPassword")}
                  fullWidth
                  passwdVis={passwd2Vis}
                  setPasswdVis={setPasswd2Vis}
                  buttonClass={classes.toggleIcon}
                  helperText={passwordHelperText}
                  className={classes.textfield}
                  FormHelperTextProps={{
                    error: !isPasswordValid,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <CustomButton
                  variant={BUTTON_VARIANTS.RED}
                  size={BUTTON_SIZES.MEDIUM}
                  clickHandler={() => {
                    handleReset(
                      values.password,
                      window.location.href?.split("=")[1]
                    );
                  }}
                  text="Dalej"
                />
              </Box>
            </Grid>
          </Box>
        )}
      </Grid>
    </div>
  );
};

export default ResetForm;
