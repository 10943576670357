import bg from "../../assets/images/background-green.jpg";

import colors from "../../constants/colors";

const styles = () => ({
  body: {
    "min-height": "100%",
    "min-width": "100%",
    position: "absolute",

    backgroundImage: `url(${bg})`,

    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  header: {
    color: colors.white,
  },
});

export default styles;
