import colors from "../../constants/colors";

const styles = (theme) => {
  return {
    box: {
      margin: "2%",
      paddingLeft: "2%",
      paddingRight: "2%",
    },
    productBox: {
      margin: "2%",
    },
    dataBox: {
      marginLeft: "5%",
      marginRight: "5%",
      borderRadius: "10px",
      border: "1px solid black",
    },
    dataBoxDesc: {
      marginLeft: "5%",
      marginRight: "5%",
      borderRadius: "10px",
      border: "1px solid black",
    },
    dataBoxPrices: {
      marginLeft: "5%",
      marginRight: "5%",
      borderRadius: "10px",
      border: "1px solid black",
      display: "flex",
      padding: "2%",
      "flex-direction": "row",
      "justify-content": "space-between",
      "flex-wrap": "wrap",
    },
    root: {
      backgroundColor: colors.white,
      borderRadius: "30px",
      [theme.breakpoints.down("sm")]: {
        height: "80vh",
      },
      [theme.breakpoints.up("sm")]: {
        height: "80vh",
      },
      padding: "5%",
    },
    cursors: {
      cursor: "pointer",
    },
    header: {
      [theme.breakpoints.down("sm")]: {
        "font-size": "20px",
      },
      [theme.breakpoints.up("sm")]: {
        "font-size": "25px",
      },
      "text-align": "left",
      "font-weight": "bold",
      "font-family": "Montserrat",
      color: colors.oxfordBlue,
    },
    rootList: {
      maxWidth: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: "inline",
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    headerCell: {
      display: "flex",
    },
    headerCellRight: {
      marginLeft: "2px",
      "align-self": "center",
    },
    active: {
      "font-weight": "bold",
      "font-family": "Montserrat",
      "text-align": "center",
      "font-size": "25px",
    },
    userType: {
      "font-weight": "bold",
      "font-family": "Montserrat",
      "font-size": "20px",
    },
    avatar: {
      [theme.breakpoints.down("sm")]: {
        width: "75px",
        height: "75px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "100px",
        height: "100px",
      },
      backgroundColor: colors.white,
    },
    icon: {
      [theme.breakpoints.down("sm")]: {
        width: "65px",
        height: "65px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "90px",
        height: "90px",
      },
      fill: colors.oxfordBlue,
    },
    text: {
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    desBox: {
      margin: "3%",
    },
    greyBox: {
      backgroundColor: colors.platinum,
      borderRadius: 20,
    },
    RightBox: {
      marginTop: "5%",
      marginBottom: "5%",
    },
    margin: {
      marginBottom: "10%",
    },
    buttonBox: {
      paddingLeft: "10%",
      paddingRight: "10%",
      marginBottom: "5%",
      marginTop: "10%",
    },
  };
};

export default styles;
