import React from "react";
import { Grid, makeStyles, Box, Typography } from "@material-ui/core";
import styles from "./Picture.styles";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(styles);

const Picture = ({
  pictureSrc,
  remove,
  renderIcon,
  customBreak,
  binaryImage = false,
}) => {
  const classes = useStyles();
  const del = () => {
    remove(pictureSrc);
  };
  return (
    <Grid
      item
      xs={customBreak ? customBreak[0] : 12}
      sm={customBreak ? customBreak[1] : 4}
      md={customBreak ? customBreak[2] : 3}
      lg={customBreak ? customBreak[3] : 3}
      className={classes.root}
    >
      <Box className={classes.box}>
        <img
          src={
            binaryImage
              ? `data:image/png;base64,${pictureSrc}`
              : pictureSrc && URL.createObjectURL(pictureSrc)
          }
          className={classes.image}
          alt=""
        />
      </Box>
      <div className={classes.iconBox}></div>
      <div className={classes.iconBox} onClick={del}>
        {renderIcon && <DeleteIcon className={classes.icon} />}
        {renderIcon && (
          <Typography className={classes.iconLabelRed}>USUŃ</Typography>
        )}
      </div>
    </Grid>
  );
};

export default Picture;
