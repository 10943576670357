import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
	palette: {
		primary: {
			main: "#67C324",
		},
		secondary: {
			main: "#DE0038",
		},
	},

	fontFamily: [
		'"Montserrat"',
		'"Arial"',
		'"Roboto"',
		"sans-serif",
		'"Helvetica"',
	].join(","),

	typography: {
		fontFamily: "Montserrat",
	},

	
});
