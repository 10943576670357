import colors from "../../constants/colors";

const styles = (theme) => {
  return {
    marginBox: {
      margin: "2%",
      paddingLeft: "2%",
      paddingRight: "2%",
    },
    topLeftBox: {
      marginTop: "2%",
      marginBottom: "2%",
    },
    buttonBox: {
      paddingLeft: "10%",
      paddingRight: "10%",
      marginBottom: "5%",
      marginTop: "10%",

      [theme.breakpoints.down("sm")]: {
        "& *": {
          fontSize: 13,
        },
      },
    },
    headerMargin: {
      marginLeft: "3%",
      marginRight: "3%",
      marginTop: "5%",
    },
    productBox: {
      margin: "2%",
    },
    dataBox: {
      marginLeft: "5%",
      marginRight: "5%",
      borderRadius: "10px",
      border: "1px solid black",
    },
    dataBoxDesc: {
      marginLeft: "5%",
      marginRight: "5%",
      borderRadius: "10px",
      border: "1px solid black",
    },
    dateBox: {
      marginLeft: "5%",
      marginRight: "5%",
      borderRadius: "10px",
      border: "1px solid black",
      display: "flex",
      padding: "2%",
      "flex-direction": "row",
      "justify-content": "space-between",
      "flex-wrap": "wrap",
    },
    root: {
      backgroundColor: colors.white,
      padding: "5%",
    },
    cursors: {
      cursor: "pointer",
    },
    header: {
      [theme.breakpoints.down("sm")]: {
        "font-size": "20px",
      },
      [theme.breakpoints.up("sm")]: {
        "font-size": "25px",
      },
      "text-align": "left",
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    dateRight: {
      "font-weight": "bold",
      "font-family": "Montserrat",
      "font-size": "20px",
    },
    dateLeft: {
      "font-family": "Montserrat",
      "font-size": "17px",
    },
    listText: {
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    avatar: {
      [theme.breakpoints.down("sm")]: {
        width: "200px",
        height: "100%",
      },
      [theme.breakpoints.up("sm")]: {
        width: "300px",
        height: "100%",
      },

      "box-shadow":
        "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
    },
    text: {
      "font-weight": "bold",
      "font-family": "Montserrat",
      wordBreak: "break-word",
    },
    desc: {
      "font-family": "Montserrat",
    },
    greyBox: {
      backgroundColor: colors.platinum,
      borderRadius: 20,
    },
    disable: {
      color: colors.quickSilver,
      display: "inline",
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    rootList: {
      marginTop: "10%",
      maxWidth: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: "inline",
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    topRightHeader: {
      "font-weight": "bold",
      "font-family": "Montserrat",
      align: "right",
      "font-size": "20px",
    },
    imgBox: {
      height: "400px",
      width: "250px",
      [theme.breakpoints.down("sm")]: {
        height: "300px",
        width: "200px",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    pic: {
      "max-width": "100%",
      "max-height": "100%",
      margin: "auto",
    },
  };
};

export default styles;
