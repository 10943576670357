const fields = [
  {
    id: 1,
    name: "Nazwa",
  },
  {
    id: 2,
    name: "Od",
  },
  {
    id: 3,
    name: "Do",
  },
  {
    id: 4,
    name: "Aktywne",
  },
  {
    id: 5,
    name: "Zaplanowane",
  },
];

export default fields;
