import back from "../../assets/images/background.jpg";

import colors from "../../constants/colors";

const styles = {
  container: {
    "background-image": `url(${back})`,
    "min-width": "100%",
    "min-height": "100%",
    position: "absolute",
    "background-size": "cover",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    backgroundColor: colors.lotion,
    borderRadius: 15,
    padding: 20,
    justifyContent: "center",
  },
  header: {
    color: colors.white,
  },
  img: {
    width: "128px",
    height: "128px",
    alignSelf: "center",
  },
  text: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "20px",
  },
};

export default styles;
