import colors from "../../constants/colors";

const styles = {
  nick: {
    width: "10%",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  name: {
    width: "10%",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  surName: {
    width: "10%",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  login: {
    width: "15%",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  permissions: {
    width: "5%",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  userType: {
    width: "20%",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  buttons: {
    width: "20%",
    "font-weight": "bold",
    "font-size": "15px",
    "font-family": "Montserrat",
    "border-bottom": "4px solid",
  },
  delete: {
    color: colors.carmine,
  },
  iconLabelRed: {
    "font-weight": "bold",
    "font-size": "12px",
    "font-family": "Montserrat",
    marginLeft: "4%",
    marginTop: "2%",
    color: colors.carmine,
  },
  iconLabelGreen: {
    "font-weight": "bold",
    "font-size": "12px",
    "font-family": "Montserrat",
    marginLeft: "4%",
    marginTop: "2%",
    color: colors.green,
  },
  iconLabel: {
    "font-weight": "bold",
    "font-size": "12px",
    "font-family": "Montserrat",
    marginLeft: "4%",
    marginTop: "2%",
  },
  cell: {
    "font-weight": "bold",
    "font-family": "Montserrat",
  },
  row: {
    transform: "all 0.25s ease-in-out",
    "&:hover": {
      transform: "scale(1.01)",
      cursor: "pointer",
    },
    unlock: {
      color: colors.green,
    },

    caption: {
      fontSize: "15px",
      "font-family": "Montserrat",
    },
    toolbar: {
      "& > p:nth-of-type(2)": {
        fontSize: "15px",
        color: colors.black,
        "font-family": "Montserrat",
        fontWeight: 600,
      },
    },
  },
};

export default styles;
