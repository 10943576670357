import React from "react";
import { Container, makeStyles, Typography } from "@material-ui/core";

import styles from "./Register.styles.js";
import Footer from "../../components/LoginScreenFooter/Footer";
import HELPER_TEXTS from "../../constants/registerHelperTexts";
import RegisterForm from "../../components/RegisterForm/RegisterForm";

const useStyles = makeStyles(styles);

const Register = () => {
	const classes = useStyles();
	return (
		<div className={classes.body}>
			<Container align="center">
				{/* osmia logo here */}
				<Typography variant="h4" className={classes.header}>
					{HELPER_TEXTS["headerText"]}
				</Typography>

				<RegisterForm />

				<Footer />
			</Container>
		</div>
	);
};

export default Register;
