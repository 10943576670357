import { Viewer,SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import { Paper, Typography } from "@material-ui/core";

const PdfViewer = ({ source }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return source ? (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
        <Viewer
          defaultScale={SpecialZoomLevel.PageFit}
          fileUrl={source}
          plugins={[defaultLayoutPluginInstance]}
        />
      </Worker>
  ) : (
    <Paper>
      <Typography>Regulamin Niedostępny</Typography>
    </Paper>
  );
};

export default PdfViewer;
