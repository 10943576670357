import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { Grid, Typography, MenuItem } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styles from "./EventsTable.styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import StyledCategorySelect from "../common/Selects/StyledCategorySelect";
import fields from "../../constants/eventsConstants";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { useHistory } from "react-router";
import { format } from "date-fns";
import Button from "@material-ui/core/Button";
import EditEventWindow from "../ModalWindows/Events/EditEventWindow";
import { SortIcon } from "../common/buttons/SortIcon";
import EventIcon from "@material-ui/icons/Event";
import CommonModal from "../common/modals/CommonModal";
import { shop } from "../../utils/newApi";
import DeleteEditButton from "../common/buttons/DeleteEditButton";
import CustomButton from "../common/buttons/CustomButton";

import {
  SERVER_ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";
import { sortConstants, eventsSort } from "../../constants/localStorage";

const useStyles = makeStyles(styles);

const EventsTable = ({
  events,
  setEvents,
  eventsCopy,
  handleError,
  page,
  rowsPerPage,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [id, setId] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [infoForEdit, setInfoForEdit] = useState("");
  const [isAscSince, setIsAscSince] = useState(null);
  const [isAscTo, setIsAscTo] = useState(null);
  const [isAscN, setIsAscN] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [isPlanned, setIsPlanned] = useState(null);
  const [isActiveN, setIsActiveN] = useState(false);
  const [isActiveS, setIsActiveS] = useState(false);
  const [isActiveT, setIsActiveT] = useState(false);
  const [isActiveA, setIsActiveA] = useState(false);
  const [isActiveP, setIsActiveP] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [sortBy, setSortBy] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (eventsCopy.length !== 0) {
      const sortToApply = localStorage.getItem(sortConstants.events);

      if (sortToApply) {
        switch (sortToApply) {
          case eventsSort.nameA:
            sortName(true);
            break;
          case eventsSort.nameD:
            sortName(false);
            break;
          case eventsSort.sinceA:
            sortDateSince(true);
            break;
          case eventsSort.sinceD:
            sortDateSince(false);
            break;
          case eventsSort.toA:
            sortDateTo(true);
            break;
          case eventsSort.toD:
            sortDateTo(false);
            break;
          case eventsSort.activeA:
            sortActive(true);
            break;
          case eventsSort.activeD:
            sortActive(false);
            break;
          case eventsSort.plannedA:
            sortPlanned(true);
            break;
          case eventsSort.plannedD:
            sortPlanned(false);
            break;
        }
      }
    }
  }, [eventsCopy]);

  const handleDelete = (id, e) => {
    e.stopPropagation();
    setOpenConfirmation(true);
    setId(id);
  };

  const handleEdit = (event, e) => {
    e.stopPropagation();
    setInfoForEdit(event);
    setOpenEdit(true);
  };

  const handlefield = (e) => {
    setSortBy(e.target.value);
  };

  const isEventActive = (event) => {
    const currentDate = new Date();
    if (
      currentDate >= new Date(event.startDate) &&
      currentDate <= new Date(event.endDate)
    )
      return true;
    else return false;
  };

  const isEventPlanned = (event) => {
    const currentDate = new Date();
    if (currentDate < new Date(event.startDate)) return true;
    else return false;
  };

  const handleSortSelected = () => {
    switch (sortBy) {
      case "Nazwa":
        sortName();
        break;
      case "Od":
        sortDateSince();
        break;
      case "Do":
        sortDateTo();
        break;
      case "Aktywne":
        sortActive();
        break;
      case "Zaplanowane":
        sortPlanned();
        break;
      default:
    }
  };

  const sortDateSince = (isAsc = null) => {
    setIsActiveA(false);
    setIsActiveN(false);
    setIsActiveT(false);
    setIsActiveS(true);
    setIsActiveP(false);
    setIsAscSince((prev) => isAsc ?? !prev);
  };

  useEffect(() => {
    if (isAscSince !== null) {
      const sorted = [...events].sort((a, b) => {
        if (isAscSince) {
          return new Date(a.startDate) - new Date(b.startDate);
        } else {
          return new Date(b.startDate) - new Date(a.startDate);
        }
      });

      setEvents(sorted);

      if (isAscSince) {
        localStorage.setItem(sortConstants.events, eventsSort.sinceA);
      } else {
        localStorage.setItem(sortConstants.events, eventsSort.sinceD);
      }
    }
  }, [isAscSince]);

  const sortDateTo = (isAsc = null) => {
    setIsActiveA(false);
    setIsActiveN(false);
    setIsActiveT(true);
    setIsActiveS(false);
    setIsActiveP(false);
    setIsAscTo((prev) => isAsc ?? !prev);
  };

  useEffect(() => {
    if (isAscTo !== null) {
      const sorted = [...events].sort((a, b) => {
        if (isAscTo) {
          return new Date(a.endDate) - new Date(b.endDate);
        } else {
          return new Date(b.endDate) - new Date(a.endDate);
        }
      });

      setEvents(sorted);

      if (isAscTo) {
        localStorage.setItem(sortConstants.events, eventsSort.toA);
      } else {
        localStorage.setItem(sortConstants.events, eventsSort.toD);
      }
    }
  }, [isAscTo]);

  const sortName = (isAsc = null) => {
    setIsActiveA(false);
    setIsActiveN(true);
    setIsActiveT(false);
    setIsActiveS(false);
    setIsActiveP(false);
    setIsAscN((prev) => isAsc ?? !prev);
  };

  useEffect(() => {
    if (isAscN !== null) {
      const sorted = [...events].sort((a, b) => {
        const nameA = a.title.toLowerCase();
        const nameB = b.title.toLowerCase();
        if (isAscN) {
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        } else {
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
        }
        return 0;
      });

      setEvents(sorted);

      if (isAscN) {
        localStorage.setItem(sortConstants.events, eventsSort.nameA);
      } else {
        localStorage.setItem(sortConstants.events, eventsSort.nameD);
      }
    }
  }, [isAscN]);

  const sortActive = (isAsc = null) => {
    setIsActiveA(true);
    setIsActiveN(false);
    setIsActiveT(false);
    setIsActiveS(false);
    setIsActiveP(false);
    setIsActive((prev) => isAsc ?? !prev);
  };

  useEffect(() => {
    if (isActive !== null) {
      const sorted = [...events].sort((a, b) => {
        if (isActive) {
          if (isEventActive(a)) {
            return 1;
          }
          if (isEventActive(b)) {
            return -1;
          }
        } else {
          if (isEventActive(a)) {
            return -1;
          }
          if (isEventActive(b)) {
            return 1;
          }
        }
      });

      setEvents(sorted);

      if (isActive) {
        localStorage.setItem(sortConstants.events, eventsSort.activeA);
      } else {
        localStorage.setItem(sortConstants.events, eventsSort.activeD);
      }
    }
  }, [isActive]);

  const sortPlanned = (isAsc = null) => {
    setIsActiveA(false);
    setIsActiveN(false);
    setIsActiveT(false);
    setIsActiveS(false);
    setIsActiveP(true);
    setIsPlanned((prev) => isAsc ?? !prev);
  };

  useEffect(() => {
    if (isPlanned !== null) {
      const sorted = [...events].sort((a, b) => {
        if (isPlanned) {
          if (isEventPlanned(a)) {
            return 1;
          }
          if (isEventPlanned(b)) {
            return -1;
          }
        } else {
          if (isEventPlanned(a)) {
            return -1;
          }
          if (isEventPlanned(b)) {
            return 1;
          }
        }
      });

      setEvents(sorted);

      if (isPlanned) {
        localStorage.setItem(sortConstants.events, eventsSort.plannedA);
      } else {
        localStorage.setItem(sortConstants.events, eventsSort.plannedD);
      }
    }
  }, [isPlanned]);

  const deleteEvent = (eventId) => {
    shop.events
      .delete(eventId)
      .then((res) => {
        setEvents(events.filter((item) => item.id !== eventId));
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_EVENT);
      });
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            {matches ? (
              <TableRow>
                <TableCell className={classes.nameRes}></TableCell>
                <TableCell className={classes.dateRes} />
                <TableCell className={classes.searchRes} align="right">
                  {sortBy !== "" && (
                    <UnfoldMoreIcon onClick={handleSortSelected} />
                  )}
                </TableCell>
                <TableCell className={classes.nameRes}>
                  <StyledCategorySelect
                    InputLabelProps={{ classes: { root: classes.label } }}
                    fullWidth={true}
                    label="Sortuj według"
                    variant="outlined"
                    shrink="true"
                    onChange={handlefield}
                    value={sortBy}
                    select={true}
                  >
                    {fields.map((field) => {
                      return (
                        <MenuItem key={field.id} value={field.name}>
                          {field.name}
                        </MenuItem>
                      );
                    })}
                  </StyledCategorySelect>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell align="left" className={classes.name}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={12}>
                      <div className={classes.headerCell}>
                        <Typography className={classes.cell}>
                          TYTUŁ WYDARZENIA
                        </Typography>
                        <div
                          className={classes.headerCellRight}
                          onClick={() => sortName()}
                        >
                          <SortIcon isactive={isActiveN} asacdesc={isAscN} />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" className={classes.value}>
                  <Grid container direction="row">
                    <Grid item xs={12} md={6} align="left">
                      <div className={classes.headerCell}>
                        <Typography className={classes.cell}>OD</Typography>
                        <div
                          className={classes.headerCellRight}
                          onClick={() => sortDateSince()}
                        >
                          <SortIcon
                            isactive={isActiveS}
                            asacdesc={isAscSince}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" className={classes.value}>
                  <Grid container direction="row">
                    <Grid item xs={12} md={6} align="left">
                      <div className={classes.headerCell}>
                        <Typography className={classes.cell}>DO</Typography>
                        <div
                          className={classes.headerCellRight}
                          onClick={() => sortDateTo()}
                        >
                          <SortIcon isactive={isActiveT} asacdesc={isAscTo} />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" className={classes.active}>
                  <Grid container direction="row">
                    <Grid item xs={12} md={6} align="left">
                      <div className={classes.headerCell}>
                        <Typography className={classes.cell}>
                          Aktywne
                        </Typography>
                        <div
                          className={classes.headerCellRight}
                          onClick={() => sortActive()}
                        >
                          <SortIcon isactive={isActiveA} asacdesc={isActive} />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" className={classes.active}>
                  <Grid container direction="row">
                    <Grid item xs={12} md={6} align="left">
                      <div className={classes.headerCell}>
                        <Typography className={classes.cell}>
                          Zaplanowane
                        </Typography>
                        <div
                          className={classes.headerCellRight}
                          onClick={() => sortPlanned()}
                        >
                          <SortIcon isactive={isActiveP} asacdesc={isPlanned} />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell className={classes.buttons}></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {events
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((event) => (
                <TableRow
                  key={event.id}
                  className={classes.row}
                  onClick={() => {
                    localStorage.setItem("currentEventsTablePage", page);
                    localStorage.setItem(
                      "currentEventsTableRowsPerPage",
                      rowsPerPage
                    );
                    history.push(`/dashboard/event/${event.id}`);
                  }}
                >
                  <TableCell align="left">
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={12}
                        align={matches ? "center" : "left"}
                        className={matches && classes.breakline}
                      >
                        <Typography className={classes.cell}>
                          {event.title}
                        </Typography>
                      </Grid>
                      {matches && (
                        <DeleteEditButton
                          mobile={true}
                          editClickHandler={(e) => {
                            handleEdit(event, e);
                          }}
                          deleteClickHandler={(e) => {
                            handleDelete(event.id, e);
                          }}
                        />
                      )}
                    </Grid>
                  </TableCell>
                  {matches ? null : (
                    <TableCell align="left">
                      <Button
                        className={
                          isEventActive(event)
                            ? classes.cell
                            : classes.disableCell
                        }
                        endIcon={<EventIcon />}
                      >
                        {format(new Date(event.startDate), "dd/MM/yyyy")}
                      </Button>
                    </TableCell>
                  )}
                  {matches ? (
                    <TableCell align="center">
                      <Button
                        className={
                          isEventActive(event)
                            ? classes.cell
                            : classes.disableCell
                        }
                        endIcon={<EventIcon />}
                      >
                        {`Od: ${format(
                          new Date(event.startDate),
                          "dd/MM/yyyy"
                        )} Do: ${format(
                          new Date(event.endDate),
                          "dd/MM/yyyy"
                        )}`}
                      </Button>
                    </TableCell>
                  ) : (
                    <TableCell align="left">
                      <Button
                        className={
                          isEventActive(event)
                            ? classes.cell
                            : classes.disableCell
                        }
                        endIcon={<EventIcon />}
                      >
                        {format(new Date(event.endDate), "dd/MM/yyyy")}
                      </Button>
                    </TableCell>
                  )}
                  {!matches && (
                    <TableCell align="left" className={classes.buttons2}>
                      <CustomButton
                        variant={
                          isEventActive(event)
                            ? BUTTON_VARIANTS.GREEN
                            : BUTTON_VARIANTS.RED
                        }
                        size={BUTTON_SIZES.LARGE}
                        text={isEventActive(event) ? "Tak" : "Nie"}
                      />
                    </TableCell>
                  )}
                  {matches ? (
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography className={classes.cell} align="center">
                            AKTYWNE
                          </Typography>
                        </Grid>
                        <Grid item xs={12} align="center">
                          <CustomButton
                            variant={
                              isEventActive(event)
                                ? BUTTON_VARIANTS.GREEN
                                : BUTTON_VARIANTS.RED
                            }
                            size={BUTTON_SIZES.SMALL}
                            text={isEventActive(event) ? "Tak" : "Nie"}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  ) : (
                    <TableCell align="left" className={classes.buttons2}>
                      <CustomButton
                        variant={
                          isEventPlanned(event)
                            ? BUTTON_VARIANTS.GREEN
                            : BUTTON_VARIANTS.RED
                        }
                        size={BUTTON_SIZES.LARGE}
                        text={isEventPlanned(event) ? "Tak" : "Nie"}
                      />
                    </TableCell>
                  )}
                  {matches ? (
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography className={classes.cell} align="center">
                            ZAPLANOWANE
                          </Typography>
                        </Grid>
                        <Grid item xs={12} align="center">
                          <CustomButton
                            variant={
                              isEventPlanned(event)
                                ? BUTTON_VARIANTS.GREEN
                                : BUTTON_VARIANTS.RED
                            }
                            size={BUTTON_SIZES.SMALL}
                            text={isEventPlanned(event) ? "Tak" : "Nie"}
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                  ) : (
                    <DeleteEditButton
                      mobile={false}
                      editClickHandler={(e) => {
                        handleEdit(event, e);
                      }}
                      deleteClickHandler={(e) => {
                        handleDelete(event.id, e);
                      }}
                    />
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() => deleteEvent(id)}
        title={COMMON_MODAL_TEXTS.EVENT_TITLE}
        content={COMMON_MODAL_TEXTS.EVENT_CONTENT}
      />
      <EditEventWindow
        events={events}
        setEvents={setEvents}
        isOpen={openEdit}
        close={() => setOpenEdit(false)}
        data={infoForEdit}
        handleError={handleError}
      />
    </>
  );
};

export default EventsTable;
