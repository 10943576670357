import React, { useState } from "react";
import { Typography, Grid, makeStyles, Box } from "@material-ui/core";
import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import StyledFormTextField from "../../common/textFields/StyledFormTextField";
import CommonModal from "../../common/modals/CommonModal";
import StyledDescriptionTextField from "../../common/textFields/StyledDescriptionTextField";

import useForm from "../../../hooks/useForm";
import styles from "./EventModa.styles";
import EVENTS_HELPER_TEXTS from "../../../constants/eventsHelperTexts";
import { shop } from "../../../utils/newApi";
import {
  SERVER_ERROR_MESSAGES,
  MODAL_TYPE,
  COMMON_MODAL_TEXTS,
} from "../../../constants/dictionary";

const useStyles = makeStyles(styles);

const AddEventWindow = ({ isOpen, close, handleError, setEvents }) => {
  const classes = useStyles();
  const { handleChange, values } = useForm({
    name: "",
    description: "",
  });
  const currentDate = new Date();
  const [dateSince, setDateSince] = useState(currentDate);
  const [dateTo, setDateTo] = useState(currentDate);
  const [hourSince, setHourSince] = useState(currentDate);
  const [hourTo, setHourTo] = useState(currentDate);
  const [error, setError] = useState(false);

  const submit = (e) => {
    const combinedDateTo = `${format(dateTo, "yyyy/MM/dd")} ${format(
      hourTo,
      "H:mm:ss"
    )}`;
    const date = Date.parse(combinedDateTo);
    const finalDateTo = new Date(date);
    const combinedDateSince = `${format(dateSince, "yyyy/MM/dd")} ${format(
      hourSince,
      "H:mm:ss"
    )}`;
    const date2 = Date.parse(combinedDateSince);
    const finalDateSince = new Date(date2);

    const data = {
      description: values.description,
      endDate: finalDateTo.toISOString(),
      startDate: finalDateSince.toISOString(),
      title: values.name,
    };

    if (values.name === "" || values.description === "") {
      setError(true);
      return;
    }

    if (Date.parse(finalDateSince) < finalDateTo) {
      setError(false);
      shop.events
        .add(data)
        .then((res) => {
          close();
        })
        .catch((err) => {
          handleError(SERVER_ERROR_MESSAGES.COULD_NOT_ADD_EVENT);
        });
    } else {
      setError(true);
    }
  };

  return (
    <CommonModal
      open={isOpen}
      toggleOpen={close}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.ADD_EVENT_TITLE}
      type={MODAL_TYPE.ADD}
    >
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledFormTextField
            InputLabelProps={{ classes: { root: classes.label } }}
            fullWidth={true}
            name="name"
            label="Wpisz tytuł wydarzenia"
            variant="outlined"
            required
            shrink="true"
            onChange={handleChange("name")}
          />
        </Box>
      </Grid>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container direction="row" justify="center">
          <Grid item xs={12} md={6}>
            <Box className={classes.box2}>
              <KeyboardDatePicker
                disableToolbar
                fullWidth={true}
                variant="outline"
                format="MM/dd/yyyy"
                margin="normal"
                label="Od"
                value={dateSince}
                onChange={(date) => setDateSince(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.box2}>
              <KeyboardDatePicker
                disableToolbar
                fullWidth={true}
                variant="outline"
                label="Do"
                format="MM/dd/yyyy"
                margin="normal"
                value={dateTo}
                onChange={(date) => setDateTo(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center">
          <Grid item xs={12} md={6}>
            <Box className={classes.box2}>
              <KeyboardTimePicker
                fullWidth={true}
                ampm={false}
                variant="outline"
                label="Godzina od"
                margin="normal"
                value={hourSince}
                onChange={(date) => setHourSince(date)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.box2}>
              <KeyboardTimePicker
                fullWidth={true}
                ampm={false}
                variant="outline"
                label="Godzina do"
                margin="normal"
                value={hourTo}
                onChange={(date) => setHourTo(date)}
              />
            </Box>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      {error && (
        <Grid item xs={12} align="center">
          <Box className={classes.box}>
            <Typography className={classes.error}>
              {values.name === "" || values.description === ""
                ? "Nie wypełniono obowiązkowych pól"
                : EVENTS_HELPER_TEXTS.dateError}
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledDescriptionTextField
            InputLabelProps={{ classes: { root: classes.label } }}
            fullWidth={true}
            label="Opis wydarzenia"
            variant="outlined"
            required
            rows={4}
            shrink="true"
            multiline={true}
            name="description"
            onChange={handleChange("description")}
          />
        </Box>
      </Grid>
    </CommonModal>
  );
};

export default AddEventWindow;
