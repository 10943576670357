import orderStatus from "../constants/orderConstants";
import colors from "../constants/colors";

export const itemStatusColor = (itemStatus) => {
  return itemStatus === orderStatus[0]
    ? { backgroundColor: colors.htmlGray }
    : itemStatus === orderStatus[1]
    ? { backgroundColor: colors.htmlOrange }
    : itemStatus === orderStatus[2]
    ? { backgroundColor: colors.brightLavender }
    : itemStatus === orderStatus[3]
    ? { backgroundColor: colors.skyBlue }
    : itemStatus === orderStatus[4]
    ? { backgroundColor: colors.htmlGreen }
    : { backgroundColor: colors.htmlRed };
};

export const itemStatusListColor = (itemStatus) => {
  return itemStatus === orderStatus[0]
    ? { color: colors.htmlGray }
    : itemStatus === orderStatus[1]
    ? { color: colors.htmlOrange }
    : itemStatus === orderStatus[2]
    ? { color: colors.brightLavender }
    : itemStatus === orderStatus[3]
    ? { color: colors.skyBlue }
    : itemStatus === orderStatus[4]
    ? { color: colors.htmlGreen }
    : { color: colors.htmlRed };
};
