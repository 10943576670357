import colors from "../../constants/colors";

const styles = (theme) => {
  return {
    label: {
      "white-space": "nowrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: 13,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 15,
      },
    },
    box: {
      marginTop: 20,
      marginBottom: 20,
      marginLeft: 50,
      marginRight: 50,
    },
    box2: {
      marginTop: 20,
      marginBottom: 20,
    },
    box3: {
      marginTop: 20,
      marginBottom: 20,
    },
    root: {
      backgroundColor: colors.white,
      borderRadius: "30px",
      marginTop: "100px",
      maxWidth: "500px",
    },
    error: {
      color: colors.htmlRed,
    },
    loginText: {
      marginTop: "20px",
      fontWeight: "bold",
      "font-family": "Montserrat",
    },
    cursors: {
      cursor: "pointer",
      "font-family": "Montserrat",
    },
  };
};

export default styles;
