export const sortConstants = {
  orders: "ordersSort",
  plantsProtectionProducts: "plantsProtectionProductsSort",
  events: "eventsSort",
  ads: "adsSort",
  qrs: "qrsSort",
  fieldWorks: "fieldWorksSort",
};

export const ordersSort = {
  idA: "idAsc",
  idD: "idDesc",
  ordererA: "ordererAsc",
  ordererD: "ordererDesc",
  valueA: "valueAsc",
  valueD: "valueDesc",
  dateA: "dateAsc",
  dateD: "dateDesc",
};

export const plantsProtectionProductsSort = {
  nameA: "nameAsc",
  nameD: "nameDesc",
};

export const eventsSort = {
  nameA: "nameAsc",
  nameD: "nameDesc",
  sinceA: "sinceAsc",
  sinceD: "sinceDesc",
  toA: "toAsc",
  toD: "toDesc",
  activeA: "activeAsc",
  activeD: "activeDesc",
  plannedA: "plannedAsc",
  plannedD: "plannedDesc",
};

export const adsSort = {
  nameA: "nameAsc",
  nameD: "nameDesc",
  sinceA: "sinceAsc",
  sinceD: "sinceDesc",
  toA: "toAsc",
  toD: "toDesc",
  activeA: "activeAsc",
  activeD: "activeDesc",
  viewsA: "viewsAsc",
  viewsD: "viewsDesc",
};

export const qrsSort = {
  nameA: "nameAsc",
  nameD: "nameDesc",
  dateA: "dateAsc",
  dateD: "dateDesc",
};

export const fieldWorksSort = {
  userA: "userAsc",
  userD: "userDesc",
};

export const filterConstants = {
  orders: "ordersFilter",
  users: "usersFilter",
};

export const userFilter = {
  email: "Email",
  login: "Login",
  permissions: "Uprawnienia",
  name: "Imię",
  surname: "Nazwisko",
};

export const searchConstants = {
  users: "usersSearch",
};
