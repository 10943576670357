import React from "react";
import { Typography, Grid, Modal } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import CustomButton from "../buttons/CustomButton";

import {
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  MODAL_TYPE,
  COMMON_MODAL_CONFIRM_BUTTON_TEXTS,
  COMMON_MODAL_REJECT_BUTTON_TEXTS,
} from "../../../constants/dictionary";

import useStyles from "./CommonModal.styles";

const CommonModal = ({
  open,
  toggleOpen,
  handleConfirmation,
  title,
  content,
  type = MODAL_TYPE.DELETE,
  children,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  return (
    <Modal open={open} onClose={toggleOpen}>
      <div className={classes.paper}>
        <Grid
          container
          spacing={8}
          direction="column"
          justify="center"
          align="center"
        >
          <Grid item xs={12}>
            <Typography
              variant={matches ? "h6" : "h5"}
              className={classes.text}
            >
              {title}
            </Typography>
          </Grid>
          {content ? (
            <Grid item xs={12}>
              <Typography
                className={classes.text}
                style={{ fontSize: matches ? 14 : 16 }}
              >
                {content}
              </Typography>
            </Grid>
          ) : (
            <>{children}</>
          )}
          <Grid item xs={12} container justify="space-between" align="center">
            <Grid item xs={6}>
              <CustomButton
                variant={BUTTON_VARIANTS.GREEN}
                size={BUTTON_SIZES.MEDIUM}
                clickHandler={(e) => {
                  {
                    type === MODAL_TYPE.DELETE && toggleOpen();
                  }
                  handleConfirmation(e);
                }}
                text={COMMON_MODAL_CONFIRM_BUTTON_TEXTS[type]}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomButton
                variant={BUTTON_VARIANTS.RED}
                size={BUTTON_SIZES.MEDIUM}
                clickHandler={toggleOpen}
                text={COMMON_MODAL_REJECT_BUTTON_TEXTS[type]}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default CommonModal;
