import { makeStyles } from "@material-ui/core";

import colors from "../../constants/colors";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: colors.eerieBlack,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: colors.eerieBlack,
  },
  drawerContainer: {
    display: "flex",
    flexFlow: "row wrap",
  },
  drawerContent: {
    display: "flex",
    flexFlow: "column nowrap",
  },
  drawerBottom: {
    marginTop: "auto",
  },
  buttonBack: {
    marginTop: 15,
    color: colors.white,
  },
}));

export default useStyles;
