import React from "react";
import { Grid, makeStyles, Box } from "@material-ui/core";

import CommonModal from "../../common/modals/CommonModal";
import StyledFormTextField from "../../common/textFields/StyledFormTextField";

import { fieldWork } from "../../../utils/newApi";
import styles from "./WorkModal.styles";
import {
  SERVER_ERROR_MESSAGES,
  MODAL_TYPE,
  COMMON_MODAL_TEXTS,
} from "../../../constants/dictionary";

const useStyles = makeStyles(styles);

const NewWorkModalWindow = ({ isOpen, close, handleError }) => {
  const classes = useStyles();
  const [name, setName] = React.useState("");

  const submit = (e) => {
    let payload = {
      name: name,
    };

    fieldWork
      .add(payload)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_ADD_WORK);
      });
  };

  return (
    <CommonModal
      open={isOpen}
      toggleOpen={close}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.NEW_WORK_TITLE}
      type={MODAL_TYPE.ADD}
    >
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledFormTextField
            InputLabelProps={{
              classes: { root: classes.label },
            }}
            fullWidth={true}
            label="Nazwa pracy"
            variant="outlined"
            required
            shrink="true"
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
      </Grid>
    </CommonModal>
  );
};

export default NewWorkModalWindow;
