import colors from "../../constants/colors";

const styles = (theme) => {
  return {
    root: {
      width: "80%",
      align: "center",
      "border-radius": "10px",
      overflow: "hidden",
      "margin-left": "10%",
      "margin-right": "10%",
      height: "70%",
      [theme.breakpoints.down("sm")]: {
        "margin-top": "25%",
      },
      [theme.breakpoints.up("sm")]: {
        "margin-top": "10%",
      },
      "margin-bottom": "10%",
      paddingTop: "20px",
      paddingBottom: "20px",
    },
    header: {
      "text-align": "center",
      "font-weight": "bold",
      "font-size": "25px",
      "font-family": "Montserrat",
    },
    scroll: {
      flex: 1,
      "overflow-y": "scroll",
      "overflow-x": "hidden",
      width: "100%",
      height: "100%",
    },
    box: {
      marginLeft: "5%",
      marginRight: "5%",
    },
    label: {
      "white-space": "nowrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: 13,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 15,
      },
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    imageList: {
      width: "80%",
      height: "80%",
    },
    error: {
      color: colors.htmlRed,
      align: "center",
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    plantsBox: {
      marginTop: "5%",
      marginLeft: "5%",
      marginRight: "5%",
    },
    doseText: {
      "text-align": "center",
      "font-weight": "bold",
      "font-family": "Montserrat",
      marginTop: "5%",
      marginBottom: "5%",
    },
    switch: {
      marginBottom: "50px",
    },
    buttonBox: {
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10%",
        marginRight: "10%",
      },
      [theme.breakpoints.up("sm")]: {
        marginLeft: "40%",
        marginRight: "40%",
      },
    },
    rootList: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      borderRadius: 20,
    },
    listText: {
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    amountsBox: {
      marginTop: "3%",
      marginBottom: "1%",
    },
    icon: {
      color: colors.htmlGreen,
    },
    avatar: {
      backgroundColor: colors.white,
      border: "1px solid black",
    },
    delete: {
      color: colors.htmlRed,
      cursor: "pointer",
    },
  };
};

export default styles;
