export const displayPrice = (price) => {
  if (price === null || price === undefined || price === 0) return 0;

  return price.toFixed(2);
};

export const validatePrice = (price) => {
  const regex = /^\d+?(?:[.,])?[(0-9)]?[(0-9)]?$/;
  return regex.test(String(price));
};

export const changePriceToInt = (price) => {
  if (price.toString().includes(",")) {
    if (
      price.toString().slice(price.toString().indexOf(",", 0) + 1).length === 2
    ) {
      return parseInt(price.toString().replace(",", ""));
    } else {
      return parseInt(price.toString().replace(",", "") + "0");
    }
  } else if (price.toString().includes(".")) {
    if (
      price.toString().slice(price.toString().indexOf(".", 0) + 1).length === 2
    ) {
      return parseInt(price.toString().replace(".", ""));
    } else {
      return parseInt(price.toString().replace(".", "") + "0");
    }
  } else {
    return parseInt(price.toString() + "00");
  }
};
