import React, { useState } from "react";
import { makeStyles, Grid, Typography, Box } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import styles from "./UserInfoBox.styles";
import AccountBoxRoundedIcon from "@material-ui/icons/AccountBoxRounded";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../../screens/Login/LoginSreen.breakpoints";
import Avatar from "@material-ui/core/Avatar";
import AdjustUserWindow from "../ModalWindows/UserInfo/AdjustUserWindow";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import { users as usersApi } from "../../utils/newApi";
import CommonModal from "../common/modals/CommonModal";

import {
  SERVER_ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";
import CustomButton from "../common/buttons/CustomButton";

const useStyles = makeStyles(styles);

const UserInfoBox = ({ info }) => {
  const classes = useStyles(styles);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  const blockUser = (userId) => {
    usersApi.usersInfo
      .block(userId)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_BLOCK_USER);
      });
  };

  const unlockUser = (userId) => {
    usersApi.usersInfo
      .unblock(userId)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_UNBLOCK_USER);
      });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="rows">
        <Grid item xs={matches ? 12 : 7}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            direction="row"
            alignItems="center"
          >
            <Grid item xs={4} align="left">
              <Avatar className={classes.avatar}>
                <AccountBoxRoundedIcon className={classes.icon} />
              </Avatar>
            </Grid>
            <Grid item xs={8} align="left">
              <Typography className={classes.header}>
                {info && info.username}
              </Typography>
            </Grid>
            <Grid item xs={12} align="left">
              <Box className={classes.desBox}>
                <Typography className={classes.text}></Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <List className={classes.rootList}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Email"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                      >
                        {info && info.email}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Uprawnienia"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                      >
                        {info && info.type}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Imię"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                      >
                        {info && info.firstName ? info.firstName : "Brak"}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Nazwisko"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                      >
                        {info && info.lastName ? info.lastName : "Brak"}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={matches ? 12 : 5} className={classes.greyBox}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            direction="row"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Box className={!matches && classes.RightBox}>
                <Grid item xs={12} align="center">
                  <Box className={classes.margin}>
                    <Typography className={classes.active}>
                      Użytkownik zweryfikowany
                    </Typography>
                    <Box className={classes.box}>
                      <CustomButton
                        variant={
                          info.verified
                            ? BUTTON_VARIANTS.GREEN
                            : BUTTON_VARIANTS.RED
                        }
                        size={BUTTON_SIZES.SMALL}
                        text={info.verified ? "Tak" : "Nie"}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={!matches && classes.RightBox}>
                <Grid item xs={12} align="center">
                  <Box className={classes.margin}>
                    <Typography className={classes.active}>
                      Użytkownik zablokowany
                    </Typography>
                    <Box className={classes.box}>
                      <CustomButton
                        variant={
                          !info.blocked
                            ? BUTTON_VARIANTS.GREEN
                            : BUTTON_VARIANTS.RED
                        }
                        size={BUTTON_SIZES.SMALL}
                        text={!info.blocked ? "Nie" : "Tak"}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={!matches && classes.RightBox}>
                <Grid item xs={12} align="center">
                  <Box className={classes.margin}>
                    <Typography className={classes.active}>
                      Samouczek
                    </Typography>
                    <Box className={classes.box}>
                      <CustomButton
                        variant={
                          info.isTutorialFinished
                            ? BUTTON_VARIANTS.GREEN
                            : BUTTON_VARIANTS.RED
                        }
                        size={BUTTON_SIZES.SMALL}
                        text={
                          info.isTutorialFinished ? "Wykonany" : "Niewykonany"
                        }
                      />
                    </Box>
                  </Box>
                </Grid>
              </Box>
            </Grid>
            <Grid container direction="row">
              <Grid item xs={6}>
                <Box className={classes.buttonBox}>
                  <CustomButton
                    variant={BUTTON_VARIANTS.WHITE}
                    size={BUTTON_SIZES.LARGE}
                    clickHandler={() => setOpenEdit(true)}
                    text="Dostosuj"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className={classes.buttonBox}>
                  <CustomButton
                    variant={
                      info.blocked ? BUTTON_VARIANTS.GREEN : BUTTON_VARIANTS.RED
                    }
                    size={BUTTON_SIZES.LARGE}
                    clickHandler={() => {
                      setOpenConfirmation(true);
                    }}
                    text={info.blocked ? "Odblokuj" : "Zablokuj"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() =>
          info.blocked ? unlockUser(info.id) : blockUser(info.id)
        }
        title={
          info.blocked
            ? COMMON_MODAL_TEXTS.USER_CONTENT_UNBLOCK
            : COMMON_MODAL_TEXTS.USER_TITLE_BLOCK
        }
        content={
          info.blocked
            ? COMMON_MODAL_TEXTS.USER_CONTENT_UNBLOCK
            : COMMON_MODAL_TEXTS.USER_CONTENT_BLOCK
        }
      />
      <AdjustUserWindow
        isOpen={openEdit}
        close={() => setOpenEdit(false)}
        info={info}
        handleError={handleError}
      />
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </div>
  );
};

export default UserInfoBox;
