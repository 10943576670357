import { Grid } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import OrderStatus from "./OrderStatus";
import { useState } from "react";
import EditOrderWindow from "../ModalWindows/EditOrderWindow";
import useStyles from "./OrderData.styles";
import orderStatus from "../../constants/orderConstants";
import CustomButton from "../common/buttons/CustomButton";

import { BUTTON_VARIANTS, BUTTON_SIZES } from "../../constants/dictionary";

const OrderData = ({
  data,
  toggleOpen,
  handleEditData,
  status,
  handleMarkAsSent,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const toggleOpenEdit = () => setOpen(!open);
  return (
    <div className={classes.root}>
      <EditOrderWindow
        handleEdit={handleEditData}
        currentData={data.orderDeliveryAddressDAO}
        open={open}
        toggleOpen={toggleOpenEdit}
      />
      <Typography className={clsx(classes.text, classes.bold)} variant="h5">
        Dane wysyłkowe:
      </Typography>
      {data?.orderDeliveryAddressDAO &&
      Object.keys(data?.orderDeliveryAddressDAO)?.length > 0 ? (
        <div className={classes.dataContainer}>
          <Typography
            className={clsx(classes.text, classes.dataItem)}
            variant="body1"
          >
            {`${data.orderDeliveryAddressDAO.name} ${data.orderDeliveryAddressDAO.surname}`}
          </Typography>
          <Divider />
          <Typography
            className={clsx(classes.text, classes.dataItem)}
            variant="body1"
          >
            {`${data.orderDeliveryAddressDAO.streetName} ${
              data.orderDeliveryAddressDAO.streetNumber
            }${
              data.orderDeliveryAddressDAO.apartmenNumbert
                ? `/${data.orderDeliveryAddressDAO.apartmentNumber}`
                : ""
            }`}
          </Typography>
          <Divider />
          <Typography
            className={clsx(classes.text, classes.dataItem)}
            variant="body1"
          >
            {`${data.orderDeliveryAddressDAO.postalCode} ${data.orderDeliveryAddressDAO.city}`}
          </Typography>
          <Divider />
          <Typography
            className={clsx(classes.text, classes.dataItem)}
            variant="body1"
          >
            {`Email: ${data?.email ? data.email : "Brak"}`}
          </Typography>
          <Divider />
          <Typography
            className={clsx(classes.text, classes.dataItem)}
            variant="body1"
          >
            {`Telefon: ${data?.phoneNumber ? data.phoneNumber : "Brak"}`}
          </Typography>
          <Divider />
        </div>
      ) : (
        <Typography
          className={clsx(classes.text, classes.dataItem)}
          variant="body1"
        >
          Brak
        </Typography>
      )}

      <Typography className={clsx(classes.text, classes.bold)} variant="h5">
        Dane do faktury:
      </Typography>
      {data.invoiceRequested && data.orderInvoiceDataDAO ? (
        <div className={classes.dataContainer}>
          <Typography
            className={clsx(classes.text, classes.dataItem)}
            variant="body1"
          >
            {`${data.orderDeliveryAddressDAO.name} ${data.orderDeliveryAddressDAO.surname}`}
          </Typography>
          <Divider />
          <Typography
            className={clsx(classes.text, classes.dataItem)}
            variant="body1"
          >
            {`${data.orderInvoiceDataDAO.streetName} ${
              data.orderInvoiceDataDAO.streetNumber
            }${
              data.orderInvoiceDataDAO.apartmentNumber
                ? `/${data.orderInvoiceDataDAO.apartmentNumber}`
                : ""
            }`}
          </Typography>
          <Divider />
          <Typography
            className={clsx(classes.text, classes.dataItem)}
            variant="body1"
          >
            {`${data.orderInvoiceDataDAO.postalCode} ${data.orderInvoiceDataDAO.city}`}
          </Typography>
          <Divider />
          <Typography
            className={clsx(classes.text, classes.dataItem)}
          >{`Nip: ${data.orderInvoiceDataDAO.nip}`}</Typography>
          <Typography
            className={clsx(classes.text, classes.dataItem)}
          >{`Nazwa Firmy: ${data.orderInvoiceDataDAO.companyName}`}</Typography>
        </div>
      ) : (
        <Typography
          className={clsx(classes.text, classes.dataItem)}
          variant="body1"
        >
          Brak
        </Typography>
      )}

      <Typography className={clsx(classes.text, classes.bold)} variant="h5">
        Status zamówienia:
      </Typography>
      <OrderStatus status={status} handleMarkAsSent={handleMarkAsSent} />

      {orderStatus.indexOf(status) < 3 && (
        <Grid container justify="space-around">
          <Grid
            className={classes.buttonContainer}
            item
            md={12}
            lg={6}
            align="center"
          >
            <CustomButton
              variant={BUTTON_VARIANTS.WHITE}
              size={BUTTON_SIZES.LARGE}
              clickHandler={toggleOpenEdit}
              text="Edytuj zamówienie"
            />
          </Grid>
          <Grid
            className={classes.buttonContainer}
            item
            md={12}
            lg={6}
            align="center"
          >
            <CustomButton
              variant={BUTTON_VARIANTS.RED}
              size={BUTTON_SIZES.LARGE}
              clickHandler={toggleOpen}
              text="Anuluj zamówienie"
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default OrderData;
