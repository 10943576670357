import { makeStyles } from "@material-ui/core";

import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.quickSilver,
    padding: "2em",
    borderRadius: 30,
  },
  text: {
    fontFamily: "Montserrat",
  },
  bold: {
    fontWeight: "bold",
  },
  dataContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "1em",
  },
  dataItem: {
    flexGrow: 1,
    padding: "0.5em",
  },
  buttonContainer: {
    padding: "1em",
  },
}));

export default useStyles;
