import colors from "../../../constants/colors";

const styles = (theme) => {
  return {
    box2: {
      marginLeft: "20%",
      marginRight: "20%",
      marginTop: "5%",
      marginBottom: "5%",
    },
    box: {
      marginLeft: "5%",
      marginRight: "5%",
    },
    error: {
      color: colors.htmlRed,
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    label: {
      "white-space": "nowrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: 13,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 15,
      },
    },
    uploader: {
      [theme.breakpoints.up("sm")]: {
        marginLeft: "30%",
        marginRight: "30%",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "5%",
        marginRight: "5%",
      },
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      height: "50px",
      "font-weight": "bold",
      "font-size": "12px",
      "font-family": "Montserrat",
      color: colors.black,
      marginLeft: 4,
      marginRight: 4,
    },
  };
};

export default styles;
