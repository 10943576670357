import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import useForm from "../../hooks/useForm";
import { TextField } from "@material-ui/core";
import useStyles from "./Modal.styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import CustomButton from "../common/buttons/CustomButton";

import { BUTTON_VARIANTS, BUTTON_SIZES } from "../../constants/dictionary";
import { validatePostCode } from "../../utils/validation";

const EditOrderWindow = ({ handleEdit, open, toggleOpen, currentData }) => {
  const [emptyFieldsError, setEmptyFieldsError] = useState(false);
  const [incorrectPostCode, setIncorrectPostCode] = useState(false);

  const classes = useStyles();
  const { handleChange, values, setFieldValue } = useForm({
    name: currentData?.name,
    surname: currentData?.surname,
    streetName: currentData?.streetName,
    streetNumber: currentData?.streetNumber,
    apartmentNumber: currentData?.apartmentNumber,
    city: currentData?.city,
    postalCode: currentData?.postalCode,
  });

  useEffect(() => {
    setIncorrectPostCode(false);
    setEmptyFieldsError(false);
    setFieldValue("name", currentData?.name ?? "");
    setFieldValue("surname", currentData?.surname ?? "");
    setFieldValue("streetName", currentData?.streetName ?? "");
    setFieldValue("streetNumber", currentData?.streetNumber ?? "");
    setFieldValue("apartmentNumber", currentData?.apartmentNumber ?? "");
    setFieldValue("city", currentData?.city ?? "");
    setFieldValue("postalCode", currentData?.postalCode ?? "");
  }, [currentData, open]);

  const submit = () => {
    if (
      values.name?.trim().length === 0 ||
      values.surname?.trim().length === 0 ||
      values.streetName?.trim().length === 0 ||
      values.streetNumber?.trim().length === 0 ||
      values.city?.trim().length === 0 ||
      values.postalCode?.trim().length === 0
    ) {
      setEmptyFieldsError(true);
      return;
    }

    if (!validatePostCode(values.postalCode) || values.postalCode.length > 6) {
      setIncorrectPostCode(true);
      return;
    }

    setEmptyFieldsError(false);
    setIncorrectPostCode(false);

    if (values.apartmentNumber === "") values.apartmentNumber = null;
    handleEdit(values);
    toggleOpen();
  };

  return (
    <Modal open={open} onClose={toggleOpen}>
      <div className={classes.paper}>
        <Grid
          container
          spacing={2}
          direction="column"
          justify="center"
          align="center"
        >
          <Grid item>
            <Typography variant="h5">Edytuj zamówienie</Typography>
          </Grid>
          <Grid item>
            <TextField
              label="Imię"
              value={values.name}
              variant="outlined"
              fullWidth
              onChange={handleChange("name")}
              required
            />
          </Grid>
          <Grid item>
            <TextField
              label="Nazwisko"
              value={values.surname}
              variant="outlined"
              fullWidth
              onChange={handleChange("surname")}
              required
            />
          </Grid>
          <Grid item>
            <TextField
              label="Ulica"
              value={values.streetName}
              variant="outlined"
              fullWidth
              onChange={handleChange("streetName")}
              required
            />
          </Grid>
          <Grid item>
            <TextField
              label="Numer ulicy"
              value={values.streetNumber}
              variant="outlined"
              fullWidth
              onChange={handleChange("streetNumber")}
              required
            />
          </Grid>
          <Grid item>
            <TextField
              label="Numer mieszkania"
              value={values.apartmentNumber}
              variant="outlined"
              fullWidth
              onChange={handleChange("apartmentNumber")}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Miasto"
              value={values.city}
              variant="outlined"
              fullWidth
              onChange={handleChange("city")}
              required
            />
          </Grid>
          <Grid item>
            <TextField
              label="Kod pocztowy"
              value={values.postalCode}
              variant="outlined"
              fullWidth
              onChange={handleChange("postalCode")}
              required
            />
          </Grid>
          {emptyFieldsError && (
            <p className={classes.error}>Proszę wypełnić wymagane pola</p>
          )}
          {incorrectPostCode && (
            <p className={classes.error}>Proszę wpisać poprawny kod pocztowy</p>
          )}
          <Grid item container justify="space-between" align="center">
            <Grid item xs={6}>
              <CustomButton
                variant={BUTTON_VARIANTS.GREEN}
                size={BUTTON_SIZES.MEDIUM}
                clickHandler={submit}
                text="Edytuj"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomButton
                variant={BUTTON_VARIANTS.RED}
                size={BUTTON_SIZES.MEDIUM}
                clickHandler={toggleOpen}
                text="Anuluj"
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default EditOrderWindow;
