import colors from "../../constants/colors";

const styles = (theme) => {
  return {
    marginBox: {
      margin: "2%",
      paddingLeft: "2%",
      paddingRight: "2%",
    },
    topLeftBox: {
      marginTop: "2%",
      marginBottom: "2%",
    },
    buttonBox: {
      paddingLeft: "10%",
      paddingRight: "10%",
      marginBottom: "5%",
      marginTop: "10%",
    },
    headerMargin: {
      marginLeft: "3%",
      marginRight: "3%",
      marginTop: "5%",
    },
    secondHeaderMargin: {
      marginLeft: "3%",
      marginRight: "3%",
      marginTop: "2%",
    },
    productBox: {
      margin: "2%",
    },
    dataBox: {
      marginLeft: "5%",
      marginRight: "5%",
      borderRadius: "10px",
      border: "1px solid black",
    },
    dataBoxDesc: {
      marginLeft: "5%",
      marginRight: "5%",
      borderRadius: "10px",
      border: "1px solid black",
    },
    dateBox: {
      marginLeft: "5%",
      marginRight: "5%",
      borderRadius: "10px",
      border: "1px solid black",
      display: "flex",
      padding: "2%",
      "flex-direction": "row",
      "justify-content": "space-between",
      "flex-wrap": "wrap",
    },
    root: {
      backgroundColor: colors.white,
      padding: "5%",
    },
    loadingRoot: {
      display: "flex",
      "flex-direction": "row",
      "justify-content": "center",
      "align-items": "center",
      height: "100%",
      width: "100%",
    },
    cursors: {
      cursor: "pointer",
    },
    header: {
      [theme.breakpoints.down("sm")]: {
        "font-size": "20px",
      },
      [theme.breakpoints.up("sm")]: {
        "font-size": "25px",
      },
      "text-align": "left",
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    rootList: {
      maxWidth: "100%",
      backgroundColor: theme.palette.background.paper,
      borderRadius: 20,
    },
    dateRight: {
      "font-weight": "bold",
      "font-family": "Montserrat",
      "font-size": "20px",
    },
    dateLeft: {
      "font-family": "Montserrat",
      "font-size": "17px",
    },
    listText: {
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    text: {
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    greyBox: {
      backgroundColor: colors.platinum,
      borderRadius: 20,
    },
    disable: {
      color: colors.quickSilver,
      "font-weight": "bold",
      "font-size": "17px",
      "font-family": "Montserrat",
    },
    avatar: {
      width: "100%",
      height: "100%",
      border: "3px solid white",
      "box-sizing": "border-box",
      "box-shadow":
        "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
    },
    downloadBox: {
      marginTop: "5%",
      marginLeft: "20%",
      marginRight: "20%",
    },
    box2: {
      marginTop: "5%",
      marginRight: "10%",
    },
  };
};

export default styles;
