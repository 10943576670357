import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { Button, Grid, Typography } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styles from "./ActiveSubstanceProductsTable.styles";
import DeleteIcon from "@material-ui/icons/Delete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { SortIcon } from "../common/buttons/SortIcon";
import CommonModal from "../common/modals/CommonModal";
import { cultivations } from "../../utils/api";

import {
  SERVER_ERROR_MESSAGES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const ActiveSubstanceProductsTable = ({
  products,
  setPlantsProducts,
  handleError,
  page,
  rowsPerPage,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [infoForDelete, setInfoForDelete] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [infoForEdit, setInfoForEdit] = useState("");
  const [isAscN, setIsAscN] = useState(true);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [isActiveName, setIsActiveName] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  const handleDelete = (product, e) => {
    e.stopPropagation();
    setOpenConfirmation(true);
    setInfoForDelete(product);
  };

  const sortName = () => {
    setIsActiveName(true);
    setIsAscN(!isAscN);
    products.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (isAscN) {
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      } else {
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }
      }
      return 0;
    });
  };

  const deleteProduct = (substanceId) => {
    const finalProducts = products.filter(
      (item) => item.id !== infoForDelete.id
    );

    cultivations.activeSubstances
      .one(substanceId)
      .then((res) => {
        const dataToSend = {
          activeSubstance: {
            name: res.data.activeSubstance.name,
            cultivationProducts: finalProducts,
          },
        };

        cultivations.activeSubstances
          .edit(substanceId, dataToSend)
          .then((res) => {
            setPlantsProducts(finalProducts);
          })
          .catch((err) => {
            handleError(SERVER_ERROR_MESSAGES.COULD_NOT_EDIT_SUBSTANCE);
          });
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_SUBSTANCE);
      });
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            {matches ? (
              <TableRow>
                <TableCell
                  className={classes.nameRes}
                  align="right"
                ></TableCell>
                <TableCell className={classes.descRes}></TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell align="left" className={classes.name}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={12}>
                      <div className={classes.headerCell}>
                        <Typography className={classes.cell}>
                          NAZWA PRODUKTU
                        </Typography>
                        <div
                          className={classes.headerCellRight}
                          onClick={sortName}
                        >
                          <SortIcon isactive={isActiveName} asacdesc={isAscN} />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" className={classes.description}>
                  OPIS
                </TableCell>
                <TableCell className={classes.buttons}></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {products
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((product) => (
                <TableRow
                  key={product.id}
                  className={classes.row}
                  onClick={() =>
                    history.push(
                      `/dashboard/plants-protection-product/${product.id}`
                    )
                  }
                >
                  <TableCell align="left">
                    <Grid
                      container
                      justifyContent={matches && "center"}
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={matches ? 12 : 8}
                        align={matches ? "center" : "left"}
                        className={matches && classes.breakline}
                      >
                        <Typography className={classes.cell}>
                          {product.name}
                        </Typography>
                      </Grid>
                      {matches ? (
                        <Grid item xs={12}>
                          <Grid
                            container
                            spacing={2}
                            direction="row"
                            alignContent="center"
                            justifyContent="center"
                          >
                            <Grid item xs={12} align="center">
                              <Button
                                onClick={() => handleDelete(product)}
                                className={classes.delete}
                                startIcon={<DeleteIcon />}
                              >
                                <Typography>USUŃ</Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null}
                    </Grid>
                  </TableCell>

                  <TableCell>
                    <Typography className={classes.cell}>
                      {product.description}
                    </Typography>
                  </TableCell>
                  {matches ? null : (
                    <TableCell className={classes.cell} align="left">
                      {
                        <Grid container spacing={2} alignItems="center">
                          <Grid item md={12}>
                            <div
                              className={classes.headerCell}
                              onClick={(e) => {
                                handleDelete(product, e);
                              }}
                            >
                              <DeleteIcon className={classes.delete} />
                              <Typography className={classes.iconLabelRed}>
                                USUŃ
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                      }
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() => deleteProduct(id)}
        title={COMMON_MODAL_TEXTS.RESOURCE_TITLE}
        content={COMMON_MODAL_TEXTS.RESOURCE_CONTENT}
      />
    </>
  );
};

export default ActiveSubstanceProductsTable;
