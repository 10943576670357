import colors from "../../constants/colors";

const styles = (theme) => {
  return {
    root: {
      backgroundColor: colors.white,
      borderRadius: "30px",
      [theme.breakpoints.down("sm")]: {
        height: "80vh",
      },
      [theme.breakpoints.up("sm")]: {
        height: "80vh",
      },
      padding: "5%",
    },
    cursors: {
      cursor: "pointer",
    },
    header: {
      [theme.breakpoints.down("sm")]: {
        "font-size": "20px",
      },
      [theme.breakpoints.up("sm")]: {
        "font-size": "25px",
      },
      "text-align": "left",
      "font-weight": "bold",
      "font-family": "Montserrat",
      wordWrap: "break-word",
      color: colors.oxfordBlue,
    },
    rootList: {
      marginTop: "3%",
      maxWidth: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: "inline",
      fontSize: "16px",
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    headerCell: {
      display: "flex",
    },
    headerCellRight: {
      marginLeft: "2px",
      "align-self": "center",
    },
    box: {
      marginLeft: "20%",
      marginRight: "20%",
    },
    avatar: {
      [theme.breakpoints.down("sm")]: {
        width: "75px",
        height: "75px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "100px",
        height: "100px",
      },
      backgroundColor: colors.white,
      color: colors.oxfordBlue,
    },
    icon: {
      [theme.breakpoints.down("sm")]: {
        width: "65px",
        height: "65px",
      },
      [theme.breakpoints.up("sm")]: {
        width: "90px",
        height: "90px",
      },
      fill: colors.oxfordBlue,
    },
    text: {
      "font-weight": "bold",
      "font-family": "Montserrat",
      wordWrap: "break-word",
    },
    desBox: {
      margin: "3%",
      maxWidth: "75vw",
    },
    greyBox: {
      backgroundColor: colors.platinum,
      borderRadius: 20,
    },
    RightBox: {
      marginTop: "30%",
      marginBottom: "20%",
    },
    margin: {
      marginBottom: "10%",
    },
    buttonBox: {
      paddingLeft: "10%",
      paddingRight: "10%",
      marginBottom: "5%",
      marginTop: "10%",
    },
    texts: {
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    rightUptext: {
      "font-weight": "bold",
      "font-family": "Montserrat",
      "font-size": "20px",
    },
    avatarT: {
      width: "10%",
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      "border-bottom": "2px solid",
    },
    name: {
      width: "30%",
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      "border-bottom": "2px solid",
    },
    price: {
      width: "20%",
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      "border-bottom": "2px solid",
    },
    amount: {
      width: "20%",
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      "border-bottom": "2px solid",
    },
    emptyAuction: {
      "font-weight": "bold",
      "font-family": "Montserrat",
      "font-size": "25px",
      color: colors.carmine,
      "text-align": "center",
    },
    bottomLine: {
      "border-bottom": "2px solid",
    },
    BottomBox: {
      marginBottom: "10%",
    },
  };
};

export default styles;
