import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core";

import colors from "../../../constants/colors";

const StyledDescriptionTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 25,
        "box-shadow": "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        bordrer: 0,
      },
      "&:hover fieldset": {
        borderColor: colors.green,
      },
      "&.Mui-focused fieldset": {
        borderColor: colors.green,
      },
    },
    "& label.Mui-focused": {
      color: colors.green,
    },
  },
})(TextField);

export default StyledDescriptionTextField;
