import React from "react";
import { Container, makeStyles, Grid, ThemeProvider } from "@material-ui/core";

import styles from "./PasswordResetScreen.styles.js";
import theme from "../Login/LoginSreen.breakpoints.js";
import { useState } from "react";
import { auth } from "../../utils/newApi.js";

import { useHistory } from "react-router";
import ResetFormAfter from "../../components/ResetForm/ResetFormAfter";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar.js";

const useStyles = makeStyles(styles);

const PasswordResetScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const handleReset = (password, token) => {
    auth
      .changePassword(password, token)
      .then(() => {
        setPasswordChanged(true);
      })
      .catch(() => setError(true));
  };

  return (
    <ThemeProvider theme={theme}>
      <ErrorSnackBar
        open={error}
        text="Coś poszło nie tak"
        close={() => setError(false)}
      />
      <Grid container>
        <Grid item xs={12}>
          <Container align="center" fixed className={classes.container}>
            <ResetFormAfter
              handleReset={handleReset}
              passwordChanged={passwordChanged}
            />
          </Container>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default PasswordResetScreen;
