import colors from "../../constants/colors";

const styles = (theme) => {
  return {
    row: {
      transform: "all 0.25s ease-in-out",
      "&:hover": {
        transform: "scale(1.01)",
        cursor: "pointer",
      },
    },
    image: {
      width: "10%",
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      "border-bottom": "4px solid",
    },
    name: {
      width: "25%",
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      "border-bottom": "4px solid",
    },
    views: {
      width: "20%",
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      "border-bottom": "4px solid",
    },
    value: {
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      "border-bottom": "4px solid",
      width: "15%",
    },
    active: {
      width: "10%",
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      "border-bottom": "4px solid",
    },
    buttons: {
      paddingRight: "13%",
      paddingBottom: "3%",
      paddingTop: "3%",
      "border-bottom": "4px solid",
    },
    buttons2: {
      paddingRight: "13%",
      paddingBottom: "3%",
      paddingTop: "3%",
    },
    cell: {
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
    },
    root2: {
      float: "center",
    },
    avatar: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      border: "3px solid white",
      "box-sizing": "border-box",
      "box-shadow":
        "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset",
    },
    box: {
      marginLeft: "5%",
      marginRight: "5%",
    },
    avatarRes: {
      "border-bottom": "3px solid",
    },
    nameRes: {
      "border-bottom": "3px solid",
      minWidth: "200px",
    },
    searchRes: {
      "border-bottom": "3px solid",
      minWidth: "200px",
    },
    viewRes: {
      "border-bottom": "3px solid",
      minWidth: "200px",
    },
    dateRes: {
      "border-bottom": "3px solid",
      minWidth: "150px",
    },
    headerRes: {
      padding: "10%",
    },
    headerCell: {
      display: "flex",
    },
    headerCellRight: {
      marginLeft: "2px",
    },
    breakline: {
      "border-bottom": "1px solid grey",
    },
    label: {
      "word-wrap": "break-word",
      fontSize: 13,
    },
    disableCell: {
      color: colors.quickSilver,
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
    },

    caption: {
      fontSize: "15px",
      "font-family": "Montserrat",
    },
    toolbar: {
      "& > p:nth-of-type(2)": {
        fontSize: "15px",
        color: colors.black,
        "font-family": "Montserrat",
        fontWeight: 600,
      },
    },
  };
};

export default styles;
