import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Grid, makeStyles, Box } from "@material-ui/core";

import CommonModal from "../common/modals/CommonModal";
import StyledFormTextField from "../common/textFields/StyledFormTextField";

import styles from "./EditQRcodeWindow.styles";
import fields from "../../constants/QRcodesConstants";
import { users, qrCodes } from "../../utils/newApi";
import {
  SERVER_ERROR_MESSAGES,
  COMMON_MODAL_TEXTS,
  MODAL_TYPE,
} from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const EditQRcodeWindow = ({
  QRcodes,
  setQRs,
  isOpen,
  close,
  data,
  handleError,
}) => {
  const classes = useStyles();
  const [connectedTo, setConnectedTo] = useState(fields);
  const [selectedConnectedTo, setSelectedConnectedTo] = useState(null);
  const [usersInfo, setUsersInfo] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    users.usersInfo
      .all()
      .then((res) => {
        setUsersInfo(res.data);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_USERS);
      });
  }, []);

  useEffect(() => {
    setSelectedConnectedTo(
      data.isHive ? fields[0] : data.isThermometer ? fields[1] : ""
    );

    if (data.username) {
      setSelectedUser(data);
    }
  }, [data]);

  const handleChange = (e, n) => {
    setSelectedUser(n);
    setSelectedUserId(n?.id);
  };

  const handleChangeConnected = (e, n) => {
    setSelectedConnectedTo(n);
  };

  const submit = (e) => {
    e.preventDefault();

    let userId = selectedUserId;

    if (!userId && selectedUser) {
      const prevSelectedUser = usersInfo.find(
        (user) => user.username === selectedUser.username
      );

      userId = prevSelectedUser.id;
    }

    const payload = {
      isHive: selectedConnectedTo === fields[0],
      isThermometer: selectedConnectedTo === fields[1],
      userId: userId,
    };

    qrCodes
      .edit(data.id, payload)
      .then((res) => {
        if (QRcodes) {
          let qrCodesCopy = [...QRcodes];
          qrCodesCopy = qrCodesCopy.map((item) => {
            if (item.id === data.id) {
              return {
                id: item.id,
                uuid: item.uuid,
                createdAt: item.createdAt,
                ...payload,
              };
            } else {
              return item;
            }
          });

          setQRs(qrCodesCopy);
          close();
        } else {
          window.location.reload();
        }
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_EDIT_QR_CODE);
      });
  };

  return (
    <CommonModal
      open={isOpen}
      toggleOpen={close}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.EDIT_QR_CODE_TITLE}
      type={MODAL_TYPE.EDIT}
    >
      <Grid item xs={12}>
        <Box className={classes.box}>
          <Grid container spacing={4} direction="row" justify="center">
            <Grid item xs={12}>
              <Autocomplete
                value={selectedConnectedTo}
                options={connectedTo}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <StyledFormTextField
                    {...params}
                    InputLabelProps={{ classes: { root: classes.label } }}
                    fullWidth={true}
                    label="Przypisany do"
                    variant="outlined"
                    required
                    shrink="true"
                  />
                )}
                onChange={(e, n) => handleChangeConnected(e, n)}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.box}>
          <Autocomplete
            value={selectedUser}
            options={usersInfo}
            getOptionLabel={(option) => option.username}
            renderInput={(params) => (
              <StyledFormTextField
                {...params}
                InputLabelProps={{ classes: { root: classes.label } }}
                fullWidth={true}
                label="Wybierz użytkownika"
                variant="outlined"
                required
                shrink="true"
              />
            )}
            onChange={(e, n) => handleChange(e, n)}
          />
        </Box>
      </Grid>
    </CommonModal>
  );
};

export default EditQRcodeWindow;
