import React, { useEffect, useState } from "react";
import { Typography, Grid, makeStyles, Box } from "@material-ui/core";

import CommonModal from "../../common/modals/CommonModal";
import StyledFormTextField from "../../common/textFields/StyledFormTextField";

import styles from "./SubstanceModal.styles";
import { cultivations } from "../../../utils/newApi";
import {
  SERVER_ERROR_MESSAGES,
  MODAL_TYPE,
  COMMON_MODAL_TEXTS,
} from "../../../constants/dictionary";

const useStyles = makeStyles(styles);

const EditSubstanceWindow = ({
  listOfSubstances,
  setSubstances,
  isOpen,
  close,
  handleError,
  data,
}) => {
  const classes = useStyles();
  const [substanceName, setSubstanceName] = useState("");
  const [emptyField, setEmptyField] = React.useState(false);

  const submit = (e) => {
    e.preventDefault();

    if (substanceName === "") {
      setEmptyField(true);
      return;
    }

    const payload = {
      name: substanceName,
    };

    cultivations.activeSubstances
      .edit(data.id, payload)
      .then((res) => {
        let substancesCopy = [...listOfSubstances];
        substancesCopy = substancesCopy.map((item) => {
          if (item.id === data.id) {
            return {
              id: item.id,
              name: substanceName,
            };
          } else {
            return item;
          }
        });
        setSubstances(substancesCopy);
        close();
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_EDIT_SUBSTANCE);
      });
  };

  useEffect(() => {
    setEmptyField(false);
    setSubstanceName(data.name);
  }, [data, isOpen]);

  return (
    <CommonModal
      open={isOpen}
      toggleOpen={close}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.EDIT_SUBSTANCE_TITLE}
      type={MODAL_TYPE.EDIT}
    >
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledFormTextField
            InputLabelProps={{ classes: { root: classes.label } }}
            fullWidth={true}
            value={substanceName}
            label="Nazwa Substancji"
            variant="outlined"
            required
            shrink="true"
            onChange={(e) => setSubstanceName(e.target.value)}
          />
        </Box>
      </Grid>
      {emptyField && (
        <Grid item xs={12} align="center">
          <Typography className={classes.error}>
            Nie podano nazwy substancji
          </Typography>
        </Grid>
      )}
    </CommonModal>
  );
};

export default EditSubstanceWindow;
