import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { Grid, Typography, Button } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styles from "./QRTable.styles";
import Avatar from "@material-ui/core/Avatar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import EditQRcodeWindow from "../QR/EditQRcodeWindow";
import { SortIcon } from "../common/buttons/SortIcon";
import image from "../../assets/images/qrcode.png";
import { format } from "date-fns";
import CommonModal from "../common/modals/CommonModal";
import { qrCodes } from "../../utils/newApi";
import DeleteEditButton from "../common/buttons/DeleteEditButton";
import fields from "../../constants/QRcodesConstants";

import {
  SERVER_ERROR_MESSAGES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";
import { sortConstants, qrsSort } from "../../constants/localStorage";

const useStyles = makeStyles(styles);

const QRTable = ({
  QRcodes,
  setQRs,
  QRcodesCopy,
  handleError,
  page,
  rowsPerPage,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [id, setId] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [infoForEdit, setInfoForEdit] = useState("");
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const [isActiveName, setIsActiveName] = useState(false);
  const [isAscN, setIsAscN] = useState(null);
  const [isAscDate, setIsAscDate] = useState(null);
  const [isActiveDate, setIsActiveDate] = useState(false);

  useEffect(() => {
    if (QRcodesCopy.length !== 0) {
      const sortToApply = localStorage.getItem(sortConstants.qrs);

      if (sortToApply) {
        switch (sortToApply) {
          case qrsSort.nameA:
            sortName(true);
            break;
          case qrsSort.nameD:
            sortName(false);
            break;
          case qrsSort.dateA:
            sortDate(true);
            break;
          case qrsSort.dateD:
            sortDate(false);
            break;
          default:
            break;
        }
      }
    }
  }, [QRcodesCopy]);

  const handleDelete = (id, e) => {
    e.stopPropagation();
    setOpenConfirmation(true);
    setId(id);
  };

  const handleEdit = (code, e) => {
    e.stopPropagation();
    setInfoForEdit(code);
    setOpenEdit(true);
  };

  const sortDate = (isAsc = null) => {
    setIsAscDate((prev) => isAsc ?? !prev);
    setIsActiveName(false);
    setIsActiveDate(true);
  };

  useEffect(() => {
    if (isAscDate !== null) {
      const sorted = [...QRcodes].sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return isAscDate ? dateA - dateB : dateB - dateA;
      });

      setQRs(sorted);

      if (isAscDate) {
        localStorage.setItem(sortConstants.qrs, qrsSort.dateA);
      } else {
        localStorage.setItem(sortConstants.qrs, qrsSort.dateD);
      }
    }
  }, [isAscDate]);

  const sortName = (isAsc = null) => {
    setIsActiveDate(false);
    setIsActiveName(true);
    setIsAscN((prev) => isAsc ?? !prev);
  };

  useEffect(() => {
    if (isAscN !== null) {
      const sorted = [...QRcodes].sort((a, b) => {
        const nameA = a.isHive
          ? fields[0].toLowerCase()
          : a.isThermometer
          ? fields[1].toLowerCase()
          : "Z";
        const nameB = b.isHive
          ? fields[0].toLowerCase()
          : b.isThermometer
          ? fields[1].toLowerCase()
          : "Z";
        if (isAscN) {
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        } else {
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
        }
        return 0;
      });

      setQRs(sorted);

      if (isAscN) {
        localStorage.setItem(sortConstants.qrs, qrsSort.nameA);
      } else {
        localStorage.setItem(sortConstants.qrs, qrsSort.nameD);
      }
    }
  }, [isAscN]);

  const deleteCode = (qrId) => {
    qrCodes
      .delete(qrId)
      .then((res) => {
        setQRs(QRcodes.filter((item) => item.id !== qrId));
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_QR_CODE);
      });
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            {matches ? (
              <TableRow>
                <TableCell className={classes.picRes}></TableCell>
                <TableCell
                  className={classes.codeRes}
                  align="right"
                ></TableCell>
                <TableCell className={classes.dateRes} align="center">
                  <Button
                    onClick={() => sortDate()}
                    className={classes.button}
                    endIcon={
                      <SortIcon isactive={isActiveDate} asacdesc={isAscDate} />
                    }
                  >
                    <Typography className={classes.cell}>
                      DATA UTWORZENIA
                    </Typography>
                  </Button>
                </TableCell>
                <TableCell className={classes.connectedToRes} align="center">
                  <Button
                    onClick={() => sortName()}
                    className={classes.button}
                    endIcon={
                      <SortIcon isactive={isActiveName} asacdesc={isAscN} />
                    }
                  >
                    PRZYPISANY
                  </Button>
                </TableCell>
                <TableCell className={classes.usersRes}>UŻYTKOWNIK</TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell align="center" className={classes.picture} />
                <TableCell align="left" className={classes.code}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={12}>
                      <Typography className={classes.cell}>KOD</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" className={classes.date}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={12}>
                      <Button
                        onClick={() => sortDate()}
                        className={classes.button}
                        endIcon={
                          <SortIcon
                            isactive={isActiveDate}
                            asacdesc={isAscDate}
                          />
                        }
                      >
                        <Typography className={classes.cell}>
                          DATA UTWORZENIA
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" className={classes.connectedTo}>
                  <Grid container direction="row">
                    <Grid item xs={12} md={6} align="left">
                      <Button
                        onClick={() => sortName()}
                        className={classes.button}
                        endIcon={
                          <SortIcon isactive={isActiveName} asacdesc={isAscN} />
                        }
                      >
                        PRZYPISANY
                      </Button>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell className={classes.users}>UŻYTKOWNIK</TableCell>
                <TableCell className={classes.buttons}></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {QRcodes &&
              QRcodes.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              ).map((code) => (
                <TableRow
                  key={code && code.uuid}
                  className={classes.row}
                  onClick={() => {
                    localStorage.setItem("currentQRTablePage", page);
                    localStorage.setItem(
                      "currentQRTableRowsPerPage",
                      rowsPerPage
                    );
                    history.push(`/dashboard/QR/${code.id}`);
                  }}
                >
                  <TableCell align="center" component="th" scope="row">
                    <Avatar
                      alt=""
                      src={image}
                      className={classes.avatar}
                      variant="square"
                    />
                  </TableCell>
                  <TableCell align="left">
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={matches ? 12 : 8}
                        align={matches ? "center" : "left"}
                        className={matches && classes.breakline}
                      >
                        <Typography className={classes.cell}>
                          {code && code.uuid}
                        </Typography>
                      </Grid>
                      {matches && (
                        <DeleteEditButton
                          mobile={true}
                          editClickHandler={(e) => {
                            handleEdit(code, e);
                          }}
                          deleteClickHandler={(e) => {
                            handleDelete(code.id, e);
                          }}
                        />
                      )}
                    </Grid>
                  </TableCell>

                  <TableCell className={classes.cell} align="left">
                    {code.createdAt
                      ? format(new Date(code.createdAt), "dd/MM/yyyy HH:mm")
                      : "Brak"}
                  </TableCell>

                  {!matches && (
                    <TableCell
                      className={
                        code.isHive || code.isThermometer
                          ? classes.cell
                          : classes.disable
                      }
                      align="left"
                    >
                      {code.isHive
                        ? "UL"
                        : code.isThermometer
                        ? "TERMOMETR"
                        : "Brak"}
                    </TableCell>
                  )}
                  {matches ? (
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item xs={12} align="center">
                          <Typography
                            className={
                              code.isHive || code.isThermometer
                                ? classes.type
                                : classes.disable
                            }
                          >
                            {code.isHive
                              ? "UL"
                              : code.isThermometer
                              ? "TERMOMETR"
                              : "Brak"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  ) : (
                    <TableCell>
                      <Typography
                        className={
                          code && code.username ? classes.type : classes.disable
                        }
                      >
                        {code && code.username ? code.username : "Brak"}
                      </Typography>
                    </TableCell>
                  )}
                  {matches ? (
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item xs={12} align="center">
                          <Typography
                            className={
                              code && code.username
                                ? classes.type
                                : classes.disable
                            }
                          >
                            {code && code.username ? code.username : "Brak"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  ) : (
                    <DeleteEditButton
                      mobile={false}
                      editClickHandler={(e) => {
                        handleEdit(code, e);
                      }}
                      deleteClickHandler={(e) => {
                        handleDelete(code.id, e);
                      }}
                    />
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() => deleteCode(id)}
        title={COMMON_MODAL_TEXTS.QR_TITLE}
        content={COMMON_MODAL_TEXTS.QR_CONTENT}
      />
      <EditQRcodeWindow
        QRcodes={QRcodes}
        setQRs={setQRs}
        isOpen={openEdit}
        close={() => setOpenEdit(false)}
        data={infoForEdit}
        handleError={handleError}
      />
    </>
  );
};

export default QRTable;
