import React, { useState, useEffect } from "react";
import { Grid, makeStyles, ThemeProvider } from "@material-ui/core";
import theme from "../Login/LoginSreen.breakpoints";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styles from "./CocconAuctionsScreen.styles";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { auctions, users } from "../../utils/api";
import CocoonAuctionTable from "../../components/CocoonAuctions/CocoonAuctionsTable";
import StyledFormTextField from "../../components/common/textFields/StyledFormTextField";
import TablePaginationActions from "../../components/common/pagination/TablePaginationActions";
import TablePagination from "@material-ui/core/TablePagination";
import ListHeader from "../../components/common/ListHeader";

import { SERVER_ERROR_MESSAGES } from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const CocoonAuctionsScreen = () => {
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [auctionsList, setAuctionsList] = useState([]);
  const [auctionsListCopy, setAuctionsListCopy] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  useEffect(() => {
    auctions
      .all()
      .then((res) => {
        if (res.data.cocoonAuctions.length === 0) {
          setIsLoaded(true);
        }
        res.data.cocoonAuctions.forEach((auction, idx, array) => {
          let obj = {
            sold: auction.sold,
            user: "",
            userPrice: auction.userPrice,
            returnDate: auction.returnDate,
            boxesCount: auction.cocoonBoxes ? auction.cocoonBoxes.length : 0,
            startDate: auction.startDate,
            endDate: auction.returnDate,
          };
          users.usersInfo.one(auction.userId).then((res) => {
            obj.user = res.data.user.email;
            setAuctionsList((oldArray) => [...oldArray, obj]);
            setAuctionsListCopy((oldArray) => [...oldArray, obj]);
          });
          if (idx === array.length - 1) {
            setIsLoaded(true);
          }
        });
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_AUCTIONS);
      });
  }, []);

  const handleChange = (e) => {
    const res = auctionsListCopy.filter((auction) => {
      return (
        auction.user &&
        auction.user.toLowerCase().startsWith(e.target.value.toLowerCase())
      );
    });
    setAuctionsList(res);
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <Grid container spacing={matches ? 3 : 0}>
          <ListHeader
            variant="title-search"
            title="Lista aukcji"
            searchComponent={
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                name="name"
                fullWidth={true}
                label="Znajdź użytkownika"
                variant="outlined"
                shrink="true"
                onChange={(e) => handleChange(e)}
              />
            }
          />
          <Grid container>
            <Grid item xs={12} align="center">
              <CocoonAuctionTable
                auctionList={auctionsList}
                page={page}
                rowsPerPage={rowsPerPage}
              />
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 50]}
                component="div"
                count={auctionsList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                classes={{
                  toolbar: classes.toolbar,
                  caption: classes.caption,
                }}
                labelRowsPerPage={"Ilość wierszy na stronę"}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}–${to} z ${
                    count !== -1 ? count : `więcej niż ${to}`
                  }`;
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </ThemeProvider>
  );
};

export default CocoonAuctionsScreen;
