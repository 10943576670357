import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { Grid, Typography } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styles from "./PathoActiveSubTable.styles";
import DeleteIcon from "@material-ui/icons/Delete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import EditSubWindow from "../ModalWindows/PathActiveSubstances/EditSub";
import CommonModal from "../../components/common/modals/CommonModal";
import { cultivations } from "../../utils/api";

import {
  SERVER_ERROR_MESSAGES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const PathoActiveSubstancesTable = ({
  pathogen,
  handleError,
  substances,
  setSubstances,
  page,
  rowsPerPage,
}) => {
  const theme = useTheme();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState("");
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [infoForEdit, setInfoForEdit] = useState("");
  const classes = useStyles(matches);
  const [pathogenC, setPathogenC] = useState({});

  const handleDelete = (substance, e) => {
    e.stopPropagation();
    setOpenConfirmation(true);
    setDeleteInfo(substance);
    setPathogenC(pathogen);
  };

  const handleEdit = (substance, e) => {
    e.stopPropagation();
    setInfoForEdit(substance);
    setPathogenC(pathogen);
    setOpenEdit(true);
  };

  const deleteSubstance = (substanceId, pathogenName) => {
    cultivations.activeSubstances
      .deleteFromPathogen(
        substanceId,
        localStorage.getItem("CultName"),
        localStorage.getItem("DevName"),
        pathogenName
      )
      .then((res) => {
        setSubstances(substances.filter((item) => item.id !== substanceId));
      })
      .catch(() =>
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_SUBSTANCE)
      );
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            {matches ? (
              <TableRow>
                <TableCell className={classes.productsRes}>
                  NAZWA SUBSTACNCJI
                </TableCell>
                <TableCell className={classes.buttonsRes}></TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell className={classes.products}>
                  NAZWA SUBSTANCJI
                </TableCell>
                <TableCell className={classes.buttons}></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {substances &&
              substances
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((substance) => (
                  <TableRow key={substance.id} className={classes.row}>
                    <TableCell>
                      <Typography className={classes.text}>
                        {substance && substance.name}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cell} align="left">
                      {
                        <Grid container spacing={2} alignItems="center">
                          <Grid item md={12}>
                            <div
                              className={classes.headerCell}
                              onClick={(e) => {
                                handleDelete(substance, e);
                              }}
                            >
                              <DeleteIcon className={classes.delete} />
                              <Typography className={classes.iconLabelRed}>
                                USUŃ
                              </Typography>
                            </div>
                          </Grid>
                        </Grid>
                      }
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() =>
          deleteSubstance(deleteInfo.id, pathogenC.name)
        }
        title={COMMON_MODAL_TEXTS.SUBSTANCE_TITLE}
        content={COMMON_MODAL_TEXTS.SUBSTANCE_CONTENT}
      />
      <EditSubWindow
        isOpen={openEdit}
        close={() => setOpenEdit(false)}
        data={infoForEdit}
        handleError={handleError}
        pathogen={pathogenC}
      />
    </>
  );
};

export default PathoActiveSubstancesTable;
