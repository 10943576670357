import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Typography,
} from "@material-ui/core";

import { styles } from "./AvailabilityTable.styles";
import { AvailabilityModifier } from "./AvailabilityModifier";
import { SortIcon } from "../../components/common/buttons/SortIcon";
import {
  validatePrice,
  changePriceToInt,
  displayPrice,
} from "../../utils/price";

const useStyles = makeStyles(styles);

export const AvailabilityTable = ({ products, page, rowsPerPage }) => {
  const [sortReversed, setSortReversed] = useState(1);
  const [sortFunc, setSortFunc] = useState("");
  const [arrows, setArrows] = useState({
    byName: false,
    byPrice: false,
    byAvailability: false,
  });
  const classes = useStyles();

  const byName = (a, b) =>
    a.name.toUpperCase() >= b.name.toUpperCase()
      ? 1 * sortReversed
      : -1 * sortReversed;
  const byPrice = (a, b) => (a.price - b.price) * sortReversed;
  const byAvailability = (a, b) =>
    (a.availableAmount - b.availableAmount) * sortReversed;

  const handleClick = (sortfunc) => {
    setSortFunc(sortfunc);
    let tempArrows = arrows;

    let currentlyTrue = "byName";

    Object.keys(tempArrows).forEach((key) => {
      if (tempArrows[key] === true) {
        currentlyTrue = key;
      }
    });

    Object.keys(tempArrows).forEach((key) => (tempArrows[key] = false));
    arrows[sortfunc] = true;
    setArrows(tempArrows);

    if (currentlyTrue === sortfunc) {
      setSortReversed(sortReversed * -1);
    } else {
      setSortReversed(1);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <TableContainer className={classes.tableContainer}>
          <Table
            className={classes.table}
            aria-label="simple table"
            stickyHeader="false"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.initHead} />
                <TableCell align="left" className={classes.value}>
                  <Button
                    endIcon={
                      <SortIcon
                        isactive={arrows["byName"]}
                        asacdesc={arrows["byName"] && sortReversed === 1}
                      />
                    }
                    onClick={() => handleClick("byName")}
                    className={classes.StyledButton}
                  >
                    <Typography>NAZWA PRODUKTU</Typography>
                  </Button>
                </TableCell>
                <TableCell align="left" className={classes.value}>
                  <Button
                    endIcon={
                      <SortIcon
                        isactive={arrows["byPrice"]}
                        asacdesc={arrows["byPrice"] && sortReversed === 1}
                      />
                    }
                    onClick={() => handleClick("byPrice")}
                    className={classes.StyledButton}
                  >
                    <Typography>CENA</Typography>
                  </Button>
                </TableCell>

                <TableCell align="center" className={classes.value}>
                  <Button
                    endIcon={
                      <SortIcon
                        isactive={arrows["byAvailability"]}
                        asacdesc={
                          arrows["byAvailability"] && sortReversed === 1
                        }
                      />
                    }
                    onClick={() => handleClick("byAvailability")}
                    className={classes.StyledButton}
                  >
                    <Typography>STAN MAGAZYNOWY</Typography>
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products
                .sort(
                  {
                    byName: byName,
                    byPrice: byPrice,
                    byAvailability: byAvailability,
                  }[sortFunc]
                )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((product) => (
                  <TableRow key={product.id} className={classes.row}>
                    <TableCell align="left">
                      <Avatar
                        alt=""
                        src={product.images ? product.images[0] : ""}
                        className={classes.avatar}
                      />
                    </TableCell>

                    <TableCell align="left">
                      <Typography className={classes.cell}>
                        {product.name}
                      </Typography>
                    </TableCell>

                    <TableCell className={classes.cell} align="left">
                      {`${displayPrice(product.price)} zł`}
                    </TableCell>

                    <TableCell className={classes.cell} align="center">
                      <AvailabilityModifier item={product} />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
