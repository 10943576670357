import colors from "../../constants/colors";

const styles = (theme, matches) => {
  return {
    products: {
      width: "80%",
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      "border-bottom": "4px solid",
    },
    buttons: {
      width: "20%",
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      "border-bottom": "4px solid",
    },
    productsRes: {
      "border-bottom": "1px solid grey",
    },
    buttonsRes: {
      "border-bottom": "1px solid grey",
    },
    row: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    cell: {
      fontFamily: "Montserrat",
      fontWeight: "bold",
      width: "42.5%",
    },
    delete: {
      color: colors.carmine,
    },
    headerCell: {
      display: "flex",
    },
    headerCellRight: {
      marginLeft: "2px",
    },
    iconLabel: {
      "font-weight": "bold",
      "font-size": "12px",
      "font-family": "Montserrat",
      marginLeft: "4%",
      marginTop: "2%",
    },
    iconLabelRed: {
      "font-weight": "bold",
      "font-size": "12px",
      "font-family": "Montserrat",
      marginLeft: "4%",
      marginTop: "2%",
      color: colors.carmine,
    },
    listFont: {
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    rootChips: {
      display: "flex",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
      justifyContent: "center",
    },
    chip: {
      height: "50px",
      "font-weight": "bold",
      "font-size": "12px",
      "font-family": "Montserrat",
      color: colors.black,
    },
    box: {
      display: "flex",
      justifyContent: "center",
      "flex-direction": "row",
    },
    iconInBox: {
      "align-self": "center",
      marginLeft: "4px",
    },
    rootChipsRes: {
      display: "flex",
      justifyContent: "center",
      "flex-direction": "column",
    },
    boxRes: {
      display: "flex",
      justifyContent: "center",
      "flex-direction": "column",
    },
    chipRes: {
      height: "50px",
      "font-weight": "bold",
      "font-size": "12px",
      "font-family": "Montserrat",
      color: colors.black,
    },
    iconInBoxRes: {
      margin: "auto",
      marginTop: "5%",
      marginBottom: "5%",
    },
    text: {
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    caption: {
      fontSize: "15px",
      "font-family": "Montserrat",
    },
    toolbar: {
      "& > p:nth-of-type(2)": {
        fontSize: "15px",
        color: colors.black,
        "font-family": "Montserrat",
        fontWeight: 600,
      },
    },
    breakline: {
      "border-bottom": "1px solid grey",
    },
  };
};

export default styles;
