import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import image from "../../../assets/images/appB.png";

const IosButton = withStyles({
  root: {
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    borderRadius: 5,
    border: 0,
    height: "50px",
    width: "100%",
    maxWidth: "200px",
    minWidth: "145px",
    
  },
})(Button);

export default IosButton;
