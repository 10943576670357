import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Grid,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import theme from "../../components/theme/theme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styles from "./PathoActiveSubstances.styles";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import StyledFormTextField from "../../components/common/textFields/StyledFormTextField";
import AddSubWindow from "../../components/ModalWindows/PathActiveSubstances/AddNewSub";
import PathoActiveSubstancesTable from "../../components/PathoActiveSubstance/PathoActiveSubTable";
import { cultivations } from "../../utils/api";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../components/common/pagination/TablePaginationActions";
import ListHeader from "../../components/common/ListHeader";

import { SERVER_ERROR_MESSAGES } from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const PathoActiveSubstancesScreen = () => {
  const [pathogen, setPathogen] = useState([]);
  const [pathogenCopy, setPathogenCopy] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [substances, setSubstances] = useState([]);
  const [substancesCopy, setSubstancesCopy] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  useEffect(() => {
    let array = window.location.pathname.split("/");

    cultivations.pathogens
      .one(
        localStorage.getItem("CultName"),
        localStorage.getItem("DevName"),
        decodeURIComponent(array[array.length - 1])
      )
      .then((res) => {
        setPathogen(res.data.pathogens[0]);
        setPathogenCopy(res.data.pathogens[0]);
        setSubstances(res.data.pathogens[0].activeSubstances);
        setSubstancesCopy(res.data.pathogens[0].activeSubstances);
        setIsLoaded(true);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_PATHOGEN);
      });
  }, []);

  const handleChange = (e) => {
    if (e.target.value !== "") {
      const res = substancesCopy.filter((sub) => {
        return (
          sub.name &&
          sub.name.toLowerCase().startsWith(e.target.value.toLowerCase())
        );
      });
      setSubstances(res);
      setPage(0);
    } else {
      setSubstances(substancesCopy);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <Grid container>
          <ListHeader
            variant="title-add-search"
            title={`Lista substancji czynnych patogenu ${
              pathogen && pathogen.name.toLowerCase()
            }`}
            handleAdd={() => setOpen(true)}
            searchComponent={
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                name="name"
                fullWidth={matches}
                label="Szukaj substancji"
                variant="outlined"
                shrink="true"
                onChange={(e) => handleChange(e)}
              />
            }
          />
          <Grid container>
            <Grid item xs={12} align="center">
              <PathoActiveSubstancesTable
                handleError={handleError}
                pathogen={pathogen}
                substances={substances}
                setSubstances={setSubstances}
                page={page}
                rowsPerPage={rowsPerPage}
              />
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 50]}
                component="div"
                count={substances ? substances.length : 1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                classes={{
                  toolbar: classes.toolbar,
                  caption: classes.caption,
                }}
                labelRowsPerPage={"Ilość wierszy na stronę"}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}–${to} z ${
                    count !== -1 ? count : `więcej niż ${to}`
                  }`;
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
      <AddSubWindow
        isOpen={open}
        close={() => setOpen(false)}
        handleError={handleError}
        pathogen={pathogen}
      />
    </ThemeProvider>
  );
};

export default PathoActiveSubstancesScreen;
