const fields = [
  {
    id: 1,
    name: "Id",
  },
  {
    id: 2,
    name: "Nazwa",
  },
  {
    id: 3,
    name: "Wartość",
  },
  {
    id: 4,
    name: "Promocja",
  },
];

export default fields;
