import {
  sortConstants,
  filterConstants,
  searchConstants,
} from "../constants/localStorage";

const manageLocalStorage = (history) => {
  if (!history.location.pathname.includes("plants")) {
    localStorage.removeItem("currentProductsToProtectPlantsPage");
    localStorage.removeItem("currentProductsToProtectPlantsRowsPerPage");
  }
  if (
    !history.location.pathname.includes("ordersList") &&
    !history.location.pathname.includes("orderInfo")
  ) {
    localStorage.removeItem("currentOrdersListPage");
    localStorage.removeItem("currentOrdersListRowsPerPage");
    localStorage.removeItem(sortConstants.orders);
    localStorage.removeItem(filterConstants.orders);
  }
  if (
    !history.location.pathname.includes("plants-protection-products") &&
    !history.location.pathname.includes("plants-protection-product")
  ) {
    localStorage.removeItem(sortConstants.plantsProtectionProducts);
  }
  if (
    !history.location.pathname.includes("users") &&
    !history.location.pathname.includes("user")
  ) {
    localStorage.removeItem("currentUserInfoListPage");
    localStorage.removeItem("currentUserInfoListRowsPerPage");
    localStorage.removeItem(filterConstants.users);
    localStorage.removeItem(searchConstants.users);
  }
  if (
    !history.location.pathname.includes("events") &&
    !history.location.pathname.includes("event")
  ) {
    localStorage.removeItem("currentEventsTablePage");
    localStorage.removeItem("currentEventsTableRowsPerPage");
    localStorage.removeItem(sortConstants.events);
  }
  if (
    !history.location.pathname.includes("ads") &&
    !history.location.pathname.includes("ad")
  ) {
    localStorage.removeItem("currentAdsTablePage");
    localStorage.removeItem("currentAdsTableRowsPerPage");
    localStorage.removeItem(sortConstants.ads);
  }
  if (!history.location.pathname.includes("QR")) {
    localStorage.removeItem("currentQRTablePage");
    localStorage.removeItem("currentQRTableRowsPerPage");
    localStorage.removeItem(sortConstants.qrs);
  }
  if (!history.location.pathname.includes("workField")) {
    localStorage.removeItem(sortConstants.fieldWorks);
  }
};

export default manageLocalStorage;
