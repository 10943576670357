import React, { useEffect, useState } from "react";
import Chip from "@material-ui/core/Chip";
import { Typography, Grid, makeStyles, Box, MenuItem } from "@material-ui/core";

import CommonModal from "../../common/modals/CommonModal";
import StyledFormTextField from "../../common/textFields/StyledFormTextField";
import StyledDescriptionTextField from "../../common/textFields/StyledDescriptionTextField";
import StyledCategorySelect from "../../common/Selects/StyledCategorySelect";

import { cultivations, plantProtectionProducts } from "../../../utils/newApi";
import styles from "./ProductsToProtectPlantsModal.styles";
import {
  SERVER_ERROR_MESSAGES,
  MODAL_TYPE,
  COMMON_MODAL_TEXTS,
} from "../../../constants/dictionary";

const useStyles = makeStyles(styles);

const EditProductToProtectPlantsWindow = ({
  products,
  setProducts,
  isOpen,
  close,
  data,
  handleError,
}) => {
  const classes = useStyles();
  const [substancesArray, setSubstancesArray] = useState([]);
  const [selectedSubstancesArray, setSelectedSubstancesArray] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [emptyField, setEmptyField] = React.useState(false);

  useEffect(() => {
    if (!data?.id) return;
    plantProtectionProducts
      .getProductById(data?.id)
      .then((res) => {
        const plantProduct = res.data;
        setName(plantProduct?.name);
        setDescription(plantProduct?.description);
        setSelectedSubstancesArray(plantProduct?.activeSubstanceDAOList);
        setEmptyField(false);
        cultivations.activeSubstances
          .all()
          .then((res) => {
            setSubstancesArray(res.data);
          })
          .catch(() => {
            handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_SUBSTANCE);
          });
      })
      .catch(() => {
        handleError(
          SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_PLANTS_PROTECTION_PRODUCTS
        );
      });
  }, [data]);

  // useEffect(() => {
  //   setName(data.name);
  //   setDescription(data.description);
  //   setSelectedSubstancesArray(data.activeSubstanceDAOList);
  //   setEmptyField(false);
  // }, [data, isOpen]);

  const selectChange = (e) => {
    const selected = e.target.value;
    const idToRemove = [];
    selected.forEach((ele1) => {
      let counter = 0;
      selected.forEach((ele2) => {
        if (ele1.id === ele2.id) {
          counter++;
          if (counter === 2) {
            idToRemove.push(ele1.id);
          }
        }
      });
    });
    const final = selected.filter((value) => {
      return value.id !== idToRemove[0];
    });

    setSelectedSubstancesArray(final);
  };

  const submit = (e) => {
    e.preventDefault();

    if (name === "") {
      setEmptyField(true);
      return;
    }

    const dataToEdit = {
      name: name,
      description: description,
      activeSubstanceIdList: selectedSubstancesArray.map((ss) => ss.id),
    };

    plantProtectionProducts
      .editProduct(data.id, dataToEdit)
      .then(() => {
        if (products) {
          let productsCopy = [...products];
          productsCopy = productsCopy.map((item) => {
            if (item.id === data.id) {
              return {
                id: data.id,
                name: name,
                description: description,
                activeSubstanceDAO: selectedSubstancesArray,
                dosageAdded: item.dosageAdded,
              };
            } else {
              return item;
            }
          });
          setProducts(productsCopy);
          close();
        } else {
          window.location.reload();
        }
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_EDIT_PRODUCT);
      });
  };

  return (
    <CommonModal
      open={isOpen}
      toggleOpen={close}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.EDIT_PRODUCT_TITLE}
      type={MODAL_TYPE.EDIT}
    >
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledFormTextField
            InputLabelProps={{ classes: { root: classes.label } }}
            fullWidth={true}
            name="name"
            label="Wpisz nazwę środka"
            variant="outlined"
            required
            value={name}
            shrink="true"
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledCategorySelect
            InputLabelProps={{ classes: { root: classes.label } }}
            name="category"
            fullWidth={true}
            label="Substancja czynna"
            variant="outlined"
            shrink="true"
            onChange={selectChange}
            value={selectedSubstancesArray}
            select={true}
            SelectProps={{
              multiple: true,
              MenuProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              },
              renderValue: (selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value.id}
                      label={value.name}
                      variant="outlined"
                      color="primary"
                      className={classes.chip}
                    />
                  ))}
                </div>
              ),
            }}
          >
            {substancesArray.map((substance) => {
              return (
                <MenuItem key={substance.id} value={substance}>
                  {substance.name}
                </MenuItem>
              );
            })}
          </StyledCategorySelect>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledDescriptionTextField
            InputLabelProps={{ classes: { root: classes.label } }}
            fullWidth={true}
            label="Opis środka"
            variant="outlined"
            rows={4}
            value={description}
            shrink="true"
            multiline={true}
            name="description"
            onChange={(e) => setDescription(e.target.value)}
          />
        </Box>
      </Grid>
      {emptyField && (
        <Grid item xs={12} align="center">
          <Typography className={classes.error}>
            Nie wypełniono obowiązkowych pól
          </Typography>
        </Grid>
      )}
    </CommonModal>
  );
};

export default EditProductToProtectPlantsWindow;
