import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { styles } from "./CultivationTable.styles";
import { useHistory } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { TableContainer } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { Eco } from "@material-ui/icons";
import { Box } from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../components/common/pagination/TablePaginationActions";
import ListHeader from "../../components/common/ListHeader";

const useStyles = makeStyles(styles);

export const CultivationTable = ({ items, handleError }) => {
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (id) => {
    history.push(`/dashboard/cultivation/${id}`);
  };

  return (
    <TableContainer component={Paper}>
      <ListHeader variant="title" title="Ochrona roślin" />
      <Table>
        <TableHead>
          <TableCell className={classes.value}>{"Nazwa typu uprawy"}</TableCell>
        </TableHead>

        <TableBody>
          {items
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item) => (
              <TableRow
                key={item}
                className={classes.click}
                onClick={() => handleClick(item.id)}
              >
                <TableCell className={classes.cell}>
                  <Box display="flex">
                    <Eco className={classes.icon} />
                    {item.name}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 15, 20, 50]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
        }}
        classes={{
          toolbar: classes.toolbar,
          caption: classes.caption,
        }}
        labelRowsPerPage={"Ilość wierszy na stronę"}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} z ${count !== -1 ? count : `więcej niż ${to}`}`;
        }}
      />
    </TableContainer>
  );
};
