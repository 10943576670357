import React, { useEffect, useState } from "react";
import { makeStyles, Grid, Box, Typography } from "@material-ui/core";
import styles from "./ProductInfoBox.styles";
import EditProductsWindow from "../ModalWindows/EditProductWindow";
import Picture from "../PictureFile.js/Picture";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Carousel from "react-material-ui-carousel";
import { displayPrice } from "../../utils/price";
import { shop } from "../../utils/api";
import { useHistory } from "react-router";
import CommonModal from "../common/modals/CommonModal";

import {
  SERVER_ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";
import CustomButton from "../common/buttons/CustomButton";

const useStyles = makeStyles(styles);

const ProductInfoBox = ({ info, handleError }) => {
  const [isDiscount, setIsDiscount] = useState(info.discountEndTime);
  const classes = useStyles(styles, isDiscount);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [customBreak, setCustomBreak] = useState([12, 4, 3, 3]);

  const history = useHistory();

  useEffect(() => {
    setCustomBreak([12, 4, 12, 6]);
  }, [matches]);

  const deleteProduct = (productId) => {
    shop.articles
      .delete(productId)
      .then((res) => {
        history.push("/dashboard/products");
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_PRODUCT);
      });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="rows">
        <Grid item xs={matches ? 12 : 7}>
          <Grid
            container
            spacing={2}
            justify="center"
            direction="rows"
            alignItems="center"
          >
            <Grid item xs={4} align="left">
              <Box className={classes.box2}>
                <Avatar src={info.images} className={classes.avatar} />
              </Box>
            </Grid>
            <Grid item xs={8} align="left">
              <Box className={classes.box2}>
                <Typography className={classes.header}>{info.name}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} align="left">
              <Box className={classes.box2}>
                <Typography className={classes.text}>
                  {info.description}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} align="center">
              <Box className={classes.marginBox}>
                <Carousel>
                  {info.images ? (
                    info.images.map((image) => (
                      <Picture pictureSrc={image} customBreak={customBreak} />
                    ))
                  ) : (
                    <Typography>Brak</Typography>
                  )}
                </Carousel>
              </Box>
            </Grid>

            <Grid item xs={12} align="center">
              <Grid container direction="row" alignItems="center">
                <Grid item xs={8} align="right">
                  <Typography className={classes.lightPrices}>
                    CENA PRODUKTU:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prices}>
                    {displayPrice(info.price) != null
                      ? `${displayPrice(info.price)} zł`
                      : "Brak"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} align="center">
              <Grid container direction="row" alignItems="center">
                <Grid item xs={8} align="right">
                  <Typography className={classes.lightPrices}>
                    CENA HURTOWA PRODUKTU:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.prices}>
                    {info.unmarginedPrice != null
                      ? `${displayPrice(info.unmarginedPrice)} zł`
                      : "Brak"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <CommonModal
              open={openConfirmation}
              toggleOpen={() => setOpenConfirmation(false)}
              handleConfirmation={() => deleteProduct(info.id)}
              title={COMMON_MODAL_TEXTS.PRODUCT_TITLE}
              content={COMMON_MODAL_TEXTS.PRODUCT_CONTENT}
            />
            <EditProductsWindow
              isOpen={openEdit}
              close={() => setOpenEdit(false)}
              data={info}
              handleError={handleError}
            />
          </Grid>
        </Grid>
        <Grid item xs={matches ? 12 : 5} className={classes.greyBox}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            direction="row"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Box className={classes.headerMargin}>
                <Typography className={classes.header}>
                  Informacje o produkcie:
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <List className={classes.rootList}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Kategorie"
                    secondary={
                      <Typography className={classes.listText}>{`${
                        info.categories && info.categories.length !== 0
                          ? info.categories.map((category) => category.name)
                          : "Brak"
                      }`}</Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Ilość"
                    secondary={
                      <Typography
                        className={classes.listText}
                      >{`${info.availableAmount}`}</Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Pojemność [kg lub l]"
                    secondary={
                      <Typography className={classes.listText}>
                        {info && info.capacity
                          ? `${info.capacity / 1000}`
                          : "Nie dotyczy"}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.headerMargin}>
                <Typography className={classes.header}>
                  Informacje o promocji
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} align="right">
              <Box className={classes.marginBox}>
                <Typography className={classes.text}>
                  Produkt w promocji
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} align="center">
              <Box className={classes.marginBox}>
                <CustomButton
                  variant={
                    info.discountEndTime
                      ? BUTTON_VARIANTS.GREEN
                      : BUTTON_VARIANTS.RED
                  }
                  size={BUTTON_SIZES.SMALL}
                  text={info.discountEndTime ? "Tak" : "Nie"}
                />
              </Box>
            </Grid>
            <Grid item xs={12} align="center">
              {info.discountEndTime && (
                <Box className={classes.marginBox}>
                  <List className={classes.rootList}>
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary="Cena po rabacie"
                        secondary={
                          <Typography
                            className={classes.listText}
                          >{`${displayPrice(info.discountPrice)}`}</Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </Box>
              )}
            </Grid>
            <Grid container direction="row">
              <Grid item xs={6}>
                <Box className={classes.buttonBox}>
                  <CustomButton
                    variant={BUTTON_VARIANTS.WHITE}
                    size={BUTTON_SIZES.LARGE}
                    clickHandler={() => setOpenEdit(true)}
                    text="Edytuj produkt"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className={classes.buttonBox}>
                  <CustomButton
                    variant={BUTTON_VARIANTS.RED}
                    size={BUTTON_SIZES.LARGE}
                    clickHandler={() => setOpenConfirmation(true)}
                    text="Usuń produkt"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductInfoBox;
