import React, { useEffect, useState } from "react";
import { Grid, makeStyles, Box } from "@material-ui/core";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

import CommonModal from "../../common/modals/CommonModal";
import StyledFormTextField from "../../common/textFields/StyledFormTextField";

import { users } from "../../../utils/newApi";
import styles from "./UserModal.styles";
import {
  SERVER_ERROR_MESSAGES,
  MODAL_TYPE,
  COMMON_MODAL_TEXTS,
} from "../../../constants/dictionary";
import { validateEmail } from "../../../utils/validation";

const useStyles = makeStyles(styles);

const AdjustUserWindow = ({
  usersList,
  setUsers,
  isOpen,
  close,
  handleError,
  info,
}) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [guidePassed, setGuidePassed] = useState(false);
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    setEmailError(false);
    setEmail(info.email);
    setGuidePassed(info.isTutorialFinished);
  }, [info, isOpen]);

  const submit = (e) => {
    e.preventDefault();

    if (email.trim().length === 0) {
      setEmailError(true);
      return;
    } else if (!validateEmail(email)) {
      setEmailError(true);
      return;
    }

    setEmailError(false);

    const payload = {
      email: email,
      isTutorialFinished: guidePassed,
    };

    users.usersInfo
      .edit(info.id, payload)
      .then((res) => {
        if (usersList) {
          let usersCopy = [...usersList];
          usersCopy = usersCopy.map((item) => {
            if (item.id === info.id) {
              let editedUser = {};
              Object.assign(editedUser, info);
              editedUser.email = email;
              editedUser.isTutorialFinished = guidePassed;
              return editedUser;
            } else {
              return item;
            }
          });
          setUsers(usersCopy);
          close();
        } else {
          window.location.reload();
        }
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_EDIT_USER);
      });
  };

  return (
    <CommonModal
      open={isOpen}
      toggleOpen={close}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.EDIT_USER_TITLE}
      type={MODAL_TYPE.EDIT}
    >
      <Grid container justify="center" spacing={4}>
        <Grid item xs={12}>
          <Box className={classes.box}>
            <StyledFormTextField
              InputLabelProps={{ classes: { root: classes.label } }}
              fullWidth={true}
              label="Email"
              variant="outlined"
              required
              value={email}
              shrink="true"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.box}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-standard-label">
                Samouczek
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                value={guidePassed}
                onChange={(e) => setGuidePassed(e.target.value)}
                label="Samouczek"
              >
                <MenuItem value={true}>Wykonany</MenuItem>
                <MenuItem value={false}>Nie wykonany</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      {emailError && (
        <p className={classes.error}>Proszę wpisać prawidłowy adres email</p>
      )}
    </CommonModal>
  );
};

export default AdjustUserWindow;
