import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styles from "./UserInfoList.styles";
import BlockIcon from "@material-ui/icons/Block";
import EditIcon from "@material-ui/icons/Edit";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import AdjustUserWindow from "../ModalWindows/UserInfo/AdjustUserWindow";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import CommonModal from "../common/modals/CommonModal";
import { users as usersApi } from "../../utils/newApi";

import {
  SERVER_ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";
import CustomButton from "../common/buttons/CustomButton";

const useStyles = makeStyles(styles);

const UserList = ({ users, setUsers, page, rowsPerPage }) => {
  const history = useHistory();
  const theme = useTheme();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [id, setId] = useState("");
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [infoForEdit, setInfoForEdit] = useState("");
  const [block, setBlock] = useState(false);
  const classes = useStyles(matches);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  const handleDelete = (id, blocked, e) => {
    e.stopPropagation();
    setOpenConfirmation(true);
    setBlock(blocked);
    setId(id);
  };

  const handleEdit = (ad, e) => {
    e.stopPropagation();
    setInfoForEdit(ad);
    setOpenEdit(true);
  };

  const blockUser = (userId) => {
    usersApi.usersInfo
      .block(userId)
      .then((res) => {
        let userCopy = [...users];
        const userIndex = userCopy.findIndex((users) => users.id === userId);
        userCopy[userIndex].blocked = true;
        setUsers(userCopy);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_BLOCK_USER);
      });
  };

  const unlockUser = (userId) => {
    usersApi.usersInfo
      .unblock(userId)
      .then((res) => {
        let userCopy = [...users];
        const userIndex = userCopy.findIndex((users) => users.id === userId);
        userCopy[userIndex].blocked = false;
        setUsers(userCopy);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_UNBLOCK_USER);
      });
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            {matches ? (
              <TableRow>
                <TableCell className={classes.nick}>Email</TableCell>
                <TableCell className={classes.name}>Imię</TableCell>
                <TableCell className={classes.surName}>Nazwisko</TableCell>
                <TableCell className={classes.login}>Login</TableCell>
                <TableCell className={classes.permissions}>
                  Uprawanienia
                </TableCell>
                <TableCell className={classes.userType}>Zablokowany</TableCell>
                <TableCell className={classes.buttons}></TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell className={classes.nick}>Email</TableCell>
                <TableCell className={classes.name}>Imię</TableCell>
                <TableCell className={classes.surName}>Nazwisko</TableCell>
                <TableCell className={classes.login}>Login</TableCell>
                <TableCell className={classes.permissions}>
                  Uprawanienia
                </TableCell>
                <TableCell className={classes.userType}>Zablokowany</TableCell>
                <TableCell className={classes.buttons}></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <TableRow
                  className={classes.row}
                  onClick={() => {
                    localStorage.setItem("currentUserInfoListPage", page);
                    localStorage.setItem(
                      "currentUserInfoListRowsPerPage",
                      rowsPerPage
                    );
                    history.push(`/dashboard/user/${user.id}`);
                  }}
                >
                  <TableCell>
                    <Typography className={classes.cell}>
                      {user.email}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.cell}>
                      {user.firstName !== "" && user.firstName !== null
                        ? user.firstName
                        : "Brak"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.cell}>
                      {user.lastName !== "" && user.lastName !== null
                        ? user.lastName
                        : "Brak"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.cell}>
                      {user.username}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.cell}>
                      {user.type}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.cell}>
                      <Grid item xs={12} align="center">
                        <CustomButton
                          variant={
                            !user.blocked
                              ? BUTTON_VARIANTS.GREEN
                              : BUTTON_VARIANTS.RED
                          }
                          size={BUTTON_SIZES.SMALL}
                          text={!user.blocked ? "Nie" : "Tak"}
                        />
                      </Grid>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Grid container alignItems="center" justifyContent="center">
                      <Grid item xs={12} align="center">
                        <Button
                          onClick={(e) => {
                            handleEdit(user, e);
                          }}
                          className={classes.iconLabel}
                          startIcon={<EditIcon />}
                        >
                          DOSTOSUJ
                        </Button>
                      </Grid>
                      <Grid item xs={12} align="center">
                        <Button
                          className={
                            user.blocked
                              ? classes.iconLabelGreen
                              : classes.iconLabelRed
                          }
                          onClick={(e) => {
                            handleDelete(user.id, user.blocked, e);
                          }}
                          startIcon={
                            user.blocked ? (
                              <LockOpenIcon className={classes.unlock} />
                            ) : (
                              <BlockIcon className={classes.delete} />
                            )
                          }
                        >
                          {user.blocked ? "ODBLOKUJ" : "ZABLOKUJ"}
                        </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() => (block ? unlockUser(id) : blockUser(id))}
        title={
          block
            ? COMMON_MODAL_TEXTS.USER_TITLE_UNBLOCK
            : COMMON_MODAL_TEXTS.USER_TITLE_BLOCK
        }
        content={
          block
            ? COMMON_MODAL_TEXTS.USER_CONTENT_UNBLOCK
            : COMMON_MODAL_TEXTS.USER_CONTENT_BLOCK
        }
      />
      <AdjustUserWindow
        usersList={users}
        setUsers={setUsers}
        isOpen={openEdit}
        close={() => setOpenEdit(false)}
        info={infoForEdit}
        handleError={handleError}
      />
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </>
  );
};

export default UserList;
