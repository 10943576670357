import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useEffect } from "react";
import { useState } from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import OrderData from "./OrderData";
import { Redirect } from "react-router-dom";
import { shop } from "../../utils/newApi";
import useStyles from "./OrderView.styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { displayPrice } from "../../utils/price";
import CommonModal from "../common/modals/CommonModal";

import { COMMON_MODAL_TEXTS } from "../../constants/dictionary";

const OrderView = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [order, setOrder] = useState({});
  const [totalCost, setTotalCost] = useState(0);
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [reload, setReload] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleCancel = () => {
    shop.orders.deleteOrder(id).then(() => setReload(!reload));
  };

  const handleMarkAsSent = () => {
    shop.orders.markAsSent(id).then(() => setReload(!reload));
  };

  const handleEditData = (data) => {
    shop.orders
      .editOrder(id, {
        orderDeliveryAddressRequest: data,
        orderInvoiceDataRequest: order.orderInvoiceDataDAO,
        invoiceRequested: order.invoiceRequested,
      })
      .then(() => setReload(!reload));
  };

  useEffect(() => {
    shop.orders
      .getOrder(id)
      .then((res) => {
        setOrder(res.data);
        setTotalCost(res.data.productsCost);
        setIsLoaded(true);
      })
      .catch(() => setRedirect(true)); // TODO: better handle error
  }, [reload]);

  return isLoaded ? (
    <Grid className={classes.root} container direction="column">
      <CommonModal
        open={open}
        toggleOpen={toggleOpen}
        handleConfirmation={handleCancel}
        title={COMMON_MODAL_TEXTS.ORDER_TITLE}
        content={COMMON_MODAL_TEXTS.GENERAL_CONTENT}
      />
      {redirect && <Redirect to="/dashboard/ordersList" />}
      <Grid item>
        <Typography className={clsx(classes.text, classes.bold)} variant="h4">
          Zamówienie #{id}
        </Typography>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item sm={12} md={7} container direction="column">
          {order.hivesAmount > 0 && (
            <Typography className={clsx(classes.productText)}>
              {`Ilość uli: ${order.hivesAmount}`}
            </Typography>
          )}
          {order.cocoonBoxesAmount > 0 && (
            <Typography className={clsx(classes.productText)}>
              {`Ilość pudełek: ${order.cocoonBoxesAmount}`}
            </Typography>
          )}
          {order.thermometersAmount > 0 && (
            <Typography className={clsx(classes.productText)}>
              {`Ilość termometrów: ${order.thermometersAmount}`}
            </Typography>
          )}
          {order.hivesAndCocoonBoxAmount > 0 && (
            <Typography className={clsx(classes.productText)}>
              {`Ilość pudełek z kokonami: ${order.hivesAndCocoonBoxAmount}`}
            </Typography>
          )}
          <Grid
            className={classes.totalContainer}
            item
            container
            direction="column"
            align="right"
          >
            <Grid item>
              <Typography
                className={clsx(classes.text, classes.bold)}
                variant="body1"
              >
                WARTOŚĆ ZAMÓWIENIA:
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={clsx(classes.text, classes.bold)}
                variant="body1"
              >
                {displayPrice(order.productsCost)} zł
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={clsx(classes.text, classes.bold)}
                variant="body1"
              >
                KOSZT WYSYŁKI:
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={clsx(classes.text, classes.bold)}
                variant="body1"
              >
                {displayPrice(order.deliveryCost)} zł
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={clsx(classes.text, classes.bold)}
                variant="body1"
              >
                CAŁKOWITA WARTOŚĆ ZAMÓWIENIA
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={clsx(classes.text, classes.bold)}
                variant="body1"
              >
                {displayPrice(order.deliveryCost + order.productsCost)} zł
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={5} container>
          <Grid item xs={12}>
            <OrderData
              toggleOpen={toggleOpen}
              handleEditData={handleEditData}
              data={order}
              handleMarkAsSent={handleMarkAsSent}
              status={order.status}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <div className={classes.container}>
      <div className={classes.loader}>
        <CircularProgress color="secondary" className={classes.loader} />
      </div>
    </div>
  );
};

export default OrderView;
