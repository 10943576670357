import tokenUtils from "../utils/tokenUtils";
import { Redirect } from "react-router-dom";
import { useEffect } from "react";

const Logout = () => {
    useEffect(() => {
        tokenUtils.clearToken();
    });

    return (
        <Redirect to="/login" />
    );
}

export default Logout;
