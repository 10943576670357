import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Chip from "@material-ui/core/Chip";
import { Typography, Paper, Grid, makeStyles, Box } from "@material-ui/core";
import styles from "./NewActiveSubstanceProductWindow.styles";
import StyledFormTextField from "../../common/textFields/StyledFormTextField";
import { cultivations } from "../../../utils/api";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  SERVER_ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
} from "../../../constants/dictionary";
import CustomButton from "../../common/buttons/CustomButton";

const useStyles = makeStyles(styles);

const NewActiveSubstanceProductWindow = ({
  isOpen,
  close,
  handleError,
  substance,
}) => {
  const classes = useStyles();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [activeSubstance, setActiveSubstance] = useState([]);

  useEffect(() => {
    setActiveSubstance(substance);
  }, [substance]);

  useEffect(() => {
    cultivations.products
      .all()
      .then((res) => {
        setProducts(res.data.cultivationProducts);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_RESOURCES);
      });
  }, []);

  const selectChangeArticle = (e, n) => {
    setSelectedProducts(n);
  };

  const submit = (e) => {
    e.preventDefault();
    let copyOfSubstance = activeSubstance;
    copyOfSubstance.cultivationProducts = [
      ...copyOfSubstance.cultivationProducts,
      ...selectedProducts,
    ];
    const dataToSend = {
      activeSubstance: {
        name: substance.name,
        cultivationProducts: copyOfSubstance.cultivationProducts,
      },
    };
    cultivations.activeSubstances
      .edit(substance.id, dataToSend)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_ASSIGN_RESOURCE);
      });
  };

  return (
    <Modal open={isOpen}>
      <Paper className={classes.root}>
        <div className={classes.scroll}>
          <Grid container spacing={4} justify="center" direction="column">
            <Grid item xs={12}>
              <Typography className={classes.header}>
                Dodaj nowy środek do substancji
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <Autocomplete
                  multiple
                  options={products}
                  onChange={selectChangeArticle}
                  filterSelectedOptions
                  getOptionLabel={(option) => option.name}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        variant="outlined"
                        color="primary"
                        className={classes.chip}
                        label={option.name}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <StyledFormTextField
                      {...params}
                      InputLabelProps={{ classes: { root: classes.label } }}
                      name="name"
                      fullWidth={true}
                      label="Znajdź środek"
                      variant="outlined"
                      shrink="true"
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <Grid container spacing={2} direction="row" justify="center">
                  <Grid item xs={6}>
                    <Box className={classes.box}>
                      <CustomButton
                        variant={BUTTON_VARIANTS.GREEN}
                        size={BUTTON_SIZES.LARGE}
                        clickHandler={submit}
                        text="Dodaj"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={classes.box}>
                      <CustomButton
                        variant={BUTTON_VARIANTS.RED}
                        size={BUTTON_SIZES.LARGE}
                        clickHandler={close}
                        text="Anuluj"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default NewActiveSubstanceProductWindow;
