import React, { useState, useEffect } from "react";
import { Grid, makeStyles, ThemeProvider } from "@material-ui/core";
import theme from "../Login/LoginSreen.breakpoints";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styles from "./ServicesScreen.styles";
import StyledFormTextField from "../../components/common/textFields/StyledFormTextField";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import ServicesTable from "../../components/Services/ServicesTable";
import { services, users } from "../../utils/api";
import AddServiceModal from "../../components/ModalWindows/Services/AddServiceModal";
import TablePaginationActions from "../../components/common/pagination/TablePaginationActions";
import TablePagination from "@material-ui/core/TablePagination";
import CommonModal from "../../components/common/modals/CommonModal";
import ListHeader from "../../components/common/ListHeader";

import {
  SERVER_ERROR_MESSAGES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const ServicesScreen = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [servicesList, setServicesList] = useState([]);
  const [copyServicesList, setCopyServicesList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [serviceInfo, setServiceInfo] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  useEffect(() => {
    let usersArray = [];
    users.usersInfo
      .all()
      .then((res) => {
        res.data.users.map((user) => {
          if (user.type === "user") {
            let userObj = {
              name: user.email,
              retailId: user.cocoonRetail.id,
            };
            usersArray.push(userObj);
          }
        });
        setUsersList(usersArray);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_USERS);
      });
    services
      .all()
      .then((res) => {
        const sorted = res.data.maintenances
          ? res.data.maintenances.sort((a, b) => b.id - a.id)
          : [];
        setServicesList(sorted);
        setCopyServicesList(sorted);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_SERVICES);
      });
    setIsLoaded(true);
  }, []);

  const handleChange = (e) => {
    if (e.target.value !== "") {
      const res = copyServicesList.filter((service) => {
        return (
          service.user.email &&
          service.user.email
            .toLowerCase()
            .startsWith(e.target.value.toLowerCase())
        );
      });
      setServicesList(res);
      setPage(0);
    } else {
      setServicesList(copyServicesList);
    }
  };

  const setCurrentService = (data) => {
    setServiceInfo(data);
    setOpenDeleteModal(true);
  };

  const deleteService = (serviceId) => {
    services
      .delete(serviceId)
      .then(() => {
        setServicesList(
          servicesList.filter((item) => item.maintenance.id !== serviceId)
        );
      })
      .catch(() => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_SERVICE);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <Grid container spacing={matches ? 3 : 0}>
          <ListHeader
            variant="title-add-search"
            title="Lista serwisów"
            handleAdd={() => setOpen(true)}
            searchComponent={
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                name="name"
                fullWidth={matches}
                label="Szukaj użytkownika"
                variant="outlined"
                shrink="true"
                onChange={(e) => handleChange(e)}
              />
            }
          />
          <Grid container>
            <Grid item xs={12} align="center">
              <ServicesTable
                services={servicesList}
                handleError={handleError}
                setCurrentService={setCurrentService}
                page={page}
                rowsPerPage={rowsPerPage}
              />
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 50]}
                component="div"
                count={servicesList ? servicesList.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                classes={{
                  toolbar: classes.toolbar,
                  caption: classes.caption,
                }}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Ilość wierszy na stronę"}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}–${to} z ${
                    count !== -1 ? count : `więcej niż ${to}`
                  }`;
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
      <AddServiceModal
        isOpen={open}
        close={() => setOpen(false)}
        handleError={handleError}
        usersList={usersList}
      />
      <CommonModal
        open={openDeleteModal}
        toggleOpen={() => setOpenDeleteModal(false)}
        handleConfirmation={() => deleteService(serviceInfo.maintenance.id)}
        title={COMMON_MODAL_TEXTS.SERVICE_TITLE}
        content={COMMON_MODAL_TEXTS.GENERAL_CONTENT}
      />
    </ThemeProvider>
  );
};

export default ServicesScreen;
