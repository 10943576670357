import "./App.css";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import routes from "./routes";
import { ThemeProvider, createTheme } from "@material-ui/core";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router forceRefresh={true}>
        <Switch>
          {routes
            .filter((r) => !r.layout)
            .map((r) =>
              r.private ? (
                <PrivateRoute
                  exact={r.exact}
                  path={r.path}
                  key={r.path}
                  component={r.component}
                />
              ) : (
                <Route
                  exact={r.exact}
                  path={r.path}
                  key={r.path}
                  component={r.component}
                />
              )
            )}
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
