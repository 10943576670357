import { Typography, Box, Grid, makeStyles } from "@material-ui/core";
import IosButton from "../common/buttons/IosButton";
import GoogleButton from "../common/buttons/GoogleButton";
import styles from "./Footer.styles";
const useStyles = makeStyles(styles);

const Footer = (theme) => {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.header}>
            Pobierz aplikację OSMIAFUTURE
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" className={classes.info}>
            Quisque pellentseque metus ac quam. Donec magna nulla, allquet,
            vitae, congue ac. faucibus ut, erat. Donec sit amet neque.
          </Typography>
        </Grid>
        <Grid container spacing={2} justify="center" direction="row">
          <Grid item sm={6} align="right">
            <Box className={classes.box2}>
              <IosButton />
            </Box>
          </Grid>
          <Grid item sm={6} align="left">
            <Box className={classes.box2}>
              <GoogleButton />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
