import colors from "../../constants/colors";

const styles = (theme) => {
  return {
    header: {
      fontSize: "20px",
      "text-align": "center",
      "font-weight": "bold",
      "font-family": "Montserrat",
      color: colors.oxfordBlue,
    },
    emptyDoses: {
      "text-align": "center",
      "font-weight": "bold",
      "font-family": "Montserrat",
      color: colors.htmlRed,
    },
    rootList: {
      width: "100%",
      backgroundColor: colors.platinum,
      justifyContent: "space-between",
    },

    listText: {
      "font-weight": "bold",
      "font-family": "Montserrat",
    },
    listItem: {
      justifyContent: "flex-start",
    },
    delete: {
      color: colors.htmlRed,
      cursor: "pointer",
    },
    plant: {
      color: colors.green,
    },
    doseButtonBox: {
      marginTop: "5%",
    },
  };
};

export const doseText = {
  color: colors.htmlRed,
  fontFamily: "Montserrat",
};

export const pathogensText = {
  color: colors.htmlGreen,
  fontFamily: "Montserrat",
};

export default styles;
