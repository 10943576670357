const styles = (theme, level) => {
    return {
      root: {
        width: "80%",
        align: "center",
        "border-radius": "10px",
        overflow: "hidden",
        "margin-left": "10%",
        "margin-right": "10%",
        [theme.breakpoints.down("sm")]: {
          "margin-top": "20vh",
        },
        [theme.breakpoints.up("sm")]: {
          "margin-top": "25vh",
        },
        "margin-bottom": "10%",
        paddingTop: "20px",
        paddingBottom: "20px",
      },
      header: {
        "text-align": "center",
        "font-weight": "bold",
        "font-size": "25px",
        "font-family": "Montserrat",
      },
      scroll: {
        flex: 1,
        "overflow-y": "scroll",
        "overflow-x": "hidden",
        width: "100%",
        height: "100%",
      },
      box: {
        marginLeft: "5%",
        marginRight: "5%",
      },
      label: {
        "white-space": "nowrap",
        [theme.breakpoints.down("sm")]: {
          fontSize: 13,
        },
        [theme.breakpoints.up("sm")]: {
          fontSize: 15,
        },
      },
    };
  };
  
  export default styles;
  