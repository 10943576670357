import colors from "../../constants/colors";

const styles = (theme) => {
  return {
    row: {
      transform: "all 0.25s ease-in-out",
      "&:hover": {
        transform: "scale(1.01)",
        cursor: "pointer",
      },
    },
    picture: {
      width: "15%",
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      "border-bottom": "4px solid",
    },
    code: {
      width: "50%",
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      "border-bottom": "4px solid",
    },
    user: {
      width: "30%",
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      "border-bottom": "4px solid",
    },
    buttons: {
      paddingRight: "13%",
      paddingBottom: "3%",
      paddingTop: "3%",
      "border-bottom": "4px solid",
    },
    buttons2: {
      paddingRight: "13%",
      paddingBottom: "3%",
      paddingTop: "3%",
    },
    cell: {
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
    },
    delete: {
      color: colors.carmine,
    },
    root2: {
      float: "center",
    },
    box: {
      marginLeft: "5%",
      marginRight: "5%",
    },
    userRes: {
      "border-bottom": "3px solid",
      width: "20%",
    },
    picRes: {
      "border-bottom": "3px solid",
    },
    codeRes: {
      "border-bottom": "3px solid",
      minWidth: "200px",
    },
    connectedToRes: {
      "border-bottom": "3px solid",
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      minWidth: "200px",
    },
    headerRes: {
      padding: "10%",
    },
    usersRes: {
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      "border-bottom": "3px solid",
      minWidth: "150px",
    },
    headerCell: {
      display: "flex",
    },
    headerCellRight: {
      marginLeft: "2px",
    },
    breakline: {
      "border-bottom": "1px solid grey",
    },
    label: {
      "word-wrap": "break-word",
      fontSize: 13,
    },
    iconLabel: {
      "font-weight": "bold",
      "font-size": "12px",
      "font-family": "Montserrat",
      marginLeft: "4%",
      marginTop: "2%",
    },
    iconLabelRed: {
      "font-weight": "bold",
      "font-size": "12px",
      "font-family": "Montserrat",
      marginLeft: "4%",
      marginTop: "2%",
      color: colors.carmine,
    },
    type: {
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      color: colors.oxfordBlue,
    },
    userHeader: {
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      color: colors.oxfordBlue,
      "text-align": "center",
    },
    button: {
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
    },
    disable: {
      "font-weight": "bold",
      "font-size": "15px",
      "font-family": "Montserrat",
      color: colors.quickSilver,
    },
    caption: {
      fontSize: "15px",
      "font-family": "Montserrat",
    },
    toolbar: {
      "& > p:nth-of-type(2)": {
        fontSize: "15px",
        color: colors.black,
        "font-family": "Montserrat",
        fontWeight: 600,
      },
    },
  };
};

export default styles;
