import React, { useState, useEffect } from "react";
import { Grid, makeStyles, ThemeProvider, Typography } from "@material-ui/core";
import theme from "../Login/LoginSreen.breakpoints";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styles from "./CocoonBoxesScreen.styles";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import CocoonBoxesTable from "../../components/CocoonBoxes/CocoonBoxesTable";
import { useParams } from "react-router-dom";
import { services, users } from "../../utils/api";
import { format } from "date-fns";

import { SERVER_ERROR_MESSAGES } from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const CocoonBoxesScreen = () => {
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const { id } = useParams();
  const [cocoonBoxes, setCocoonBoxes] = useState([]);
  const [user, setUser] = useState("");
  const [date, setDate] = useState(new Date());

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  useEffect(() => {
    services
      .one(id)
      .then((res) => {
        setCocoonBoxes(res.data.maintenance.cocoonBoxes);
        setDate(res.data.maintenance.date);
        if (res.data.maintenance.cocoonBoxes.length > 0) {
          users.usersInfo
            .one(res.data.maintenance.cocoonBoxes[0].userId)
            .then((res) => {
              setUser(res.data.user.login);
            });
        }
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_SERVICES);
      });
    setIsLoaded(true);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <Grid container spacing={matches ? 3 : 0}>
          <Grid item xs={10}>
            <Typography className={classes.header}>
              {`Lista pudełek zebranych przez użytkownika ${
                user && user
              } dnia ${date ? format(new Date(date), "dd/MM/yyyy") : "Brak"}`}
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={12} align="center">
              <CocoonBoxesTable
                cooconBoxes={cocoonBoxes}
                handleError={handleError}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </ThemeProvider>
  );
};

export default CocoonBoxesScreen;
