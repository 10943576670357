import React from "react";
import StyledUploadFileButton from "../common/buttons/StyledUploadFileButton";
import PublishIcon from "@material-ui/icons/Publish";

const FileUploader = (props) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    props.handleFile(fileUploaded);
  };
  return (
    <>
      <StyledUploadFileButton onClick={handleClick} endIcon={<PublishIcon />}>
        {props.name ? props.name : "Dodaj zdjęcia"}
      </StyledUploadFileButton>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  );
};

export default FileUploader;
