import React, { useEffect, useState } from "react";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";

import StyledFormTextField from "../common/textFields/StyledFormTextField";
import StyledFormPasswordField from "../common/textFields/StyledFormPasswordField";

import { auth } from "../../utils/newApi.js";
import useForm from "../../hooks/useForm";
import styles from "./RegisterForm.styles";
import HELPER_TEXTS from "../../constants/registerHelperTexts";

import { useHistory } from "react-router-dom";
import CustomButton from "../common/buttons/CustomButton";

import { BUTTON_VARIANTS, BUTTON_SIZES } from "../../constants/dictionary";

const registerPost = (values, setApiResponse, history) => {
  auth
    .register(values)
    .then((res) => {
      history.push("/login");
    })
    .catch((err) => {
      if (err === undefined || err.response === undefined) {
        setApiResponse(HELPER_TEXTS["unknownServerError"]);
      } else {
        setApiResponse(HELPER_TEXTS[err.response.status]);
      }
    });
};

const useStyles = makeStyles(styles);

const RegisterForm = () => {
  const classes = useStyles();

  let history = useHistory();

  const { handleChange, values } = useForm({
    username: "",
    email: "",
    repeatedPassword: "",
    password: "",
  });

  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [passwordHelperText, setPasswordHelperText] = useState(HELPER_TEXTS[0]);

  const [passwd1Vis, setPasswd1Vis] = useState(false);
  const [passwd2Vis, setPasswd2Vis] = useState(false);
  const [apiError, setApiResponse] = useState(HELPER_TEXTS[0]);

  useEffect(() => {
    if (values.password.length > 0) {
      if (values.password.length < 4) {
        setIsPasswordValid(false);
        setPasswordHelperText(HELPER_TEXTS[403]);
      } else if (values.password !== values.repeatedPassword) {
        setIsPasswordValid(false);
        setPasswordHelperText(HELPER_TEXTS[69]);
      } else {
        setIsPasswordValid(true);
      }
    } else {
      setIsPasswordValid(true);
    }
    if (isPasswordValid) setPasswordHelperText("");
  }, [values.password, values.repeatedPassword, isPasswordValid]);

  return (
    <div className={classes.form}>
      <Grid
        container
        className={classes.root}
        spacing={window.innerWidth < 700 ? 2 : 5}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.registerHeader}>
            {HELPER_TEXTS["registerHeader"]}
          </Typography>
        </Grid>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          item
          xs={12}
          spacing={2}
        >
          <Grid item xs={12} sm={6}>
            <StyledFormTextField
              name="username"
              label={HELPER_TEXTS["usernameLabel"]}
              fullWidth
              variant="outlined"
              className={classes.textfield}
              onChange={handleChange("username")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <StyledFormTextField
              name="email"
              label={HELPER_TEXTS["emailLabel"]}
              fullWidth
              variant="outlined"
              className={classes.textfield}
              onChange={handleChange("email")}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          item
          xs={12}
          spacing={2}
        >
          <Grid item xs={12} sm={6}>
            <StyledFormPasswordField
              name="repeatedPassword"
              label={HELPER_TEXTS["repeatedPasswdLabel"]}
              fullWidth
              onChange={handleChange("repeatedPassword")}
              passwdVis={passwd1Vis}
              setPasswdVis={setPasswd1Vis}
              buttonClass={classes.toggleIcon}
              className={classes.textfield}
              helperText={""}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledFormPasswordField
              name="password"
              label={HELPER_TEXTS["passwdLabel"]}
              onChange={handleChange("password")}
              fullWidth
              passwdVis={passwd2Vis}
              setPasswdVis={setPasswd2Vis}
              buttonClass={classes.toggleIcon}
              helperText={passwordHelperText}
              className={classes.textfield}
              FormHelperTextProps={{
                error: !isPasswordValid,
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        item
        xs={12}
        spacing={5}
      >
        <Grid item>
          <Typography className={classes.apiError}>{apiError}</Typography>

          <CustomButton
            variant={BUTTON_VARIANTS.RED}
            size={BUTTON_SIZES.LARGE}
            clickHandler={() =>
              registerPost(
                {
                  email: values.email,
                  password: values.password,
                  username: values.username,
                },
                setApiResponse,
                history
              )
            }
            text={HELPER_TEXTS["registerLabel"]}
          />
        </Grid>
        <Grid>
          <Button
            variant="text"
            color="primary"
            onClick={() => history.push("/login")}
          >
            {HELPER_TEXTS["loginLabel"]}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default RegisterForm;
