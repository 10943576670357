import useStyles from "./UserInfo.styles";
import { Person } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { users } from "../../utils/newApi";

import colors from "../../constants/colors";

const UserInfo = () => {
  const classes = useStyles();
  const [user, setUser] = React.useState({});

  const PersonIcon = withStyles({
    root: {
      color: colors.white,
    },
  })(Person);

  React.useEffect(() => {
    users
      .whoami()
      .then((res) => setUser(res.data))
      .catch(() => {});
  }, []);

  return (
    <Box display="flex" flexGrow="1" alignItems="center" padding={2}>
      <Grid container spacing={1} direction="row">
        <Grid item xs={3}>
          <Box
            display="flex"
            height="100%"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <PersonIcon />
          </Box>
        </Grid>
        <Grid item xs={9} container spacing={1} direction="column">
          <Grid item>
            <Typography className={classes.primary}>{user.username}</Typography>
            <Typography className={classes.secondary}>{user.email}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserInfo;
