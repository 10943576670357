const styles = (theme) => {
    return {
      header: {
        "text-align": "left",
        "font-weight": "bold",
        [theme.breakpoints.down("sm")]: {
          "font-size": "20px",
        },
        [theme.breakpoints.up("sm")]: {
          "font-size": "25px",
        },
        "font-family": "Montserrat",
        marginLeft: "5%",
        marginTop: "3%",
      },
      label: {
        "white-space": "nowrap",
        [theme.breakpoints.down("sm")]: {
          fontSize: 13,
        },
        [theme.breakpoints.up("sm")]: {
          fontSize: 15,
        },
      },
      box: {
        marginTop: "2%",
      },
      container: {
        display: "flex",
        "flex-direction": "row",
        "justify-content": "center",
        "align-items": "center",
        height: "100%",
        width: "100%",
      },
      loader: {
        "justify-content": "center",
        "align-items": "center",
      },
    };
  };
  
  export default styles;
  