import { Button, InputAdornment } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import StyledFormTextField from "./StyledFormTextField";

const StyledFormPasswordField = (props) => {
	return (
		<StyledFormTextField
			type={props.passwdVis ? "text" : "password"}
			variant="outlined"
			{...props}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Button
							variant="text"
							className={props.buttonClass}
							onClick={() => props.setPasswdVis(!props.passwdVis)}
						>
							{props.passwdVis ? (
								<VisibilityIcon />
							) : (
								<VisibilityOffIcon />
							)}
						</Button>
					</InputAdornment>
				),
			}}
		/>
	);
};

export default StyledFormPasswordField;
