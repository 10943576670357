import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { Grid, Typography, Button } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styles from "./FieldWorkTable.styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import EditWorkModalWindow from "../ModalWindows/WorkField/EditWorkModal";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../components/common/pagination/TablePaginationActions";
import CommonModal from "../common/modals/CommonModal";
import { fieldWork } from "../../utils/newApi";
import CustomButton from "../common/buttons/CustomButton";

import {
  SERVER_ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";
import { sortConstants, fieldWorksSort } from "../../constants/localStorage";

const useStyles = makeStyles(styles);

const FieldWorkTable = ({ works, setWorks, worksCopy, handleError }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [id, setId] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [infoForEdit, setInfoForEdit] = useState("");
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [isUser, setIsUser] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    if (worksCopy.length !== 0) {
      const sortToApply = localStorage.getItem(sortConstants.fieldWorks);

      if (sortToApply) {
        switch (sortToApply) {
          case fieldWorksSort.userA:
            sortByUser(true);
            break;
          case fieldWorksSort.userD:
            sortByUser(false);
            break;
          default:
            break;
        }
      }
    }
  }, [worksCopy]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDelete = (id, e) => {
    e.stopPropagation();
    setOpenConfirmation(true);
    setId(id);
  };

  const handleEdit = (work, e) => {
    e.stopPropagation();
    setInfoForEdit(work);
    setOpenEdit(true);
  };

  const isFromUser = (work) => {
    if (!work.isFromAdmin) return true;
    else return false;
  };

  const sortByUser = (isAsc = null) => {
    setIsUser((prev) => isAsc ?? !prev);
  };

  useEffect(() => {
    if (isUser !== null) {
      const sorted = [...works].sort((a, b) => {
        if (isUser) {
          if (isFromUser(a)) {
            return 1;
          }
          if (isFromUser(b)) {
            return -1;
          }
        } else {
          if (isFromUser(a)) {
            return -1;
          }
          if (isFromUser(b)) {
            return 1;
          }
        }
      });

      setWorks(sorted);

      if (isUser) {
        localStorage.setItem(sortConstants.fieldWorks, fieldWorksSort.userA);
      } else {
        localStorage.setItem(sortConstants.fieldWorks, fieldWorksSort.userD);
      }
    }
  }, [isUser]);

  const deleteWork = (workId) => {
    fieldWork
      .delete(workId)
      .then((res) => {
        setWorks(works.filter((item) => item.id !== workId));
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_WORK);
      });
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            {matches ? (
              <TableRow>
                <TableCell
                  className={classes.codeRes}
                  align="right"
                ></TableCell>
                <TableCell className={classes.userRes} align="right">
                  <Typography className={classes.userHeader}>
                    Od admina
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell align="left" className={classes.code}>
                  <Typography className={classes.cell}>Nazwa pracy</Typography>
                </TableCell>
                <TableCell className={classes.user}>
                  <Button
                    endIcon={
                      isUser ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />
                    }
                    onClick={() => {
                      sortByUser();
                    }}
                  >
                    <Typography className={classes.cell}>Od admina</Typography>
                  </Button>
                </TableCell>
                <TableCell className={classes.buttons}></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {works
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((work) => (
                <TableRow key={work.id} className={classes.row}>
                  <TableCell align="left">
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={matches ? 12 : 8}
                        align={matches ? "center" : "left"}
                        className={matches && classes.breakline}
                      >
                        <Typography className={classes.cell}>
                          {work && work.name}
                        </Typography>
                      </Grid>
                      {matches && (
                        <Grid item xs={12}>
                          <Grid
                            container
                            spacing={2}
                            direction="row"
                            alignContent="center"
                            justifyContent="center"
                          >
                            <Grid item xs={6}>
                              <div
                                className={classes.headerCell}
                                onClick={(e) => {
                                  handleEdit(work, e);
                                }}
                              >
                                <EditIcon />
                                <Typography className={classes.iconLabel}>
                                  EDYTUJ
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6} align="left">
                              <div
                                className={classes.headerCell}
                                onClick={(e) => {
                                  handleDelete(work.id, e);
                                }}
                              >
                                <DeleteIcon className={classes.delete} />
                                <Typography className={classes.iconLabelRed}>
                                  USUŃ
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </TableCell>

                  {!matches && (
                    <TableCell align="left">
                      <CustomButton
                        variant={
                          work.isFromAdmin
                            ? BUTTON_VARIANTS.GREEN
                            : BUTTON_VARIANTS.RED
                        }
                        size={BUTTON_SIZES.SMALL}
                        text={work.isFromAdmin ? "Tak" : "Nie"}
                      />
                    </TableCell>
                  )}

                  {matches ? (
                    <TableCell align="center">
                      <CustomButton
                        variant={
                          work.isFromAdmin
                            ? BUTTON_VARIANTS.GREEN
                            : BUTTON_VARIANTS.RED
                        }
                        size={BUTTON_SIZES.SMALL}
                        text={work.isFromAdmin ? "Tak" : "Nie"}
                      />
                    </TableCell>
                  ) : (
                    <TableCell className={classes.cell} align="left">
                      {
                        <Grid container spacing={2} alignItems="center">
                          <Grid item md={12}>
                            <Button
                              disabled={!work.isFromAdmin ? true : false}
                              startIcon={<EditIcon />}
                              onClick={(e) => {
                                if (work.isFromAdmin) handleEdit(work, e);
                              }}
                            >
                              <Typography className={classes.iconLabel}>
                                EDYTUJ
                              </Typography>
                            </Button>
                          </Grid>
                          <Grid item md={12}>
                            <Button
                              disabled={!work.isFromAdmin ? true : false}
                              startIcon={
                                <DeleteIcon
                                  className={
                                    !work.isFromAdmin ? null : classes.delete
                                  }
                                />
                              }
                              onClick={(e) => {
                                if (work.isFromAdmin) handleDelete(work.id, e);
                              }}
                            >
                              <Typography
                                className={
                                  !work.isFromAdmin
                                    ? classes.iconLabel
                                    : classes.iconLabelRed
                                }
                              >
                                USUŃ
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      }
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 15, 20, 50]}
          component="div"
          count={works.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
          classes={{
            toolbar: classes.toolbar,
            caption: classes.caption,
          }}
          ActionsComponent={TablePaginationActions}
          labelRowsPerPage={"Ilość wierszy na stronę"}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}–${to} z ${
              count !== -1 ? count : `więcej niż ${to}`
            }`;
          }}
        />
      </TableContainer>
      <EditWorkModalWindow
        works={works}
        setWorks={setWorks}
        handleError={handleError}
        isOpen={openEdit}
        close={() => setOpenEdit(false)}
        info={infoForEdit}
      />
      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() => deleteWork(id)}
        title={COMMON_MODAL_TEXTS.WORK_TITLE}
        content={COMMON_MODAL_TEXTS.WORK_CONTENT}
      />
    </>
  );
};

export default FieldWorkTable;
