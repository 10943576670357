const REGISTER_HELPER_TEXTS = {
	0: " ",
	69: "Hasła nie są identyczne",
	403: "Hasło nie spełnia wymagań",
	409: "Podany email już istnieje",
	unknownServerError: "Nieznany błąd serwera",
	usernameLabel: "NAZWA UŻYTKOWNIKA",
	emailLabel: "ADRES E-MAIL",
	registerHeader: "Zarejestruj się",
	headerText: "PANEL REJESTRACJI DO SYSTEMU",
	repeatedPasswdLabel: "WPISZ HASŁO",
	passwdLabel: "POWTÓRZ HASŁO",
	registerLabel: "ZAREJESTRUJ SIĘ",
	loginLabel: "ZALOGUJ SIĘ",
};

export default REGISTER_HELPER_TEXTS;
