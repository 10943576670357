import {
  Box,
  Divider,
  Grid,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  BUTTON_SIZES,
  BUTTON_VARIANTS,
  SERVER_ERROR_MESSAGES,
} from "../../constants/dictionary";
import CustomButton from "../common/buttons/CustomButton";
import styles, { doseText, pathogensText } from "./Doses.styles";
import DeleteIcon from "@material-ui/icons/Delete";
import PushPinIcon from "@mui/icons-material/PushPin";
import { useEffect, useState } from "react";
import DoseAdder from "./DoseAdder";
import { plantProtectionProducts } from "../../utils/newApi";
import { displayFloat, removeLastComma } from "../../utils/formatter";

const useStyles = makeStyles(styles);

const Doses = ({ doses, productId, handleError }) => {
  const classes = useStyles(styles);
  const [edit, setEdit] = useState(false);
  const [displayDoses, setDisplayDoses] = useState(false);
  const [dosesList, setDosesList] = useState([]);

  useEffect(() => {
    setDosesList(doses);
    setDisplayDoses(doses?.length > 0);
  }, [doses]);

  const toogleEditOrSave = () => {
    if (!edit) {
      setEdit(true);
    } else {
      const payload = {
        requestList: dosesList.map((d) => {
          return {
            dosagePerHa: Number(d.dosagePerHa),
            pathogensId: d.pathogenDAOList.map((p) => p.id),
          };
        }),
      };
      plantProtectionProducts
        .changeDoses(productId, payload)
        .then(() => {
          setEdit(false);
        })
        .catch(() => {
          handleError(
            SERVER_ERROR_MESSAGES.COULD_CHANGE_DOSAGE_FOR_PLANTS_PROTECTION_PRODUCT
          );
        });
    }
  };

  const onDoseAdd = (newDose) => {
    setDosesList((prevDoses) => [...prevDoses, newDose]);
  };

  const onDoseRemove = (dose_idx) => {
    const newDoses = dosesList.filter((d, idx) => idx !== dose_idx);
    setDosesList(newDoses);
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12} align="center">
        <Typography className={classes.header}>Dawki</Typography>
      </Grid>
      <Grid item xs={12} align="center">
        <Typography className={classes.emptyDoses}>
          {!displayDoses && dosesList?.length === 0 && "Brak"}
        </Typography>
      </Grid>
      {dosesList?.map((dose, idx) => (
        <Box className={classes.rootList}>
          <Grid
            container
            spacing={2}
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item xs={8} justifyContent="space-around">
              <ListItemText
                primary="Dawka / Ha"
                primaryTypographyProps={{ style: doseText }}
                secondary={
                  <Typography className={classes.listText}>
                    {displayFloat(dose.dosagePerHa)}
                  </Typography>
                }
              />
              <ListItemText
                primary="Patogeny"
                primaryTypographyProps={{ style: pathogensText }}
                secondary={
                  <Typography className={classes.listText}>
                    {dose.pathogenDAOList &&
                      removeLastComma(
                        dose.pathogenDAOList.map(
                          (pathogen) => `${pathogen.name}, `
                        )
                      )}
                  </Typography>
                }
              />
              <ListItemText
                primary="Typ uprawy"
                primaryTypographyProps={{ style: pathogensText }}
                secondary={
                  <Typography className={classes.listText}>
                    {dose.pathogenDAOList &&
                      dose.pathogenDAOList[0].cultivationName}
                  </Typography>
                }
              />
              <ListItemText
                primary="Faza rozwoju"
                primaryTypographyProps={{ style: pathogensText }}
                secondary={
                  <Typography className={classes.listText}>
                    {dose.pathogenDAOList &&
                      dose.pathogenDAOList[0].cultivationDevelopmentStageName}
                  </Typography>
                }
              />
            </Grid>
            {edit ? (
              <DeleteIcon
                className={classes.delete}
                onClick={() => {
                  onDoseRemove(idx);
                }}
              />
            ) : (
              <PushPinIcon className={classes.plant} />
            )}
          </Grid>
          <Divider variant="fullWidth" />
        </Box>
      ))}
      {edit && <DoseAdder onDoseAdd={onDoseAdd} />}
      <Grid item xs={12} align="center" className={classes.doseButtonBox}>
        <CustomButton
          variant={BUTTON_VARIANTS.GREEN}
          size={BUTTON_SIZES.MEDIUM}
          clickHandler={toogleEditOrSave}
          text={edit ? "Zapisz" : displayDoses ? "Zmień dawki" : "Dodaj dawki"}
        />
      </Grid>
    </Grid>
  );
};

export default Doses;
