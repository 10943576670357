import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { Typography, Paper, Grid, makeStyles, Box } from "@material-ui/core";
import styles from "./EditSub.styles";
import StyledFormTextField from "../../common/textFields/StyledFormTextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { cultivations } from "../../../utils/api";

import {
  SERVER_ERROR_MESSAGES,
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from "../../../constants/dictionary";
import CustomButton from "../../common/buttons/CustomButton";

const useStyles = makeStyles(styles);

const EditSubWindow = ({ isOpen, close, handleError, data, pathogen }) => {
  const classes = useStyles();
  const [subArray, setSubArray] = useState([]);
  const [selectedSub, setSelectedSub] = useState({});

  useEffect(() => {
    cultivations.activeSubstances
      .all()
      .then((res) => {
        setSubArray(res.data.activeSubstances);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_SUBSTANCE);
      });
  }, []);

  useEffect(() => {
    setSelectedSub(data && data);
    if (data) {
      let sub = subArray;
      let finalSubstance = [];
      let is = false;
      sub.forEach((ele1) => {
        pathogen.activeSubstances.forEach((ele2) => {
          if (ele1.id === ele2.id) {
            is = true;
          }
        });
        if (!is) {
          finalSubstance.push(ele1);
        }
        is = false;
      });
      setSubArray(finalSubstance);
    }
  }, [data]);

  const submit = (e) => {
    e.preventDefault();
    let pathogenC = pathogen;
    let array = pathogenC.activeSubstances.filter((v) => {
      if (v.id === data.id) {
        return false;
      } else return true;
    });
    array.push(selectedSub);
    pathogenC.activeSubstances = array;
    const dataToSend = {
      pathogen: pathogenC,
    };
    cultivations.pathogens
      .edit(pathogen.id, dataToSend)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_EDIT_SUBSTANCE);
      });
  };

  return (
    <Modal open={isOpen}>
      <Paper className={classes.root}>
        <div className={classes.scroll}>
          <Grid container spacing={4} justify="center" direction="column">
            <Grid item xs={12}>
              <Typography className={classes.header}>
                Zmień substancję
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <Autocomplete
                  options={subArray}
                  value={selectedSub}
                  onChange={(e, n) => setSelectedSub(n)}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <StyledFormTextField
                      {...params}
                      InputLabelProps={{ classes: { root: classes.label } }}
                      name="name"
                      fullWidth={true}
                      label="substancja"
                      variant="outlined"
                      shrink="true"
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <Grid container spacing={2} direction="row" justify="center">
                  <Grid item xs={6}>
                    <Box className={classes.box}>
                      <CustomButton
                        variant={BUTTON_VARIANTS.GREEN}
                        size={BUTTON_SIZES.LARGE}
                        clickHandler={submit}
                        text="Edytuj"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={classes.box}>
                      <CustomButton
                        variant={BUTTON_VARIANTS.RED}
                        size={BUTTON_SIZES.LARGE}
                        clickHandler={close}
                        text="Anuluj"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default EditSubWindow;
