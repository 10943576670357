import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import {
  Hidden,
  Switch,
  FormControlLabel,
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  Checkbox,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import CommonModal from "../common/modals/CommonModal";
import CustomButton from "../common/buttons/CustomButton";

import useForm from "../../hooks/useForm";
import useStyles from "./Modal.styles";
import { validateConfig } from "../../utils/validation";
import {
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  COMMON_MODAL_TEXTS,
  MODAL_TYPE,
} from "../../constants/dictionary";
import { HARVEST_MESSAGES } from "../../constants/dictionary";

const AddCultivationTypeWindow = ({ handleAdd, open, toggleOpen }) => {
  const classes = useStyles();
  const { handleChange, values, setFieldValue } = useForm({
    name: "",
    harvestType: "",
    suggestedHivesCount: "",
    lowerDensityRate: "",
    upperDensityRate: "",
  });
  const [switched, setSwitched] = useState(false);
  const [manualHarvesting, setManualHarvesting] = useState(true);
  const [combineHarvesting, setCombineHarvesting] = useState(false);
  const [configs, setConfigs] = useState([]);
  const [lowerDensityRate, setLowerDensityRate] = useState("");
  const [errorLower, setErrorLower] = useState(false);
  const [errorUpper, setErrorUpper] = useState(false);
  const [errorHives, setErrorHives] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [noConfigsError, setNoConfigsError] = useState(false);
  const errorMsg = "Niepoprawna wartość";

  useEffect(() => {
    setErrorHives(false);
    setErrorLower(false);
    setErrorUpper(false);
    setErrorName(false);
    setConfigs([]);
    setFieldValue("name", "");
    setFieldValue("suggestedHivesCount", "");
    setLowerDensityRate("");
    setFieldValue("upperDensityRate", "");
  }, [open]);

  const handleSwitch = (event) => {
    setSwitched(event.target.checked);
  };

  const handleManualHarvestingSwitch = (event) => {
    setManualHarvesting(event.target.checked);
    setCombineHarvesting(!event.target.checked);
  };

  const handleCombineHarvestingSwitch = (event) => {
    setCombineHarvesting(event.target.checked);
    setManualHarvesting(!event.target.checked);
  };

  const addConfig = () => {
    const error = validateConfig(
      values.name,
      values.suggestedHivesCount,
      lowerDensityRate,
      values.upperDensityRate,
      setErrorName,
      setErrorHives,
      setErrorLower,
      setErrorUpper
    );

    const isHarvestManual = switched ? (manualHarvesting ? true : false) : null;

    if (!error) {
      const config = {
        isHarvestManual: isHarvestManual,
        lowerTreeDensityRate: lowerDensityRate,
        suggestedHivesCount: values.suggestedHivesCount,
        upperTreeDensityRate: values.upperDensityRate,
      };

      setConfigs((prev) => [...prev, config]);
      setNoConfigsError(false);
    }
  };

  return (
    <CommonModal
      open={open}
      toggleOpen={toggleOpen}
      handleConfirmation={() => {
        if (configs.length > 0) {
          handleAdd({
            hiveAmountConfigRequestList: configs,
            name: values.name,
          });
          toggleOpen();
        } else {
          setNoConfigsError(true);
        }
      }}
      title={COMMON_MODAL_TEXTS.ADD_CULTIVATION_TYPE_TITLE}
      type={MODAL_TYPE.ADD}
    >
      <Grid item xs={12}>
        <TextField
          label="Wpisz nazwę typu"
          InputLabelProps={{ classes: { root: classes.label } }}
          placeholder="Nowy typ"
          variant="outlined"
          fullWidth
          required
          onChange={handleChange("name")}
          error={errorName}
        />
      </Grid>

      {configs.length > 0 && (
        <Grid item xs={12} align="center" style={{ marginBottom: "2%" }}>
          <Typography className={classes.listText}>
            Lista konfiguracji
          </Typography>
        </Grid>
      )}

      <Grid container>
        {configs.map((config, idx) => (
          <List className={classes.rootList}>
            <ListItem className={classes.rootElements}>
              <ListItemText
                primary="Typ zbiorów"
                style={{ width: "15%" }}
                secondary={
                  <Typography className={classes.listText}>
                    {HARVEST_MESSAGES[config.isHarvestManual]}
                  </Typography>
                }
              />
              <ListItemText
                primary="Zalecana ilość uli"
                secondary={
                  <Typography className={classes.listText}>
                    {config.suggestedHivesCount}
                  </Typography>
                }
              />
              <ListItemText
                primary="Dolna granica"
                secondary={
                  <Typography className={classes.listText}>
                    {config.lowerTreeDensityRate.trim().length > 0
                      ? config.lowerTreeDensityRate
                      : "Nieokreślono"}
                  </Typography>
                }
              />
              <ListItemText
                primary="Górna granica"
                secondary={
                  <Typography className={classes.listText}>
                    {config.upperTreeDensityRate.trim().length > 0
                      ? config.upperTreeDensityRate
                      : "Nieokreślono"}
                  </Typography>
                }
              />
              <DeleteIcon
                className={classes.delete}
                onClick={() => {
                  let filtered = configs.filter((e, i) => {
                    if (i === idx) return false;
                    else return true;
                  });
                  setConfigs(filtered);
                }}
              />
            </ListItem>
            <Divider />
          </List>
        ))}
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={switched}
                onChange={handleSwitch}
                color="primary"
              />
            }
            label="Określ typ zbioru"
          />
        </Grid>
        {switched && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={manualHarvesting}
                  onChange={handleManualHarvestingSwitch}
                  color="primary"
                />
              }
              label="Zbiory ręczne"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={combineHarvesting}
                  onChange={handleCombineHarvestingSwitch}
                  color="primary"
                />
              }
              label="Zbiory maszynowe"
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Wpisz zalecaną ilość uli"
          InputLabelProps={{ classes: { root: classes.label } }}
          placeholder="1"
          variant="outlined"
          fullWidth
          required
          onChange={handleChange("suggestedHivesCount")}
          error={errorHives}
          errorMsg={errorMsg}
        />
      </Grid>
      <Grid item>
        <Typography variant="h6">Ilość drzew</Typography>
        <Typography variant="subtitle1">
          (pozostaw puste, jeśli któraś z granic nie istnieje)
        </Typography>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        spacing={1}
        justifyContent="space-around"
      >
        <Grid item xs={12} sm={5}>
          <TextField
            className={classes.area}
            label="Dolna granica"
            InputLabelProps={{ classes: { root: classes.label } }}
            variant="outlined"
            onChange={(e) => setLowerDensityRate(e.target.value)}
            error={errorLower}
            errorMsg={errorMsg}
          />
        </Grid>
        <Hidden xsDown>
          <Grid item xs={2}>
            <Typography variant="body1">-</Typography>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={5}>
          <TextField
            className={classes.area}
            label="Górna granica"
            InputLabelProps={{ classes: { root: classes.label } }}
            variant="outlined"
            onChange={handleChange("upperDensityRate")}
            error={errorUpper}
            errorMsg={errorMsg}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.buttonBox}>
          <CustomButton
            variant={BUTTON_VARIANTS.GREEN}
            size={BUTTON_SIZES.LARGE}
            clickHandler={addConfig}
            text="Dodaj konfigurację"
          />
        </Box>
        {noConfigsError && (
          <Typography className={classes.error} style={{ marginTop: "1%" }}>
            Proszę dodać przynajmniej jedną konfigurację
          </Typography>
        )}
      </Grid>
    </CommonModal>
  );
};

export default AddCultivationTypeWindow;
