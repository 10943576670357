import React, { useState } from "react";
import { Grid, makeStyles, Box } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import StyledFormTextField from "../../common/textFields/StyledFormTextField";
import CommonModal from "../../common/modals/CommonModal";

import styles from "./UserModal.styles";
import useForm from "../../../hooks/useForm";
import { users } from "../../../utils/newApi";
import { userTypes } from "../../../constants/usersConstants";
import {
  SERVER_ERROR_MESSAGES,
  MODAL_TYPE,
  COMMON_MODAL_TEXTS,
} from "../../../constants/dictionary";

const useStyles = makeStyles(styles);

const NewUserWindow = ({ isOpen, close, handleError }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [userType, setUserType] = useState({});
  const { handleChange, values } = useForm({
    password: "",
    username: "",
    email: "",
  });

  const submit = (e) => {
    e.preventDefault();

    let isAdmin = userType === userTypes[0];

    const payload = {
      email: values.email,
      isAdmin: isAdmin,
      password: values.password,
      username: values.username,
    };

    users.usersInfo
      .add(payload)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_ADD_USER);
      });
  };

  return (
    <CommonModal
      open={isOpen}
      toggleOpen={close}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.ADD_USER_TITLE}
      type={MODAL_TYPE.ADD}
    >
      <Grid container justify="center" spacing={4}>
        <Grid item xs={12}>
          <Box className={classes.box}>
            <StyledFormTextField
              InputLabelProps={{ classes: { root: classes.label } }}
              fullWidth={true}
              name="username"
              label="Login"
              variant="outlined"
              required
              shrink="true"
              onChange={handleChange("username")}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.box}>
            <StyledFormTextField
              InputLabelProps={{ classes: { root: classes.label } }}
              fullWidth={true}
              name="email"
              label="Email"
              variant="outlined"
              required
              shrink="true"
              onChange={handleChange("email")}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.box}>
            <StyledFormTextField
              InputLabelProps={{ classes: { root: classes.label } }}
              fullWidth={true}
              name="password"
              label="Hasło"
              variant="outlined"
              type="password"
              required
              shrink="true"
              onChange={handleChange("password")}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.box}>
            <Autocomplete
              options={userTypes}
              onChange={(event, newValue) => {
                setUserType(newValue);
              }}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <StyledFormTextField
                  {...params}
                  InputLabelProps={{ classes: { root: classes.label } }}
                  fullWidth={true}
                  label="Typ użytkownika"
                  variant="outlined"
                  shrink="true"
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default NewUserWindow;
