import React, { useEffect, useState } from "react";
import { ThemeProvider, makeStyles, Grid } from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../components/common/pagination/TablePaginationActions";
import { shop } from "../../utils/api";
import { AvailabilityTable } from "./AvailabilityTable";
import { theme } from "./Availability.theme";
import styles from "./Availability.styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListHeader from "../../components/common/ListHeader";

import { ERROR_MESSAGES } from "../../constants/dictionary";

const useStyles = makeStyles(styles);

export const Availability = () => {
  const classes = useStyles();
  const [articles, setArticles] = useState([]);
  const [articlesCopy, setArticlesCopy] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    shop.articles
      .all()
      .then((products) => {
        let origin = new URL(products.config.url).origin;
        products.data.articles.map((article) => {
          let repairedImages = [];
          article.images.map((image) => {
            let pathname = new URL(image).pathname;
            repairedImages.push(origin + pathname);
          });
          article.images = repairedImages;
        });
        const sorted = products.data.articles
          ? products.data.articles.sort((a, b) => b.id - a.id)
          : [];
        setArticles(sorted);
        setArticlesCopy(sorted);
        setIsLoaded(true);
      })
      .catch((err) => {
        setError(true);
      });
  }, []);

  const handleChange = (e) => {
    if (e.target.value !== "") {
      const res = articlesCopy.filter((article) => {
        return (
          article.name &&
          article.name.toLowerCase().startsWith(e.target.value.toLowerCase())
        );
      });
      setArticles(res);
      setPage(0);
    } else {
      setArticles(articlesCopy);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <Grid container>
          <ListHeader
            variant="title-search"
            title="Stany Magazynowe"
            handleChange={(e) => handleChange(e)}
            inputLabelProps={{ classes: { root: classes.label } }}
            fullWidth={matches}
            inputLabel="Szukaj produktu"
          />
          <Grid container>
            <Grid item xs={12} align="center">
              <AvailabilityTable
                products={articles}
                page={page}
                rowsPerPage={rowsPerPage}
              />
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 50]}
                component="div"
                count={articles.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
                classes={{
                  toolbar: classes.toolbar,
                  caption: classes.caption,
                }}
                labelRowsPerPage={"Ilość wierszy na stronę"}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}–${to} z ${
                    count !== -1 ? count : `więcej niż ${to}`
                  }`;
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={ERROR_MESSAGES.COULD_NOT_CONNECT_TO_SERVER}
        close={() => setError(false)}
      />
    </ThemeProvider>
  );
};
