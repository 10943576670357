import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";

import colors from "../../../constants/colors";

const DownloadButton = withStyles({
  root: {
    background: colors.oxfordBlue,
    borderRadius: 50,
    border: 0,
    color: colors.white,
    height: 50,
    width: "80%",
    padding: "0 30px",
    "font-family": "Montserrat",
    "font-size": "12px",
    "font-weight": "bold",
    "&:hover": {
      background: colors.oxfordBlue,
    },
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

export default DownloadButton;
