import colors from "../../constants/colors";

const styles = () => {
  return {
    mainBox: {
      marginTop: "5%",
    },
    box: {
      marginLeft: "5%",
      marginRight: "5%",
      marginBottom: "5%",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      "font-weight": "bold",
      "font-size": "12px",
      "font-family": "Montserrat",
      color: colors.black,
      maxWidth: "250px",
      marginLeft: 4,
      marginRight: 4,
    },
  };
};

export default styles;
