import { useEffect } from "react";
import styles from "./Dashboard.styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Toolbar } from "@material-ui/core";
import NavBar from "../../components/Dashboard/NavBar";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import Categories from "../../components/Categories/Categories";
import OrderView from "../../components/Orders/OrderView";
import Products from "../Products/ProductsScreen";
import ProductInfo from "../ProductInfoScreen/ProductInfo";
import { Availability } from "../Availability/Availability";
import EventsScreen from "../Events/EventsScreen";
import EventInfoScreen from "../EventInfoScreen/EventInfoScreen";
import Discounts from "../Discounts/Discounts";
import AdsScreen from "../Ads/AdsScreen";
import AdInfoScreen from "../AdInfoScreen/AdInfoScreen";
import QRScreen from "../QR/QRScreen";
import QRInfoScreen from "../QRInfoScreen/QRInfoScreen";
import FAQscreen from "../FAQscreen/FAQscreen";
import UserInfoScreen from "../UserInfoScreen/UserInfoScreen";
import OneUserInfoScreen from "../OneUserInfoScreen.js/OneUserInfoScreen";
import InfoScreenProductsToProtectPlants from "../InfoScreenProductsToProtectPlants/InfoScreenProductsToProtectPlants";
import ProductsToProtectPlantsScreen from "../ProductsToProtectPlantsScreen/ProductsToProtectPlantsScreen";
import Cultivation from "../Cultivation/Cultivation";
import CultivationDevelopmentStagesTable from "../Cultivation/CultivationDevelopmentStagesTable";
import PathoActiveSubstancesScreen from "../PathoActiveSub/PathoActiveSubstances";
import TypesTable from "../Cultivation/TypesTable";
import PathogensScreen from "../Pathogens/PathogensScreen";
import ActiveSubstancesScreen from "../ActiveSubstances/ActiveSubstance";
import ActiveSubstancesProductScreen from "../ActiveSubstancesProduct/ActiveSubstancesProduct";
import CocoonMarketGlobalsScreen from "../CocoonGlobals/CocoonMarketGlobalsScreen";
import ServicesScreen from "../../screens/Services/ServicesScreen";
import CocoonBoxesScreen from "../CocoonBoxesScreen/CocoonBoxesScreen";
import OrdersScreen from "../Orders/OrdersScreen";
import PushNotifications from "../PushNotifications/PushNotifications";
import WorkFieldScreen from "../../screens/WorkField/WorkField";
import CocoonAuctionsScreen from "../../screens/CocconAuctions/CocconAuctionsScreen";

import manageLocalStorage from "../../utils/manageLocalStorage";

const useStyles = makeStyles(styles);

const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    manageLocalStorage(history);
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <NavBar />
      <div className={classes.content}>
        <Toolbar />
        <div className={classes.paperContainer}>
          <Paper className={classes.paper}>
            <div className={classes.paperContent}>
              <Switch>
                <Route exact path="/dashboard/products">
                  <Products />
                </Route>
                <Route exact path="/dashboard/product/:id">
                  <ProductInfo />
                </Route>
                <Route exact path="/dashboard/ordersList">
                  <OrdersScreen />
                </Route>
                <Route exact path="/dashboard/orderInfo/:id">
                  <OrderView />
                </Route>
                <Route exact path="/dashboard/availability">
                  <Availability />
                </Route>
                <Route exact path="/dashboard/categories">
                  <Categories />
                </Route>
                <Route exact path="/dashboard/events">
                  <EventsScreen />
                </Route>
                <Route exact path="/dashboard/event/:id">
                  <EventInfoScreen />
                </Route>
                <Route exact path="/dashboard/discounts">
                  <Discounts />
                </Route>
                <Route exact path="/dashboard/ads">
                  <AdsScreen />
                </Route>
                <Route exact path="/dashboard/ad/:id">
                  <AdInfoScreen />
                </Route>
                <Route exact path="/dashboard/QR">
                  <QRScreen />
                </Route>
                <Route exact path="/dashboard/QR/:id">
                  <QRInfoScreen />
                </Route>
                <Route exact path="/dashboard/plants-protection-products">
                  <ProductsToProtectPlantsScreen />
                </Route>
                <Route exact path="/dashboard/plants-protection-product/:id">
                  <InfoScreenProductsToProtectPlants />
                </Route>
                <Route exact path="/dashboard/faq">
                  <FAQscreen />
                </Route>
                <Route exact path="/dashboard/cocoonGlobals">
                  <CocoonMarketGlobalsScreen />
                </Route>
                <Route exact path="/dashboard/users">
                  <UserInfoScreen />
                </Route>
                <Route exact path="/dashboard/user/:id">
                  <OneUserInfoScreen />
                </Route>
                <Route exact path="/dashboard/active-substances">
                  <ActiveSubstancesScreen />
                </Route>
                <Route exact path="/dashboard/active-substances/:id">
                  <ActiveSubstancesProductScreen />
                </Route>
                <Route exact path="/dashboard/cultivation">
                  <Cultivation />
                </Route>
                <Route exact path="/dashboard/cultivationtypes">
                  <TypesTable />
                </Route>
                <Route exact path="/dashboard/cultivation/:cultivationId/">
                  <CultivationDevelopmentStagesTable />
                </Route>
                <Route
                  exact
                  path="/dashboard/cultivation/:cultivationId/pathogens/:developmentId"
                >
                  <PathogensScreen />
                </Route>
                <Route
                  exact
                  path="/dashboard/cultivation/:cultivationId/pathogens/active-substances/:pathogenId"
                >
                  <PathoActiveSubstancesScreen />
                </Route>
                <Route exact path="/dashboard/push">
                  <PushNotifications />
                </Route>
                <Route exact path="/dashboard/services">
                  <ServicesScreen />
                </Route>
                <Route exact path="/dashboard/cocoonBoxes/:id">
                  <CocoonBoxesScreen />
                </Route>
                <Route exact path="/dashboard/workField">
                  <WorkFieldScreen />
                </Route>
                <Route exact path="/dashboard/cocoonAuctions">
                  <CocoonAuctionsScreen />
                </Route>
                <Route>
                  <Redirect to="/dashboard/plants-protection-products" />
                </Route>
              </Switch>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
