import React from "react";
import {
  Container,
  Typography,
  makeStyles,
  Box,
  Grid,
  ThemeProvider,
} from "@material-ui/core";
import theme from "./VerificationScreen.breakpoints";
import styles from "./VerificationScreen.styles";
import IconSrc from "../../assets/images/icon.png";
import { auth } from "../../utils/newApi";
import { useParams } from "react-router-dom";

import { SERVER_ERROR_MESSAGES } from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const VerificationScreen = () => {
  const classes = useStyles();
  const [msg, setMsg] = React.useState("...");
  const { hash } = useParams();

  React.useEffect(() => {
    window.document.title = "BeeGrow";
    auth
      .activate(hash)
      .then(() => setMsg("Weryfikacja użytkownika powiodła się!"))
      .catch((err) => {
        switch (err.response.status) {
          case 400:
            setMsg("Niepoprawny token weryfikacji.");
            break;
          default:
            setMsg(SERVER_ERROR_MESSAGES.SERVER_ERROR);
        }
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid item xs={12}>
          <Container align="center" fixed className={classes.container}>
            <Box className={classes.box}>
              <img className={classes.img} src={IconSrc} alt="icon" />
              <Typography className={classes.text}>Weryfikacja</Typography>
              <Typography variant="body1">{msg}</Typography>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default VerificationScreen;
