import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, ThemeProvider, makeStyles } from "@material-ui/core";
import theme from "../Login/LoginSreen.breakpoints";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./InfoScreenProductsToProtect.styles";
import ProductsToProtectPlantsInfoBox from "../../components/ProductsToProtectPlantsTable/roductsToProtectPlantsInfoBox";

import { SERVER_ERROR_MESSAGES } from "../../constants/dictionary";
import { plantProtectionProducts } from "../../utils/newApi";

const useStyles = makeStyles(styles);

const InfoScreenProductsToProtectPlants = () => {
  const classes = useStyles();
  const { id } = useParams();

  const [product, setProduct] = useState({});
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  useEffect(() => {
    plantProtectionProducts
      .getProductById(id)
      .then((res) => {
        setProduct(res.data);
      })
      .catch(() => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_FIND_RESOURCE);
      });
    setIsLoaded(true);
  }, [id]);

  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <Grid
          container
          justifyContent="center"
          direction="row"
          alignItems="center"
        >
          <Grid item xs={12}>
            <ProductsToProtectPlantsInfoBox
              info={product}
              handleError={handleError}
            />
          </Grid>
        </Grid>
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </ThemeProvider>
  );
};

export default InfoScreenProductsToProtectPlants;
