import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Typography,
} from "@material-ui/core";
import { displayPrice } from "../../utils/price";
import { styles } from "./DiscountTable.styles";
import { SortIcon } from "../../components/common/buttons/SortIcon";
import { format } from "date-fns";
import DeleteEditButton from "../../components/common/buttons/DeleteEditButton";

const useStyles = makeStyles(styles);

export const DiscountTable = ({
  products,
  openeditmodal,
  handledelete,
  page,
  rowsPerPage,
}) => {
  const [sortReversed, setSortReversed] = useState(1);
  const [sortFunc, setSortFunc] = useState("");
  const [arrows, setArrows] = useState({
    byName: false,
    byPrice: false,
    byDiscountPrice: false,
    byDiscountDate: false,
  });

  const classes = useStyles();

  const byName = (a, b) =>
    a.name.toUpperCase() >= b.name.toUpperCase()
      ? 1 * sortReversed
      : -1 * sortReversed;
  const byPrice = (a, b) => (a.price - b.price) * sortReversed;
  const byDiscountPrice = (a, b) =>
    (a.discountPrice - b.discountPrice) * sortReversed;
  const byDiscountDate = (a, b) =>
    new Date(a.discountEndTime) >= new Date(b.discountEndTime)
      ? 1 * sortReversed
      : -1 * sortReversed;

  const handleClick = (sortfunc) => {
    setSortFunc(sortfunc);
    let tempArrows = arrows;

    let currentlyTrue = "byName";

    Object.keys(tempArrows).forEach((key) => {
      if (tempArrows[key] === true) {
        currentlyTrue = key;
      }
    });

    Object.keys(tempArrows).forEach((key) => (tempArrows[key] = false));
    arrows[sortfunc] = true;
    setArrows(tempArrows);

    if (currentlyTrue === sortfunc) {
      setSortReversed(sortReversed * -1);
    } else {
      setSortReversed(1);
    }
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table
        className={classes.table}
        aria-label="simple table"
        stickyHeader="false"
      >
        <TableHead>
          <TableRow>
            <TableCell align="center" className={classes.initHead} />
            <TableCell align="left" className={classes.value}>
              <Button
                endIcon={
                  <SortIcon
                    isactive={arrows["byName"]}
                    asacdesc={arrows["byName"] && sortReversed === 1}
                  />
                }
                onClick={() => handleClick("byName")}
                className={classes.StyledButton}
              >
                <Typography>NAZWA PRODUKTU</Typography>
              </Button>
            </TableCell>
            <TableCell align="left" className={classes.value}>
              <Button
                endIcon={
                  <SortIcon
                    isactive={arrows["byPrice"]}
                    asacdesc={arrows["byPrice"] && sortReversed === 1}
                  />
                }
                onClick={() => handleClick("byPrice")}
                className={classes.StyledButton}
              >
                <Typography>CENA</Typography>
              </Button>
            </TableCell>
            <TableCell align="left" className={classes.value}>
              <Button
                endIcon={
                  <SortIcon
                    isactive={arrows["byDiscountPrice"]}
                    asacdesc={arrows["byDiscountPrice"] && sortReversed === 1}
                  />
                }
                onClick={() => handleClick("byDiscountPrice")}
                className={classes.StyledButton}
              >
                <Typography>PO RABACIE</Typography>
              </Button>
            </TableCell>

            <TableCell align="left" className={classes.value}>
              <Button
                endIcon={
                  <SortIcon
                    isactive={arrows["byDiscountDate"]}
                    asacdesc={arrows["byDiscountDate"] && sortReversed === 1}
                  />
                }
                onClick={() => handleClick("byDiscountDate")}
                classes={{ label: classes.styledButton }}
              >
                <Typography>OBOWIĄZUJE DO</Typography>
              </Button>
            </TableCell>
            <TableCell className={classes.buttons_v} />
          </TableRow>
        </TableHead>
        <TableBody>
          {products
            .sort(
              {
                byName: byName,
                byPrice: byPrice,
                byDiscountPrice: byDiscountPrice,
                byDiscountDate: byDiscountDate,
              }[sortFunc]
            )
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((product) => (
              <TableRow key={product.id} className={classes.row}>
                <TableCell align="left">
                  <Avatar
                    alt=""
                    src={product.images ? product.images[0] : ""}
                    className={classes.avatar}
                  />
                </TableCell>

                <TableCell align="left">
                  <Typography className={classes.cell}>
                    {product.name}
                  </Typography>
                </TableCell>

                <TableCell className={classes.cell} align="left">
                  {`${displayPrice(product.price)} zł`}
                </TableCell>

                <TableCell className={classes.cell} align="left">
                  {`${displayPrice(product.discountPrice)} zł`}
                </TableCell>
                <TableCell className={classes.cell} align="left">
                  {`${format(
                    new Date(product.discountEndTime),
                    "dd/MM/yyyy HH:mm"
                  )}`}
                </TableCell>

                <DeleteEditButton
                  mobile={false}
                  editClickHandler={() => openeditmodal(product)}
                  deleteClickHandler={() => handledelete(product)}
                />
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
