import {
  Container,
  Grid,
  Modal,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  validatePrice,
  changePriceToInt,
  displayPrice,
} from "../../utils/price";
import styles from "./EditDiscountModal.styles";
import DatePickers from "./DatePickers";
import { shop } from "../../utils/api";
import StyledFormTextField from "../../components/common/textFields/StyledFormTextField";

import {
  SERVER_ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
} from "../../constants/dictionary";
import CustomButton from "../../components/common/buttons/CustomButton";

const useStyles = makeStyles(styles);

const EditDiscountModal = (props) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [displayedPrice, setDisplayedPrice] = useState();
  const [discountEndTime, setDiscountEndTime] = useState(new Date());
  const [discountStartTime, setDiscountStartTime] = useState(new Date());

  React.useEffect(() => {
    setDisplayedPrice(displayPrice(props.item.discountPrice));
    setDiscountEndTime(new Date(props.discountEndTime));
    setDiscountStartTime(new Date(props.discountStartTime));
  }, [props]);

  const modalSubmitAction = (item) => {
    if (new Date(discountStartTime) >= new Date(discountEndTime)) {
      setError(true);
      return;
    }

    let payload = item;
    payload.discountPrice = displayedPrice;
    payload.discountEndTime = new Date(discountEndTime).toISOString();
    payload.discountStartTime = new Date(discountStartTime).toISOString();

    if (validatePrice(payload.discountPrice) === false) {
      setError(true);
      return;
    }

    payload.discountPrice = changePriceToInt(displayedPrice);
    shop.articles
      .edit(item.id, payload)
      .then(() => window.location.reload())
      .catch((err) => {
        props.handleError(SERVER_ERROR_MESSAGES.COULD_NOT_EDIT_DISCOUNT);
      });
  };
  return (
    <Modal open={props.open} onClose={props.handleClose}>
      <Container className={classes.modalBody}>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.modalGrid}
          spacing={3}
        >
          <Grid item>
            <Typography variant="h4">{props.modalname}</Typography>
          </Grid>

          <Grid
            item
            container
            className={classes.addNewDiscountGrid}
            direction="row"
            spacing={2}
          >
            <Grid item xs={12} sm={12}>
              <Typography>Produkt: {props.item.name}</Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <StyledFormTextField
                label="Cena promocyjna"
                error={error}
                variant="outlined"
                value={displayedPrice}
                onChange={(event) => setDisplayedPrice(event.target.value)}
                fullWidth
              />
            </Grid>

            <Grid container item xs={12} sm={12}>
              <DatePickers
                discountEndTime={discountEndTime}
                setDiscountEndTime={setDiscountEndTime}
                discountStartTime={discountStartTime}
                setDiscountStartTime={setDiscountStartTime}
                error={error}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={5}
          >
            <Grid item>
              <CustomButton
                variant={BUTTON_VARIANTS.GREEN}
                size={BUTTON_SIZES.LARGE}
                clickHandler={() => {
                  modalSubmitAction(props.item);
                }}
                text="Edytuj"
              />
            </Grid>

            <Grid item>
              <CustomButton
                variant={BUTTON_VARIANTS.RED}
                size={BUTTON_SIZES.LARGE}
                clickHandler={props.handleClose}
                text="Anuluj"
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Modal>
  );
};

export default EditDiscountModal;
