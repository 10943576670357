import axios from "axios";
import tokenUtils from "./tokenUtils";

const API_URL = process.env.REACT_APP_API_URL || "https://dev.api.beegrow.pl";
const getHeaders = () => ({
  headers: {
    authorization: !!tokenUtils.getToken() ? tokenUtils.getToken().token : "",
  },
});

const getHeadersQR = () => ({
  headers: {
    authorization: !!tokenUtils.getToken() ? tokenUtils.getToken().token : "",
  },
  responseType: "arraybuffer",
});

class Request {
  delete = (url) => axios.delete(`${API_URL}${url}`, getHeaders());
  get = (url) => axios.get(`${API_URL}${url}`, getHeaders());
  getSpecial = (url) => axios.get(`${API_URL}${url}`, getHeadersQR());
  put = (url, body) => axios.put(`${API_URL}${url}`, body, getHeaders());
  post = (url, body) => axios.post(`${API_URL}${url}`, body, getHeaders());
}

const requests = new Request();

export const auth = {
  login: (login, password) =>
    requests.post("/login", { login: login, password: password }),
  register: (payload) => requests.post("/register", payload),
  requestReset: (email) => requests.post("/reset", { email: email }),
  getReset: (hash) => requests.get(`/reset/${hash}`),
  sendReset: (hash, password) =>
    requests.post(`/reset/${hash}`, { password: password }),
  verify: (hash) => requests.get(`/verify/${hash}`),
};

export const shop = {
  categories: {
    all: () => requests.get("/shop/categories"),
    add: (payload) => requests.post("/shop/category", payload),
    edit: (id, payload) => requests.put(`/shop/category/${id}`, payload),
    delete: (id) => requests.delete(`/shop/category/${id}`),
  },
  articles: {
    all: () => requests.get("/shop/articles"),
    byCategory: (id) => requests.get(`/shop/category/${id}`),
    discounts: () => requests.get("/shop/articles/discounts"),
    discountsByCategory: (id) => requests.get(`/shop/category/${id}/discounts`),
    add: (payload) => requests.post("/shop/article", payload),
    edit: (id, payload) => requests.put(`/shop/article/${id}`, payload),
    delete: (id) => requests.delete(`/shop/article/${id}`),
    one: (id) => requests.get(`/shop/article/${id}`),
  },
  files: {
    send: (payload) => requests.post("/assets", payload),
  },
  events: {
    all: () => requests.get("/events"),
    add: (payload) => requests.post("/event", payload),
    edit: (id, payload) => requests.put(`/event/${id}`, payload),
    delete: (id) => requests.delete(`/event/${id}`),
    one: (id) => requests.get(`/event/${id}`),
  },
  ads: {
    all: () => requests.get("/ads"),
    add: (payload) => requests.post("/ad", payload),
    edit: (id, payload) => requests.put(`/ad/${id}`, payload),
    delete: (id) => requests.delete(`/ad/${id}`),
    one: (id) => requests.get(`/ad/${id}`),
  },
  orders: {
    all: () => requests.get("/orders"),
    add: (payload) => requests.post("/order", payload),
    edit: (id, payload) => requests.put(`/order/${id}`, payload),
    delete: (id) => requests.delete(`/order/${id}`),
    deleteItem: (id) => requests.delete(`/order/item/${id}`),
    addItem: (id, payload) => requests.post(`/order/${id}/item`, payload),
    editItem: (orderId, id, payload) =>
      requests.put(`/order/${orderId}/item/${id}`, payload),
    one: (id) => requests.get(`/order/${id}`),
    markAsSent: (id) => requests.post(`/order/${id}/markAsSent`),
  },
};

export const cultivations = {
  types: {
    all: () => requests.get("/cultivation/types"),
    add: (payload) => requests.post("/cultivation/type", payload),
    one: (id) => requests.get(`/cultivation/type/${id}`),
    edit: (id, payload) => requests.put(`/cultivation/type/${id}`, payload),
    delete: (id) => requests.delete(`/cultivation/type/${id}`),
    distinct: () => requests.get("/cultivation/distinctTypes"),
  },
  products: {
    all: () => requests.get("/cultivation/products"),
    add: (payload) => requests.post("/cultivation/product", payload),
    one: (id) => requests.get(`/cultivation/product/${id}`),
    edit: (id, payload) => requests.put(`/cultivation/product/${id}`, payload),
    delete: (id) => requests.delete(`/cultivation/product/${id}`),
    byStageId: (id) => requests.get(`/cultivation/product/byStageId/${id}`),
  },
  stages: {
    all: () => requests.get("/cultivation/stages"),
    add: (payload) => requests.post("/cultivation/stage", payload),
    one: (id) => requests.get(`/cultivation/stage/${id}`),
    edit: (id, payload) => requests.put(`/cultivation/stage/${id}`, payload),
    delete: (id) => requests.delete(`/cultivation/stage/${id}`),
    byTypeName: (name) => requests.get(`/cultivation/stage/byTypeName/${name}`),
    addForTypeName: (name, payload) =>
      requests.post(`/cultivation/stage/byTypeName/${name}`, payload),
    takeByDevName: (devName, cultName) =>
      requests.get(`/cultivation/stage/byName/${devName}/${cultName}`),
  },
  mixes: {
    all: () => requests.get("/cultivation/product/mixes"),
    add: (payload) => requests.post("/cultivation/product/mix", payload),
    edit: (id, payload) =>
      requests.put(`/cultivation/product/mix/${id}`, payload),
    delete: (id) => requests.delete(`/cultivation/product/mix/${id}`),
  },
  activeSubstances: {
    all: () => requests.get("/cultivation/activeSubstances"),
    add: (payload) => requests.post("/cultivation/activeSubstance", payload),
    addToPathogen: (cultName, devName, pathoName, payload) =>
      requests.post(
        `/cultivation/activeSubstance/${cultName}/${devName}/${pathoName}`,
        payload
      ),
    deleteFromPathogen: (subId, cultName, devName, pathoName) =>
      requests.delete(
        `/cultivation/activeSubstance/${subId}/${cultName}/${devName}/${pathoName}`
      ),
    edit: (id, payload) =>
      requests.put(`/cultivation/activeSubstance/${id}`, payload),
    delete: (id) => requests.delete(`/cultivation/activeSubstance/${id}`),
    one: (id) => requests.get(`/cultivation/activeSubstance/${id}`),
  },
  pathogens: {
    all: (id) => requests.get(`/cultivation/pathogens/forDevStageId/${id}`),
    add: (cultName, devName, payload) =>
      requests.post(
        `/cultivation/pathogens/forName/${cultName}/${devName}`,
        payload
      ),
    delete: (cultName, stageName, pathoName) =>
      requests.delete(
        `/cultivation/pathogens/forName/${cultName}/${stageName}/${pathoName}`
      ),
    one: (cultType, devStage, pathoName) =>
      requests.get(
        `/cultivation/pathogens/${cultType}/${devStage}/${pathoName}`
      ),
  },
};

export const users = {
  faq: {
    all: () => requests.get("/faqs"),
    add: (payload) => requests.post("/faq", payload),
    one: (id) => requests.get(`/faq/${id}`),
    edit: (id, payload) => requests.put(`/faq/${id}`, payload),
    delete: (id) => requests.delete(`/faq/${id}`),
  },
  usersInfo: {
    all: () => requests.get("/users"),
    add: (payload) => requests.post("/user", payload),
    one: (id) => requests.get(`/user/${id}`),
    edit: (id, payload) => requests.put(`/user/${id}`, payload),
    block: (id) => requests.put(`/user/block/${id}`),
    unblock: (id) => requests.put(`/user/unblock/${id}`),
    withThermometers: () => requests.get("/users/withThermometers"),
    withHives: () => requests.get("/users/withHives"),
    withCultivationType: (type) =>
      requests.get(`/users/withCultivationType/${type}`),
  },
  whoami: () => requests.get("/user/whoami"),
};

export const qrcodes = {
  qr: {
    all: () => requests.get("/qrcodes"),
    add: () => requests.post("/qrcode"),
    one: (id) => requests.get(`/qrcode/${id}`),
    edit: (id, payload) => requests.put(`/qrcode/${id}`, payload),
    delete: (id) => requests.delete(`/qrcode/${id}`),
    givePicture: (id) => requests.getSpecial(`/qrcode/png/${id}`),
  },
};

export const services = {
  all: () => requests.get("/cocoonBox/maintenances"),
  one: (id) => requests.get(`/cocoonBox/maintenance/${id}`),
  add: (retailId, payload) =>
    requests.post(`/cocoonBox/maintenance/forRetail/${retailId}`, payload),
  delete: (id) => requests.delete(`/cocoonBox/maintenance/${id}`),
};

export const globalBoxSettings = {
  takeSettings: () => requests.get(`/globalSettings/current`),
  changeSettings: (payload) => requests.post(`/globalSettings`, payload),
  takeAllGlobals: () => requests.get(`/globalSettings`),
};

export const push = {
  send: (payload) => requests.post("/push", payload),
};

export const fieldWork = {
  all: () => requests.get("/cultivation/work/types"),
  add: (payload) => requests.post("/cultivation/work/type", payload),
  delete: (id) => requests.delete(`/cultivation/work/type/${id}`),
};

export const auctions = {
  all: () => requests.get("/cocoonBox/auctions"),
};
