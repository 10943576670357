import React from "react";
import { Typography, Grid, makeStyles, Box } from "@material-ui/core";

import CommonModal from "../../common/modals/CommonModal";
import StyledFormTextField from "../../common/textFields/StyledFormTextField";

import styles from "./PathogensModal.styles";
import useForm from "../../../hooks/useForm";
import { cultivations } from "../../../utils/newApi";
import {
  SERVER_ERROR_MESSAGES,
  MODAL_TYPE,
  COMMON_MODAL_TEXTS,
} from "../../../constants/dictionary";

const useStyles = makeStyles(styles);

const AddNewPathogenWindow = ({
  isOpen,
  close,
  handleError,
  developmentId,
}) => {
  const classes = useStyles();
  const [emptyField, setEmptyField] = React.useState(false);
  const { handleChange, values } = useForm({
    pathogen: "",
  });

  const submit = (e) => {
    e.preventDefault();

    if (values.pathogen === "") {
      setEmptyField(true);
      return;
    }

    const payload = {
      name: values.pathogen,
    };

    cultivations.pathogens
      .add(developmentId, payload)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_ADD_PATHOGEN);
      });
  };

  return (
    <CommonModal
      open={isOpen}
      toggleOpen={close}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.ADD_PATHOGEN_TITLE}
      type={MODAL_TYPE.ADD}
    >
      <Grid item xs={12}>
        <Box className={classes.box}>
          <StyledFormTextField
            InputLabelProps={{ classes: { root: classes.label } }}
            fullWidth={true}
            name="pathogen"
            label="Nazwa"
            variant="outlined"
            required
            shrink="true"
            onChange={handleChange("pathogen")}
          />
        </Box>
      </Grid>
      {emptyField && (
        <Grid item xs={12} align="center">
          <Typography className={classes.error}>
            Nie podano nazwy patogenu
          </Typography>
        </Grid>
      )}
    </CommonModal>
  );
};

export default AddNewPathogenWindow;
