import React, { useEffect, useState } from "react";
import { Typography, Grid, makeStyles, Box } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import StyledFormTextField from "../../common/textFields/StyledFormTextField";
import CommonModal from "../../common/modals/CommonModal";

import { globalBoxSettings } from "../../../utils/newApi";
import { validatePrice } from "../../../utils/price";
import styles from "./GlobalCocoonBoxEditWindow.styles";
import {
  SERVER_ERROR_MESSAGES,
  ERROR_MESSAGES,
  MODAL_TYPE,
  COMMON_MODAL_TEXTS,
} from "../../../constants/dictionary";
import { formatDateToyyyyddMM } from "../../../utils/formatter";

const useStyles = makeStyles(styles);

const GlobalCocoonBoxEditWindow = ({ isOpen, close, handleError, data }) => {
  const classes = useStyles();

  const [hivePrice, setHivePrice] = useState(0);
  const [error, setError] = useState("");
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [freeDeliveryPrice, setFreeDeliveryPrice] = useState(0);
  const [thermometerPrice, setThermometerPrice] = useState(0);
  const [boxPrice, setBoxPrice] = useState(0);
  const [maxHivesAmount, setMaxHivesAmount] = useState(0);
  const [hiveAndCocoonBoxPrice, setHiveAndCocoonBoxPrice] = useState(0);

  useEffect(() => {
    if (data) {
      setHivePrice(data.hivePrice);
      setFreeDeliveryPrice(data.freeDeliveryMinimumOrderPrice);
      setDeliveryPrice(data.courierPrice);
      setThermometerPrice(data.thermometerPrice);
      setBoxPrice(data.cocoonBoxPrice);
      setMaxHivesAmount(data.hiveMaxShipmentSize);
      setHiveAndCocoonBoxPrice(data.hiveAndCocoonPrice);
    }
  }, [data, isOpen]);

  const submit = (e) => {
    if (
      validatePrice(hivePrice) === false ||
      validatePrice(thermometerPrice) === false ||
      validatePrice(freeDeliveryPrice) === false ||
      validatePrice(deliveryPrice) === false ||
      validatePrice(hiveAndCocoonBoxPrice) === false
    ) {
      setError(ERROR_MESSAGES.INCORRECT_PRICES);
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }

    let dataToSend = {
      cocoonBoxPrice: Number(boxPrice),
      courierPrice: Number(deliveryPrice),
      freeDeliveryMinimumOrderPrice: Number(freeDeliveryPrice),
      hiveMaxShipmentSize: Number(maxHivesAmount),
      hivePrice: Number(hivePrice),
      thermometerPrice: Number(thermometerPrice),
      hiveAndCocoonBoxPrice: Number(hiveAndCocoonBoxPrice),
    };

    globalBoxSettings
      .editSettings(dataToSend)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_CHANGE_SETTINGS);
      });
  };

  return (
    <CommonModal
      open={isOpen}
      toggleOpen={close}
      handleConfirmation={submit}
      title={COMMON_MODAL_TEXTS.GLOBAL_SETTINGS_TITLE}
      type={MODAL_TYPE.EDIT}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container direction="row" justify="center">
          <Grid item xs={12} md={4}>
            <Box className={classes.box2}>
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                fullWidth={true}
                label="Cena za ul"
                variant="outlined"
                required
                value={hivePrice}
                shrink="true"
                onChange={(e) => setHivePrice(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.box2}>
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                fullWidth={true}
                label="Cena za ul z kokonami"
                variant="outlined"
                required
                value={hiveAndCocoonBoxPrice}
                shrink="true"
                onChange={(e) => setHiveAndCocoonBoxPrice(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.box2}>
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                fullWidth={true}
                label="Cena dostawy"
                variant="outlined"
                required
                value={deliveryPrice}
                shrink="true"
                onChange={(e) => setDeliveryPrice(e.target.value)}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center">
          <Grid item xs={12} md={4}>
            <Box className={classes.box2}>
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                fullWidth={true}
                label="Darmowa dostawa od"
                variant="outlined"
                required
                value={freeDeliveryPrice}
                shrink="true"
                onChange={(e) => setFreeDeliveryPrice(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.box2}>
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                fullWidth={true}
                label="Cena za termometr"
                variant="outlined"
                required
                value={thermometerPrice}
                shrink="true"
                onChange={(e) => setThermometerPrice(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.box2}>
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                fullWidth={true}
                label="Cena pudełka"
                variant="outlined"
                required
                value={boxPrice}
                shrink="true"
                onChange={(e) => setBoxPrice(e.target.value)}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box className={classes.box2}>
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                fullWidth={true}
                label="Maksymalna ilość uli"
                variant="outlined"
                required
                value={maxHivesAmount}
                shrink="true"
                onChange={(e) => setMaxHivesAmount(e.target.value)}
              />
            </Box>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      <Grid item xs={12} align="center">
        <Typography className={classes.error}>{error}</Typography>
      </Grid>
    </CommonModal>
  );
};

export default GlobalCocoonBoxEditWindow;
