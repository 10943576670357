const fields = [
  {
    id: 1,
    name: "Nazwa"
  },
  {
    id: 2,
    name: "Wyświetlenia"
  },
  {
    id: 3,
    name: "Od"
  },
  {
    id: 4,
    name: "Do"
  },
  {
    id: 5,
    name: "Aktywna"
  }
];

export default fields;
