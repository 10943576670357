import React, { useState, useEffect } from "react";
import { Grid, makeStyles, ThemeProvider } from "@material-ui/core";
import theme from "../Login/LoginSreen.breakpoints";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styles from "./WorkField.styles";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import FieldWorkTable from "../../components/FieldWork/FieldWorkTable";
import NewWorkModalWindow from "../../components/ModalWindows/WorkField/NewWorkModal";
import { fieldWork } from "../../utils/newApi";
import ListHeader from "../../components/common/ListHeader";

import { SERVER_ERROR_MESSAGES } from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const WorkFieldScreen = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [works, setWorks] = useState([]);
  const [worksCopy, setWorksCopy] = useState([]);
  const [errorText, setErrorText] = useState("");

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  useEffect(() => {
    fieldWork
      .all()
      .then((res) => {
        const sorted = res.data ? res.data.sort((a, b) => b.id - a.id) : [];
        setWorks(sorted);
        setWorksCopy(sorted);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_WORKS);
      });
    setIsLoaded(true);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <Grid container spacing={matches ? 3 : 0}>
          <ListHeader
            variant="title-add"
            title="Lista prac polowych"
            handleAdd={() => setOpen(true)}
          />
          <Grid container>
            <Grid item xs={12} align="center">
              <FieldWorkTable
                handleError={handleError}
                works={works}
                setWorks={setWorks}
                worksCopy={worksCopy}
              />
            </Grid>
          </Grid>
          <NewWorkModalWindow
            isOpen={open}
            close={() => setOpen(false)}
            handleError={handleError}
          />
        </Grid>
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
    </ThemeProvider>
  );
};

export default WorkFieldScreen;
