import colors from "../../../constants/colors";

const styles = (theme) => {
  return {
    box2: {
      marginLeft: "20%",
      marginRight: "20%",
      marginTop: "5%",
      marginBottom: "5%",
    },
    box: {
      marginLeft: "5%",
      marginRight: "5%",
    },
    error: {
      color: colors.carmine,
      "font-weight": "bold",
      fontSize: "15px",
      "font-family": "Montserrat",
    },
    label: {
      "white-space": "nowrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: 15,
      },
    },
  };
};

export default styles;
