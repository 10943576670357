import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Grid,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import { cultivations } from "../../utils/newApi";
import theme from "../../components/theme/theme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styles from "./PathogensScreen.styles";
import PathogensTable from "../../components/Pathogens/PathogensTable.";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import StyledFormTextField from "../../components/common/textFields/StyledFormTextField";
import AddNewPathogenWindow from "../../components/ModalWindows/Pythogens/AddNewPathogen";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../components/common/pagination/TablePaginationActions";
import ListHeader from "../../components/common/ListHeader";

import { SERVER_ERROR_MESSAGES } from "../../constants/dictionary";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(styles);

const PathogensScreen = () => {
  const [pathogens, setPathogens] = useState([]);
  const [pathogensCopy, setPathogensCopy] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { developmentId } = useParams();

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  useEffect(() => {
    cultivations.pathogens
      .all(developmentId)
      .then((res) => {
        const sorted = res.data ? res.data.sort((a, b) => b.id - a.id) : [];
        setPathogens(sorted);
        setPathogensCopy(sorted);
        setIsLoaded(true);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.SERVER_ERROR);
      });
  }, []);

  const handleChange = (e) => {
    if (e.target.value !== "") {
      const res = pathogensCopy.filter((pato) => {
        return (
          pato.name &&
          pato.name.toLowerCase().startsWith(e.target.value.toLowerCase())
        );
      });
      setPathogens(res);
      setPage(0);
    } else {
      setPathogens(pathogensCopy);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <Grid container>
          <ListHeader
            variant="title-add-search"
            title="Lista patogenów"
            handleAdd={() => setOpen(true)}
            searchComponent={
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                name="name"
                fullWidth={matches}
                label="Szukaj patogenu"
                variant="outlined"
                shrink="true"
                onChange={(e) => handleChange(e)}
              />
            }
          />
          <Grid container>
            <Grid item xs={12} align="center">
              <PathogensTable
                listOfPathogens={pathogens}
                setPathogens={setPathogens}
                handleError={handleError}
                page={page}
                rowsPerPage={rowsPerPage}
              />
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 50]}
                component="div"
                count={pathogens.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                classes={{
                  toolbar: classes.toolbar,
                  caption: classes.caption,
                }}
                labelRowsPerPage={"Ilość wierszy na stronę"}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}–${to} z ${
                    count !== -1 ? count : `więcej niż ${to}`
                  }`;
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
      <AddNewPathogenWindow
        isOpen={open}
        close={() => setOpen(false)}
        handleError={handleError}
        developmentId={developmentId}
      />
    </ThemeProvider>
  );
};

export default PathogensScreen;
