import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Grid,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core";
import theme from "../../components/theme/theme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styles from "./ActiveSubstance.styles";
import { cultivations } from "../../utils/newApi";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import StyledFormTextField from "../../components/common/textFields/StyledFormTextField";
import NewSubstanceWindow from "../../components/ModalWindows/ActiveSubstances/NewSubstance";
import ActiveSubstancesTable from "../../components/ActiveSubstance/ActiveSubTable";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../components/common/pagination/TablePaginationActions";
import ListHeader from "../../components/common/ListHeader";

import { SERVER_ERROR_MESSAGES } from "../../constants/dictionary";

const useStyles = makeStyles(styles);

const ActiveSubstancesScreen = () => {
  const [substances, setSubstances] = useState([]);
  const [substancesCopy, setSubstancesCopy] = useState([]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  useEffect(() => {
    cultivations.activeSubstances
      .all()
      .then((res) => {
        const sorted = res.data ? res.data.sort((a, b) => b.id - a.id) : [];
        setSubstances(sorted);
        setSubstancesCopy(sorted);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_SUBSTANCE);
      });
    setIsLoaded(true);
  }, []);

  const handleChange = (e) => {
    if (e.target.value !== "") {
      const res = substancesCopy.filter((sub) => {
        return (
          sub.name &&
          sub.name.toLowerCase().startsWith(e.target.value.toLowerCase())
        );
      });
      setSubstances(res);
      setPage(0);
    } else {
      setSubstances(substancesCopy);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <Grid container>
          <ListHeader
            variant="title-add-search"
            title="Lista substancji czynnych"
            handleAdd={() => setOpen(true)}
            searchComponent={
              <StyledFormTextField
                InputLabelProps={{ classes: { root: classes.label } }}
                fullWidth={matches}
                label="Szukaj substancji"
                variant="outlined"
                shrink="true"
                onChange={(e) => handleChange(e)}
              />
            }
          />
          <Grid container>
            <Grid item xs={12} align="center">
              <ActiveSubstancesTable
                listOfSubstances={substances}
                setSubstances={setSubstances}
                handleError={handleError}
                page={page}
                rowsPerPage={rowsPerPage}
              />
              <TablePagination
                rowsPerPageOptions={[10, 15, 20, 50]}
                component="div"
                count={substances.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
                classes={{
                  toolbar: classes.toolbar,
                  caption: classes.caption,
                }}
                labelRowsPerPage={"Ilość wierszy na stronę"}
                labelDisplayedRows={({ from, to, count }) => {
                  return `${from}–${to} z ${
                    count !== -1 ? count : `więcej niż ${to}`
                  }`;
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
      <NewSubstanceWindow
        isOpen={open}
        close={() => setOpen(false)}
        handleError={handleError}
      />
    </ThemeProvider>
  );
};

export default ActiveSubstancesScreen;
