import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { Typography, Paper, Grid, makeStyles, Box } from "@material-ui/core";
import styles from "./AddNewSub.styles";
import StyledFormTextField from "../../common/textFields/StyledFormTextField";
import { cultivations } from "../../../utils/api";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  SERVER_ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
} from "../../../constants/dictionary";
import CustomButton from "../../common/buttons/CustomButton";

const useStyles = makeStyles(styles);

const AddSubWindow = ({ isOpen, close, handleError, pathogen }) => {
  const classes = useStyles();
  const [subArray, setSubArray] = useState([]);
  const [selectedSub, setSelectedSub] = useState({});
  const [pathogenC, setPathogenC] = useState({});

  useEffect(() => {
    cultivations.activeSubstances
      .all()
      .then((res) => {
        setSubArray(res.data.activeSubstances);
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_SUBSTANCE);
      });
  }, []);

  useEffect(() => {
    setPathogenC(pathogen);
  }, [pathogen]);

  const submit = (e) => {
    e.preventDefault();
    cultivations.activeSubstances
      .addToPathogen(
        localStorage.getItem("CultName"),
        localStorage.getItem("DevName"),
        pathogen.name,
        [{ id: selectedSub.id }]
      )
      .then((res) => {
        window.location.reload();
      })
      .catch(() => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_ADD_SUBSTANCE);
      });
  };

  return (
    <Modal open={isOpen}>
      <Paper className={classes.root}>
        <div className={classes.scroll}>
          <Grid container spacing={4} justify="center" direction="column">
            <Grid item xs={12}>
              <Typography className={classes.header}>
                Dodaj nową substancję
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <Autocomplete
                  options={subArray}
                  onChange={(e, n) => setSelectedSub(n)}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <StyledFormTextField
                      {...params}
                      InputLabelProps={{ classes: { root: classes.label } }}
                      name="name"
                      fullWidth={true}
                      label="Substancja"
                      variant="outlined"
                      shrink="true"
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <Grid container spacing={2} direction="row" justify="center">
                  <Grid item xs={6}>
                    <Box className={classes.box}>
                      <CustomButton
                        variant={BUTTON_VARIANTS.GREEN}
                        size={BUTTON_SIZES.LARGE}
                        clickHandler={submit}
                        text="Dodaj"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={classes.box}>
                      <CustomButton
                        variant={BUTTON_VARIANTS.RED}
                        size={BUTTON_SIZES.LARGE}
                        clickHandler={close}
                        text="Anuluj"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default AddSubWindow;
