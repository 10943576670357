import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import styles from "./AdInfoBox.styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { format } from "date-fns";
import EditAdWindow from "../ModalWindows/Ads/EditAdWindow";
import CommonModal from "../common/modals/CommonModal";
import { useHistory } from "react-router";
import { shop } from "../../utils/newApi";
import { getFirstFrameFromVideo } from "../../utils/ads";

import {
  SERVER_ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";
import CustomButton from "../common/buttons/CustomButton";

const useStyles = makeStyles(styles);

const AdInfoBox = ({ info, handleError }) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [img, setImg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles(styles);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const isAdActive = () => {
    const currentDate = new Date();
    if (
      currentDate >= new Date(info.startDate) &&
      currentDate <= new Date(info.endDate)
    )
      return true;
    else return false;
  };

  const deleteAd = (adId) => {
    shop.ads
      .delete(adId)
      .then((res) => {
        history.push("/dashboard/ads");
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_AD);
      });
  };

  const getAdImage = (adId) => {
    setIsLoading(true);

    shop.ads
      .media(adId)
      .then((res) => {
        if (res.headers["content-type"].includes("video")) {
          getFirstFrameFromVideo(
            `data:${res.headers["content-type"]};base64,${Buffer.from(
              res.data,
              "binary"
            ).toString("base64")}`
          ).then((res) => {
            setImg(res.toDataURL());
          });
        } else {
          setImg(
            `data:${res.headers["content-type"]};base64,${Buffer.from(
              res.data,
              "binary"
            ).toString("base64")}`
          );
        }

        setIsLoading(false);
      })
      .catch(() => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_RESOURCES);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAdImage(info.id);
  }, [info]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="rows">
        <Grid item xs={matches ? 12 : 7}>
          <Grid
            container
            spacing={2}
            justify="center"
            direction="rows"
            alignItems="center"
          >
            <Grid item xs={12} align="left">
              <Box className={classes.box2}>
                <Typography className={classes.header}>
                  {info && info.name}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} align="left">
              <Box className={classes.box2}>
                <div className={classes.imgBox}>
                  {isLoading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <img src={img} className={classes.pic} alt="BRAK" />
                  )}
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} align="left">
              <Box className={classes.box2}>
                <Typography className={classes.desc}>Opis: </Typography>
                <Typography className={classes.text}>
                  {info && info.description}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={matches ? 12 : 5} className={classes.greyBox}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            direction="row"
            alignItems="center"
          >
            <Grid item xs={12} align="center">
              <Box className={classes.headerMargin}>
                <Typography className={classes.topRightHeader}>
                  Informacje o reklamie:
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <List className={classes.rootList}>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Uprawy"
                    secondary={
                      <Typography className={classes.listText}>{`${
                        info &&
                        info.cultivationTypeDAOList.map((type) => type.name)
                      }`}</Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Wyświetlenia"
                    secondary={
                      <Typography className={classes.listText}>{`${
                        info && info.views
                      }`}</Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Start"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={
                          isAdActive() ? classes.inline : classes.disable
                        }
                      >
                        {info.startDate &&
                          format(new Date(info.startDate), "yyyy/MM/dd")}
                      </Typography>
                    }
                  />
                  <ListItemText
                    primary="Godzina"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={
                          isAdActive() ? classes.inline : classes.disable
                        }
                      >
                        {info.startDate &&
                          format(new Date(info.startDate), "HH:mm")}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary="Koniec"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={
                          isAdActive() ? classes.inline : classes.disable
                        }
                      >
                        {info.endDate &&
                          format(new Date(info.endDate), "yyyy/MM/dd")}
                      </Typography>
                    }
                  />
                  <ListItemText
                    primary="Godzina"
                    secondary={
                      <Typography
                        component="span"
                        variant="body2"
                        className={
                          isAdActive() ? classes.inline : classes.disable
                        }
                      >
                        {info.endDate &&
                          format(new Date(info.endDate), "HH:mm")}
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Grid>

            <Grid item xs={6} align="right">
              <Box className={classes.marginBox}>
                <Typography className={classes.text}>
                  Reklama aktywna
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} align="center">
              <Box className={classes.marginBox}>
                <CustomButton
                  variant={
                    isAdActive() ? BUTTON_VARIANTS.GREEN : BUTTON_VARIANTS.RED
                  }
                  size={BUTTON_SIZES.SMALL}
                  text={isAdActive() ? "Tak" : "Nie"}
                />
              </Box>
            </Grid>
            <Grid container direction="row">
              <Grid item xs={6}>
                <Box className={classes.buttonBox}>
                  <CustomButton
                    variant={BUTTON_VARIANTS.WHITE}
                    size={BUTTON_SIZES.LARGE}
                    clickHandler={() => setOpenEdit(true)}
                    text="Edytuj reklamę"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className={classes.buttonBox}>
                  <CustomButton
                    variant={BUTTON_VARIANTS.RED}
                    size={BUTTON_SIZES.LARGE}
                    clickHandler={() => setOpenConfirmation(true)}
                    text="Usuń reklamę"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <CommonModal
          open={openConfirmation}
          toggleOpen={() => setOpenConfirmation(false)}
          handleConfirmation={() => deleteAd(info.id)}
          title={COMMON_MODAL_TEXTS.AD_TITLE}
          content={COMMON_MODAL_TEXTS.AD_CONTENT}
        />
        <EditAdWindow
          isOpen={openEdit}
          close={() => setOpenEdit(false)}
          data={info}
          handleError={handleError}
        />
      </Grid>
    </div>
  );
};

export default AdInfoBox;
