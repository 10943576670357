export const displayFloat = (number) => {
  return parseFloat(number);
};

export const removeLastComma = (text) => {
  const textToChange = Array.isArray(text) ? text.join("") : text;
  const lastcommaIndex = textToChange.lastIndexOf(",");
  const stringWithoutCommaAtTheEnd = textToChange.substring(0, lastcommaIndex);

  return stringWithoutCommaAtTheEnd;
};
