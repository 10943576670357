import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { Grid, Typography, MenuItem } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import styles from "./AdsTable.styles";
import Avatar from "@material-ui/core/Avatar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import StyledCategorySelect from "../common/Selects/StyledCategorySelect";
import fields from "../../constants/adsConstants";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { useHistory } from "react-router";
import { format } from "date-fns";
import EditAdWindow from "../ModalWindows/Ads/EditAdWindow";
import { SortIcon } from "../common/buttons/SortIcon";
import CommonModal from "../common/modals/CommonModal";
import { shop } from "../../utils/newApi";
import DeleteEditButton from "../common/buttons/DeleteEditButton";
import { getFirstFrameFromVideo } from "../../utils/ads";
import CustomButton from "../common/buttons/CustomButton";

import {
  SERVER_ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";
import { sortConstants, adsSort } from "../../constants/localStorage";

const useStyles = makeStyles(styles);

const AdsTable = ({ page, rowsPerPage, ads, setAds, adsCopy, handleError }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [id, setId] = useState("");
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [infoForEdit, setInfoForEdit] = useState("");
  const [isAscSince, setIsAscSince] = useState(null);
  const [isAscTo, setIsAscTo] = useState(null);
  const [isAscN, setIsAscN] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [isActiveN, setIsActiveN] = useState(false);
  const [isActiveS, setIsActiveS] = useState(false);
  const [isActiveT, setIsActiveT] = useState(false);
  const [isActiveA, setIsActiveA] = useState(false);
  const [isActiveV, setIsActiveV] = useState(false);
  const [isAscV, setIsAscV] = useState(null);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [sortBy, setSortBy] = useState("");
  const history = useHistory();
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (ads) {
      ads.forEach((ad) => getAdImage(ad.id));
    }

    return setImages([]);
  }, [ads]);

  useEffect(() => {
    if (adsCopy.length !== 0) {
      const sortToApply = localStorage.getItem(sortConstants.ads);

      if (sortToApply) {
        switch (sortToApply) {
          case adsSort.nameA:
            sortName(true);
            break;
          case adsSort.nameD:
            sortName(false);
            break;
          case adsSort.sinceA:
            sortDateSince(true);
            break;
          case adsSort.sinceD:
            sortDateSince(false);
            break;
          case adsSort.toA:
            sortDateTo(true);
            break;
          case adsSort.toD:
            sortDateTo(false);
            break;
          case adsSort.activeA:
            sortActive(true);
            break;
          case adsSort.activeD:
            sortActive(false);
            break;
          case adsSort.viewsA:
            sortViews(true);
            break;
          case adsSort.viewsD:
            sortViews(false);
            break;
        }
      }
    }
  }, [adsCopy]);

  const getAdImage = (adId) => {
    shop.ads
      .media(adId)
      .then((res) => {
        if (res.headers["content-type"].includes("video")) {
          getFirstFrameFromVideo(
            `data:${res.headers["content-type"]};base64,${Buffer.from(
              res.data,
              "binary"
            ).toString("base64")}`
          ).then((res) => {
            setImages((prev) => [
              {
                id: adId,
                image: res.toDataURL(),
              },
              ...prev,
            ]);
          });
        } else {
          setImages((prev) => [
            {
              id: adId,
              image: `data:${res.headers["content-type"]};base64,${Buffer.from(
                res.data,
                "binary"
              ).toString("base64")}`,
            },
            ...prev,
          ]);
        }
      })
      .catch(() =>
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_RESOURCES)
      );
  };

  const handleDelete = (id, e) => {
    e.stopPropagation();
    setOpenConfirmation(true);
    setId(id);
  };

  const handleEdit = (ad, e) => {
    e.stopPropagation();
    setInfoForEdit(ad);
    setOpenEdit(true);
  };

  const handlefield = (e) => {
    setSortBy(e.target.value);
  };

  const isAdActive = (ad) => {
    const currentDate = new Date();
    if (
      currentDate >= new Date(ad.startDate) &&
      currentDate <= new Date(ad.endDate)
    )
      return true;
    else return false;
  };

  const handleSortSelected = () => {
    switch (sortBy) {
      case "Nazwa":
        sortName();
        break;
      case "Wyświetlenia":
        sortViews();
        break;
      case "Od":
        sortDateSince();
        break;
      case "Do":
        sortDateTo();
        break;
      case "Aktywna":
        sortActive();
        break;
    }
  };

  const sortDateSince = (isAsc = null) => {
    setIsActiveA(false);
    setIsActiveN(false);
    setIsActiveT(false);
    setIsActiveS(true);
    setIsActiveV(false);
    setIsAscSince((prev) => isAsc ?? !prev);
  };

  useEffect(() => {
    if (isAscSince !== null) {
      const sorted = [...ads].sort((a, b) => {
        if (isAscSince) {
          return new Date(a.startDate) - new Date(b.startDate);
        } else {
          return new Date(b.startDate) - new Date(a.startDate);
        }
      });

      setAds(sorted);

      if (isAscSince) {
        localStorage.setItem(sortConstants.ads, adsSort.sinceA);
      } else {
        localStorage.setItem(sortConstants.ads, adsSort.sinceD);
      }
    }
  }, [isAscSince]);

  const sortDateTo = (isAsc = null) => {
    setIsActiveV(false);
    setIsActiveA(false);
    setIsActiveN(false);
    setIsActiveT(true);
    setIsActiveS(false);
    setIsAscTo((prev) => isAsc ?? !prev);
  };

  useEffect(() => {
    if (isAscTo !== null) {
      const sorted = [...ads].sort((a, b) => {
        if (isAscTo) {
          return new Date(a.endDate) - new Date(b.endDate);
        } else {
          return new Date(b.endDate) - new Date(a.endDate);
        }
      });

      setAds(sorted);

      if (isAscTo) {
        localStorage.setItem(sortConstants.ads, adsSort.toA);
      } else {
        localStorage.setItem(sortConstants.ads, adsSort.toD);
      }
    }
  }, [isAscTo]);

  const sortName = (isAsc = null) => {
    setIsActiveA(false);
    setIsActiveN(true);
    setIsActiveT(false);
    setIsActiveS(false);
    setIsActiveV(false);
    setIsAscN((prev) => isAsc ?? !prev);
  };

  useEffect(() => {
    if (isAscN !== null) {
      const sorted = [...ads].sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (isAscN) {
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        } else {
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
        }
        return 0;
      });

      setAds(sorted);

      if (isAscN) {
        localStorage.setItem(sortConstants.ads, adsSort.nameA);
      } else {
        localStorage.setItem(sortConstants.ads, adsSort.nameD);
      }
    }
  }, [isAscN]);

  const sortViews = (isAsc = null) => {
    setIsActiveA(false);
    setIsActiveN(false);
    setIsActiveT(false);
    setIsActiveS(false);
    setIsActiveV(true);
    setIsAscV((prev) => isAsc ?? !prev);
  };

  useEffect(() => {
    if (isAscV !== null) {
      const sorted = [...ads].sort((a, b) => {
        if (isAscV) {
          return a.views - b.views;
        } else {
          return b.views - a.views;
        }
      });

      setAds(sorted);

      if (isAscV) {
        localStorage.setItem(sortConstants.ads, adsSort.viewsA);
      } else {
        localStorage.setItem(sortConstants.ads, adsSort.viewsD);
      }
    }
  }, [isAscV]);

  const sortActive = (isAsc = null) => {
    setIsActiveV(false);
    setIsActiveA(true);
    setIsActiveN(false);
    setIsActiveT(false);
    setIsActiveS(false);
    setIsActive((prev) => isAsc ?? !prev);
  };

  useEffect(() => {
    if (isActive !== null) {
      const sorted = [...ads].sort((a, b) => {
        if (isActive) {
          if (isAdActive(a)) {
            return 1;
          }
          if (isAdActive(b)) {
            return -1;
          }
        } else {
          if (isAdActive(a)) {
            return -1;
          }
          if (isAdActive(b)) {
            return 1;
          }
        }
      });

      setAds(sorted);

      if (isActive) {
        localStorage.setItem(sortConstants.ads, adsSort.activeA);
      } else {
        localStorage.setItem(sortConstants.ads, adsSort.activeD);
      }
    }
  }, [isActive]);

  const deleteAd = (adId) => {
    shop.ads
      .delete(adId)
      .then((res) => {
        setAds(ads.filter((item) => item.id !== adId));
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_AD);
      });
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.table}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            {matches ? (
              <TableRow>
                <TableCell className={classes.avatarRes}></TableCell>
                <TableCell className={classes.nameRes}></TableCell>
                <TableCell className={classes.viewRes}></TableCell>
                <TableCell className={classes.viewRes}></TableCell>
                <TableCell className={classes.dateRes} align="right">
                  {sortBy !== "" && (
                    <UnfoldMoreIcon onClick={handleSortSelected} />
                  )}
                </TableCell>
                <TableCell className={classes.searchRes}>
                  <StyledCategorySelect
                    InputLabelProps={{ classes: { root: classes.label } }}
                    fullWidth={true}
                    label="Sortuj według"
                    variant="outlined"
                    shrink="true"
                    onChange={handlefield}
                    value={sortBy}
                    select={true}
                  >
                    {fields
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((field) => {
                        return (
                          <MenuItem key={field.id} value={field.name}>
                            {field.name}
                          </MenuItem>
                        );
                      })}
                  </StyledCategorySelect>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell align="center" className={classes.image} />
                <TableCell align="left" className={classes.name}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={12}>
                      <div className={classes.headerCell}>
                        <Typography className={classes.cell}>
                          TYTUŁ REKLAMY
                        </Typography>
                        <div
                          className={classes.headerCellRight}
                          onClick={() => sortName()}
                        >
                          <SortIcon isactive={isActiveN} asacdesc={isAscN} />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" className={classes.views}>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={12}>
                      <div className={classes.headerCell}>
                        <Typography className={classes.cell}>
                          WYŚWIETLENIA
                        </Typography>
                        <div
                          className={classes.headerCellRight}
                          onClick={() => sortViews()}
                        >
                          <SortIcon isactive={isActiveV} asacdesc={isAscV} />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" className={classes.value}>
                  <Grid container direction="row">
                    <Grid item xs={12} md={6} align="left">
                      <div className={classes.headerCell}>
                        <Typography className={classes.cell}>OD</Typography>
                        <div
                          className={classes.headerCellRight}
                          onClick={() => sortDateSince()}
                        >
                          <SortIcon
                            isactive={isActiveS}
                            asacdesc={isAscSince}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" className={classes.value}>
                  <Grid container direction="row">
                    <Grid item xs={12} md={6} align="left">
                      <div className={classes.headerCell}>
                        <Typography className={classes.cell}>DO</Typography>
                        <div
                          className={classes.headerCellRight}
                          onClick={() => sortDateTo()}
                        >
                          <SortIcon isactive={isActiveT} asacdesc={isAscTo} />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="left" className={classes.active}>
                  <Grid container direction="row">
                    <Grid item xs={12} md={6} align="left">
                      <div className={classes.headerCell}>
                        <Typography className={classes.cell}>
                          AKTYWNA
                        </Typography>
                        <div
                          className={classes.headerCellRight}
                          onClick={() => sortActive()}
                        >
                          <SortIcon isactive={isActiveA} asacdesc={isActive} />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell className={classes.buttons}></TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {ads
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((ad) => (
                <TableRow
                  key={ad.id}
                  className={classes.row}
                  onClick={() => {
                    localStorage.setItem("currentAdsTablePage", page);
                    localStorage.setItem(
                      "currentAdsTableRowsPerPage",
                      rowsPerPage
                    );
                    history.push(`/dashboard/ad/${ad.id}`);
                  }}
                >
                  <TableCell align="center" component="th" scope="row">
                    <Avatar
                      alt=""
                      src={images.find((image) => image.id === ad.id)?.image}
                      className={classes.avatar}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={12}
                        align={matches ? "center" : "left"}
                        className={matches && classes.breakline}
                      >
                        <Typography className={classes.cell}>
                          {ad.name}
                        </Typography>
                      </Grid>
                      {matches && (
                        <DeleteEditButton
                          mobile={true}
                          editClickHandler={(e) => {
                            handleEdit(ad, e);
                          }}
                          deleteClickHandler={(e) => {
                            handleDelete(ad.id, e);
                          }}
                        />
                      )}
                    </Grid>
                  </TableCell>
                  {matches ? (
                    <TableCell align="center">
                      <Typography className={classes.cell}>
                        Wyświetlenia
                      </Typography>
                      <Typography className={classes.cell}>
                        {ad.views}
                      </Typography>
                    </TableCell>
                  ) : (
                    <>
                      <TableCell align="left">
                        <Typography className={classes.cell}>
                          {ad.views}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          className={
                            isAdActive(ad) ? classes.cell : classes.disableCell
                          }
                        >
                          {format(new Date(ad.startDate), "dd/MM/yyyy")}
                        </Typography>
                      </TableCell>
                    </>
                  )}
                  {matches ? (
                    <TableCell align="center">
                      <Typography
                        className={
                          isAdActive(ad) ? classes.cell : classes.disableCell
                        }
                      >{`Od: ${format(
                        new Date(ad.startDate),
                        "dd/MM/yyyy"
                      )}`}</Typography>
                      <Typography
                        className={
                          isAdActive(ad) ? classes.cell : classes.disableCell
                        }
                      >{`Do: ${format(
                        new Date(ad.endDate),
                        "dd/MM/yyyy"
                      )}`}</Typography>
                    </TableCell>
                  ) : (
                    <TableCell align="left">
                      <Typography
                        className={
                          isAdActive(ad) ? classes.cell : classes.disableCell
                        }
                      >
                        {format(new Date(ad.endDate), "dd/MM/yyyy")}
                      </Typography>
                    </TableCell>
                  )}
                  {!matches && (
                    <TableCell align="left" className={classes.buttons2}>
                      <CustomButton
                        variant={
                          isAdActive(ad)
                            ? BUTTON_VARIANTS.GREEN
                            : BUTTON_VARIANTS.RED
                        }
                        size={BUTTON_SIZES.LARGE}
                        text={isAdActive(ad) ? "Tak" : "Nie"}
                      />
                    </TableCell>
                  )}
                  {matches && (
                    <>
                      <TableCell>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography className={classes.cell} align="center">
                              AKTYWNA
                            </Typography>
                          </Grid>
                          <Grid item xs={12} align="center">
                            <CustomButton
                              variant={
                                isAdActive(ad)
                                  ? BUTTON_VARIANTS.GREEN
                                  : BUTTON_VARIANTS.RED
                              }
                              size={BUTTON_SIZES.SMALL}
                              text={isAdActive(ad) ? "Tak" : "Nie"}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell />
                    </>
                  )}
                  {!matches && (
                    <DeleteEditButton
                      mobile={false}
                      editClickHandler={(e) => {
                        handleEdit(ad, e);
                      }}
                      deleteClickHandler={(e) => {
                        handleDelete(ad.id, e);
                      }}
                    />
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CommonModal
        open={openConfirmation}
        toggleOpen={() => setOpenConfirmation(false)}
        handleConfirmation={() => deleteAd(id)}
        title={COMMON_MODAL_TEXTS.AD_TITLE}
        content={COMMON_MODAL_TEXTS.AD_CONTENT}
      />
      <EditAdWindow
        ads={ads}
        setAds={setAds}
        isOpen={openEdit}
        close={() => setOpenEdit(false)}
        data={infoForEdit}
        handleError={handleError}
      />
    </>
  );
};

export default AdsTable;
