import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Chip from "@material-ui/core/Chip";
import {
  Typography,
  Paper,
  Grid,
  makeStyles,
  Box,
  MenuItem,
} from "@material-ui/core";
import styles from "./NewProductWindow.styles";
import FileUploader from "../FileUploader/FileUploader";
import StyledFormTextField from "../common/textFields/StyledFormTextField";
import StyledDescriptionTextField from "../common/textFields/StyledDescriptionTextField";
import useForm from "../../hooks/useForm";
import StyledCategorySelect from "../common/Selects/StyledCategorySelect";
import { shop, cultivations } from "../../utils/api";
import Picture from "../PictureFile.js/Picture";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { validatePrice, changePriceToInt } from "../../utils/price";
import Autocomplete from "@material-ui/lab/Autocomplete";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { Avatar } from "@material-ui/core";
import LocalFloristIcon from "@material-ui/icons/LocalFlorist";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  SERVER_ERROR_MESSAGES,
  ERROR_MESSAGES,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
} from "../../constants/dictionary";
import CustomButton from "../common/buttons/CustomButton";

const useStyles = makeStyles(styles);

const NewProductsWindow = ({ isOpen, close, handleError }) => {
  const classes = useStyles();
  const [categoriesArray, setCategories] = useState([]);
  const [selectedCategoriesArray, setSelectedCategories] = useState([]);
  const [imageSrcArray, setImageSrcArray] = useState([]);
  const [switched, setSwitch] = useState(false);
  const [emptyField, setEmptyField] = useState(false);
  const [error, setError] = useState("");
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [devStages, setDevStages] = useState([]);
  const [selectedDev, setSelectedDev] = useState({});
  const [suggestedAmounts, setSuggestedAmount] = useState([]);
  const [quantityPerH, setQuantityPerH] = useState(0);
  const [label, setLabel] = useState("");

  const { handleChange, values } = useForm({
    name: "",
    description: "",
    price: "",
    amount: "",
    unmarginedPrice: "",
    capacity: "",
  });

  useEffect(() => {
    cultivations.types
      .distinct()
      .then((res) => {
        setTypes(res.data.distinctNames);
      })
      .catch((err) => {
        handleError(ERROR_MESSAGES.COULD_NOT_FIND_CROPS_TYPES);
      });

    shop.categories
      .all()
      .then((category) => {
        const {
          data: { categories },
        } = category;
        setCategories(categories);
      })
      .catch((err) => {
        handleError(ERROR_MESSAGES.COULD_NOT_FIND_CATEGORIES_FOR_PRODUCT);
      });
  }, []);

  const handleFile = (file) => {
    const data = new FormData();
    data.append("file", file);
    shop.files
      .send(data)
      .then((res) => {
        setImageSrcArray([
          ...imageSrcArray,
          `${res.config.url}/${res.data.address}`,
        ]);
      })
      .catch((err) => {
        handleError(ERROR_MESSAGES.COULD_NOT_UPLOAD_PHOTO);
      });
  };

  const handleLabel = (file) => {
    const data = new FormData();
    data.append("file", file);
    shop.files
      .send(data)
      .then((res) => {
        if (Object.keys(res.data).length > 0)
          setLabel(`${res.config.url}/${res.data.address}`);
        else setLabel("");
      })
      .catch((err) => {
        handleError(ERROR_MESSAGES.COULD_NOT_UPLOAD_PHOTO);
      });
  };

  const selectChange = (e) => {
    setSelectedCategories(e.target.value);
  };

  const remove = (del) => {
    const filtered = imageSrcArray.filter((ele) => {
      return ele !== del;
    });
    setImageSrcArray(filtered);
  };

  const handleSwitch = (event) => {
    setSwitch(event.target.checked);
  };

  const submit = (e) => {
    e.preventDefault();
    if (
      values.name === "" ||
      values.description === "" ||
      values.amount === "" ||
      values.unmarginedPrice === "" ||
      values.price === ""
    ) {
      setEmptyField(true);
      return;
    }

    if (
      validatePrice(values.price) === false ||
      validatePrice(values.unmarginedPrice) === false
    ) {
      setError(" Podana cena jest nieprawidłowa");
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }

    const data = {
      name: values.name,
      description: values.description,
      categories: selectedCategoriesArray,
      price: changePriceToInt(values.price),
      images: imageSrcArray,
      availableAmount: parseInt(values.amount, 10),
      capacity: switched
        ? parseInt(values.capacity.toString().replace(",", ".") * 1000, 10)
        : 0,
      unmarginedPrice: changePriceToInt(values.unmarginedPrice),
      suggestedAmounts: switched ? suggestedAmounts : [],
      labelURL: switched ? label : "",
    };

    shop.articles
      .add(data)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_ADD_PRODUCT);
      });
  };

  const addSuggestedDose = () => {
    if (
      selectedType !== "" &&
      Object.keys(selectedDev).length > 0 &&
      quantityPerH != 0
    ) {
      const suggestedAmount = {
        cultivationTypeName: selectedType,
        developmentStageName: selectedDev.name,
        amountPerHectare: parseInt(
          quantityPerH.toString().replace(",", ".") * 1000,
          10
        ),
      };
      setSuggestedAmount((data) => [...data, suggestedAmount]);
      setSelectedDev({});
      setQuantityPerH(0);
    }
  };

  return (
    <Modal open={isOpen}>
      <Paper className={classes.root}>
        <div className={classes.scroll}>
          <Grid container spacing={4} justify="center" direction="column">
            <Grid item xs={12}>
              <Typography className={classes.header}>
                Dodaj nowy produkt
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <StyledFormTextField
                  InputLabelProps={{ classes: { root: classes.label } }}
                  fullWidth={true}
                  name="name"
                  label="Wpisz nazwę produktu"
                  variant="outlined"
                  required
                  shrink="true"
                  onChange={handleChange("name")}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <Grid container spacing={4} direction="row" justify="center">
                  <Grid item xs={12} md={6}>
                    <StyledCategorySelect
                      InputLabelProps={{ classes: { root: classes.label } }}
                      name="category"
                      fullWidth={true}
                      label="Kategoria"
                      variant="outlined"
                      shrink="true"
                      onChange={selectChange}
                      value={selectedCategoriesArray}
                      select={true}
                      SelectProps={{
                        multiple: true,
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        },
                        renderValue: (selected) => (
                          <div className={classes.chips}>
                            {selected.map((value) => (
                              <Chip
                                key={value.id}
                                label={value.name}
                                className={classes.chip}
                              />
                            ))}
                          </div>
                        ),
                      }}
                    >
                      {categoriesArray.map((category) => {
                        return (
                          <MenuItem key={category.id} value={category}>
                            {category.name}
                          </MenuItem>
                        );
                      })}
                    </StyledCategorySelect>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <StyledFormTextField
                      InputLabelProps={{ classes: { root: classes.label } }}
                      fullWidth={true}
                      label="Cena produktu"
                      variant="outlined"
                      required
                      shrink="true"
                      multiline={true}
                      name="price"
                      onChange={handleChange("price")}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box className={classes.box}>
                <StyledFormTextField
                  InputLabelProps={{ classes: { root: classes.label } }}
                  fullWidth={true}
                  required
                  label="Cena hurtowa produktu"
                  variant="outlined"
                  shrink="true"
                  multiline={true}
                  name="unmarginedPrice"
                  onChange={handleChange("unmarginedPrice")}
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box className={classes.box}>
                <Grid container spacing={4} direction="row" justify="center">
                  <Grid item xs={12} md={6}>
                    <StyledFormTextField
                      InputLabelProps={{ classes: { root: classes.label } }}
                      fullWidth={true}
                      name="amount"
                      label="Ilość"
                      variant="outlined"
                      required
                      shrink="true"
                      onChange={handleChange("amount")}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FileUploader handleFile={handleFile} />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} align="center">
              <Box className={classes.box}>
                <Grid
                  container
                  spacing={0}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {imageSrcArray.map((ele) => (
                    <Picture pictureSrc={ele} remove={remove} renderIcon />
                  ))}
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <StyledDescriptionTextField
                  InputLabelProps={{ classes: { root: classes.label } }}
                  fullWidth={true}
                  label="Opis produktu"
                  variant="outlined"
                  required
                  rows={4}
                  shrink="true"
                  multiline={true}
                  name="description"
                  onChange={handleChange("description")}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.box}>
                <Grid
                  container
                  spacing={4}
                  direction="row"
                  justify="center"
                  alignContent="center"
                  alignItems="center"
                >
                  {switched && (
                    <Grid item xs={12}>
                      <Box className={classes.box}>
                        <Grid
                          container
                          spacing={4}
                          direction="row"
                          justify="center"
                        >
                          <Grid item xs={12}>
                            <StyledFormTextField
                              InputLabelProps={{
                                classes: { root: classes.label },
                              }}
                              fullWidth={true}
                              name="capacity"
                              label="Pojemność"
                              variant="outlined"
                              shrink="true"
                              onChange={handleChange("capacity")}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FileUploader
                              handleFile={handleLabel}
                              name="Dodaj etykiete"
                            />
                          </Grid>
                          <Grid item xs={12} align="center">
                            <Box className={classes.box}>
                              <Grid
                                container
                                spacing={0}
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                {label !== "" ? (
                                  <Picture
                                    pictureSrc={label}
                                    customBreak={[12, 6, 6, 6]}
                                    remove={() => setLabel("")}
                                    renderIcon
                                  />
                                ) : null}
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>

                        {suggestedAmounts.length > 0 && (
                          <Grid
                            item
                            xs={12}
                            align="center"
                            className={classes.amountsBox}
                          >
                            <Typography className={classes.listText}>
                              Lista proponowanych dawek
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          {suggestedAmounts.map((item, idx) => (
                            <List className={classes.rootList}>
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar className={classes.avatar}>
                                    <LocalFloristIcon
                                      className={classes.icon}
                                    />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary="Typ"
                                  secondary={
                                    <Typography className={classes.listText}>
                                      {item.cultivationTypeName}
                                    </Typography>
                                  }
                                />
                                <ListItemText
                                  primary="Faza rozwoju"
                                  secondary={
                                    <Typography className={classes.listText}>
                                      {item.developmentStageName}
                                    </Typography>
                                  }
                                />
                                <ListItemText
                                  primary="Ilość na hektar [kg lub l]"
                                  secondary={
                                    <Typography className={classes.listText}>
                                      {item.amountPerHectare / 1000}
                                    </Typography>
                                  }
                                />
                                <DeleteIcon
                                  className={classes.delete}
                                  onClick={() => {
                                    let filtered = suggestedAmounts.filter(
                                      (e, i) => {
                                        if (i === idx) return false;
                                        else return true;
                                      }
                                    );
                                    setSuggestedAmount(filtered);
                                  }}
                                />
                              </ListItem>
                              <Divider />
                            </List>
                          ))}
                        </Grid>
                        <Grid item xs={12} aling="center">
                          <Typography className={classes.doseText}>
                            Dodaj sugerowane dawki (Typ, Faza, Ilość na hektar)
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignContent="center"
                          alignItems="center"
                          spacing={4}
                        >
                          <Grid item xs={6}>
                            <Autocomplete
                              getOptionLabel={(option) => option}
                              options={types}
                              onChange={(e, val) => {
                                setSelectedType(val);
                                setSelectedDev({});
                                setDevStages([]);
                                cultivations.stages
                                  .byTypeName(val)
                                  .then((res) => {
                                    const key = "name";

                                    let uniqueNames = [
                                      ...new Map(
                                        res.data.developmentStages.map(
                                          (item) => [item[key], item]
                                        )
                                      ).values(),
                                    ];
                                    setDevStages(uniqueNames);
                                  })
                                  .catch((err) => {
                                    handleError(
                                      SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_DEVELOP_PHASES
                                    );
                                  });
                              }}
                              renderInput={(params) => (
                                <StyledFormTextField
                                  {...params}
                                  InputLabelProps={{
                                    classes: { root: classes.label },
                                  }}
                                  label="Typ uprawy"
                                  fullWidth={true}
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Autocomplete
                              getOptionLabel={(option) => option.name}
                              options={devStages}
                              value={selectedDev}
                              onChange={(e, val) => {
                                if (val) setSelectedDev(val);
                              }}
                              renderInput={(params) => (
                                <StyledFormTextField
                                  {...params}
                                  InputLabelProps={{
                                    classes: { root: classes.label },
                                  }}
                                  label="Faza rozwoju"
                                  fullWidth={true}
                                  variant="outlined"
                                  shrink="true"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <StyledFormTextField
                              InputLabelProps={{
                                classes: { root: classes.label },
                              }}
                              fullWidth={true}
                              value={quantityPerH}
                              label="Ilość na Hektar"
                              variant="outlined"
                              shrink="true"
                              onChange={(e) => setQuantityPerH(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Box className={classes.buttonBox}>
                              <CustomButton
                                variant={BUTTON_VARIANTS.GREEN}
                                size={BUTTON_SIZES.LARGE}
                                clickHandler={addSuggestedDose}
                                text="Dodaj dawkę"
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={6} align="center">
                    <FormControlLabel
                      className={classes.switch}
                      control={
                        <Switch
                          checked={switched}
                          onChange={handleSwitch}
                          color="primary"
                        />
                      }
                      label="Produkt dawkowany"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {emptyField && (
              <Grid item xs={12} align="center">
                <Typography className={classes.error}>
                  Nie wypełniono obowiązkowych pól
                </Typography>
              </Grid>
            )}
            {error !== "" && (
              <Grid item xs={12} align="center">
                <Typography className={classes.error}>{error}</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box className={classes.box}>
                <Grid container spacing={2} direction="row" justify="center">
                  <Grid item xs={6}>
                    <Box className={classes.box}>
                      <CustomButton
                        variant={BUTTON_VARIANTS.GREEN}
                        size={BUTTON_SIZES.LARGE}
                        clickHandler={submit}
                        text="Dodaj"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className={classes.box}>
                      <CustomButton
                        variant={BUTTON_VARIANTS.RED}
                        size={BUTTON_SIZES.LARGE}
                        clickHandler={close}
                        text="Anuluj"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default NewProductsWindow;
