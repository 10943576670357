import { makeStyles } from "@material-ui/core";

import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1em",
  },
  text: {
    fontFamily: "Montserrat",
  },
  bold: {
    fontWeight: "bolder",
  },
  totalContainer: {
    backgroundColor: colors.quickSilver,
    borderRadius: 30,
    marginTop: "2em",
    padding: "2em 3em",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1em 3em 0",
  },
  button: {
    borderRadius: "1em",
    fontFamily: "Montserrat",
  },
  productText: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: 20,
    marginTop: 10,
  },
  container: {
    display: "flex",
    "flex-direction": "row",
    "justify-content": "center",
    "align-items": "center",
    height: "100%",
    width: "100%",
  },
  loader: {
    "justify-content": "center",
    "align-items": "center",
  },
}));

export default useStyles;
