import { Box, Chip, Grid, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useEffect, useState } from "react";
import { BUTTON_SIZES, BUTTON_VARIANTS } from "../../constants/dictionary";
import { cultivations } from "../../utils/newApi";
import { validateFloat } from "../../utils/validation";
import CustomButton from "../common/buttons/CustomButton";
import StyledFormTextField from "../common/textFields/StyledFormTextField";
import styles from "./DoseAdder.styles";

const useStyles = makeStyles(styles);

const DoseAdder = ({ onDoseAdd }) => {
  const [cultivationTypes, setCultivationTypes] = useState([]);
  const [devStages, setDevStages] = useState([]);
  const [pathogens, setPathogens] = useState([]);
  const [selectedFieldType, setSelectedFieldType] = useState({});
  const [selectedStage, setSelectedStage] = useState({});
  const [selectedPathogens, setSelectedPathogens] = useState([]);
  const [properFilledDosageData, setProperFilledDosageData] = useState(false);
  const [dose, setDose] = useState(null);

  useEffect(() => {
    cultivations.types.all().then((res) => {
      setCultivationTypes(res.data);
    });
  }, []);

  useEffect(() => {
    if (selectedPathogens?.length > 0 && validateFloat(dose)) {
      setProperFilledDosageData(true);
    } else {
      setProperFilledDosageData(false);
    }
  }, [selectedPathogens, dose]);

  const onTypeChange = (e, n) => {
    if (n) {
      setSelectedStage({});
      setSelectedPathogens([]);
      setSelectedFieldType(n);
      setDevStages(n.developmentStageList);
    } else {
      setSelectedStage({});
      setDevStages([]);
      setSelectedFieldType({});
      setSelectedPathogens([]);
    }
  };

  const onDevStageChange = (e, n) => {
    if (n) {
      setSelectedStage(n);
      setPathogens(n.pathogenList);
    } else {
      setSelectedStage({});
      setPathogens([]);
    }
  };

  const onPathogensChange = (e, n) => {
    setSelectedPathogens(n);
  };

  const onDoseAddHandler = () => {
    onDoseAdd({
      dosagePerHa: dose,
      pathogenDAOList: selectedPathogens.map((pathogen) => {
        return {
          id: pathogen.id,
          name: pathogen.name,
          cultivationName: selectedFieldType.name,
          cultivationDevelopmentStageName: selectedStage.name,
        };
      }),
    });
  };

  const plantBoxes = [
    {
      title: "Typ uprawy",
      onChangeHandler: onTypeChange,
      options: cultivationTypes,
      multiple: false,
      xs: 6,
    },
    {
      title: "Faza rozwoju",
      onChangeHandler: onDevStageChange,
      options: devStages,
      multiple: false,
      value: selectedStage,
      xs: 6,
    },
    {
      title: "Patogeny",
      multiple: true,
      onChangeHandler: onPathogensChange,
      options: pathogens,
      value: selectedPathogens,
      xs: 12,
    },
  ];

  const classes = useStyles();
  return (
    <Grid container direction="row" spacing={2} className={classes.mainBox}>
      {plantBoxes.map((box) => (
        <Grid item xs={box.xs}>
          <Box className={classes.box}>
            <Autocomplete
              multiple={box.multiple}
              options={box.options}
              value={box?.value}
              onChange={box.onChangeHandler}
              filterSelectedOptions
              getOptionLabel={(option) => option.name}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    className={classes.chip}
                    label={option.name}
                  />
                ))
              }
              renderInput={(params) => (
                <StyledFormTextField
                  {...params}
                  InputLabelProps={{ classes: { root: classes.label } }}
                  name="name"
                  fullWidth={true}
                  label={box.title}
                  variant="outlined"
                  shrink="true"
                />
              )}
            />
          </Box>
        </Grid>
      ))}
      <Grid item xs={12} align="center">
        <StyledFormTextField
          label="Wpisz dawkę /H"
          placeholder="1"
          variant="outlined"
          required
          value={dose}
          onChange={(e) => {
            setDose(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12} align="center">
        <CustomButton
          disabled={!properFilledDosageData}
          variant={BUTTON_VARIANTS.WHITE}
          size={BUTTON_SIZES.SMALL}
          clickHandler={onDoseAddHandler}
          text="Dodaj dawkę"
        />
      </Grid>
    </Grid>
  );
};

export default DoseAdder;
