import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { SortIcon } from "../common/buttons/SortIcon";
import { ThemeProvider } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import { shop } from "../../utils/newApi";
import { useStyles } from "./OrdersList.styles";
import ErrorSnackBar from "../Snackbar/ErrorSnackBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { displayPrice } from "../../utils/price";
import Autocomplete from "@material-ui/lab/Autocomplete";
import orderStatus from "../../constants/orderConstants";
import StyledFormTextField from "../common/textFields/StyledFormTextField";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../common/pagination/TablePaginationActions";
import CommonModal from "../common/modals/CommonModal";
import ListHeader from "../common/ListHeader";
import EmojiNatureIcon from "@mui/icons-material/EmojiNature";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import colors from "../../constants/colors";
import { Box } from "@material-ui/core";
import BoxIcon from "../../assets/images/boxIcon.png";
import { itemStatusListColor } from "../../utils/orders";
import DeleteEditButton from "../common/buttons/DeleteEditButton";

import {
  SERVER_ERROR_MESSAGES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";

import {
  sortConstants,
  ordersSort,
  filterConstants,
} from "../../constants/localStorage";

const OrdersList = () => {
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(false);
  const [isAscID, setIsAscID] = useState(null);
  const [isAscOrderer, setIsAscOrderer] = useState(null);
  const [isAscValue, setIsAscValue] = useState(null);
  const [isAscDate, setIsAscDate] = useState(null);
  const [isActiveID, setIsActiveID] = useState(null);
  const [isActiveOrderer, setIsActiveOrderer] = useState(null);
  const [isActiveValue, setIsActiveValue] = useState(null);
  const [isActiveDate, setIsActiveDate] = useState(null);
  const [errorText, setErrorText] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const theme = useTheme();
  const history = useHistory();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [ordersCopy, setOrdersCopy] = useState([]);
  const [page, setPage] = React.useState(
    localStorage.getItem("currentOrdersListPage")
      ? Number(localStorage.getItem("currentOrdersListPage"))
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(
    localStorage.getItem("currentOrdersListRowsPerPage")
      ? Number(localStorage.getItem("currentOrdersListRowsPerPage"))
      : 10
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    shop.orders
      .getAllOreders()
      .then((res) => {
        if (res.data) {
          setOrders(res.data);
          setOrdersCopy(res.data);
        }

        setIsLoaded(true);
      })
      .catch(() =>
        handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_ORDERS)
      );
  }, []);

  useEffect(() => {
    if (ordersCopy.length !== 0) {
      const filterToApply = localStorage.getItem(filterConstants.orders);
      const sortToApply = localStorage.getItem(sortConstants.orders);

      filterStatus(null, filterToApply);

      if (sortToApply) {
        switch (sortToApply) {
          case ordersSort.idA:
            sortID(true);
            break;
          case ordersSort.idD:
            sortID(false);
            break;
          case ordersSort.ordererA:
            sortOrderer(true);
            break;
          case ordersSort.ordererD:
            sortOrderer(false);
            break;
          case ordersSort.valueA:
            sortValue(true);
            break;
          case ordersSort.valueD:
            sortValue(false);
            break;
          case ordersSort.dateA:
            sortDate(true);
            break;
          case ordersSort.dateD:
            sortDate(false);
            break;
        }
      }
    }
  }, [ordersCopy]);

  const handleDelete = () => {
    shop.orders
      .deleteOrder(deleteId)
      .then(() => setOrders(orders.filter((item) => item.id !== deleteId)))
      .catch(() => handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_ORDER));
  };

  const sortID = (isAsc = null) => {
    setIsAscID((prev) => isAsc ?? !prev);
    setIsActiveID(true);
    setIsActiveOrderer(false);
    setIsActiveValue(false);
    setIsActiveDate(false);
  };

  useEffect(() => {
    if (isAscID !== null) {
      const sorted = [...orders].sort((a, b) =>
        isAscID ? a.id - b.id : b.id - a.id
      );

      setOrders(sorted);

      if (isAscID) {
        localStorage.setItem(sortConstants.orders, ordersSort.idA);
      } else {
        localStorage.setItem(sortConstants.orders, ordersSort.idD);
      }
    }
  }, [isAscID]);

  const sortOrderer = (isAsc = null) => {
    setIsAscOrderer((prev) => isAsc ?? !prev);
    setIsActiveID(false);
    setIsActiveOrderer(true);
    setIsActiveValue(false);
    setIsActiveDate(false);
  };

  useEffect(() => {
    if (isAscOrderer !== null) {
      const sorted = [...orders].sort((a, b) => {
        const nameA = a?.username.toLowerCase();
        const nameB = b?.username.toLowerCase();
        if (isAscOrderer) {
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
        } else {
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
        }
        return 0;
      });

      setOrders(sorted);

      if (isAscOrderer) {
        localStorage.setItem(sortConstants.orders, ordersSort.ordererA);
      } else {
        localStorage.setItem(sortConstants.orders, ordersSort.ordererD);
      }
    }
  }, [isAscOrderer]);

  const sortValue = (isAsc = null) => {
    setIsAscValue((prev) => isAsc ?? !prev);
    setIsActiveID(false);
    setIsActiveOrderer(false);
    setIsActiveValue(true);
    setIsActiveDate(false);
  };

  useEffect(() => {
    if (isAscValue !== null) {
      const sorted = [...orders].sort((a, b) => {
        if (isAscValue) {
          return a.productsCost - b.productsCost;
        } else {
          return b.productsCost - a.productsCost;
        }
      });

      setOrders(sorted);

      if (isAscValue) {
        localStorage.setItem(sortConstants.orders, ordersSort.valueA);
      } else {
        localStorage.setItem(sortConstants.orders, ordersSort.valueD);
      }
    }
  }, [isAscValue]);

  const sortDate = (isAsc = null) => {
    setIsAscDate((prev) => isAsc ?? !prev);
    setIsActiveID(false);
    setIsActiveOrderer(false);
    setIsActiveValue(false);
    setIsActiveDate(true);
  };

  useEffect(() => {
    if (isAscDate !== null) {
      const sorted = [...orders].sort((a, b) => {
        const dateA = new Date(a.orderDate);
        const dateB = new Date(b.orderDate);
        return isAscDate ? dateA - dateB : dateB - dateA;
      });

      setOrders(sorted);

      if (isAscDate) {
        localStorage.setItem(sortConstants.orders, ordersSort.dateA);
      } else {
        localStorage.setItem(sortConstants.orders, ordersSort.dateD);
      }
    }
  }, [isAscDate]);

  const filterStatus = (e, v) => {
    if (v !== null) {
      const res = ordersCopy.filter((order) => {
        if (order.status === v) {
          return true;
        } else {
          return false;
        }
      });
      setOrders(res);
      localStorage.setItem(filterConstants.orders, v);
    } else {
      setOrders(ordersCopy);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <div className={classes.root}>
          <ListHeader
            variant="title-search"
            title="Lista zamówień"
            searchComponent={
              <Autocomplete
                options={orderStatus}
                defaultValue={localStorage.getItem(filterConstants.orders)}
                onChange={filterStatus}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <StyledFormTextField
                    {...params}
                    InputLabelProps={{
                      classes: { root: classes.label },
                    }}
                    name="name"
                    fullWidth={true}
                    label="Filtruj po statusie"
                    variant="outlined"
                    shrink="true"
                  />
                )}
              />
            }
          />
          <TableContainer component={Paper} className={classes.table}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className={matches ? classes.idRes : classes.id}
                    align="left"
                  >
                    <Button
                      onClick={() => sortID()}
                      endIcon={
                        <SortIcon isactive={isActiveID} asacdesc={isAscID} />
                      }
                    >
                      <Typography className={classes.text}>ID</Typography>
                    </Button>
                  </TableCell>
                  <TableCell
                    className={matches ? classes.ordererRes : classes.orderer}
                  >
                    <Button
                      onClick={() => sortOrderer()}
                      endIcon={
                        <SortIcon
                          isactive={isActiveOrderer}
                          asacdesc={isAscOrderer}
                        />
                      }
                    >
                      <Typography className={classes.text}>
                        ZAMAWIAJĄCY
                      </Typography>
                    </Button>
                  </TableCell>
                  <TableCell
                    className={matches ? classes.statusRes : classes.status}
                  >
                    <Button>
                      <Typography className={classes.text}>STATUS</Typography>
                    </Button>
                  </TableCell>
                  <TableCell
                    className={matches ? classes.valueRes : classes.value}
                  >
                    <Button
                      onClick={() => sortValue()}
                      endIcon={
                        <SortIcon
                          isactive={isActiveValue}
                          asacdesc={isAscValue}
                        />
                      }
                    >
                      <Typography className={classes.text}>WARTOŚĆ</Typography>
                    </Button>
                  </TableCell>
                  <TableCell
                    className={matches ? classes.dateRes : classes.date}
                  >
                    <Button
                      onClick={() => sortDate()}
                      endIcon={
                        <SortIcon
                          isactive={isActiveDate}
                          asacdesc={isAscDate}
                        />
                      }
                    >
                      <Typography className={classes.text}>DATA</Typography>
                    </Button>
                  </TableCell>
                  <TableCell
                    className={matches ? classes.buttonsRes : classes.buttons}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <TableRow
                      className={
                        item.status === orderStatus[5]
                          ? classes.cancel
                          : classes.row
                      }
                      key={item && item.id}
                    >
                      <TableCell>
                        <Typography className={classes.cell}>
                          {item && item.id}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Grid container direction="row" alignItems="center">
                          <Grid item xs={8} alignItems="center">
                            <Typography className={classes.cell}>
                              {item?.username}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Grid container direction="column">
                              {item.hivesAmount > 0 && (
                                <EmojiNatureIcon
                                  style={{ color: colors.htmlOrange }}
                                />
                              )}
                              {item.thermometersAmount > 0 && (
                                <DeviceThermostatIcon
                                  style={{ color: colors.htmlRed }}
                                />
                              )}
                              {item.cocoonBoxesAmount > 0 && (
                                <Box
                                  component={"img"}
                                  src={BoxIcon}
                                  sx={{ height: "25px", width: "25px" }}
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Typography
                          className={classes.cell}
                          style={itemStatusListColor(item.status)}
                        >
                          {item && item.status}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.cell}>
                          {item && `${displayPrice(item.productsCost)} zł`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.cell}>
                          {item && item.orderDate
                            ? format(new Date(item.orderDate), "dd.MM.yyyy")
                            : "Brak"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <DeleteEditButton
                          mobile={true}
                          editClickHandler={() => {
                            localStorage.setItem("currentOrdersListPage", page);
                            localStorage.setItem(
                              "currentOrdersListRowsPerPage",
                              rowsPerPage
                            );
                            history.push(`orderInfo/${item.id}`);
                          }}
                          deleteClickHandler={() => {
                            setDeleteId(item.id);
                            setOpen(true);
                          }}
                          editOnly={item.status === orderStatus[5]}
                          deleteText="ANULUJ"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 15, 20, 50]}
              component="div"
              count={orders.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
              classes={{
                toolbar: classes.toolbar,
                caption: classes.caption,
              }}
              labelRowsPerPage={"Ilość wierszy na stronę"}
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}–${to} z ${
                  count !== -1 ? count : `więcej niż ${to}`
                }`;
              }}
            />
          </TableContainer>
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.loader}>
            <CircularProgress color="secondary" className={classes.loader} />
          </div>
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />
      <CommonModal
        open={open}
        toggleOpen={toggleOpen}
        handleConfirmation={handleDelete}
        title={COMMON_MODAL_TEXTS.ORDER_TITLE}
        content={COMMON_MODAL_TEXTS.GENERAL_CONTENT}
      />
    </ThemeProvider>
  );
};

export default OrdersList;
