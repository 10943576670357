import React, { useEffect, useState } from "react";
import {
  ThemeProvider,
  makeStyles,
  Grid,
  Table,
  TableHead,
  TableBody,
  Typography,
  TableRow,
  TableCell,
  useMediaQuery,
} from "@material-ui/core";
import { cultivations } from "../../utils/newApi";
import { theme } from "./Cultivation.theme";
import { styles } from "./CultivationDevelopmentStagesTable.styles";
import { useHistory, useParams } from "react-router-dom";
import NewDevelopmentStageWindow from "../../components/ModalWindows/NewDevelopmentStageWindow";
import EditDevelopmentStageWindow from "../../components/ModalWindows/EditDevelopmentStageWindow";
import { Hidden } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorSnackBar from "../../components/Snackbar/ErrorSnackBar";
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TablePaginationActions from "../../components/common/pagination/TablePaginationActions";
import CommonModal from "../../components/common/modals/CommonModal";
import DeleteEditButton from "../../components/common/buttons/DeleteEditButton";
import ListHeader from "../../components/common/ListHeader";

import {
  SERVER_ERROR_MESSAGES,
  COMMON_MODAL_TEXTS,
} from "../../constants/dictionary";

const useStyles = makeStyles(styles);
const CultivationDevelopmentStagesTable = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [developmentStages, setDevelopmentStages] = useState([]);
  const history = useHistory();
  const { cultivationId } = useParams();
  const [cultivation, setCultivation] = useState(null);
  const [reload, setReload] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [current, setCurrent] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const toggleOpen = () => setOpen(!open);
  const toggleOpenEdit = () => setOpenEdit(!openEdit);
  const toggleOpenDelete = () => setOpenDelete(!openDelete);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleError = (text) => {
    setError(true);
    setErrorText(text);
  };

  useEffect(() => {
    cultivations.stages
      .all(cultivationId)
      .then((res) => {
        setDevelopmentStages(res.data);
      })
      .catch((err) => handleError(SERVER_ERROR_MESSAGES.COULD_NOT_FIND_CROP));

    cultivations.types
      .one(cultivationId)
      .then((res) => {
        setCultivation(res.data);
        setIsLoaded(true);
      })
      .catch((err) =>
        handleError(
          SERVER_ERROR_MESSAGES.COULD_NOT_DOWNLOAD_PLANTS_PROTECTION_PRODUCTS
        )
      );
  }, [reload]);

  const handleClick = (developmentId) => {
    history.push(
      `/dashboard/cultivation/${cultivationId}/pathogens/${developmentId}`
    );
  };

  const handleEdit = (payload) => {
    cultivations.stages
      .edit(current.id, payload)
      .then(() => setReload(!reload))
      .catch((err) => handleError(SERVER_ERROR_MESSAGES.COULD_NOT_EDIT_CROP));
  };

  const handleDelete = () => {
    cultivations.stages
      .delete(current.id)
      .then(() => setReload(!reload))
      .catch((err) => handleError(SERVER_ERROR_MESSAGES.COULD_NOT_DELETE_CROP));
  };

  const handleAdd = (payload) => {
    cultivations.stages
      .add(cultivationId, payload)
      .then(() => setReload(!reload))
      .catch((err) => handleError(SERVER_ERROR_MESSAGES.COULD_NOT_ADD_CROP));
  };

  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <TableContainer>
          <ListHeader
            variant="title-add"
            title={`Edycja stanów rozwoju należących do uprawy ${cultivation.name.toLowerCase()}`}
            handleAdd={toggleOpen}
          />
          <Table>
            <TableHead>
              <TableCell className={classes.value}>Nazwa</TableCell>
              <TableCell className={classes.value}>Opis</TableCell>
              <Hidden smDown>
                <TableCell className={classes.value} />
              </Hidden>
            </TableHead>
            <TableBody>
              {developmentStages
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((devStage) => (
                  <TableRow
                    key={devStage.id}
                    className={classes.row}
                    onClick={() => handleClick(devStage.id)}
                  >
                    <TableCell align="left" className={classes.cell}>
                      <Grid
                        container
                        justifyContent={matches && "center"}
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={matches ? 12 : 8}
                          align={matches ? "center" : "left"}
                          className={matches && classes.breakline}
                        >
                          <Typography className={classes.text}>
                            {devStage.name}
                          </Typography>
                        </Grid>
                        {matches && (
                          <DeleteEditButton
                            mobile={true}
                            editClickHandler={(e) => {
                              e.stopPropagation();
                              setCurrent(devStage);
                              toggleOpenEdit();
                            }}
                            deleteClickHandler={(e) => {
                              e.stopPropagation();
                              setCurrent(devStage);
                              toggleOpenDelete();
                            }}
                          />
                        )}
                      </Grid>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Typography className={classes.text}>
                        {devStage.description}
                      </Typography>
                    </TableCell>
                    {!matches && (
                      <DeleteEditButton
                        mobile={false}
                        editClickHandler={(e) => {
                          e.stopPropagation();
                          setCurrent(devStage);
                          toggleOpenEdit();
                        }}
                        deleteClickHandler={(e) => {
                          e.stopPropagation();
                          setCurrent(devStage);
                          toggleOpenDelete();
                        }}
                      />
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 50]}
            component="div"
            count={developmentStages.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
            classes={{
              toolbar: classes.toolbar,
              caption: classes.caption,
            }}
            labelRowsPerPage={"Ilość wierszy na stronę"}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}–${to} z ${
                count !== -1 ? count : `więcej niż ${to}`
              }`;
            }}
          />
        </TableContainer>
      ) : (
        <div className={classes.container}>
          <CircularProgress color="secondary" className={classes.loader} />
        </div>
      )}
      <ErrorSnackBar
        open={error}
        text={errorText}
        close={() => setError(false)}
      />

      <NewDevelopmentStageWindow
        handleAdd={handleAdd}
        open={open}
        toggleOpen={toggleOpen}
      />
      <EditDevelopmentStageWindow
        handleEdit={handleEdit}
        open={openEdit}
        toggleOpen={toggleOpenEdit}
        current={current}
      />
      <CommonModal
        open={openDelete}
        toggleOpen={toggleOpenDelete}
        handleConfirmation={handleDelete}
        title={COMMON_MODAL_TEXTS.DEVELOPMENT_STAGE_TITLE}
        content={COMMON_MODAL_TEXTS.GENERAL_CONTENT}
      />
    </ThemeProvider>
  );
};

export default CultivationDevelopmentStagesTable;
