import axios from "axios";
import tokenUtils from "./tokenUtils";

import { API_URL } from "../constants/dictionary";

const getHeaders = () => ({
  headers: {
    authorization: !!tokenUtils.getToken()
      ? `Bearer ${tokenUtils.getToken()}`
      : "",
  },
});

const getHeadersQR = () => ({
  headers: {
    authorization: !!tokenUtils.getToken()
      ? `Bearer ${tokenUtils.getToken()}`
      : "",
  },
  responseType: "arraybuffer",
});

class Request {
  delete = (url) => axios.delete(`${API_URL}${url}`, getHeaders());
  get = (url) => axios.get(`${API_URL}${url}`, getHeaders());
  getSpecial = (url) => axios.get(`${API_URL}${url}`, getHeadersQR());
  put = (url, body) => axios.put(`${API_URL}${url}`, body, getHeaders());
  post = (url, body) => axios.post(`${API_URL}${url}`, body, getHeaders());
  patch = (url, body) => axios.patch(`${API_URL}${url}`, body, getHeaders());
}

const requests = new Request();

export const auth = {
  login: (username, password) =>
    requests.post("/auth/login", { username, password }),
  register: (payload) => requests.post("/auth/register", payload),
  activate: (code) => requests.post("/auth/activate", { code }),
  changePassword: (newPassword, token) =>
    requests.post("/auth/password/change-password", { newPassword, token }),
};

export const users = {
  whoami: () => requests.get("/api/users/whoami"),

  faq: {
    all: () => requests.get("/api/faq"),
    add: (payload) => requests.post("/admin/faq", payload),
    one: (id) => requests.get(`/admin/faq/${id}`),
    edit: (id, payload) => requests.patch(`/admin/faq/${id}`, payload),
    delete: (id) => requests.delete(`/admin/faq/${id}`),
  },
  usersInfo: {
    all: () => requests.get("/admin/users"),
    one: (id) => requests.get(`/admin/users/${id}`),
    block: (id) => requests.post(`/admin/users/block/${id}`),
    unblock: (id) => requests.post(`/admin/users/unblock/${id}`),
    delete: (id) => requests.delete(`/admin/users/${id}`),
    add: (payload) => requests.post("/admin/user", payload),
    edit: (id, payload) => requests.patch(`/admin/user/${id}`, payload),
  },
};

export const fieldWork = {
  all: () => requests.get(`/admin/work-type`),
  add: (payload) => requests.post(`/api/work-type`, payload),
  delete: (id) => requests.delete(`/api/work-type/${id}`),
  edit: (id, payload) => requests.patch(`/api/work-type/${id}`, payload),
};

export const qrCodes = {
  all: () => requests.get("/admin/qr"),
  add: () => requests.post("/admin/qr"),
  one: (id) => requests.get(`/admin/qr/${id}`),
  edit: (id, payload) => requests.patch(`/admin/qr/${id}`, payload),
  delete: (id) => requests.delete(`/admin/qr/${id}`),
  getImage: (id) => requests.get(`/admin/qr/image/${id}`),
};

export const shop = {
  events: {
    all: () => requests.get("/api/event/global"),
    one: (id) => requests.get(`/api/event/${id}`),
    add: (payload) => requests.post("/api/event", payload),
    edit: (id, payload) => requests.patch(`/api/event/${id}`, payload),
    delete: (id) => requests.delete(`/api/event/${id}`),
  },
  ads: {
    all: () => requests.get("/admin/ad"),
    add: (payload) => requests.post("/admin/ad", payload),
    edit: (id, payload) => requests.patch(`/admin/ad/${id}`, payload),
    delete: (id) => requests.delete(`/admin/ad/${id}`),
    one: (id) => requests.get(`/admin/ad/${id}`),
    media: (id) => requests.getSpecial(`/api/ad/media/${id}`),
    editMedia: (id, payload) =>
      requests.patch(`/admin/ad/media/${id}`, payload),
  },
  orders: {
    getAllOreders: () => requests.get("/admin/order"),
    deleteOrder: (id) => requests.delete(`/api/order/${id}`),
    getOrder: (id) => requests.get(`/api/order/${id}`),
    editOrder: (id, payload) => requests.patch(`/api/order/${id}`, payload),
    markAsSent: (id) => requests.patch(`/admin/order/shipment/${id}`),
  },
};

export const cultivations = {
  types: {
    all: () => requests.get("/api/cultivation-types"),
    add: (payload) => requests.post("/admin/cultivation-types", payload),
    one: (id) => requests.get(`/api/cultivation-types/${id}`),
    edit: (id, payload) =>
      requests.patch(`/admin/cultivation-types/${id}`, payload),
    delete: (id) => requests.delete(`/admin/cultivation-types/${id}`),
  },
  activeSubstances: {
    all: () => requests.get("/api/active-substance"),
    add: (payload) => requests.post("/admin/active-substance", payload),
    one: (id) => requests.get(`/api/active-substance/${id}`),
    edit: (id, payload) =>
      requests.patch(`/admin/active-substance/${id}`, payload),
    delete: (id) => requests.delete(`/admin/active-substance/${id}`),
  },
  stages: {
    all: (id) => requests.get(`/api/development-stage/${id}`),
    add: (id, payload) =>
      requests.post(`/admin/development-stage/${id}`, payload),
    edit: (id, payload) =>
      requests.patch(`/admin/development-stage/${id}`, payload),
    delete: (id) => requests.delete(`/admin/development-stage/${id}`),
  },
  pathogens: {
    all: (id) => requests.get(`/api/pathogen/${id}`),
    add: (id, payload) => requests.post(`/admin/pathogen/${id}`, payload),
    delete: (id) => requests.delete(`/admin/pathogen/${id}`),
    edit: (id, payload) => requests.patch(`/admin/pathogen/${id}`, payload),
  },
};

export const plantProtectionProducts = {
  getAllProducts: () => requests.get("/api/protection-product"),
  addProduct: (payload) => requests.post("/admin/protection-product", payload),
  editProduct: (id, payload) =>
    requests.patch(`/admin/protection-product/${id}`, payload),
  deleteProduct: (id) => requests.delete(`/admin/protection-product/${id}`),
  getProductById: (id) => requests.get(`/api/protection-product/${id}`),
  changeDoses: (id, payload) =>
    requests.patch(`/admin/protection-product/dosage/${id}`, payload),
};

export const globalBoxSettings = {
  getSettings: () => requests.get("/api/settings"),
  editSettings: (payload) => requests.patch("/admin/settings", payload),
};

export const push = {
  send: (payload) => requests.post("/admin/push", payload),
};
