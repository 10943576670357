import React, { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { List } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import colors from "../../constants/colors";

const useStyle = makeStyles((theme) => ({
  item: {
    marginTop: "2vh",
    marginBottom: "2vh",
  },
  noUnderline: {
    textDecoration: "none",
  },
}));

function ListItemLink(props) {
  const classes = useStyle();

  return (
    <Link to={props.to} className={classes.noUnderline}>
      <ListItem className={classes.item} button {...props} />
    </Link>
  );
}

const ListItemWhiteText = withStyles({
  root: {
    color: colors.white,
  },
})(ListItemText);

// TODO: better redirect
export const MainListItems = () => {
  const [openShop, setOpenShop] = useState(false);
  const [openPlants, setOpenPlants] = useState(false);
  const [openOrders, setOpenOrders] = useState(false);
  const [openCocoonHandling, setOpenCocoonHandling] = useState(false);
  const [openUsersHandling, setOpenUsersHandling] = useState(false);

  return (
    <List>
      {/* <ListItem button onClick={() => setOpenShop(!openShop)}>
        <ListItemWhiteText primary="Sklep" />
        {openShop ? (
          <ExpandLess style={{ color: colors.white }} />
        ) : (
          <ExpandMore style={{ color: colors.white }} />
        )}
      </ListItem>
      <Collapse in={openShop} timeout="auto" unmountOnExit>
        <List component="div" style={{ paddingLeft: "1.5em" }} disablePadding>
          <ListItemLink to={"/dashboard/categories"}>
            <ListItemWhiteText primary="Kategorie" />
          </ListItemLink>

          <ListItemLink to={"/dashboard/products"}>
            <ListItemWhiteText primary="Produkty" />
          </ListItemLink>

          <ListItemLink to={"/dashboard/availability"}>
            <ListItemWhiteText primary="Dostępność" />
          </ListItemLink>
          <ListItemLink to={"/dashboard/discounts"}>
            <ListItemWhiteText primary="Promocje" />
          </ListItemLink>
        </List>
      </Collapse> */}
      <ListItem button onClick={() => setOpenOrders(!openOrders)}>
        <ListItemWhiteText primary="Zamówienia" />
        {openOrders ? (
          <ExpandLess style={{ color: colors.white }} />
        ) : (
          <ExpandMore style={{ color: colors.white }} />
        )}
      </ListItem>
      <Collapse in={openOrders} timeout="auto" unmountOnExit>
        <ListItemLink to={"/dashboard/ordersList"}>
          <ListItemWhiteText primary="Lista zamówień" />
        </ListItemLink>
      </Collapse>

      <ListItem button onClick={() => setOpenPlants(!openPlants)}>
        <ListItemWhiteText primary="Środki ochrony roślin" />
        {openPlants ? (
          <ExpandLess style={{ color: colors.white }} />
        ) : (
          <ExpandMore style={{ color: colors.white }} />
        )}
      </ListItem>

      <Collapse in={openPlants} timeout="auto" unmountOnExit>
        <List component="div" style={{ paddingLeft: "1.5em" }} disablePadding>
          <ListItemLink to={"/dashboard/plants-protection-products"}>
            <ListItemWhiteText primary="Środki ochrony roślin - produkty" />
          </ListItemLink>
          <ListItemLink to={"/dashboard/cultivation"}>
            <ListItemWhiteText primary="Ochrona roślin" />
          </ListItemLink>
          <ListItemLink to={"/dashboard/active-substances"}>
            <ListItemWhiteText primary="Substancje czynne" />
          </ListItemLink>
          <ListItemLink to={"/dashboard/cultivationtypes"}>
            <ListItemWhiteText primary="Typy upraw" />
          </ListItemLink>
        </List>
      </Collapse>

      {/* <ListItem
        button
        onClick={() => setOpenCocoonHandling(!openCocoonHandling)}
      >
        <ListItemWhiteText primary="Obsługa kokonów" />
        {openCocoonHandling ? (
          <ExpandLess style={{ color: colors.white }} />
        ) : (
          <ExpandMore style={{ color: colors.white }} />
        )}
      </ListItem>

      <Collapse in={openCocoonHandling} timeout="auto" unmountOnExit>
        <List component="div" style={{ paddingLeft: "1.5em" }} disablePadding>
          <ListItemLink to={"/dashboard/services"}>
            <ListItemWhiteText primary="Serwisy" />
          </ListItemLink>
          <ListItemLink to={"/dashboard/cocoonAuctions"}>
            <ListItemWhiteText primary="Aukcje" />
          </ListItemLink>
        </List>
      </Collapse> */}

      <ListItem button onClick={() => setOpenUsersHandling(!openUsersHandling)}>
        <ListItemWhiteText primary="Obsługa użytkowników" />
        {openUsersHandling ? (
          <ExpandLess style={{ color: colors.white }} />
        ) : (
          <ExpandMore style={{ color: colors.white }} />
        )}
      </ListItem>

      <Collapse in={openUsersHandling} timeout="auto" unmountOnExit>
        <List component="div" style={{ paddingLeft: "1.5em" }} disablePadding>
          <ListItemLink to={"/dashboard/users"}>
            <ListItemWhiteText primary="Użytkownicy" />
          </ListItemLink>
          <ListItemLink style={{ marginBottom: 0 }} to={"/dashboard/push"}>
            <ListItemWhiteText primary="Powiadomienia push" />
          </ListItemLink>
          <ListItemLink to={"/dashboard/faq"}>
            <ListItemWhiteText primary="FAQ" />
          </ListItemLink>
        </List>
      </Collapse>
      <ListItemLink to={"/dashboard/events"}>
        <ListItemWhiteText primary="Wydarzenia" />
      </ListItemLink>
      <ListItemLink to={"/dashboard/ads"}>
        <ListItemWhiteText primary="Reklamy" />
      </ListItemLink>
      <ListItemLink to={"/dashboard/QR"}>
        <ListItemWhiteText primary="Kody QR" />
      </ListItemLink>
      <ListItemLink to={"/dashboard/workField"}>
        <ListItemWhiteText primary="Prace polowe" />
      </ListItemLink>
      <ListItemLink to={"/dashboard/cocoonGlobals"}>
        <ListItemWhiteText primary="Globalne ustawienia" />
      </ListItemLink>
    </List>
  );
};

export const logoutListItems = (
  <List style={{ height: 50, margin: 0, padding: 0 }}>
    <ListItemLink style={{ height: 50, margin: 0, padding: 0 }} to={"/logout"}>
      <ListItemIcon>
        <PowerSettingsNewIcon style={{ color: colors.white }} />
      </ListItemIcon>
      <ListItemWhiteText primary="Wyloguj" />
    </ListItemLink>
  </List>
);
