import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { users } from "../../utils/newApi";
import AuthScreen from "../../screens/AuthScreen/AuthScreen";

function PrivateRoute({ component: Component, ...rest }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (Component) {
      users
        .whoami()
        .then((res) => {
          setIsLoggedIn(true);
          setIsLoaded(true);
        })
        .catch((err) => {
          setIsLoggedIn(false);
          setIsLoaded(true);
        });
    }

    return function cleanup() {
      setIsLoaded(false);
    };
  }, [Component]);

  return isLoaded ? (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  ) : (
    <AuthScreen />
  );
}

export default PrivateRoute;
