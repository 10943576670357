import Register from "./screens/Register/Register.js";
import LoginScreen from "./screens/Login/LoginScreen";
import TermsScreen from "./screens/Terms/TermsScreen.js";
import PasswordResetScreen from "./screens/PasswordReset/PasswordResetScreen.js";
import VerificationScreen from "./screens/VerificationScreen/VerificationScreen.js";
import Dashboard from "./screens/Dashboard/Dashboard";
import Logout from "./components/Logout.js";

const routes = [
  {
    path: "/register",
    component: Register,
    exact: true,
  },
  {
    path: "/login",
    component: LoginScreen,
    exact: true,
  },
  {
    path: "/logout",
    component: Logout,
    exact: true,
  },
  {
    path: "/activate/:hash",
    component: VerificationScreen,
    exact: true,
  },
  {
    path: "/terms",
    component: TermsScreen,
    exact: true,
  },
  {
    path: "/reset",
    component: PasswordResetScreen,
    exact: true,
  },
  {
    path: "/dashboard",
    component: Dashboard,
    exact: true,
    private: true,
  },
  {
    path: "/dashboard/*",
    component: Dashboard,
    exact: true,
    private: true,
  },
  {
    path: "/",
    component: Logout,
  },
];

export default routes;
